/* eslint-disable max-len */
import Axios, { AxiosResponse } from 'axios';
import { AnyAction, Dispatch } from 'redux';
import AuthService from '../auth/auth.service';
import { REMOVE_USER } from '../../store/types';
import { ROUTES } from '../../utils/constants';

class NetworkInterceptors {
  setupInterceptors = (dispatchFunction: Dispatch<AnyAction>) => {
    // Response interceptor
    Axios.interceptors.response.use(
      (response: AxiosResponse<any>) => response,
      error => {
        const { response } = error;
        const { pathname } = window.location;
        
        if (
          !pathname.startsWith('/auth') &&
          (response?.status === 401 || response?.status === 403)
        ) {
          try {
            AuthService.logout({});
            const element = document.getElementById('ch-call-icn');
            if (element) {
              element.style.display = 'none';
            }
            dispatchFunction({ type: REMOVE_USER });
          } catch (err) {
            console.log(err.response.message);
          } finally {
            localStorage.removeItem('user_data');
            localStorage.removeItem('auth_provider');
            localStorage.removeItem('identities');
            localStorage.removeItem('workspaces_data');
            localStorage.removeItem('selected_workspace');
            localStorage.removeItem('availability_toggle');
            localStorage.removeItem('zipme-auth');
            sessionStorage.clear();
            const url = new URL(decodeURIComponent(window.location.href));
            const app = url.searchParams.get('rd_app');
            const searchState = url?.searchParams?.get('state') || '';
            if (app) url.searchParams.delete('rd_app');
            window.location.href = `${window.location.origin}/auth/${searchState?.includes('action=ziplanding') ? `${ROUTES.AUTH_TYPES.SIGN_UP}` : `${ROUTES.AUTH_TYPES.LOGIN}`}?rd_path=${url.pathname}${url.search}${app ? `&rd_app=${app}` : ''}`
          }
        }
        return Promise.reject(error);
      },
    );
  };
}
const NetworkInterceptor = new NetworkInterceptors();
export default NetworkInterceptor;
