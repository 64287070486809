import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const DateTimePickerComponent = ({
  open,
  date,
  handleDateChange,
  handleClose,
  allowPastDate,
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DateTimePicker
      defaultValue={new Date()}
      open={open}
      onOpen={() => handleClose(true)}
      onClose={() => handleClose(false)}
      value={date}
      disablePast={!allowPastDate}
      format="d MMM yyyy"
      onChange={handleDateChange}
      TextFieldComponent={() => null}
    />
  </MuiPickersUtilsProvider>
);

export default DateTimePickerComponent;
