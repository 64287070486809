/* eslint-disable import/extensions */
import { API_BASE_URL } from '../../utils/constants';
import HttpClient from '../../utils/http-client';

class CRM {
  getCRMStatusList = () => HttpClient.get(`${API_BASE_URL}/v1/crm/statuses`);

  trackCRMStatus = (data) => HttpClient.post(`${API_BASE_URL}/v1/crm/status/${data.crm_status_id}/customer/${data.customer_id}`, {})
  
  getZipteamsCrmStatusList = () => HttpClient.get(`${API_BASE_URL}/v1/crm/zipteams/crm-status`);

  addZipteamsCrmStatus = (data) => HttpClient.post(`${API_BASE_URL}/v1/crm/zipteams/crm-status`, data);
  
  updateZipteamsCrmStatus = (data) => HttpClient.put(`${API_BASE_URL}/v1/crm/zipteams/crm-status`, data);

  deleteZipteamsCrmStatus = (status_id) => HttpClient.delete(`${API_BASE_URL}/v1/crm/zipteams/crm-status/${status_id}`);
}

const CRMService = new CRM();

export default CRMService;
