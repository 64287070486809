import { useDispatch } from 'react-redux';
import { SHOW_SNACKBAR } from '../store/types';

type SnackbarPayload = {
  type: "error" | "success" | "info" | "warning",
  message: string
}
const useSnackbar = () => {
  const dispatch = useDispatch();

  const showSnackbar = (payload : SnackbarPayload) => {
    dispatch({
      type: SHOW_SNACKBAR,
      payload
    })
  }

  const showChangesSavedSnackbar = () => {
    dispatch({
      type: SHOW_SNACKBAR,
      payload: {
        type: 'success',
        noTitle: true,
        message: 'Changes saved successfully!'
      }
    })
  }

  return {showSnackbar, showChangesSavedSnackbar};
};

export default useSnackbar;
