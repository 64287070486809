/* eslint-disable arrow-body-style */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import {
  Badge,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
  withStyles,
} from '@material-ui/core';

import { differenceInMinutes, format, parseISO, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import router from 'next/router';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
// import { MdArrowDropDown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import InsightsIcon from '../../public/icons/insights-icon.svg';
import CalendarIcon from '../../public/icons/calendar.svg';
import KebabIcon from '../../public/icons/kebab-icon.svg';
import VideoIcon from '../../public/icons/video-icon.svg';
import styles from './Table.module.css';

import { ICRMStatus, ICustomers } from '../../pages/dashboard/[[...index]]';
import ArrowIcon from '../../public/icons/arrow.svg';
import AZIcon from '../../public/icons/az.svg';
// import MeetingSourceIcon from '../../public/icons/meeting-source.svg';
import { GOOGLE_MEET_BASE_URL, MEET_BASE_URL, ROWS_PER_PAGE, ZOOM_MEET_BASE_URL } from '../../utils/constants';
import Pagination from '../PaginationComponent/Pagination.component';
import {
  homePageListSortEvent,
  insightsClickEvent,
  optionsClickEvent,
  pageChangeEvent,
} from '../../lib/ga/home.events';
import { insightsLockedButtonClicked } from '../../lib/ga/onboarding.events';
import LockIcon from '../../public/assets/lock.json';
import PhoneCallIcon from '../../public/icons/phone-call.svg';
import { RootState } from '../../store/reducers';
import { PREVIOUS_PAGE, SHOW_INSIGHTS_UNLOCK_MODAL } from '../../store/types';
import Dropdown from '../Dropdown/Dropdown.component';
import MenuListComponent from '../MenuList/MenuList.component';
import ScheduleMeetingMenu from '../ScheduleMeetingMenu/schedule-meeting-menu.component';
// import FilterAssigneeListComponent from '../filter-assignee-dropdown/filter-assignee-dropdown.component';
// import FilterIntentListComponent from '../filter-intent-dropdown/filter-intent-dropdown.component';
// import FilterStatusListComponent from '../filter-status-dropdown/filter-status-dropdown.component';
// import MeetingSourceDropdown from '../meeting-source-dropdown/meeting-source-dropdown.component';
import FilterStatusListComponentNew from '../filter-status-dropdown/filter-status-dropdown-headless.component';
import FilterIntentListComponentNew from '../filter-intent-dropdown/filter-intent-dropdown-new.component';
import FilterAssigneeListComponentNew from '../filter-assignee-dropdown/filter-assignee-dropdown-new.component';
// import MenuListComponentNew from '../meeting-source-dropdown/meeting-source-dropdown-new.component';
import { formatDurationToHrsAndMins } from '../../utils/helper';
import {
  SheetTrigger,
} from '../ui/sheets';
import MeetingSourceIcon from '../../public/icons/meeting-source.svg';
import ConversationsFilter from '../conversation-filter/conversation-filter';
import AssigneeSelector from '../assignee-selector/assignee-selector';
import Pie from '../common/pie';

declare let window: Window;
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    chCall: any;
  }
}

interface IIntent {
  assigned_intent: string;
  checked: boolean;
}

export interface IMeetingSource {
  id: number;
  label: string;
  name: string;
  checked: boolean;
  latest: boolean;
  created_at: string;
}

interface IModifiedCRMList {
  name: string;
  checked: boolean;
}

interface IContactIntent {
  assigned_intent: string;
  checked: boolean;
}

export interface IAssigneeList {
  assigned_user_avatar_url: string | null;
  assigned_user_first_name: string | null;
  assigned_user_id: number; 
  assigned_user_last_name: string | null;
  assigned_user_email: string | null;
  checked: boolean;
}

interface ITableComponentProps {
  customers: Array<ICustomers>;
  count: number;
  CRMStatusList: Array<ICRMStatus>;
  handleCRMStatusChange: Function;
  handleEditCustomer: Function;
  handleDeleteCustomer: Function;
  handleShare: Function;
  handleTablePageChange: Function;
  handleTablePageSort: Function;
  handleJoinMeeting: Function;
  handleEditDate: Function;
  pageNo: number;
  sort: string | null;
  sortBy: string | null;
  isInsightsLocked: boolean;
  isWorkspaceAdmin: boolean;
  isAllConversation: boolean;
  handleAddCustomerModalOpen: Function;
  customerCount: number;
  isInSearch: boolean;
  // prospectScoreEnabled: boolean;
  meetingSource: IMeetingSource[] | any;
  selectMeetingSource: Function;
  assigneeList: IAssigneeList[] | any;
  filterAssigneeConversation: Function;
  userConnectedCallers: number;
  handleSetNextMeetingSchedule: Function;
  handleSelectModalIndex: Function;
  getCustomerContactInfo: Function;
  handleCancelScheduledMeeting: Function;
  handleSetNextMeetingDetails: Function;
  filterIntentConversation: Function;
  intentList: IIntent[];
  isContactIntentFilter: boolean;
  filterStatusConversation: Function;
  modifiedCrmList: IModifiedCRMList[] | any;
  isStatusFilter: boolean;
  qualificationQuestions: {[key:string]: any}[];
  handleFilterApply: (questions: {[key:string]: any}[], sources: IMeetingSource[]) => void
  handleFiltersReset: () => void;
  selectAssignee: (cust_id: number, id: number) => void;
  allUsers: {[key: string]: any}[];
}

// type ISortDirection = 'asc' | 'desc';

// const useStyles = makeStyles(() => ({
//   root_red: {
//     color: '#F0142F',
//   },
//   root_green: {
//     color: '#34A853',
//   },
//   root_orange: {
//     color: '#FFA959',
//   },
//   root_bg: {
//     color: '#E8E8E8',
//     position: 'relative',
//     left: '40px',
//   },
// }));

const TableComponent: React.FC<ITableComponentProps> = ({
  customers,
  count,
  pageNo,
  sort,
  sortBy,
  CRMStatusList,
  handleCRMStatusChange,
  handleEditCustomer,
  handleDeleteCustomer,
  handleShare,
  handleTablePageChange,
  handleTablePageSort,
  handleJoinMeeting,
  handleEditDate,
  isInsightsLocked,
  isWorkspaceAdmin,
  isAllConversation,
  handleAddCustomerModalOpen,
  customerCount,
  isInSearch,
  // prospectScoreEnabled,
  meetingSource,
  selectMeetingSource,
  assigneeList,
  filterAssigneeConversation,
  userConnectedCallers,
  handleSetNextMeetingSchedule,
  handleSelectModalIndex,
  getCustomerContactInfo,
  handleCancelScheduledMeeting,
  handleSetNextMeetingDetails,
  filterIntentConversation,
  intentList,
  isContactIntentFilter,
  filterStatusConversation,
  isStatusFilter,
  modifiedCrmList,
  qualificationQuestions,
  handleFilterApply,
  handleFiltersReset,
  selectAssignee,
  allUsers
}) => {
  const [customer, setCustomer] = useState<ICustomers>();
  const [optionButtonEl, setOptionButtonEl] = useState<any>(null);
  const [nextConvoOptionButtonEl, setNextConvoOptionButtonEl] =
    useState<any>(null);
  // const [meetingSourceButtonEl, setMeetingSourceButtonEl] = useState<any>(null);
  // const [filterAssigneeButtonEl, setFilterAssigneeButtonEl] =
  //   useState<any>(null);
  // const [filterIntentButtonEl, setFilterIntentButtonEl] =
  //   useState<any>(null);
  // const [filterStatusButtonEl, setFilterStatusButtonEl] =
  //   useState<any>(null);
  const [order, setOrder] = useState<any>(sort);
  const [orderBy, setOrderBy] = useState<string>(sortBy);
  const [page, setPage] = useState<number>(pageNo);

  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const { user } = useSelector((state: RootState) => state.common);

  const dispatch = useDispatch();
  // const classes = useStyles();

  useEffect(() => {
    setPage(pageNo);
  }, [pageNo]);

  useEffect(() => {
    setOrderBy(sortBy);
  }, [sortBy]);

  useEffect(() => {
    setOrder(sort);
  }, [sort]);

  const handleChange = (event, row) => {
    handleCRMStatusChange({
      customer_id: row.cust_id,
      crm_status_id: event.target.value,
    });
  };

  const CustomTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: '#14262F',
      color: '#FFFFFF',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  const handleDateModalOpen = (nextDate: string, customerId: number) => {
    handleEditDate(nextDate, customerId);
  };

  const convertToUserTimezone = (time:string) => {
    const utcTimestamp = new Date(time);
    const targetTimezone = user.timezone;
    const convertedDate = utcToZonedTime(utcTimestamp, targetTimezone);
    return format(convertedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSX", { timeZone: targetTimezone } as any);
  }

  const getFormattedDate = rawDate =>
    format(parseISO(convertToUserTimezone(rawDate)), "dd MMM''' 'yy");

  const getDaysSinceDate = date => {
    const pastDate = startOfDay(utcToZonedTime(parseISO(date), user.timezone));
    const today = startOfDay(utcToZonedTime(new Date(), user.timezone));
    const diff = today.getTime() - pastDate.getTime();
    const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return '1 day ago';
    } else {
      return `${diffDays} days ago`;
    }
  };

  const getNextMeetingCell = (
    nextMeetingAt: string,
    customerId: number,
    meetUrl: string,
    row,
  ) => {
    let meetingDate = '';
    let meetingTime = '';
    let meetingOverdue = false;
    if (nextMeetingAt) {
      meetingDate = getFormattedDate(nextMeetingAt);
      meetingTime = format(parseISO(convertToUserTimezone(nextMeetingAt)), 'hh:mm a');

      if (differenceInMinutes(new Date(), parseISO(nextMeetingAt)) > 0) {
        meetingOverdue = true;
      }
    }

    return (
      <>
        {nextMeetingAt ? (
          <div className={styles['with-icon']}>
            {meetingOverdue && (
              <Tooltip
                title="Overdue! Please reschedule."
                placement="top"
                arrow
              >
                <div style={{ width: '90px' }}>
                  <div className={styles['meeting-overdue']}>{meetingDate}</div>
                  <div
                    className={`${styles['table-cell-secondary-item']} ${styles['meeting-overdue']}`}
                  >
                    {meetingTime}
                  </div>
                </div>
              </Tooltip>
            )}
            {!meetingOverdue && (
              <div style={{ width: '90px' }}>
                <div>{meetingDate}</div>
                <div className={styles['table-cell-secondary-item']}>
                  {meetingTime}
                </div>
              </div>
            )}
            {meetUrl && (  
              <Tooltip
                title={!nextMeetingAt ? 'Please schedule the meeting first' : ''}
              >
                <div className="inline-block">
                  <Button
                    className={`${styles['action-button']} ${styles['small']}`}
                    color="primary"
                    variant="contained"
                    disableElevation
                    disableRipple
                    onClick={() => handleJoinMeeting(meetUrl)}
                    disabled={!nextMeetingAt}
                  >
                    <VideoIcon className="text-white fill-current w-3 h-3" />
                  </Button>
                </div>
              </Tooltip>
            )}
            {!isAllConversation && (
              <>
                <IconButton
                  className={`${styles['action-button-kebab']} ${styles['small']}`}
                  style={{ padding: '4px' }}
                  color="primary"
                  onClick={event => {
                    setCustomer(row);
                    setNextConvoOptionButtonEl(event.currentTarget);
                  }}
                >
                  <KebabIcon className="h-3 text-primary-dark fill-current" />
                </IconButton>
                <ScheduleMeetingMenu
                  el={nextConvoOptionButtonEl}
                  handleMenuClose={() => {
                    handleMenuClose();
                    setNextConvoOptionButtonEl(null);
                  }}
                  handleDateModalOpen={handleDateModalOpen}
                  customerInfo={customer}
                  handleCancelScheduledMeeting={handleCancelScheduledMeeting}
                  handleShare={handleShare}
                  handleSetNextMeetingDetails={handleSetNextMeetingDetails}
                />
              </>
            )}
            {/* <CalendarIcon
              onClick={() => handleDateModalOpen(nextMeetingAt, customerId)}
              className="w-4 h-4 ml-1 xl:ml-3 cursor-pointer text-icon-gray stroke-current"
            />
            <Tooltip title="Cancel meeting" placement="top" arrow>
              <div>
                <CrossIcon
                  className="ml-1 xl:ml-3 cursor-pointer"
                  onClick={() => handleCancelMeeting(customerId)}
                />
              </div>
            </Tooltip> */}
          </div>
        ) : (
          <>
            {isAllConversation ? (
              '-'
            ) : (
              <div
                role='button'
                tabIndex={0}
                className={`hover:underline cursor-pointer ${styles['with-icon']}`}
                onClick={() => handleDateModalOpen(nextMeetingAt, customerId)}
              >
                <div>Schedule Meeting</div>
                <CalendarIcon className="ml-1 xl:ml-3" />
              </div>
            )}
          </>
        )}
      </>
    );
  };

  // eslint-disable-next-line consistent-return
  const callSoftwareButton = row => {
    if (userConnectedCallers > 0 && row?.contact_phone) {
      return (
        <CustomTooltip title="Make a phone call" placement="bottom-end">
          <IconButton
            className={styles['action-button']}
            onClick={() => {
              window?.chCall(row.contact_phone);
            }}
          >
            <PhoneCallIcon className="text-white" />
          </IconButton>
        </CustomTooltip>
      );
    } else if (userConnectedCallers > 0) {
      return (
        <CustomTooltip title="Please add phone number" placement="bottom-end">
          <IconButton className={`${styles['meeting-source-button']}`}>
            <PhoneCallIcon className="text-white " />
          </IconButton>
        </CustomTooltip>
      );
    }
  };

  const handleOptionsClick = (
    event: SyntheticEvent,
    customerData: ICustomers,
  ) => {
    optionsClickEvent();
    setCustomer(customerData);
    setOptionButtonEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setOptionButtonEl(null);
  };

  const handleOnFieldSort = property => {
    homePageListSortEvent(property);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const params = {
      page_number: page,
      page_size: ROWS_PER_PAGE,
      sort: isAsc ? 'desc' : 'asc',
      sort_field: property,
    };
    handleTablePageSort({ params });
  };

  const handlePageChange = (pageNumber: number) => {
    if (page !== pageNumber) {
      const event = page > pageNumber ? 'previous' : 'next';
      pageChangeEvent(event);
    }

    setPage(pageNumber);
    const params = {
      page_number: pageNumber,
      page_size: ROWS_PER_PAGE,
      sort: order,
      sort_field: orderBy,
    };
    handleTablePageChange({ params });
  };

  const handleInsightsClick = (customerId: number) => {
    if (!isInsightsLocked) {
      insightsClickEvent();
      dispatch({ type: PREVIOUS_PAGE, payload: `${window.location.pathname}${window.location.search || ''}`})
      if (isAllConversation) {
        router.push(`/insights/details/${customerId}?admin=true`);
      } else {
        router.push(`/insights/details/${customerId}`);
      }
    }
  };

  // const handleInsightsRawClick = (customerId: number) => {
  //   router.push(`/insights/raw/${customerId}`);
  // };

  // const handleTagClick = (customerId: number) => {
  //   // router.push(`/insights/raw/${customerId}?entity=${tag}`);
  //   router.push(`/insights/details/${customerId}`);
  // };

  const getCustomerIntent = (intent: string | null) => {
    switch (intent) {
      case 'INTERESTED':
        return (
          <div className="w-[100px] h-[35px] ml-4 text-sm leading-4 text-secondary-orange font-semibold py-2 flex justify-center items-center bg-secondary-orange bg-opacity-20 rounded">
            Interested
          </div>
        );
      case 'HIGH_INTENT':
        return (
          <div className="w-[100px] h-[35px] ml-4 text-sm leading-4 text-positive-green font-semibold py-2 flex justify-center items-center bg-positive-green bg-opacity-20 rounded">
            High
          </div>
        );
      case 'NOT_INTERESTED':
      case 'LOW_INTENT':
        return (
          <div className="w-[100px] h-[35px] ml-4 text-sm leading-4 text-negative-red font-semibold py-2 flex justify-center items-center bg-negative-red bg-opacity-20 rounded">
            Low
          </div>
        );
      case 'NEUTRAL':
        return (
          <div className="w-[100px] h-[35px] ml-4 text-sm leading-4 text-neutral-orange font-semibold py-2 flex justify-center items-center bg-neutral-orange bg-opacity-20 rounded">
            Neutral
          </div>
        );
      default:
        return <div className={styles['null-state']}>-</div>;
    }
  };

  // const getProspectScoreColorClass = (score: number) => {
  //   if (score >= 0 && score <= 34) {
  //     return classes.root_red;
  //   } else if (score > 34 && score <= 67) {
  //     return classes.root_orange;
  //   } else if (score > 67 && score <= 100) {
  //     return classes.root_green;
  //   }
  //   return classes.root_red;
  // };

  // const handleMeetingSourceDropdownClose = () => {
  //   setMeetingSourceButtonEl(null);
  // };

  // const handleAssigneeListDropdownClose = () => {
  //   setFilterAssigneeButtonEl(null);
  // };

  // const handleIntentListDropdownClose = () => {
  //   setFilterIntentButtonEl(null);
  // };

  // const handleStatusListDropdownClose = () => {
  //   setFilterStatusButtonEl(null);
  // };

  const getNextSchMeetUrl = (roomId: string, passcode: string) => {
    let url;
    if (roomId?.split('-').length >= 2) {
      url = `${GOOGLE_MEET_BASE_URL}/${roomId}`
    }

    if (roomId?.includes('j/')) {
      url = `${ZOOM_MEET_BASE_URL}/${roomId}`
    }

    const regex = new RegExp('^\\d+$');

    if (regex.test(roomId) && passcode.length === 6) {
      url = `https://teams.microsoft.com/l/meetup-join/${roomId}`
    }

    if (!url?.length) {
      return `${MEET_BASE_URL}/${roomId}?org=${user?.organisation?.name}&passcode=${passcode}`;
    }

    return url;
  }

  const [selectedMeetingSources, setSelectedMeetingSources] = useState<IMeetingSource[]>([]);
  const [selectedContactIntent, setSelectedContactIntent] = useState<IContactIntent[]>([]);
  const [selectedCRMStatus, setSelectedCRMStatus] = useState<IModifiedCRMList[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<IAssigneeList[]>([]);
  const [selectedQualificationQuestions, setSelectedQualificationQuestions] = useState<{[key: string]: any}[]>([])

  const handleFilterApplyClick = () => {
    handleFilterApply(selectedQualificationQuestions, selectedMeetingSources)
  }

  useEffect(() => {
    setSelectedQualificationQuestions(qualificationQuestions);
  }, [qualificationQuestions]);

  useEffect(() => {
    setSelectedMeetingSources(meetingSource);
  }, [meetingSource]);

  useEffect(() => {
    const result = modifiedCrmList && modifiedCrmList.filter((source) => source.checked === true);
    setSelectedCRMStatus(result);
  }, [modifiedCrmList]);

  useEffect(() => {
    const result = assigneeList && assigneeList.filter((source) => source.checked === true);
    setSelectedAssignee(result);
  }, [assigneeList]);

  useEffect(() => {
    const result = intentList && intentList.filter((source) => source.checked === true);
    setSelectedContactIntent(result);
  }, [intentList]);

  // useEffect(() => {
  //   homePageListSortEvent('next_scheduled_meeting');
  //   const isAsc = showOverdueMeeting;
  //   setOrder(isAsc ? 'asc' : 'desc');
  //   setOrderBy('next_scheduled_meeting');
  //   const params = {
  //     page_number: page,
  //     page_size: ROWS_PER_PAGE,
  //     sort: isAsc ? 'asc' : 'desc',
  //     sort_field: 'next_scheduled_meeting',
  //   };
  //   handleTablePageSort({ params });
  // }, [showOverdueMeeting]);

  const meetingSourceLength = meetingSource && meetingSource.filter((source) => source.checked === true)?.length
  const questionsLength = qualificationQuestions && qualificationQuestions.filter((source) => source?.filter && source.filter?.length)?.length
 
  return (
    <>
      <div className="flex-1 relative hidden lg:block">
        <div className="h-full bottom-0 left-0 top-0 right-0 absolute whitespace-nowrap overflow-y-auto overflow-x-hidden">
          <div className={`${styles['customer-table-container']} min-h-[100%]`}>
            <Table stickyHeader className={styles['customer-table']}>
              <TableHead className={styles['table-header']}>
                <TableRow>
                  <TableCell
                    className={styles['table-header-cell']}
                    sortDirection={orderBy === 'customer_name' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'customer_name'}
                      direction={orderBy === 'customer_name' ? order : 'asc'}
                      onClick={() => handleOnFieldSort('customer_name')}
                      IconComponent={ArrowIcon}
                      classes={{
                        active: styles['table-header-text'],
                        icon: styles['table-sort-icon'],
                      }}
                      style={{ paddingLeft: '16px', flexDirection: 'row-reverse', gap: 2, color: orderBy === 'customer_name' ? '#31667f' : 'inherit' }}
                    >
                      Company/Contact Name
                      <AZIcon
                        className={`ml-1 transform scale-110 fill-current ${orderBy === 'customer_name'
                          ? 'text-primary'
                          : 'text-primary-grey'
                          }`}
                      />
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={styles['table-header-cell']}>
                  <TableSortLabel
                      active={orderBy === 'number_of_conversations'}
                      direction={
                        orderBy === 'number_of_conversations' ? order : 'asc'
                      }
                      onClick={() => handleOnFieldSort('number_of_conversations')}
                      IconComponent={ArrowIcon}
                      classes={{
                        active: styles['table-header-text'],
                        icon: styles['table-sort-icon'],
                      }}
                      style={{ flexDirection: 'row-reverse', gap: 2, color: orderBy === 'number_of_conversations' ? '#31667f' : 'inherit' }}
                      
                    >
                      No. of conversations
                    </TableSortLabel>
                  </TableCell>

                  {/* <TableCell
                    className={`${styles['table-header-cell']} ${styles['large-cell']}`}
                  >
                    Conversation Insights
                  </TableCell> */}
                  <TableCell
                    className={styles['table-header-cell']}
                    sortDirection={
                      orderBy === 'last_conversation_at' ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === 'last_conversation_at'}
                      direction={
                        orderBy === 'last_conversation_at' ? order : 'asc'
                      }
                      onClick={() => handleOnFieldSort('last_conversation_at')}
                      IconComponent={ArrowIcon}
                      classes={{
                        active: styles['table-header-text'],
                        icon: styles['table-sort-icon'],
                      }}
                      style={{ flexDirection: 'row-reverse', gap: 2, color: orderBy === 'last_conversation_at' ? '#31667f' : 'inherit' }}
                      
                    >
                      Last Conversation
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={styles['table-header-cell']}
                    sortDirection={
                      orderBy === 'next_scheduled_meeting' ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === 'next_scheduled_meeting'}
                      direction={
                        orderBy === 'next_scheduled_meeting' ? order : 'asc'
                      }
                      onClick={() =>
                        handleOnFieldSort('next_scheduled_meeting')
                      }
                      IconComponent={ArrowIcon}
                      classes={{
                        active: styles['table-header-text'],
                        icon: `${orderBy === 'next_scheduled_meeting' ? styles['table-sort-icon-active'] : styles['table-sort-icon']}`,
                      }}
                      style={{ flexDirection: 'row-reverse', gap: 2, color: orderBy === 'next_scheduled_meeting' ? '#31667f' : 'inherit' }}
                    >
                      Next Conversation
                    </TableSortLabel>
                  </TableCell>
                  {isWorkspaceAdmin && isAllConversation ? (
                    <TableCell
                      className={`${styles['table-header-cell']} ${styles['fixed-cell']}`}
                    >
                      {/* <TableSortLabel
                        active={orderBy === 'crm_status'}
                        direction={orderBy === 'crm_status' ? order : 'asc'}
                        onClick={() => handleOnFieldSort('crm_status')}
                        IconComponent={ArrowIcon}
                        classes={{
                          active: styles['table-header-text'],
                          icon: styles['table-sort-icon'],
                        }}
                      > */}
                      Assigned To
                      {/* <button
                        type="button"
                        onClick={event => {
                          setFilterAssigneeButtonEl(event.target);
                        }}
                      >
                        <MdArrowDropDown className="inline-block h-5 w-5" />
                      </button> */}
                      <FilterAssigneeListComponentNew
                        assigneeList={assigneeList}
                        filterAssigneeConversation={filterAssigneeConversation}
                      />
                      {
                        selectedAssignee && selectedAssignee?.length > 0 && 
                        <div className='inline-flex justify-center items-center w-4 h-4 bg-secondary text-white font-bold rounded-full text-xxs'>
                          {selectedAssignee?.length}
                        </div>
                      }
                      {/* <FilterAssigneeListComponent
                        el={filterAssigneeButtonEl}
                        handleMenuClose={handleAssigneeListDropdownClose}
                        assigneeList={assigneeList}
                        filterAssigneeConversation={filterAssigneeConversation}
                      /> */}
                      {/* </TableSortLabel> */}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  <TableCell
                    style={{ paddingLeft: 0 }}
                    className={`${styles['table-header-cell']} max-w-[110] p-0`}
                    sortDirection={orderBy === 'crm_status' ? order : false}
                  >
                    {/* <TableSortLabel
                      active={orderBy === 'crm_status'}
                      direction={orderBy === 'crm_status' ? order : 'asc'}
                      onClick={() => handleOnFieldSort('crm_status')}
                      IconComponent={ArrowIcon}
                      classes={{
                        active: styles['table-header-text'],
                        icon: styles['table-sort-icon'],
                      }}
                      style={{ flexDirection: 'row-reverse', gap: 2, color: orderBy === 'crm_status' ? '#31667f' : 'inherit' }}
                    >
                      
                    </TableSortLabel> */}
                    Status
                    {/* <button
                      type="button"
                      onClick={event => {
                        setFilterStatusButtonEl(event.target);
                      }}
                    >
                      <MdArrowDropDown className="inline-block h-5 w-5" />
                    </button> */}
                    <FilterStatusListComponentNew 
                      filterStatusConversation={filterStatusConversation}
                      statusList={modifiedCrmList}
                    />
                    {
                      selectedCRMStatus && selectedCRMStatus?.length > 0 && 
                      <div className='inline-flex justify-center items-center w-4 h-4 bg-secondary text-white font-bold rounded-full text-xxs'>
                        {selectedCRMStatus?.length}
                      </div>
                    }
                    {/* <FilterStatusListComponent
                      el={filterStatusButtonEl}
                      handleMenuClose={handleStatusListDropdownClose}
                      filterStatusConversation={filterStatusConversation}
                      statusList={modifiedCrmList}
                    /> */}
                  </TableCell>
                  {/* {prospectScoreEnabled && !isAllConversation && (
                    <TableCell
                      className={`${styles['table-header-cell']} ${styles['small-cell']} ${styles['center-cell']}`}
                      sortDirection={
                        orderBy === 'prospect_score' ? order : false
                      }
                    >
                      <TableSortLabel
                        active={orderBy === 'prospect_score'}
                        direction={orderBy === 'prospect_score' ? order : 'asc'}
                        onClick={() => handleOnFieldSort('prospect_score')}
                        IconComponent={ArrowIcon}
                        classes={{
                          active: styles['table-header-text'],
                          icon: styles['table-sort-icon'],
                        }}
                      >
                        Prospect Score
                      </TableSortLabel>
                    </TableCell>
                  )} */}
                  <TableCell
                    className={`${styles['table-header-cell']} ${styles['center-cell']} max-w-[110] p-0`}
                  >
                    {/* <TableSortLabel
                      active={orderBy === 'prospect_score'}
                      direction={orderBy === 'prospect_score' ? order : 'asc'}
                      onClick={() => handleOnFieldSort('prospect_score')}
                      IconComponent={ArrowIcon}
                      classes={{
                        active: styles['table-header-text'],
                        icon: styles['table-sort-icon'],
                      }}
                    >
                    </TableSortLabel> */}
                    Contact Intent
                    <FilterIntentListComponentNew
                      filterIntentConversation={filterIntentConversation}
                      intentList={intentList}
                    />
                    {
                      selectedContactIntent && selectedContactIntent?.length > 0 && 
                      <div className='inline-flex justify-center items-center w-4 h-4 bg-secondary text-white font-bold rounded-full text-xxs'>
                        {selectedContactIntent?.length}
                      </div>
                    }
                    {/* <button
                      type="button"
                      onClick={event => {
                        setFilterIntentButtonEl(event.target);
                      }}
                    >
                      <MdArrowDropDown className="inline-block h-5 w-5" />
                    </button> */}
                    {/* <FilterIntentListComponent
                      el={filterIntentButtonEl}
                      handleMenuClose={handleIntentListDropdownClose}
                      filterIntentConversation={filterIntentConversation}
                      intentList={intentList}
                    /> */}
                  </TableCell>

                  {!isAllConversation && (
                    <TableCell
                      className={styles['table-header-cell']}
                      sortDirection={
                        orderBy === 'quality_score' ? order : false
                      }
                    >
                      <TableSortLabel
                        active={orderBy === 'quality_score'}
                        direction={
                          orderBy === 'quality_score' ? order : 'asc'
                        }
                        onClick={() =>
                          handleOnFieldSort('quality_score')
                        }
                        IconComponent={ArrowIcon}
                        classes={{
                          active: styles['table-header-text'],
                          icon: `${orderBy === 'quality_score' ? styles['table-sort-icon-active'] : styles['table-sort-icon']}`,
                        }}
                        style={{ flexDirection: 'row-reverse', gap: 2, color: orderBy === 'quality_score' ? '#31667f' : 'inherit' }}
                      >
                        Quality Score
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {/* <TableCell
                    className={`${styles['table-header-cell']}`}
                    // width="14%"
                  /> */}
                  <TableCell
                    style={{ textAlign: 'right', paddingRight: '20px' }}
                    className={`${styles['table-header-cell']} ${styles['small-cell']}  ${styles['left-cell']}`}
                  >
                    <Badge 
                      badgeContent={(questionsLength ? 1 : 0) + (meetingSourceLength ? 1 : 0)} 
                      color="primary"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      style={{
                        fontSize: 8,
                      }}
                    >
                      <SheetTrigger>
                        <IconButton
                          className={`${styles['meeting-source-button']}`}
                        >
                          <MeetingSourceIcon className="" />
                        </IconButton>
                      </SheetTrigger>
                    </Badge>
                    {/* <MenuListComponentNew
                      meeting_source={meetingSource}
                      selectMeetingSource={selectMeetingSource}
                    /> */}
                    {/* <MeetingSourceDropdown
                      el={meetingSourceButtonEl}
                      handleMenuClose={handleMeetingSourceDropdownClose}
                      meeting_source={meetingSource}
                      selectMeetingSource={selectMeetingSource}
                    /> */}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {customers && customers?.length > 0 ? (
                  customers.map(row => (
                    <TableRow key={row.contact_id}>
                      <TableCell
                        className={`${styles['table-body-cell']} ${styles['fixed-name-cell']}`}
                      >
                        <div
                          className={`${styles['table-cell-primary-item']} ${styles['ellipsis']} pl-4 underline cursor-pointer`}
                          onClick={() => {
                            handleInsightsClick(row?.cust_id);
                          }}
                        >
                          {row.cust_name}
                        </div>
                        {row.cust_name !== row.contact_name && (
                          <div
                            className={`${styles['table-cell-secondary-item']} pl-4`}
                          >
                            {row.contact_name}
                          </div>
                        )}
                        {row.contact_phone && (
                          <div
                            className={`${styles['table-cell-secondary-item']} pl-4 ${row.cust_name !== row.contact_name ? '!mt-[2px]' : ''}`}
                          >
                          {row.contact_phone}
                        </div>
                        )}
                      </TableCell>
                      <TableCell className={styles['table-body-cell']}>
                        <div
                          className={`${styles['table-cell-primary-item']} ${'cursor-pointer underline'}`}
                          onClick={() => {
                            handleInsightsClick(row?.cust_id);
                          }}
                        >
                          {row.conversation_count || '0'} conversations
                        </div>
                        <div className={styles['table-cell-secondary-item']}>
                          {row.duration &&
                            formatDurationToHrsAndMins(row.duration * 60)}
                        </div>
                      </TableCell>
                      {/* <TableCell
                        className={`${styles['table-body-cell']} ${styles['large-cell']}`}
                      >
                        {row?.sentiment_analysis &&
                        Object.keys(row?.sentiment_analysis).length ? (
                          <div className={styles['tag-container']}>
                            {Object.keys(row?.sentiment_analysis)
                              .slice(0, 3)
                              .map(tag => (
                                <TopicsTag
                                  type="non-card"
                                  key={tag}
                                  text={tag}
                                  rating={
                                    row?.sentiment_analysis[tag]
                                      ?.predicted_sentiment
                                      ? Math.ceil(
                                          Number(
                                            row?.sentiment_analysis[tag]
                                              ?.predicted_sentiment,
                                          ),
                                        )
                                      : 0
                                  }
                                  occurrence={
                                    row?.sentiment_analysis[tag]
                                      ?.num_occurrences
                                      ? Math.ceil(
                                          Number(
                                            row?.sentiment_analysis[tag]
                                              ?.num_occurrences,
                                          ),
                                        )
                                      : 0
                                  }
                                  onTagClick={() => {
                                    homePageListTagClickEvent(tag);
                                    handleTagClick(row?.cust_id);
                                  }}
                                />
                              ))}
                            {Object.keys(row?.sentiment_analysis).length >
                              3 && (
                              <div
                                className="underline text-primary cursor-pointer"
                                onClick={() => {
                                  homePageListTagMoreClickEvent();
                                  handleInsightsRawClick(row?.cust_id);
                                }}
                              >
                                +
                                {Object.keys(row?.sentiment_analysis).length -
                                  3}{' '}
                                more
                              </div>
                            )}
                          </div>
                        ) : (
                          <span>-</span>
                        )}
                      </TableCell> */}
                      <TableCell className={styles['table-body-cell']}>
                        <div className={styles['table-cell-primary-item']}>
                          {(row.updated_at &&
                            getFormattedDate(row.updated_at)) ||
                            '-'}
                        </div>
                        <div className={styles['table-cell-secondary-item']}>
                          {row.updated_at &&
                            `${getDaysSinceDate(row.updated_at)}`}
                        </div>
                      </TableCell>
                      <TableCell className={styles['table-body-cell']}>
                        {getNextMeetingCell(
                          row?.schedule?.[0]?.scheduled_at ||
                          row?.cust_next_meeting_at,
                          row?.cust_id,
                          row?.schedule?.[0]?.room_id &&
                            row?.schedule?.[0]?.passcode
                            ? getNextSchMeetUrl(
                              row?.schedule?.[0]?.room_id,
                              row?.schedule?.[0]?.passcode.toString()
                            )
                            : row?.cust_meet_url,
                          row,
                        )}
                        {!isAllConversation && row?.schedule?.length > 1 && (
                          <button
                            className="text-secondary underline"
                            type="button"
                            onClick={() => {
                              handleSetNextMeetingSchedule(row);
                              handleSelectModalIndex(row?.cust_id);
                              getCustomerContactInfo(row);
                            }}
                          >
                            +{row?.schedule?.length - 1}
                          </button>
                        )}
                      </TableCell>

                      {isWorkspaceAdmin && isAllConversation ? (
                        <TableCell
                          className={`${styles['table-body-cell']} ${styles['medium-cell']}`}
                        >
                          <div className={styles['assigned-to-container']}>
                            <div className={styles['avatar']}>
                              <img
                                src={
                                  row.assigned_user_avatar_url ||
                                  '/icons/profile-default.svg'
                                }
                                alt="profile-img"
                                onError={e => {
                                  e.currentTarget.onerror = null; // prevents looping
                                  e.currentTarget.src =
                                    '/icons/profile-default.svg';
                                }}
                              />
                            </div>
                            <div className={styles['assigned-to-user']}>
                              {`${row.assigned_user_first_name} ${row.assigned_user_last_name ?? ''}`}
                            </div>
                            <AssigneeSelector 
                              allUsers={allUsers}
                              selectAssignee={(id: number) => selectAssignee(row.cust_id, id)}
                            />
                          </div>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                      
                      <TableCell
                        className={`${styles['table-body-cell']} ${styles['fixed-cell']}`}
                      >
                        <Dropdown
                          list={CRMStatusList}
                          value={row}
                          handleChange={handleChange}
                          shouldDisableUnderline
                        />
                      </TableCell>
                      
                      {/* {prospectScoreEnabled && !isAllConversation && (
                        <TableCell className={`${styles['table-body-cell']} `}>
                          {row.prospect_score ? (
                            <div className={styles['score-container']}>
                              <CircularProgress
                                variant="determinate"
                                value={100}
                                className={classes.root_bg}
                                size={40}
                                thickness={4}
                              />
                              <CircularProgress
                                variant="determinate"
                                value={row.prospect_score}
                                className={getProspectScoreColorClass(
                                  row.prospect_score,
                                )}
                                size={40}
                                thickness={4}
                              />
                              <div className={styles['score-value']}>
                                {row.prospect_score}
                              </div>
                            </div>
                          ) : (
                            <div className={styles['null-state']}>-</div>
                          )}
                        </TableCell>
                      )} */}
                      <TableCell className={`${styles['table-body-cell']} `}>
                        {getCustomerIntent(row?.intent || row?.contact_intent)}
                      </TableCell>

                      {!isAllConversation && (
                        <TableCell className={`${styles['table-body-cell']} `}>
                          <div className='flex justify-center'>
                            {row?.avg_audit_score && Number(row?.avg_audit_score) > 0 ? <Pie percentage={Number(row?.avg_audit_score)}/> : "-"}
                          </div>
                        </TableCell>
                      )}

                      <TableCell
                        className={styles['table-body-cell']}
                        align="right"
                      // width="10%"
                      >
                        {callSoftwareButton(row)}
                        <div className="relative inline-block">
                          {isInsightsLocked && (
                            <div
                              role='button'
                              tabIndex={0}
                              className="absolute left-0 right-0 top-0 bottom-0 bg-transparent cursor-pointer"
                              onClick={() => {
                                insightsLockedButtonClicked();
                                if (lottieRef.current) {
                                  lottieRef.current.goToAndPlay(0);
                                }
                                dispatch({
                                  type: SHOW_INSIGHTS_UNLOCK_MODAL,
                                  payload: {
                                    isUnlocked: !isInsightsLocked,
                                  },
                                });
                              }}
                            >
                              <Lottie
                                lottieRef={lottieRef}
                                animationData={LockIcon}
                                loop
                                autoplay
                                className="w-3.5 h-3.5 absolute -top-1 left-2"
                              />
                            </div>
                          )}
                          <Button
                            className={styles['action-button']}
                            color="primary"
                            variant="contained"
                            disableElevation
                            disableRipple
                            classes={{
                              disabled: styles['action-button-disabled'],
                            }}
                            disabled={isInsightsLocked}
                            onClick={() => handleInsightsClick(row?.cust_id)}
                          >
                            <InsightsIcon />
                          </Button>
                        </div>
                        {/* <Tooltip
                          title={
                            !row?.cust_next_meeting_at
                              ? 'Please schedule the meeting first'
                              : ''
                          }
                        >
                          <div className="inline-block">
                            <Button
                              className={styles['action-button']}
                              color="primary"
                              variant="contained"
                              disableElevation
                              disableRipple
                              onClick={() =>
                                handleJoinMeeting(row.cust_meet_url)
                              }
                              disabled={!row?.cust_next_meeting_at}
                            >
                              <VideoIcon className="text-white fill-current w-4.5 h-3" />
                            </Button>
                          </div>
                        </Tooltip> */}
                        <IconButton
                          className={styles['action-button-kebab']}
                          color="primary"
                          onClick={event => handleOptionsClick(event, row)}
                        >
                          <KebabIcon className="h-4 text-primary-dark fill-current" />
                        </IconButton>
                        <MenuListComponent
                          el={optionButtonEl}
                          handleMenuClose={handleMenuClose}
                          customerInfo={customer}
                          handleEditCustomer={handleEditCustomer}
                          handleDeleteCustomer={handleDeleteCustomer}
                        />
                      </TableCell>
                      {/* <TableCell
                        className={styles['table-body-cell']}
                        align="right"
                      /> */}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    {isContactIntentFilter || isStatusFilter ? (
                      <TableCell align="center" colSpan={10}>
                        <div className=" flex flex-col items-center my-10">
                          <img
                            src="/images/no-result-found.svg"
                            alt="No results found"
                          />
                          <p className="text-primary-dark text-xl font-semibold text-center my-5 pl-4">
                            No results found
                          </p>
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell align="center" colSpan={10}>
                        <div className=" flex flex-col items-center my-12">
                          <img
                            src="/images/no-result-found.svg"
                            alt="No customers found"
                          />
                          <p className="text-primary-dark text-xl font-bold text-center my-6 pl-4">
                            No conversations found
                          </p>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {
              (isContactIntentFilter || isStatusFilter) && !customers ? null : (
                <div className={styles['new-contact-button-container']}>
                  {(customerCount === 0 && !isInSearch) || isAllConversation ? (
                    []
                  ) : (
                    <div
                      className="flex justify-center place-items-center"
                      style={{ minHeight: '60px' }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        className={styles['add-contact-button']}
                        onClick={() => {
                          handleAddCustomerModalOpen();
                        }}
                        disableElevation
                        disableRipple
                      >
                        <span className="px-8 py-1">Add new contact</span>
                      </Button>
                    </div>
                  )}
                  {
                    customers && customers?.length > 0 && (
                      <div className="pt-5 pb-3 hidden lg:block">
                        <Pagination
                          totalCount={Math.ceil((count || 0) / ROWS_PER_PAGE)}
                          selectedPage={page}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
      </div>
      <ConversationsFilter
        isMobile={false}
        meetingSources={selectedMeetingSources}
        selectedMeetingSourcesLength={meetingSourceLength}
        setSelectedMeetingSources={(id: number) => {
          const questionsArr =  selectedMeetingSources.map(item => {
            if (item.id === id) {
              return {
                ...item,
                checked: !item.checked
              }
            }
            return item;
          });
          setSelectedMeetingSources([...questionsArr]);
        }}
        qualificationQuestions={selectedQualificationQuestions}
        selectedQualificationQuestionsLength={questionsLength}
        setSelectedQualificationQuestions={(id, value, type) => {
          const getFilterVal = (val) => {
            let filter = [...val];
            if (type === 'number_min') {
              filter[0] = value;
            } else if (type === 'number_max') {
              filter[1] = value;
            } else if (type === 'boolean' || type === 'bool') {
              filter = [value]; 
            } else if (Array.isArray(value)){
              filter = value;
            } else {
              filter = [];
            }
            return filter;
          }
          const questionsArr = selectedQualificationQuestions.map(item => {
            if (item._id === id) {
              const filter = getFilterVal(item?.filter || []);
              return { 
                ...item, 
                filter
              }
            }
            return item;
          });
          setSelectedQualificationQuestions([...questionsArr]);
        }}
        handleFiltersApply={handleFilterApplyClick}
        handleFiltersReset={handleFiltersReset}
      />
    </>
  );
};

export default TableComponent;
