/* eslint-disable import/extensions */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initStore = (preloadedState = {}) => {
  const middleware = [thunk];
  const enhancers = [];

  if (
    process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window['__REDUX_DEVTOOLS_EXTENSION__']
  ) {
    const devToolsExtension = window['__REDUX_DEVTOOLS_EXTENSION__'];

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composeEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  const store = createStore(rootReducer, preloadedState, composeEnhancers);
  return store;
};

export default initStore;
