import copy from 'copy-to-clipboard';
import { parseISO, add, format } from 'date-fns';
import ConversationsService from '../services/conversations/conversations.service';

import CRMService from '../services/crm/crm.service';
import CustomerService from '../services/customer/customer.service';
import OrganisationService from '../services/organisation/organisation.service';
import UserService from '../services/user/user.service';
import MeetingService from '../services/meeting/meeting.service';
import { getGoogleCalendarTemplate, getDeviceTimeZone } from '../utils/helper';

const getDetailsForShareBody = meetingDetails => {
  const user = JSON.parse(localStorage.getItem('user_data'));
  const mLink = `Meeting Room URL: ${meetingDetails?.meeting_url}`;
  const mCode = !meetingDetails?.meeting_url?.includes("google") ? `Room Passcode: ${meetingDetails?.meeting_passcode}` : null;
  const mDateTime = `Date and Time: ${format(
    parseISO(meetingDetails?.next_meeting),
    'do MMM yyyy, hh:mm a',
  )} ${getDeviceTimeZone()}`;
  return { user, mLink, mCode, mDateTime };
};

const beautifiedDomain = (email: string): string => {
  const domain = email.split('@')[1].split('.')[0];
  return domain[0].toUpperCase() + domain?.substring(1, domain.length);
};

const getShareDetailsBody = (customer, user, mLink, mCode, mDateTime) =>
  `Dear ${customer?.contacts?.[0]?.name
  // eslint-disable-next-line max-len
  },\n\nThis is our private meeting room. The room is easy to access from your laptop or mobile and fully secure.\n\nPlease note the meeting room details below:\n\n${mDateTime}\n${mLink}\n${mCode}\n\nLook forward to speaking to you soon.\n\nWarm Regards,\n${user.first_name
  } ${user?.last_name || ""}\n${beautifiedDomain(
    user.email,
  )}\n\nPS: This meeting room can be used best in Chrome, Firefox and Safari browsers on both desktop and mobile devices.`;

const getShareCancelledMeetingDetailsBody = (customer, user, mLink, mCode, mDateTime) =>
  `Dear ${customer?.contacts?.[0]?.name || customer?.cust_name
  // eslint-disable-next-line max-len
  },\n\nMeeting scheduled with you as per the below details has been cancelled.\n\n${mDateTime}\n\nStatus: Cancelled\n\nLook forward to connecting with you later sometime.\n\nWarm Regards,\n${user.first_name
  } ${user?.last_name || ""}\n${beautifiedDomain(
    user.email,
  )}\n\n`;

const getShareDetails = (customer, meetingDetails) => {
  const { user, mLink, mCode, mDateTime } =
    getDetailsForShareBody(meetingDetails);
  const subject = `${beautifiedDomain(user.email)} - ${customer?.name
    } Personal Meeting Room`;
  const body = getShareDetailsBody(customer, user, mLink, mCode, mDateTime);
  return { subject, body };
};

const getShareCancelledMeetingDetails = (customer, meetingDetails) => {
  const { user, mLink, mCode, mDateTime } =
    getDetailsForShareBody(meetingDetails);
  const subject = `${beautifiedDomain(user.email)} - ${customer?.name
    } Personal Meeting Room`;
  const body = getShareCancelledMeetingDetailsBody(customer, user, mLink, mCode, mDateTime);
  return { subject, body };
};

const getShareAudioDetails = (customer, meetingDetails, audioUrl) => {
  const subject = 'Zipteams Meeting Recording Link - Valid for next 6 hours';
  const body = `Conversation With: ${customer?.contacts[0]?.name} ${customer?.contacts[0]?.name !== customer?.name ? `- ${customer?.name}` : ''
    }\nConversation Date: ${format(
      parseISO(meetingDetails),
      'do MMM yyyy',
    )}\nConversation Time: ${format(
      parseISO(meetingDetails),
      'hh:mm a',
    )} ${getDeviceTimeZone()}\nDownload Link: ${audioUrl}\n\nPlease note: This link is valid for the next 6 hours only.`;
  return { subject, body };
};

export const addCustomer = async (formValues, workspaceId: number) => {
  const { customerName, primaryContactName, primaryContactEmail, phoneNumber } = formValues;
  const param = {
    customer_name: customerName,
    primary_contact_name: primaryContactName,
    primary_contact_email: primaryContactEmail,
    primary_contact_phone: phoneNumber,
    generate_meeting: true,
  };

  await CustomerService.addCustomer(param, workspaceId);
};

export const editCustomer = async (formValues, selectedCustomer) => {
  const {
    customerName,
    primaryContactName,
    primaryContactEmail,
    designation,
    phoneNumber,
    linkedInUrl,
  } = formValues;
  const param = {
    customer_name: customerName,
    primary_contact_name: primaryContactName,
    primary_contact_email: primaryContactEmail,
    primary_contact_designation: designation,
    primary_contact_phone: phoneNumber,
    primary_contact_linkedin_url: linkedInUrl,
    contact_id: selectedCustomer?.contact_id,
    customer_id: selectedCustomer?.cust_id,
  };
  await CustomerService.editCustomer(param);
};

export const deleteCustomer = async customerId => {
  await CustomerService.deleteCustomer(customerId);
};

export const getCRMStatus = async () => {
  const response = await CRMService.getCRMStatusList();
  const { data } = response?.data;
  return data;
};

export const getZipteamsCrmStatus = async () => {
  const response = await CRMService.getZipteamsCrmStatusList();
  const { data } = response?.data;
  return data;
};

export const addZipteamsCrmStatus = async payload => {
  const response = await CRMService.addZipteamsCrmStatus(payload);
  const { data } = response?.data;
  return data;
};

export const updateZipteamsCrmStatus = async payload => {
  const response = await CRMService.updateZipteamsCrmStatus(payload);
  const { data } = response?.data;
  return data;
};

export const deleteZipteamsCrmStatus = async status_id => {
  const response = await CRMService.deleteZipteamsCrmStatus(status_id);
  const { data } = response?.data;
  return data;
};

export const getMetrics = async (workspace_id, params) => {
  const response = await ConversationsService.getConversationMetrics(
    workspace_id,
    '',
    params
  );
  const { data } = response?.data;
  return data;
};

export const getWorkspaces = async (organisation_id: number) => {
  const response = await OrganisationService.getWorkspaces(organisation_id);
  const { data } = response?.data;
  return data;
};

export const getFeaturesList = async (organisation_id: number) => {
  const response = await OrganisationService.getFeaturesList(organisation_id);
  const { data } = response?.data;
  return data;
};

export const getSubscriptionDetails = async (organisation_id: number) => {
  const response = await OrganisationService.getSubscriptionDetails(organisation_id);
  const { data } = response?.data;
  return data;
};

export const getEnterpriseSubscriptionDetails = async (organisation_id: number) => {
  const response = await OrganisationService.getEnterpriseSubscriptionDetails(organisation_id);
  const { data } = response?.data;
  return data;
};

export const getStripeCustomerPortalLink = async (organisation_id: number) => {
  const response = await OrganisationService.getStripeCustomerPortalLink(organisation_id);
  const { data } = response?.data;
  return data;
};

export const getStripeBillingPortalUrl = async (payload) => {
  const response = await OrganisationService.getStripeBillingPortalUrl(payload);
  const { data } = response?.data;
  return data;
};

export const getOrgUsageHistory = async (startDate, endDate) => {
  const response = await OrganisationService.getOrgUsageHistory(startDate, endDate);
  const { data } = response?.data;
  return data;
};

export const getOrgFeatureFlags = async () => {
  const response = await OrganisationService.getOrgFeatureFlags();
  const { data } = response?.data;
  return data;
};

export const crmStatusChange = async param => {
  await CRMService.trackCRMStatus(param);
};

export const scheduleMeeting = async (customerId, param) => {
  const response = await CustomerService.scheduleMeeting(customerId, param);
  return response;
};

export const cancelMeeting = async (customerId, send_cancelled_event) => {
  await CustomerService.cancelMeeting(customerId, send_cancelled_event);
};

export const cancelScheduledMeeting = async (customerId, next_meeting, send_cancelled_event) => {
  await CustomerService.cancelScheduledMeeting(customerId, next_meeting, send_cancelled_event);
};

export const shareViaEmail = (customer, meetingDetails) => {
  const { subject, body } = getShareDetails(customer, meetingDetails);
  window.location.href = `mailto:${customer?.contacts && customer?.contacts[0]?.email
    }?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
};


export const shareCancelledMeetingViaEmail = (customer, meetingDetails) => {
  const { subject, body } = getShareCancelledMeetingDetails(customer, meetingDetails);
  window.location.href = `mailto:${customer?.contacts && customer?.contacts[0]?.email
    }?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
};

export const shareAudioViaEmail = (customer, meetingDetails, audioUrl) => {
  const { subject, body } = getShareAudioDetails(
    customer,
    meetingDetails,
    audioUrl,
  );
  window.location.href = `mailto:?subject=${encodeURI(
    subject,
  )}&body=${encodeURI(body)}`;
};

export const shareViaNavigator = async (customer, meetingDetails) => {
  const { subject, body } = getShareDetails(customer, meetingDetails);
  navigator
    .share({
      title: subject,
      text: body,
    })
    .catch(() => alert('Error'));
};

export const shareCancelledMeetingViaNavigator = async (customer, meetingDetails) => {
  const { subject, body } = getShareCancelledMeetingDetails(customer, meetingDetails);
  navigator
    .share({
      title: subject,
      text: body,
    })
    .catch(() => alert('Error'));
};

export const shareAudioViaNavigator = async (
  customer,
  meetingDetails,
  audioUrl,
) => {
  const { subject, body } = getShareAudioDetails(
    customer,
    meetingDetails,
    audioUrl,
  );
  navigator
    .share({
      title: subject,
      text: body,
    })
    .catch(() => alert('Error'));
};

export const shareCopyToClipboard = (customer, meetingDetails) => {
  const { body } = getShareDetails(customer, meetingDetails);
  copy(body);
};

export const shareAudioCopyToClipboard = (
  customer,
  meetingDetails,
  audioUrl,
) => {
  const { body } = getShareAudioDetails(customer, meetingDetails, audioUrl);
  copy(body);
};

export const shareCancelledMeetingCopyToClipboard = (customer, meetingDetails) => {
  const { body } = getShareCancelledMeetingDetails(customer, meetingDetails);
  copy(body);
};

export const opengCalUrl = (customer, meetingDetails) => {
  const { user, mLink, mCode, mDateTime } =
    getDetailsForShareBody(meetingDetails);
  const gCalUrl = getGoogleCalendarTemplate(
    `${beautifiedDomain(user.email)} - ${customer?.name}`,
    parseISO(meetingDetails.next_meeting),
    add(parseISO(meetingDetails.next_meeting), { hours: 1 }),
    customer?.contacts?.[0]?.email,
    getShareDetailsBody(customer, user, mLink, mCode, mDateTime),
    meetingDetails?.meeting_url,
  );
  window.open(gCalUrl, '_blank').focus();
};

export const opengCalUrlForCancelledMeeting = (customer, meetingDetails) => {
  const { user, mLink, mCode, mDateTime } =
    getDetailsForShareBody(meetingDetails);
  const gCalUrl = getGoogleCalendarTemplate(
    `${beautifiedDomain(user.email)} - ${customer?.name || customer?.contact_name}`,
    parseISO(meetingDetails.next_meeting),
    add(parseISO(meetingDetails.next_meeting), { hours: 1 }),
    customer?.contacts?.[0]?.email || customer?.contact_email,
    getShareCancelledMeetingDetailsBody(customer, user, mLink, mCode, mDateTime),
    meetingDetails?.meeting_url,
  );
  window.open(gCalUrl, '_blank').focus();
};

export const joinMeeting = url => {
  // 💀 comment: to removed before push 💀
  let newUrl = url;
  if (!url.includes('teams') && url.split('-').length >= 2) {
    const splitArray = url.split('/');
    const path = splitArray[splitArray.length - 1];
    const meetCode = path.split('?')[0]
    newUrl = `https://meet.google.com/${meetCode}`
  }
  window.open(newUrl, '_blank').focus();
};

export const updateUserMeta = async payload => {
  const user: any =
    localStorage && JSON.parse(localStorage.getItem('user_data'));
  await UserService.update(user?.id, payload);
};

export const getUserProfileData = async () => {
  const response = await UserService.getUserProfileData();
  const { data } = await response?.data;
  return data;
};

export const getUserMarkedCaptures = async (convo_id: number) => {
  const response = await ConversationsService.getUserMarkedCaptures(convo_id);
  const { data } = await response?.data;
  return data;
};

export const getConversationActionItems = async (convo_id: number) => {
  const response = await ConversationsService.getConversationActionItems(
    convo_id,
  );
  const { data } = await response?.data;
  return data;
};

export const updateProfile = async data => {
  const response = await UserService.updateProfile(data);
  return response?.data?.data;
};

export const updateProfilePicture = async data => {
  const response = await UserService.updateProfilePicture(data);
  return response?.data?.data;
};

export const connectGoogleCalender = async (data, mode:string = '') => {
  const response = await UserService.connectGoogleCalender(data, mode);
  return response?.data?.data;
};

export const connectZoomAccount = async data => {
  const response = await MeetingService.connectZoom(data);
  return response?.data?.data;
};

export const authorizeZoomAccount = async () => {
  await MeetingService.zoomAuthorize();
};

export const authorizeMsTeamsAccount = async () => {
  const response = await MeetingService.msTeamsAuthorize();
  window.location.href = response?.data?.data;
};

export const connectMsTeamsAccount = async data => {
  const response = await MeetingService.connectMsTeams(data);
  return response?.data?.data;
};

export const disconnectMsTeamsAccount = async () => {
  const response = await MeetingService.disconnectMsTeams();
  return response?.data?.data;
};

export const currentRoomName = async () => {
  const response = await MeetingService.currentRoom();
  return response?.data?.data;
};

export const updateRoom = async data => {
  let app = ''
  if (data === 'zipteams') {
    app = 'Zipteams'
  } else if (data === 'gmeet') {
    app = 'Google Meet'
  } else if (data === 'zoom') {
    app = 'Zoom'
  } else if (data==='teams') {
    app = 'MS Teams'
  }
  const response = await MeetingService.updateRoomProvider(app);
  return response?.data?.data;
};

export const connectOutlookCalender = async data => {
  const response = await UserService.connectOutlookCalender(data);
  return response?.data?.data;
};

export const disconnectGoogleCalender = async () => {
  const response = await UserService.disconnectGoogleCalender();
  return response?.data?.data;
};

export const disconnectZoomAccount = async () => {
  const response = await MeetingService.disconnectZoom();
  return response?.data?.data;
};

export const disconnectOutlookCalender = async () => {
  const response = await UserService.disconnectOutlookCalender();
  return response?.data?.data;
};

export const connectHubspotCrm = async data => {
  const response = await UserService.connectHubspotCrm(data);
  return response?.data?.data;
};

export const disconnectHubspotCrm = async () => {
  const response = await UserService.disconnectHubspotCrm();
  return response?.data?.data;
};

export const sendDataToHubspot = async data => {
  const response = await ConversationsService.sendDataToHubspot(data);

  return response?.data?.data;
};
