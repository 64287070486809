import { useEffect, useRef } from 'react';
import { Button, Modal } from '@material-ui/core';

import { format, parseISO } from 'date-fns';
import styles from './meeting-notify-modal.module.css';
import { joinMeeting } from '../../handlers/handlers';
import { GOOGLE_MEET_BASE_URL, MEET_BASE_URL } from '../../utils/constants';

type MeetingNotifyModalProps = {
  open: boolean;
  handleClose: () => void;
  meetingDetails: {
    customerName: string;
    contactName: string;
    meetingUrl: string;
    passcode: string;
    meetingSource: string;
    scheduledAt: string;
  };
};

const MeetingNotifyModal = (props: MeetingNotifyModalProps): JSX.Element => {
  const { open, handleClose, meetingDetails } = props;
  let googleMeetUrl;
  if (
    meetingDetails?.meetingUrl?.split('-').length >= 2 &&
    meetingDetails?.meetingUrl.includes(MEET_BASE_URL)
  ) {
    googleMeetUrl =
      GOOGLE_MEET_BASE_URL +
      meetingDetails?.meetingUrl
        ?.substring(MEET_BASE_URL?.length, meetingDetails?.meetingUrl?.length)
        ?.split('?')[0];
  }
  const audioRef = useRef<HTMLAudioElement>(
    new Audio('/assets/sweet_text.ogg'),
  );
  const audioPlayPromise = useRef<Promise<void> | null>(null);

  const stopAudio = () => {
    if (audioPlayPromise.current) {
      audioRef.current.pause();
      audioPlayPromise.current = null;
    }
    audioRef.current.removeEventListener('ended', loopAudio, false);
  };

  const loopAudio = () => {
    audioRef.current.currentTime = 0;
    audioPlayPromise.current = audioRef.current.play();
  };

  const handleModalClose = () => {
    stopAudio();
    handleClose();
  };

  const handleJoinMeeting = () => {
    if(googleMeetUrl)joinMeeting(googleMeetUrl);
    else joinMeeting(meetingDetails?.meetingUrl);
    handleModalClose();
  };

  useEffect(() => {
    if (meetingDetails.meetingSource === 'ZIPTEAMS_CALENDAR_REALTIME')
      audioRef.current.addEventListener('ended', loopAudio, false);
    audioPlayPromise.current = audioRef.current.play();
  }, [meetingDetails?.scheduledAt]);

  setTimeout(stopAudio, 20000);

  return (
    <Modal
      className={styles['modal']}
      open={open}
      onClose={(event, reason) =>
        reason !== 'backdropClick' ? handleClose() : () => {}
      }
      disableEscapeKeyDown
      onClick={stopAudio}
      // onFocus={stopAudio}
    >
      <div className={styles['inner-container']}>
        <div className={styles['dialog-content']}>
          <div className={styles['logo']}>
            <img src='images/zip-logo.svg' alt='zipteams-log' />
          </div>
          {meetingDetails.meetingSource === 'ZIPTEAMS_CALENDAR_REALTIME' ? (
            <div className={styles['share-title']}>
              You have a new Instant Meeting request
            </div>
          ) : (
            <div className={styles['share-title']}>
              Your meeting has started
            </div>
          )}
          <div className="flex justify-center mt-2 my-3 text-sm lg:text-base">
            {`${format(
              parseISO(meetingDetails?.scheduledAt),
              'MMM dd, yyyy',
            )} |`}

            <span>
              &nbsp;{format(parseISO(meetingDetails?.scheduledAt), 'hh:mm a')}
            </span>
          </div>
          <div className={styles['share-link']}>
            <div className={styles['link-item']}>
              <span>Company Name:</span>
              <span className={styles['code']}>
                {meetingDetails?.customerName || ''}
              </span>
            </div>
            <div className={styles['link-item']}>
              <span>Contact Name:</span>
              <span className={`${styles['code']} pl-2`}>
                {meetingDetails?.contactName || ''}
              </span>
            </div>
            <div className={styles['link-item']}>
              <span>Meeting Link:</span>
              <a
                target="_blank"
                href={googleMeetUrl || meetingDetails?.meetingUrl}
                className={`${styles['link']} pl-4`}
                rel="noreferrer"
              >
                {googleMeetUrl || meetingDetails?.meetingUrl || ''}
              </a>
            </div>
            {(!googleMeetUrl || meetingDetails?.passcode!==".") && (
              <div className={styles['link-item']}>
                <span>Passcode:</span>
                <span className={`${styles['code']} pl-10`}>
                  {meetingDetails?.passcode || ''}
                </span>
              </div>
            )}
          </div>
          <div className={styles['actions-container']}>
            <Button
              color="primary"
              variant="contained"
              className={`${styles['primary-button']}`}
              disableElevation
              disableRipple
              onClick={() => handleJoinMeeting()}
            >
              Join the meeting
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={`${styles['secondary-button']}`}
              disableElevation
              disableRipple
              onClick={() => handleModalClose()}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MeetingNotifyModal;
