import { ListItemIcon, makeStyles, Menu, MenuItem } from '@material-ui/core';

import { AiOutlineRedo } from 'react-icons/ai'
import CrossIcon from '../../public/icons/close-circle-icon.svg';
import CalendarIcon from '../../public/icons/calendar.svg';
import ResendIcon from '../../public/icons/resend-icon.svg';
import { GOOGLE_MEET_BASE_URL, MEET_BASE_URL, ZOOM_MEET_BASE_URL } from '../../utils/constants';

const useStyles = makeStyles({
  menu: {
    '& li': {
      fontSize: 12,
      fontWeight: 400,
      minHeight: 0,
    },
    border: '1px solid #efefef',
  },
});

const ScheduleMeetingMenu = props => {
  const {
    el,
    handleMenuClose,
    handleDateModalOpen,
    customerInfo,
    handleCancelScheduledMeeting,
    handleShare,
    handleSetNextMeetingDetails
  } = props;
  const classes = useStyles();
  const kebabMenuOpen = Boolean(el);

  const getMeetingUrl = (roomId: string, passcode: string, cust_meet_url?: string) => {
    let url;
    if (
      roomId?.split('-').length >= 2
    ) {
      url = `${GOOGLE_MEET_BASE_URL}/${roomId}`
    }

    if (
      roomId?.includes('j/')
    ) {
      url = `${ZOOM_MEET_BASE_URL}/${roomId}`
    }

    const regex = new RegExp('^\\d+$');

    if (regex.test(roomId) && passcode.length === 6) {
      url = `https://teams.microsoft.com/l/meetup-join/${roomId}`
    }

    if (!url?.length) {
      return `${MEET_BASE_URL}/${roomId}?passcode=${passcode}` || cust_meet_url;
    }

    return url;
  }

  return (
    <Menu
      anchorEl={el}
      open={kebabMenuOpen}
      onClose={handleMenuClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: classes.menu,
      }}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          handleShare(customerInfo?.cust_id, {
            next_meeting:
              customerInfo?.schedule?.[0]?.scheduled_at ||
              customerInfo?.cust_next_meeting_at,
            meeting_passcode:
              customerInfo?.schedule?.[0]?.passcode ||
              customerInfo?.cust_meet_passcode,
            meeting_url: customerInfo?.schedule?.[0]?.scheduled_at ?
              getMeetingUrl(customerInfo?.schedule?.[0]?.room_id, customerInfo?.schedule?.[0]?.passcode, customerInfo?.cust_meet_url)
              : customerInfo?.cust_meet_url
          });
        }}
      >
        <ListItemIcon style={{ minWidth: '30px' }}>
          <ResendIcon className="cursor-pointer text-icon-gray stroke-current" />
        </ListItemIcon>
        <span className="text-primary">Resend link</span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          handleSetNextMeetingDetails(new Date(), null, null, null);
          handleDateModalOpen(
            customerInfo?.cust_next_meeting_at,
            customerInfo?.cust_id,
          );
        }}
      >
        <ListItemIcon style={{ minWidth: '30px' }}>
          <CalendarIcon className=" cursor-pointer text-icon-gray stroke-current" />
        </ListItemIcon>
        <span className="text-primary">Schedule meeting</span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          handleSetNextMeetingDetails(
            customerInfo?.schedule?.[0]?.scheduled_at,
            null,
            customerInfo?.schedule?.[0]?.passcode,
            customerInfo?.schedule?.[0]?.room_id,
          );
          handleDateModalOpen(
            customerInfo?.schedule?.[0]?.scheduled_at,
            customerInfo?.cust_id,
            'reschedule',
          );
        }}
      >
        <ListItemIcon style={{ minWidth: '30px' }}>
          <AiOutlineRedo className="text-secondary w-5 h-5" />
        </ListItemIcon>
        <span className="text-primary">Reschedule meeting</span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          if (customerInfo?.schedule?.length) {
            handleCancelScheduledMeeting(
              true,
              customerInfo?.cust_id,
              customerInfo?.schedule?.[0]?.scheduled_at,
              customerInfo?.schedule?.[0]?.passcode ||
              customerInfo?.cust_meet_passcode,
              (customerInfo?.schedule?.[0]?.scheduled_at &&
                `${MEET_BASE_URL}/${customerInfo?.schedule?.[0]?.room_id}?passcode=${customerInfo?.schedule?.[0]?.passcode}`) ||
              customerInfo?.cust_meet_url,
            );
          } else {
            handleCancelScheduledMeeting(
              false,
              customerInfo?.cust_id,
              customerInfo?.cust_next_meeting_at,
              customerInfo?.cust_meet_passcode,
              customerInfo?.cust_meet_url,
            );
          }
        }}
      >
        <ListItemIcon style={{ minWidth: '30px' }}>
          <CrossIcon className="cursor-pointer" />
        </ListItemIcon>
        <span className="text-primary">Cancel meeting</span>
      </MenuItem>
        
    </Menu>
  );
};

export default ScheduleMeetingMenu;
