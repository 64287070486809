import { Button, Modal } from '@material-ui/core';
import styles from './CancelScheduledMeetingModal.module.css';
import CancelMeeting from '../../public/icons/cancel-meeting-modal.svg';

const CancelScheduledMeetingModal = ({
  open,
  handleClose,
  primaryClickHandler,
  secondaryClickHandler,
}) => (
  <Modal className={styles['modal']} open={open} onClose={handleClose}>
    <div className={styles['inner-container']}>
      <div className={styles['dialog-content']}>
        <div className=" flex justify-center">
          <CancelMeeting />
        </div>
        <div className={styles['title']}>
          Are you sure you want to cancel the meeting?
        </div>
        <div className={styles['action-container']}>
          <Button
            variant="outlined"
            disableElevation
            disableRipple
            className={styles['secondary']}
            onClick={secondaryClickHandler}
          >
            No
          </Button>
          <Button
            variant="contained"
            disableRipple
            disableElevation
            className={styles['primary']}
            onClick={primaryClickHandler}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);

export default CancelScheduledMeetingModal;
