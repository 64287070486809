/* eslint-disable max-len */
import { API_BASE_URL } from '../../utils/constants';
import HttpClient from '../../utils/http-client';

class Customer {
  getAllCustomers = () => HttpClient.get(`${API_BASE_URL}/v1/customer`);

  getCustomer = id => HttpClient.get(`${API_BASE_URL}/v1/customer/${id}`);

  addCustomer = (data, workspaceId) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/customer?workspaceId=${workspaceId}`,
      data,
    );

  editCustomer = data => HttpClient.put(`${API_BASE_URL}/v1/customer`, data);

  deleteCustomer = id => HttpClient.delete(`${API_BASE_URL}/v1/customer/${id}`);

  scheduleMeeting = (id, data) =>
    HttpClient.put(`${API_BASE_URL}/v1/customer/${id}/schedule`, data);

  cancelMeeting = (id, send_cancelled_event) =>
    HttpClient.delete(`${API_BASE_URL}/v1/customer/${id}/schedule?send_cancelled_event=${send_cancelled_event}`);

  cancelScheduledMeeting = (id, data, send_cancelled_event) =>
    HttpClient.delete(`${API_BASE_URL}/v1/customer/${id}/schedule/${data}?send_cancelled_event=${send_cancelled_event}`);

  getSearchSuggestions = (
    search_term: string,
    page_size: number,
    workspace_id: string,
  ) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/customer/suggestions?page_size=${page_size}&search_term=${search_term}&workspace_id=${workspace_id}`,
    );

  getSearchSuggestionsWorkspace = (
    search_term: string,
    page_size: number,
    workspace_id: string,
    manager_id?: number
  ) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/customer/suggestions/workspace/${workspace_id}?page_size=${page_size}&search_term=${search_term}${manager_id ? `&manager_id=${manager_id}` : ''}`,
    );

  getTrackedEntities = (customerId: string | string[]) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/customer/${customerId}/tracked_entities`,
    );

  getSentimentOccurences = (customerId: string | string[], params) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/customer/${customerId}/sentiment_occurences`,
      { params },
    );

  rateSentiment = (customerId: string | string[], occurenceId: string, data) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/customer/${customerId}/sentiment_occurences/${occurenceId}/feedback`,
      data,
    );

  getCustomerRecommedations = (customerId: string | string[], params = {}) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/customer/${customerId}/recommendations`,
      { params },
    );

  updateRecommendation = (
    customerId: string | string[],
    recommendationId: string,
    state: number,
  ) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/customer/${customerId}/recommendation/${recommendationId}`,
      { state },
    );
  
  updateCustomerAssignee = (customerId: number, assigneeId: number) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/customer/${customerId}/update-assignee/${assigneeId}`,
      {},
    );

  getCustomerLevelInsights = (customerId: number) => 
    HttpClient.get(
      `${API_BASE_URL}/v1/customer/${customerId}/customer-insights`
    );
}

const CustomerService = new Customer();
export default CustomerService;
