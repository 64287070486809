/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable max-len */
import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { isChrome, isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { format, isValid, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { FiUpload } from 'react-icons/fi';

import AddEditModal from '../../components/AddEditModal/AddEditModal.component';
import TableComponent, { IMeetingSource } from '../../components/TableComponent/Table.component';
import withAuthenticator from '../../hoc/withAuthenticator/withAuthenticator';
import ConversationsService from '../../services/conversations/conversations.service';
import CustomerService from '../../services/customer/customer.service';
import {
  DASHBOARD_MODALS,
  ROUTES,
  ROWS_PER_PAGE,
} from '../../utils/constants';
import styles from './common.module.css';
import CancelMeetingModal from '../../components/AddEditModal/CancelMeetingModal.component';
import DeleteModal from '../../components/AddEditModal/DeleteCustModal.component';
import PostCancelMeetingModal from '../../components/AddEditModal/PostMeetingCancel.component';
import ShareLinkModal from '../../components/AddEditModal/ShareModal.component';
import MetricsComponent from '../../components/MetricsComponent';
import MobileListComponent from '../../components/MobileListComponent/MobileList.component';
import SearchComponent from '../../components/SearchComponent/Search.component';
import DateTimePickerComponent from '../../components/datetime-picker/datetime-picker.component';
import EmptyDashboard from '../../components/empty-components/empty-dashboard';
import EmptySearch from '../../components/empty-components/empty-search';
import {
  addCustomer,
  cancelMeeting,
  cancelScheduledMeeting,
  crmStatusChange,
  deleteCustomer,
  editCustomer,
  getCRMStatus,
  getMetrics,
  getUserProfileData,
  joinMeeting,
  opengCalUrl,
  opengCalUrlForCancelledMeeting,
  scheduleMeeting,
  shareCancelledMeetingCopyToClipboard,
  shareCancelledMeetingViaEmail,
  shareCancelledMeetingViaNavigator,
  shareCopyToClipboard,
  shareViaEmail,
  shareViaNavigator,
} from '../../handlers/handlers';
import {
  addNewCustomerEvent,
  cancelNewCustomerEvent,
  closeShareModalEvent,
  copyClickShareEvent,
  deleteCustomerModalClickEvent,
  editCustomerModalClickEvent,
  homePageListErrorEvent,
  inviteClickShareEvent,
  joinMeetingClickEvent,
  removeMeetingClickEvent,
  removeMeetingConfirmedEvent,
  scheduleCalendarClickEvent,
  shareClickEvent,
  shareModalClickEvent,
} from '../../lib/ga/home.events';
import {
  HIDE_LOADER,
  IS_GCAL_CONNECTED,
  IS_OUTLOOKCAL_CONNECTED,
  REALTIME_WIDGET_AVAILABILITY,
  SAVE_USER,
  SHOW_LOADER,
  SHOW_SNACKBAR,
  TOGGLE_MULTIUPLOAD_MODAL
} from '../../store/types';
import CancelScheduledMeetingModal from '../../components/AddEditModal/CancelScheduledMeetingModal.component';
import NextMeetingShareModal from '../../components/AddEditModal/NextMeetingShareModal.component';
import AvailabilitySwitch from '../../components/AvailabilitySwitch/availability-switch.component';
import ChromeExtensionModal from '../../components/chromeextensionmodal';
import MeetingSourceDropdown from '../../components/meeting-source-dropdown/meeting-source-dropdown.component';
import NextMeetingScheduleModal from '../../components/next-meeting-schedule-modal/next-meeting-schedule-modal.component';
import PreTourComponent from '../../components/pre-tour-modal/pretour.component';
import ZipmeOnbModal from '../../components/pre-tour-modal/zipme-onb-modal.component';
import useScript from '../../hooks/useScript';
import ChromePerfIcon from '../../public/icons/chrome-perf-ss.svg';
import InfoIcon from '../../public/icons/fluent_info-24-regular.svg';
import MeetingSourceIcon from '../../public/icons/meeting-source.svg';
import MetricsIcon from '../../public/icons/metrics-icon.svg';
import CrossIcon from '../../public/icons/thin-cross.svg';
import OrganisationService from '../../services/organisation/organisation.service';
import ScheduleService from '../../services/schedule/schedule.service';
import UserService from '../../services/user/user.service';
import { RootState } from '../../store/reducers';
import { Sheet, SheetTrigger } from '../../components/ui/sheets';
import ConversationsFilter from '../../components/conversation-filter/conversation-filter';
import useSnackbar from '../../hooks/useSnackbar';

export interface ICRMStatus {
  id: number;
  name: string;
  sequence: number;
  checked?: boolean;
}

export interface ISentiments {
  predicted_sentiment: number | null;
  num_occurrences: number | null;
}

export interface NextMeetingShareModalDataType {
  next_meeting: Date | null;
  meeting_url: string | null;
  meeting_passcode: string | null;
  room_id: string | null;
}

export interface ICustomers {
  contact_id: number;
  contact_name: string;
  contact_email: string;
  conversation_count: number;
  crm_status_id: number;
  cust_id: number;
  cust_meet_passcode: string;
  cust_meet_url: string;
  cust_name: string;
  cust_next_meeting_at: string | null;
  duration: number | null;
  sentiment_analysis: Record<string, ISentiments> | null;
  contact_intent?: string | null;
  updated_at: string | null;
  assigned_user_id?: number;
  assigned_intent?: string;
  assigned_user_first_name?: string;
  assigned_user_last_name?: string;
  assigned_user_avatar_url?: string;
  prospect_score?: number;
  contact_phone?: string;
  schedule?: {
    id: number;
    scheduled_at: string;
    passcode: number;
    room_id: string;
    meet_url?: string;
  }[];
  intent?: string;
  avg_audit_score?: number;
}

interface IOverviewParam {
  params: {
    page_number: number;
    page_size: number;
    sort_field: string;
    sort: string;
    customer_id: number;
    search_date: any;
    search_term: string;
    workspace_id: string;
    meeting_source_id: Array<string>;
  };
}

export interface IDashboardContext {
  handleCRMStatusChange: Function;
  handleScheduleMeeting: Function;
  handleEditCustomer: Function;
  handleDeleteCustomer: Function;
  handleShare: Function;
  handleJoinMeeting: Function;
  handleEditDate: Function;
  handleCancelMeeting: Function;
}

export interface IDashMetrics {
  customer_involvement: number | null;
  customer_involvement_growth: number | null;
  customer_sentiment: number | null;
  customer_sentiment_growth: number | null;
  total_conversation_count: number | null;
  total_duration: number | null;
  current_week_conversation_count: number | null;
  conversation_count_growth: number | null;
  mandatory_tracked_topic_percentage: number | null;
  salesperson_involvement_growth: number | null;
  salesperson_involvement: number | null;
  conversation_duration_growth: number | null;
}

// export interface IDashMetricsLoading {
//   metricsLoading: boolean;
// }

export interface IShareModalData {
  next_meeting: string | null;
  meeting_url: string | null;
  meeting_passcode: string | null;
}

export const DashboardContext = React.createContext<IDashboardContext>({
  handleCRMStatusChange: () => {},
  handleScheduleMeeting: () => {},
  handleEditCustomer: () => {},
  handleDeleteCustomer: () => {},
  handleShare: () => {},
  handleJoinMeeting: () => {},
  handleEditDate: () => {},
  handleCancelMeeting: () => {},
});

const CustomToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#122832',
    color: '#ffffff',
    fontSize: 14,
    padding: '8px',
  },
}))(Tooltip);

const Dashboard: React.FC = () => {
  const { 
    idp, 
    userType, 
    userMeta, 
    callHippoToken, 
    isAdmin, 
    isManager, 
    user, 
    realtimeWidget
  } = useSelector(
    (state: RootState) => state.common,
  );
  const { availability } = realtimeWidget;
  const { insights_unlocked, insights_unlocked_viewed } = userMeta;

  const dispatch = useDispatch();

  const router = useRouter();
  const { asPath } = router;
  const { query } = router;
  
  const [CRMStatusList, setCRMStatusList] = useState<Array<ICRMStatus>>([]);
  const [customerList, setCustomerList] = useState<Array<ICustomers>>([]);
  const [customerCount, setCustomerCount] = useState<number>(0);
  const [addEditModalOpen, setAddEditModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState(DASHBOARD_MODALS.ADD);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [cancelMeetingModalOpen, setCancelMeetingModalOpen] =
    useState<boolean>(false);
  const [postCancelMeetingModalOpen, setPostCancelMeetingModalOpen] =
    useState<boolean>(false);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [selectedCustomerRow, setSelectedCustomerRow] = useState<ICustomers>();
  const [customerContactInfo, setCustomerContactInfo] = useState<any>({});
  const [cancelledMeetingCustomerInfo, setCancelledMeetingCustomerInfo] =
    useState<any>({});
  const [showOverdueMeeting, setShowOverdueMeeting] = useState<boolean>("show_missed_overdue_meetings_first" in user?.meta ? user?.meta?.show_missed_overdue_meetings_first : true);
  const [overviewParams, setOverviewParams] = useState<IOverviewParam>({
    params: {
      page_number: 1,
      page_size: ROWS_PER_PAGE,
      sort_field: 'next_scheduled_meeting',
      sort: showOverdueMeeting ? 'asc' : 'desc',
      customer_id: null,
      search_date: null,
      search_term: null,
      workspace_id: null,
      meeting_source_id: null,
    },
  });
  const [adminSortBy, setAdminSortBy] = useState<string | null>();
  const [adminSort, setAdminSort] = useState<string | null>();

  const [isInSearch, setIsInSearch] = useState<boolean>(false);
  const [urlSearchTerm, setUrlSearchTerm] = useState<any>('');
  const [isDateModalOpen, setIsDateModalOpen] = useState<boolean>(false);
  const [nextMeetingDate, setNextMeetingDate] = useState<Date | null>(null);
  const [selectedCustomerId, setCustomerId] = useState<number | null>(null);
  const [isFetchingOverviewData, setIsFetchingOverviewData] =
    useState<boolean>(false);
  const [showMetrics, setShowMetrics] = useState<boolean>(false);
  const [metricsData, setMetricsData] = useState<IDashMetrics | any>({});
  const [showIntroModal, setShowIntroModal] = useState<boolean>(false);
  const [isAllConversation, setIsAllConversation] = useState<boolean>(false);
  const [isWorkspaceAdmin, setIsWorkspaceAdmin] = useState<boolean>(false);
  const [shareModalData, setShareModalData] = useState<IShareModalData | null>(
    null,
  );
  const [isInsightsLocked, setIsInsightsLocked] = useState<boolean>(false);

  const [customerListAdmin, setCustomerListAdmin] = useState<Array<ICustomers>>([]);
  const [customerListAdminCount, setCustomerListAdminCount] = useState<number>(0);

  const [metricsLoading, setMetricsLoading] = useState<boolean>(true);
  const [meetingSourceButtonEl, setMeetingSourceButtonEl] = useState(null);

  const [allConversationAssigneeUsers, setAllConversationAssigneeUsers] = useState([]);
  const [checkedFilteredAssignee, setCheckedFilteredAssignee] = useState(allConversationAssigneeUsers || []);
  const [selectedAssigneeConversation, setSelectedAssigneeConversation] = useState([]);

  const [selectedMeetingSource, setSelectedMeetingSource] = useState([]);
  const [initialMeetingSource, setInitialMeetingSource] = useState<{[key: string]: any}[]>([]);
  const [checkedMeetingSource, setCheckedMeetingSource] = useState(initialMeetingSource || []);

  const [selectedStatusConversation, setSelectedStatusConversation] = useState([]);
  const [checkedFilteredStatus, setCheckedFilteredStatus] = useState([]);
  const [isStatusFilter, setIsStatusFilter] = useState<boolean>(false);

  const [searchDate, setSearchDate] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [selectedIntentConversation, setSelectedIntentConversation] = useState([]);
  const [checkedFilteredIntent, setCheckedFilteredIntent] = useState([]);
  const [isContactIntentFilter, setIsContactIntentFilter] = useState<boolean>(false);

  const [userConnectedCallers, setUserConnectedCallers] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [openNextMeetingShareModal, setOpenNextMeetingShareModal] = useState(false);
  const [isGoogleCalenderConnected, setIsGoogleCalenderConnected] = useState<boolean>(false);
  const [nextMeetingSchedule, setNextMeetingSchedule] = useState<ICustomers>();
  const [selectedModalIndex, setSelectedModalIndex] = useState(0);
  const [cancelScheduledMeetingModalOpen, setCancelScheduledMeetingModalOpen] = useState(false);
  const [scheduledMeetingDetails, setScheduledMeetingDetails] = useState({
    reschedule: false,
    customerId: null,
    nextMeeting: new Date(),
    meeting_passcode: '',
    room_id: null,
  });
  const [showZipmeOnbModal, setShowZipmeOnbModal] = useState<boolean>(false);
  const [showChromeExtensionModal, setShowChromeExtensionModal] =
    useState<boolean>(false);

  const [showChromeExtnInstallModal, setShowChromeExtnInstallModal] =
    useState<boolean>(false);
  const [isMetricsDataAdmin, setIsMetricsDataAdmin] = useState<boolean>(false);
  
  const [workspaceQualificationQuestion, setWorkspaceQualificationQuestions] = useState<{[key:string]: any}[] | []>([]);
  const [selectedQualificationQuestions, setSelectedQualificationQuestions] = useState<{capture_id: string, capture_output: any[]}[]>([])
  const [filterMeetingSources, setFilterMeetingSources] = useState<IMeetingSource[] | [] | any>([]);
  const [filterQualificationQuestions, setFilterQualificationQuestions] = useState<{[key:string]: any}[] | []>([]);
  const [allUsers, setAllUsers] = useState<{[key: string]: any}[]>([]);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

  const shouldFetch = useRef<boolean>(true);
  const { showChangesSavedSnackbar } = useSnackbar();

  const selectedWorkspace = useSelector(
    (state: RootState) => state.common.selectedWorkspace[0],
  );

  const updateUser = async () => {
    const response = await UserService.me();
    dispatch({
      type: SAVE_USER,
      payload: { ...response?.data?.data },
    });
  };

  const handleUpdateMissedOverdueMeetingFirst = async() => {
    try {
      await UserService.updateShowMissedOverdueMeetingsFirst({ status: !showOverdueMeeting });
      updateUser();
      setShowOverdueMeeting(prevState => !prevState);
      const isAsc = !showOverdueMeeting
      const params = {
        page_number: Number(query?.page_number) || 1,
        page_size: ROWS_PER_PAGE,
        sort: isAsc ? 'asc' : 'desc',
        sort_field: 'next_scheduled_meeting',
      };
      handleTablePageSort({ params });
    } catch (error) {
      console.error(error);
    }
  }
  const chromeExtensionModalIgnoreClickHandler = () => {
    setShowChromeExtensionModal(false);
    localStorage.setItem('availability_toggle', JSON.stringify({ count: 2 }));
  };

  const handleShowSnackbar = (type, message) => {
    dispatch({
      type: SHOW_SNACKBAR,
      payload: {
        type,
        message,
      },
    });
  };

  const fetchingOverviewData = (isFetching: boolean) => {
    const eventType: string = isFetching ? SHOW_LOADER : HIDE_LOADER;
    setIsFetchingOverviewData(isFetching);
    dispatch({ type: eventType });
  };

  // const checkWorkspaceAdmin = async () => {
  //   const org_id = user.organisation_id;
  //   const workspace_id = selectedWorkspace?.id;
  //   const response = await OrganisationService.getWorkspaceAdmins(
  //     org_id,
  //     workspace_id,
  //   );
  //   const workspace_admins = response?.data?.data;
  //   console.log(isAdmin);
  //   workspace_admins.forEach(entry => {
  //     if (entry['id'] === user.id) {
  //       isWorkspaceAdminRef.current = true;
  //       setIsWorkspaceAdmin(true);
  //     }
  //   });
  // };

  const setAdminOverviewCustomers = async (param: any) => {
    const workspace_id = selectedWorkspace?.id;
    try {
      fetchingOverviewData(true);
      const response = await ConversationsService.getAdminOverview(
        param,
        workspace_id,
      );
      const { data } = response?.data;
      const { list, count } = data;

      setCustomerListAdmin(list);
      setCustomerListAdminCount(count);
      fetchingOverviewData(false);
      setAdminSortBy(param?.params?.sort_field);
      setAdminSort(param?.params?.sort);
      setCustomers(list?.map(x => x?.cust_id));
    } catch (err) {
      fetchingOverviewData(false);
      homePageListErrorEvent(err.response?.data.message);
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  const getUserProfile = async () => {
    try {
      const data = await getUserProfileData();
      setIsGoogleCalenderConnected(data?.google_access);
      dispatch({
        type: IS_GCAL_CONNECTED,
        payload: data?.google_access
      });
      dispatch({
        type: IS_OUTLOOKCAL_CONNECTED,
        payload: data?.outlook_access
      });
      setUserConnectedCallers(data?.user_connected_callers?.length);
    } catch (err) {
      console.log(err);
    }
  };

  const getUserForManager = async () => {
    try {
      const response = await OrganisationService.getUsersForManager(
        user?.id,
      );
      const users = response?.data?.data;
      const ids = users.map(pUser => pUser.user_id); 
      return ids;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const queryOverview = (meetingSource, crmFilterStatus, filteredIntent, filteredAssignee, filteredQuestions, pSearchDate = '') => {
    const meetingSourceQ = (updateMeetingSource(meetingSource) && updateMeetingSource(meetingSource)?.toString()) || null;
    const assignedUserIdQ = (updateAssigneeConveration(filteredAssignee) && updateAssigneeConveration(filteredAssignee)?.toString()) || null;
    const assignedIntentQ = (updateIntentConversation(filteredIntent) && updateIntentConversation(filteredIntent)?.toString()) || null;
    const assignedStatusQ = (updateStatusConversation(crmFilterStatus) && updateStatusConversation(crmFilterStatus)?.toString()) || null; 
       
    if (asPath.includes('admin')) {
      setIsAllConversation(true);
      // checkWorkspaceAdmin();
      setIsInSearch(true);
      setUrlSearchTerm(query?.search_term);
      const params = {
        page_number: Number(query?.page_number) || 1,
          sort_field: query?.sort_field || 'last_conversation_at',
          sort: query?.sort || 'desc',
          ...(query?.search_term ? { search_term: query?.search_term } : {}),
          ...(query?.customer_id ? { customer_id: query?.customer_id } : {}),
          ...(query?.search_date ? { search_date: query?.search_date } : {}),
          ...(meetingSourceQ ? { meeting_source_id: meetingSourceQ } : {}),
          ...(assignedUserIdQ ? { assigned_user_id: assignedUserIdQ } : {}),
          ...(assignedIntentQ ? { assigned_intent: assignedIntentQ } : {}),
          ...(assignedStatusQ ? { assigned_status: assignedStatusQ } : {}),
          ...(pSearchDate || searchDate ? { last_conversation_date: pSearchDate || searchDate } : {}),
          ...(!isAdmin && isManager ? { manager_id: user.id } : {}),
          qualification_questions: filteredQuestions
      }
      setAdminOverviewCustomers({
        params,
      });
      getWorkspaceMetricsData(params)
      if (selectedWorkspace) getAllAssigneeUsers();
    } else if (query && (query?.search_term || query?.customer_id || query?.search_date)) {
      setIsInSearch(true);
      setUrlSearchTerm(query?.search_term);
      setIsAllConversation(false);
      const params = {
        page_number: Number(query?.page_number) || 1,
          sort_field: query?.sort_field || 'next_scheduled_meeting',
          sort: query?.sort || (showOverdueMeeting ? 'asc' : 'desc'),
          ...(selectedWorkspace?.['id'] ? { workspace_id: selectedWorkspace?.['id'] } : {}),
          ...(query?.search_term ? { search_term: query?.search_term } : {}),
          ...(query?.customer_id ? { customer_id: query?.customer_id } : {}),
          ...(query?.search_date ? { search_date: query?.search_date } : {}),
          ...(meetingSourceQ ? { meeting_source_id: meetingSourceQ } : {}),
          ...(assignedIntentQ ? { assigned_intent: assignedIntentQ } : {}),
          ...(assignedStatusQ ? { assigned_status: assignedStatusQ } : {}),
          ...(pSearchDate || searchDate ? { last_conversation_date: pSearchDate || searchDate } : {}),
          qualification_questions: filteredQuestions
      }
      getOverviewData({
        params,
      });
      getMetricsData(selectedWorkspace?.id, params);
    } else if (Object.keys(query).length) {
      setIsInSearch(false);
      setUrlSearchTerm('');
      setIsAllConversation(false);
      const params = {
        page_number: Number(query?.page_number) || 1,
          sort_field: query?.sort_field || 'next_scheduled_meeting',
          sort: query?.sort || (showOverdueMeeting ? 'asc' : 'desc'),
          ...(selectedWorkspace?.['id'] ? { workspace_id: selectedWorkspace?.['id'] } : {}),
          ...(query?.search_term ? { search_term: query?.search_term } : {}),
          ...(query?.customer_id ? { customer_id: query?.customer_id } : {}),
          ...(query?.search_date ? { search_date: query?.search_date } : {}),
          ...(meetingSourceQ ? { meeting_source_id: meetingSourceQ } : {}),
          ...(assignedIntentQ ? { assigned_intent: assignedIntentQ } : {}),
          ...(assignedStatusQ ? { assigned_status: assignedStatusQ } : {}),
          ...(pSearchDate || searchDate ? { last_conversation_date: pSearchDate || searchDate } : {}),
          qualification_questions: filteredQuestions 
      }
      getOverviewData({
        params,
      });
      getMetricsData(selectedWorkspace?.id, params);
    } else {
      setIsInSearch(false);
      setUrlSearchTerm('');
      setIsAllConversation(false);
      const params = {
        page_number: Number(query?.page_number) || 1,
        sort_field: 'next_scheduled_meeting',
        sort: showOverdueMeeting ? 'asc' : 'desc',
        workspace_id: selectedWorkspace?.['id'] || null,
        ...(meetingSourceQ ? { meeting_source_id: meetingSourceQ } : {}),
        ...(assignedIntentQ ? { assigned_intent: assignedIntentQ } : {}),
        ...(assignedStatusQ ? { assigned_status: assignedStatusQ } : {}),
        ...(pSearchDate || searchDate ? { last_conversation_date: pSearchDate || searchDate } : {}),  
        qualification_questions: filteredQuestions
      }
      getOverviewData({
        params,
      });
      getMetricsData(selectedWorkspace?.id, params);
    }
  }

  useScript(callHippoToken, user?.email);

  useEffect(() => {
    if (
      !insights_unlocked_viewed &&
      !insights_unlocked &&
      userType === 'free_tier'
    ) {
      setIsInsightsLocked(true);
    }
  }, [insights_unlocked, insights_unlocked_viewed, userType]);

  const getFilteredIntentFromURL = () => {
    const existingIntent = window && window.sessionStorage.getItem('filtered_intent') ? JSON.parse(window.sessionStorage.getItem('filtered_intent')) : [];
    const intentList = [
      {
        assigned_intent_value: 'HIGH_INTENT',
        assigned_intent: "High",
        checked: false,
      },
      {
        assigned_intent_value: 'INTERESTED',
        assigned_intent: "Interested",
        checked: false,
      },
      {
        assigned_intent_value: 'NEUTRAL',
        assigned_intent: "Neutral",
        checked: false,
      },
      {
        assigned_intent_value: 'LOW_INTENT',
        assigned_intent: "Low",
        checked: false,
      },
    ];
    let newIntent = [];
    if (query.intent_filter) {
      const filterArr = query.intent_filter.toString().split(',');
      newIntent = intentList.map(intent => {
        if (filterArr.includes(intent.assigned_intent_value)) {
          return {
            ...intent,
            checked: true
          }
        }
        return intent;
      });
      window.sessionStorage.setItem('filtered_intent', JSON.stringify(newIntent));
      // const { intent_filter, ...rest } = query;
      // router.replace({
      //   query: { ...rest }
      // })
    }
    return newIntent || existingIntent;
  }

  const getSearchDateFromURL = () => {
    const existingIntent = window && window.sessionStorage.getItem('filtered_date') ? JSON.parse(window.sessionStorage.getItem('filtered_date')) : [];
    if (query?.search_param && isValid(new Date(query?.search_param.toString()))) {
      const dates = JSON.stringify([new Date(query?.search_param.toString()), new Date(query?.search_param.toString())]);
      setSearchDate(dates);
      setStartDate(new Date(query?.search_param.toString()));
      setEndDate(new Date(query?.search_param.toString()));
      window.sessionStorage.setItem('filtered_date', JSON.stringify(dates));
      return dates; 
    }
    return '';
  }

  useEffect(() => {
    if (searchDate === null) {
      window.sessionStorage.removeItem('filtered_date');
    }
    if (!searchDate) {
      setStartDate(null);
      setEndDate(null);
    } else {
      window.sessionStorage.setItem('filtered_date', JSON.stringify(JSON.parse(searchDate)));
    }
  }, [searchDate])

  useEffect(() => {
    if (!shouldFetch.current || !selectedWorkspace?.id) {
      shouldFetch.current = true;
      return;
    }
    const existingSource = window && window.sessionStorage.getItem('meeting_source') ? JSON.parse(window.sessionStorage.getItem('meeting_source')) : [];
    const existingStatus = window && window.sessionStorage.getItem('filtered_status') ? JSON.parse(window.sessionStorage.getItem('filtered_status')) : [];
    const existingIntent = window && window.sessionStorage.getItem('filtered_intent') ? JSON.parse(window.sessionStorage.getItem('filtered_intent')) : [];
    const existingAssignee = window && window.sessionStorage.getItem('filtered_assignee') ? JSON.parse(window.sessionStorage.getItem('filtered_assignee')) : [];
    const existingQualifications = window && window.sessionStorage.getItem('qualification_questions') ? JSON.parse(window.sessionStorage.getItem('qualification_questions')) : [];
    const existingDates = window && window.sessionStorage.getItem('filtered_date') ? JSON.parse(window.sessionStorage.getItem('filtered_date')) : '';
    
    const meetingSource = checkedMeetingSource?.length ? checkedMeetingSource : existingSource;
    const crmFilterStatus = checkedFilteredStatus?.length ? checkedFilteredStatus : existingStatus;
    let filteredIntent = checkedFilteredIntent?.length ? checkedFilteredIntent : existingIntent;
    const filteredAssignee = checkedFilteredAssignee?.length ? checkedFilteredAssignee : existingAssignee;
    const filteredQuestions = selectedQualificationQuestions?.length ? selectedQualificationQuestions : getQualificationQuestionFormat(existingQualifications || []);
    let filterDates = searchDate?.length ? searchDate : existingDates ? JSON.stringify(existingDates) : '';
    
    if (query?.intent_filter) {
      filteredIntent = getFilteredIntentFromURL();
    }

    if (query?.search_param) {
      filterDates = getSearchDateFromURL();
    }

    if (query?.intent_filter && query?.search_param) {
      // const url = new URL(window.location.href);
      // url.searchParams.delete('intent_filter');
      // url.searchParams.delete('search_param');
      // window.history.pushState(null, document.title, url);
      const { intent_filter, search_param, ...rest } = query;
      router.replace({
        query: { ...rest}
      });
      shouldFetch.current = false;
    }

    queryOverview(meetingSource, crmFilterStatus, filteredIntent, filteredAssignee, filteredQuestions, filterDates);
  }, [
    query,
    asPath,
    selectedAssigneeConversation,
    selectedIntentConversation,
    selectedStatusConversation,
    searchDate,
    selectedWorkspace
  ]);

  // useEffect(() => {
  //   getWorkspacesData();
  // }, [selectedWorkspace]);

  useEffect(() => {
    if (query?.action === 'ziplanding' && !user?.meta?.onboarding_viewed) {
      setShowZipmeOnbModal(true);
    } else if (!user?.meta?.onboarding_viewed) {
      setShowIntroModal(true);
    }
  }, [query])

  // useEffect(() => {
  //   if (asPath.includes('admin')) {
  //     if (!(metricsData && Object.keys(metricsData).length) || !isMetricsDataAdmin) {
  //       getWorkspaceMetricsData()
  //     }
  //   } else {
  //     if (!(metricsData && Object.keys(metricsData).length) || isMetricsDataAdmin) {
  //       getMetricsData(selectedWorkspace?.id);
  //     }
  //   }
  // }, [asPath])

  const getMetricsData = async (workspace_id, params) => {
    try {
      setMetricsLoading(true);
      const data = await getMetrics(workspace_id, params);
      const keys = params && Object.keys(params);
      const filterKeys = ["qualification_questions", "last_conversation_date", "assigned_intent", "meeting_source_id", "assigned_status", "assigned_user_id"];
      const filtersApplied = keys.some(key => 
        filterKeys.includes(key) && params[key] && 
        (key !== 'qualification_questions' || (params[key] && params[key].length))
      );
      setIsFilterApplied(filtersApplied);
      setMetricsData(data);
      setIsMetricsDataAdmin(false);
      setMetricsLoading(false);
    } catch (err) {
      handleShowSnackbar('error', err.response?.data.message);
      setMetricsLoading(false);
    }
  };

  const getWorkspaceMetricsData = async (params) => {
    try {
      setMetricsLoading(true);
      const workspace_id = selectedWorkspace?.id;
      const response =  await ConversationsService.getWorkspaceConversationMetrics(
        workspace_id,
        params,
      );
      const keys = params && Object.keys(params);
      const filterKeys = ["qualification_questions", "last_conversation_date", "assigned_intent", "meeting_source_id", "assigned_status", "assigned_user_id"];
      const filtersApplied = keys.some(key => 
        filterKeys.includes(key) && params[key] && 
        (key !== 'qualification_questions' || (params[key] && params[key].length))
      );
      setIsFilterApplied(filtersApplied);
      setMetricsData(response?.data?.data);
      setIsMetricsDataAdmin(true);
      setMetricsLoading(false);
    } catch (err) {
      handleShowSnackbar('error', err.response?.data.message);
      setMetricsLoading(false);
    }
  };

  // const getWorkspacesData = async () => {
  //   try {
  //     const data = await getWorkspaces(user?.organisation.id);
  //     const selected_workspace = data.filter((item)=>item?.id === user?.workspace_preference_id);

  //     localStorage.setItem('selected_workspace', JSON.stringify([selected_workspace?.[0]]));
  //     await UserService.setPreferredWorkspace(selected_workspace?.[0]?.id);
  //   } catch (err) {
  //     handleShowSnackbar('error', err.response?.data.message);
  //   }
  // };

  const getCustomersNextSchedules = async () => {
    try {
      const isAllConversations = asPath.includes('admin');
      const response = await ScheduleService.customersNextSchedule({customerId: customers, wpLevel: isAllConversations});

      const customersArr = [];
      const list = isAllConversations ? customerListAdmin : customerList;

      for (let i = 0; i < list.length; i++) {
        customersArr.push({
          ...list?.[i],
          ...response?.data?.data?.find(
            item => item?.cust_id === list?.[i]?.cust_id,
          ),
        });
      }

      if (isAllConversations) {
        setCustomerListAdmin(customersArr)
      } else {
        setCustomerList(customersArr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOverview = async (param: any) => {
    try {
      fetchingOverviewData(true);
      const response = await ConversationsService.getOverview(param);
      const { data } = response?.data;
      const { list, count } = data;
      setCustomerList(list);
      setCustomerCount(count);
      fetchingOverviewData(false);
      setCustomers(list?.map(x => x?.cust_id));
    } catch (err) {
      fetchingOverviewData(false);
      homePageListErrorEvent(err.response?.data.message);
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  useEffect(() => {
    if (customers?.length) {
      getCustomersNextSchedules();
    }
  }, [customers]);

  const handleTablePageChange = async param => {
    const { params } = param;
    const newParams = { ...overviewParams.params, ...params };
    const payload = Object.keys(newParams)
      .filter(k => newParams[k] != null)
      .reduce((a, k) => ({ ...a, [k]: newParams[k] }), {});
    const { ...rest }: any = payload;
    if (isAllConversation && (isWorkspaceAdmin || isManager)) {
      router.push({
        pathname: `/${ROUTES.ALLCONVERSATIONS}`,
        query: {
          ...rest,
        },
      });
    } else {
      router.push({
        pathname: `/${ROUTES.DASHBOARD}`,
        query: {
          ...rest,
        },
      });
    }

    // await getOverviewData(param);
  };

  const handleTablePageSort = async param => {
    const { params } = param;
    const newParams = { ...overviewParams.params, ...params };
    const payload = Object.keys(newParams)
      .filter(k => newParams[k] != null)
      .reduce((a, k) => ({ ...a, [k]: newParams[k] }), {});
    const { ...rest }: any = payload;
    if (isAllConversation && (isWorkspaceAdmin || isManager)) {
      router.push({
        pathname: `/${ROUTES.ALLCONVERSATIONS}`,
        query: {
          ...rest,
        },
      });
    } else {
      router.push({
        pathname: `/${ROUTES.DASHBOARD}`,
        query: {
          ...rest,
        },
      });
    }

    // await getOverviewData(param);
  };

  const handleAddSumbit = async formValues => {
    try {
      await addCustomer(formValues, selectedWorkspace.id);
      showChangesSavedSnackbar();
      addNewCustomerEvent();
      setAddEditModalOpen(!addEditModalOpen);
      if (isInSearch) {
        if (isAllConversation && (isWorkspaceAdmin || isManager)) {
          router.push(`/${ROUTES.ALLCONVERSATIONS}`);
        } else {
          router.push(`/${ROUTES.DASHBOARD}`);
        }
      } else {
        await getOverview(overviewParams);
      }
    } catch (err) {
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  const handleEditSubmit = async formValues => {
    try {
      await editCustomer(formValues, selectedCustomerRow);
      showChangesSavedSnackbar();
      setAddEditModalOpen(!addEditModalOpen);
      if (isInSearch) {
        if (isAllConversation && (isWorkspaceAdmin || isManager)) {
          router.push(`/${ROUTES.ALLCONVERSATIONS}`);
        } else {
          router.push(`/${ROUTES.DASHBOARD}`);
        }
      } else {
        await getOverview(overviewParams);
      }
    } catch (err) {
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  const handleCRMStatusChange = async param => {
    dispatch({ type: 'SHOW_LOADER' });
    try {
      await crmStatusChange(param);
      showChangesSavedSnackbar();
      dispatch({ type: 'HIDE_LOADER' });
    } catch (err) {
      dispatch({ type: 'HIDE_LOADER' });
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  const handleScheduleMeeting = async param => {
    try {
      dispatch({ type: 'SHOW_LOADER' });
      const response = await scheduleMeeting(param.customer_id, param);
      if (isAllConversation && (isWorkspaceAdmin)) {
        const user_data: any =
          localStorage && JSON.parse(localStorage.getItem('user_data'));
        const workspace_admin_id = user_data?.id;
        await scheduleMeeting(workspace_admin_id, param);
      }
      const { data } = response?.data;
      const { meet_url, passcode, scheduled_at } = data;
      const shareData = {
        next_meeting: scheduled_at,
        meeting_passcode: passcode,
        meeting_url: meet_url,
      };
      handleShareModalOpen(param.customer_id, shareData);
      dispatch({ type: 'HIDE_LOADER' });
      await getOverview(overviewParams);
    } catch (err) {
      dispatch({ type: 'HIDE_LOADER' });
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  const handleCancelMeeting = async () => {
    try {
      removeMeetingConfirmedEvent();

      await cancelMeeting(selectedCustomerId, !!isGoogleCalenderConnected);
      handleCancelmeetingModalClose();
      setPostCancelMeetingModalOpen(true);
      if (isInSearch) {
        if (isAllConversation && isWorkspaceAdmin) {
          router.push(`/${ROUTES.ALLCONVERSATIONS}`);
        } else {
          router.push(`/${ROUTES.DASHBOARD}`);
        }
      } else {
        await getOverview(overviewParams);
      }
    } catch (err) {
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  const handleCancelScheduledMeetingModalOpen = (
    reschedule = false,
    customerId?,
    nextMeeting?,
    meeting_passcode?,
    meet_room?,
  ) => {
    setCancelScheduledMeetingModalOpen(true);
    setScheduledMeetingDetails({
      reschedule,
      customerId: customerId || nextMeetingSchedule?.cust_id,
      nextMeeting: nextMeeting || nextMeetingShareModalData?.next_meeting,
      meeting_passcode:
        meeting_passcode || nextMeetingShareModalData?.meeting_passcode,
      room_id: meet_room || nextMeetingShareModalData?.meeting_url,
    });
  };

  const handleCancelScheduledMeetingModalClose = () => {
    setCancelScheduledMeetingModalOpen(false);
  };

  const getCancelledMeetingCustomerInfo = async () => {
    try {
      const response = await CustomerService.getCustomer(
        scheduledMeetingDetails.customerId,
      );
      const { data } = response?.data;
      setCancelledMeetingCustomerInfo(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelScheduledMeeting = async () => {
    try {
      handleCancelScheduledMeetingModalClose();
      removeMeetingConfirmedEvent();
      getCancelledMeetingCustomerInfo();
      if (scheduledMeetingDetails?.reschedule) {
        await cancelScheduledMeeting(
          scheduledMeetingDetails?.customerId,
          scheduledMeetingDetails?.nextMeeting,
          !!isGoogleCalenderConnected,
        );
      } else {
        await cancelMeeting(
          scheduledMeetingDetails?.customerId,
          !!isGoogleCalenderConnected,
        );
      }
      handleCancelmeetingModalClose();
      if (!isGoogleCalenderConnected) {
        setPostCancelMeetingModalOpen(true);
      }

      await getOverview(overviewParams);
    } catch (err) {
      handleCancelScheduledMeetingModalClose();
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  const handleDeleteCustomer = async () => {
    try {
      handleDeleteCustomerModalClose();
      await deleteCustomer(selectedCustomerRow?.cust_id || '');
      if (isInSearch) {
        if (isAllConversation && (isWorkspaceAdmin || isManager)) {
          router.push(`/${ROUTES.ALLCONVERSATIONS}`);
        } else {
          router.push(`/${ROUTES.DASHBOARD}`);
        }
      } else {
        await getOverview(overviewParams);
      }
    } catch (err) {
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  const handleAddCustomerModalOpen = () => {
    setModalType(DASHBOARD_MODALS.ADD);
    setAddEditModalOpen(true);
  };

  const handleEditCustomerModalOpen = async (row: ICustomers) => {
    try {
      editCustomerModalClickEvent();
      setModalType(DASHBOARD_MODALS.EDIT);
      setSelectedCustomerRow(row);
      await getCustomerContactInfo(row);
      setAddEditModalOpen(true);
    } catch (err) {
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  const getCustomerContactInfo = async (row: ICustomers) => {
    const response = await CustomerService.getCustomer(row?.cust_id);
    const { data } = response?.data;
    setCustomerContactInfo(data);
  };

  const handleAddEditModalClose = () => {
    cancelNewCustomerEvent();
    setAddEditModalOpen(false);
  };

  const handleDeleteCustomerModalOpen = (row: ICustomers) => {
    deleteCustomerModalClickEvent();
    setSelectedCustomerRow(row);
    setDeleteModalOpen(true);
  };

  const handleDeleteCustomerModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleShareModalOpen = async (
    cust_id: number,
    shareMeetingData: IShareModalData,
  ) => {
    try {
      shareModalClickEvent();
      setShareModalData(shareMeetingData);
      const response = await CustomerService.getCustomer(cust_id);
      const { data } = response?.data;
      setCustomerContactInfo(data);
      setShareModalOpen(true);
    } catch (err) {
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  const [nextMeetingShareModalData, setNextMeetingShareModalData] =
    useState<NextMeetingShareModalDataType>({
      next_meeting: new Date(),
      meeting_url: '',
      meeting_passcode: '',
      room_id: null,
    });

  const handleSetNextMeetingDetails = (
    next_meeting = null,
    meeting_url = null,
    meeting_passcode = null,
    room_id = null,
  ) => {
    setNextMeetingShareModalData({
      next_meeting,
      meeting_url,
      meeting_passcode,
      room_id,
    });
  };

  const handleShareModalClose = (): void => {
    closeShareModalEvent();
    setShareModalOpen(false);
  };

  // const handleShareModalOpen=()=>{
  //   setShareModalOpen(true)
  // }

  const handleDateModalOpen = (
    nextDate: Date | null,
    customerId: number,
    reschedule?: string,
  ) => {
    scheduleCalendarClickEvent();
    if (reschedule) {
      setNextMeetingDate(nextMeetingShareModalData?.next_meeting);
    } else {
      setNextMeetingDate(nextDate);
    }

    setCustomerId(customerId);
    setIsDateModalOpen(true);
  };

  const handleCancelMeetingModalOpen = (customerId: number) => {
    removeMeetingClickEvent();
    setCancelMeetingModalOpen(true);
    setCustomerId(customerId);
  };

  const handleCancelmeetingModalClose = () => {
    setCancelMeetingModalOpen(false);
  };

  const handlePostCancelMeetingModalClose = () => {
    setPostCancelMeetingModalOpen(false);
    setScheduledMeetingDetails({
      reschedule: false,
      customerId: null,
      nextMeeting: new Date(),
      meeting_passcode: '',
      room_id: null,
    });
  };

  const handleShareModalEmail = (reschedule?): void => {
    shareClickEvent();
    if (window) {
      if (reschedule) {
        shareViaEmail(customerContactInfo, reschedule);
      } else {
        shareViaEmail(customerContactInfo, shareModalData);
      }
    }
  };

  const handleShareCancelledMeetingModalEmail = (reschedule?): void => {
    const cancelledMeetingDetails = {
      next_meeting: scheduledMeetingDetails?.nextMeeting,
      meeting_url: scheduledMeetingDetails?.room_id,
      meeting_passcode: scheduledMeetingDetails?.meeting_passcode,
    };
    if (window) {
      if (reschedule) {
        shareCancelledMeetingViaEmail(cancelledMeetingCustomerInfo, reschedule);
      } else {
        shareCancelledMeetingViaEmail(
          cancelledMeetingCustomerInfo,
          cancelledMeetingDetails,
        );
      }
    }
  };

  const handleShareModalTray = async () => {
    shareClickEvent();
    if (navigator.share) {
      shareViaNavigator(customerContactInfo, shareModalData);
    } else {
      handleShareModalEmail();
    }
  };

  const handleCancelledMeetingShareModalTray = async () => {
    const cancelledMeetingDetails = {
      next_meeting: scheduledMeetingDetails?.nextMeeting,
      meeting_url: scheduledMeetingDetails?.room_id,
      meeting_passcode: scheduledMeetingDetails?.meeting_passcode,
    };
    if (navigator.share) {
      shareCancelledMeetingViaNavigator(
        cancelledMeetingCustomerInfo,
        cancelledMeetingDetails,
      );
    } else {
      handleShareCancelledMeetingModalEmail();
    }
  };

  const handleShareModalCopy = (reschedule?) => {
    copyClickShareEvent();
    if (reschedule) {
      shareCopyToClipboard(customerContactInfo, reschedule);
    } else {
      shareCopyToClipboard(customerContactInfo, shareModalData);
    }
    handleShowSnackbar('success', 'Meeting details copied to the clipboard.');
  };

  const handleCancelledMeetingShareModalCopy = (reschedule?) => {
    const cancelledMeetingDetails = {
      next_meeting: scheduledMeetingDetails?.nextMeeting,
      meeting_url: scheduledMeetingDetails?.room_id,
      meeting_passcode: scheduledMeetingDetails?.meeting_passcode,
    };
    if (reschedule) {
      shareCancelledMeetingCopyToClipboard(
        cancelledMeetingCustomerInfo,
        reschedule,
      );
    } else {
      shareCancelledMeetingCopyToClipboard(
        cancelledMeetingCustomerInfo,
        cancelledMeetingDetails,
      );
    }
    handleShowSnackbar('success', 'Meeting details copied to the clipboard.');
  };

  const handleShareCancelledMeetingInvite = async () => {
    const cancelledMeetingDetails = {
      next_meeting: scheduledMeetingDetails?.nextMeeting,
      meeting_url: scheduledMeetingDetails?.room_id,
      meeting_passcode: scheduledMeetingDetails?.meeting_passcode,
    };
    opengCalUrlForCancelledMeeting(
      cancelledMeetingCustomerInfo,
      cancelledMeetingDetails,
    );
  };

  const handleShareInvite = () => {
    inviteClickShareEvent();

    if (!shareModalData.next_meeting) {
      handleShowSnackbar(
        'error',
        'Please schedule the next meeting before sending an invite.',
      );
      return;
    }
    opengCalUrl(customerContactInfo, shareModalData);
  };

  const handleShareInviteForMultipleSchedule = (reschedule?) => {
    inviteClickShareEvent();

    if (reschedule && reschedule?.next_meeting) {
      opengCalUrl(customerContactInfo, reschedule);
    }
  };

  const handleNextMeetingChange = (
    newDate: Date | null,
    customerId: number,
  ) => {
    handleScheduleMeeting({
      customer_id: customerId,
      scheduled_at: newDate,
      room_id: nextMeetingShareModalData?.room_id,
      passcode: nextMeetingShareModalData?.meeting_passcode,
    });
    setNextMeetingDate(null);
    setCustomerId(null);
  };

  const getOverviewData = async (param: any = { params: {} }) => {
    const newObj = { ...param?.params }
    const paramsObj: any = Object.keys(newObj)
      .filter((k) => newObj[k] != null)
      .reduce((a, k) => ({ ...a, [k]: newObj[k] }), {});
    const params = { params: { ...paramsObj } };
    setOverviewParams(params);
    await getOverview(params);
  };

  const handleJoinMeetingClick = (url: string) => {
    joinMeetingClickEvent();
    joinMeeting(url);
  };

  const toggleMetricsButton = () => {
    setShowMetrics(!showMetrics);
  };

  const checkListCount = () => {
    if (isAllConversation && (isWorkspaceAdmin || isManager)) {
      return customerListAdminCount === 0;
    }
    return customerCount === 0;
  };

// Filter meeting sources-------------------------------------------------

  const selectMeetingSource = updatedMeetingSource => {
    if (window && window.sessionStorage) {
      window.sessionStorage.setItem('meeting_source', JSON.stringify(updatedMeetingSource));
    }
    setCheckedMeetingSource(updatedMeetingSource);
    setSelectedMeetingSource(updateMeetingSource(updatedMeetingSource));
  };

  const updateMeetingSource = (updatedMeetingSource = []) => {
    const mappedMeetingSources = updatedMeetingSource
      .filter(object => object.checked === true)
      .map(object => object.id);

    return mappedMeetingSources;
  };

  const getMeetingSources = async () => {
    try {
      const response = await ScheduleService.getMeetingSources();
      const sources = response?.data?.data;
      const newSources = sources.map(source => ({ ...source, checked: false, latest: true }));
      const existingSource = window && window.sessionStorage.getItem('meeting_source') ? JSON.parse(window.sessionStorage.getItem('meeting_source')) : [];
      setInitialMeetingSource(newSources);
      if (existingSource && existingSource.length) {
        setCheckedMeetingSource(existingSource);
        setFilterMeetingSources(existingSource);
      } else {
        setCheckedMeetingSource(newSources);
        setFilterMeetingSources(newSources);
      }
      return newSources;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

// Filter assignee-----------------------------------------------------------------------

  const filterAssigneeConversation = updatedFilteredAssignee => {
    if (window && window.sessionStorage) {
      window.sessionStorage.setItem('filtered_assignee', JSON.stringify(updatedFilteredAssignee));
    }
    setCheckedFilteredAssignee(updatedFilteredAssignee);
    setSelectedAssigneeConversation(
      updateAssigneeConveration(updatedFilteredAssignee),
    );
  };

  const updateAssigneeConveration = (updatedAssineeConversation = []) => {
    const mappedAssigneeConveration = updatedAssineeConversation
      .filter(object => object.checked === true)
      .map(object => object?.assigned_user_id);

    return mappedAssigneeConveration;
  };
  // trigger fresh commit
  const getAllAssigneeUsers = async () => {
    try {
      const response = await ConversationsService.getAllAssigneeUsers(
        selectedWorkspace?.id,
      );
      const assigneeUsers = response?.data?.data;
      const existingAssignee = window && window.sessionStorage.getItem('filtered_assignee') ? JSON.parse(window.sessionStorage.getItem('filtered_assignee')) : [];
      let asUser: any = [];
      if (!isAdmin && isManager) {
        const userIds = await getUserForManager();
        userIds.push(user?.id);
        asUser = assigneeUsers.map(o => ({ ...o, checked: false, organisation_id: user?.organisation_id })).filter(pUser => userIds.includes(pUser.assigned_user_id));
        setAllConversationAssigneeUsers(asUser);
      } else {
        asUser = assigneeUsers.map(o => ({ ...o, checked: false, organisation_id: user?.organisation_id  }));
        setAllConversationAssigneeUsers(asUser);
      }
      const fromSameOrg = existingAssignee && existingAssignee.length && existingAssignee?.[0]?.organisation_id === user?.organisation_id;
      if (existingAssignee && existingAssignee.length && fromSameOrg) {
        setCheckedFilteredAssignee(existingAssignee);
      } else {
        setCheckedFilteredAssignee(asUser);
      }
    } catch (error) {
      console.log(error);
    }
  };

// Filter intent----------------------------------------------------------------

  const filterIntentConversation = (updatedFilteredIntent) => {
    if (window && window.sessionStorage) {
      window.sessionStorage.setItem('filtered_intent', JSON.stringify(updatedFilteredIntent));
    }
    setCheckedFilteredIntent(updatedFilteredIntent);
    setSelectedIntentConversation(
      updateIntentConversation(updatedFilteredIntent),
    );
  };

  const updateIntentConversation = (updatedIntentConversation = []) => {
    const mappedIntentConversation = updatedIntentConversation
      .filter(obj => obj.checked === true)
      .map(obj => obj?.assigned_intent_value);
    
    return mappedIntentConversation;  
  };

  const getContactIntent = () => {
    const intentList = [
      {
        assigned_intent_value: 'HIGH_INTENT',
        assigned_intent: "High",
        checked: false,
      },
      {
        assigned_intent_value: 'INTERESTED',
        assigned_intent: "Interested",
        checked: false,
      },
      {
        assigned_intent_value: 'NEUTRAL',
        assigned_intent: "Neutral",
        checked: false,
      },
      {
        assigned_intent_value: 'LOW_INTENT',
        assigned_intent: "Low",
        checked: false,
      },
    ]    
    const existingIntent = window && window.sessionStorage.getItem('filtered_intent') ? JSON.parse(window.sessionStorage.getItem('filtered_intent')) : [];
    if (existingIntent && existingIntent.length) {
      setCheckedFilteredIntent(existingIntent);
    } else {
      setCheckedFilteredIntent(intentList);
    }
  };

  useEffect(()=>{
    const intent = checkedFilteredIntent.filter((item)=>item?.checked);
    if(intent && intent.length > 0) {
      setIsContactIntentFilter(true);
    } else {
      setIsContactIntentFilter(false);
    }
  },[checkedFilteredIntent]);

// -----------------------------------------------------------------------------

// Filter status----------------------------------------------------------------

  const filterStatusConversation = (updatedFilteredStatus) => {
    if (window && window.sessionStorage) {
      window.sessionStorage.setItem('filtered_status', JSON.stringify(updatedFilteredStatus));
    }
    setCheckedFilteredStatus(updatedFilteredStatus);
    setSelectedStatusConversation(
      updateStatusConversation(updatedFilteredStatus),
    );
  };

  const updateStatusConversation = (updatedStatusConversation = []) => {
    const mappedStatusConversation = updatedStatusConversation
      .filter(obj => obj.checked === true)
      .map(obj => obj?.name);
    
    return mappedStatusConversation;  
  }

  const getCRMStatusList = async () => {
    try {
      const data = await getCRMStatus();
      const existingStatus = window && window.sessionStorage.getItem('filtered_status') ? JSON.parse(window.sessionStorage.getItem('filtered_status')) : [];
      const sortedStatus = data.sort(
        (fe: ICRMStatus, se: ICRMStatus) => fe.sequence - se.sequence,
      ); 
      setCRMStatusList(sortedStatus);
      const fromSameOrg = existingStatus && existingStatus.length && existingStatus?.[0]?.organisation_id === sortedStatus?.[0]?.organisation_id
      if (existingStatus && existingStatus.length && fromSameOrg) {
        setCheckedFilteredStatus(existingStatus);
      } else {
        const freshStatus = sortedStatus.map(status => ({ ...status, checked: false}));
        setCheckedFilteredStatus(freshStatus);
      }
    } catch (err) {
      handleShowSnackbar('error', err.response?.data.message);
    }
  };

  useEffect(()=>{
    const status = checkedFilteredStatus.filter((item) => item?.checked);
    if(status && status.length > 0) {
      setIsStatusFilter(true);
    } else {
      setIsStatusFilter(false);
    }
  },[checkedFilteredStatus]);

// -----------------------------------------------------------------------------

  const handleMeetingSourceDropdownClose = () => {
    setMeetingSourceButtonEl(null);
  };

  const handleNextMeetingShareModalOpen = () => {
    setOpenNextMeetingShareModal(true);
  };

  const handleBackClick = () => {
    setSelectedModalIndex(nextMeetingSchedule?.cust_id);
  };

  const handleSelectModalIndex = id => {
    setSelectedModalIndex(id);
  };

  const handleSetNextMeetingSchedule = data => {
    setNextMeetingSchedule(data);
  };

  const convertToUserTimezone = (time: string) => {
    const utcTimestamp = new Date(time);
    const targetTimezone = user.timezone;
    const convertedDate = utcToZonedTime(utcTimestamp, targetTimezone);
    return format(convertedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSX", { timeZone: targetTimezone } as any);
  }

  const getFormattedDate = rawDate =>
    format(parseISO(convertToUserTimezone(rawDate)), "dd MMM''' 'yy");

  const handleMenuClose = () => {
    setSelectedModalIndex(0);
  };

  const [isAvailable, setIsAvailable] = useState(Boolean(availability));

  const toggleInstantMeetingAvailability = async () => {
    const audio = new Audio('/assets/sweet_text.ogg');
    audio.muted = true;
    audio.play();
    try {
      const realtime_meeting_availaibility = !isAvailable;
      const userData: any =
        localStorage && JSON.parse(localStorage.getItem('user_data'));
        let count = JSON.parse(localStorage.getItem('availability_toggle'))?.count||0;
        if(realtime_meeting_availaibility){
          localStorage.setItem(
            'availability_toggle',
            JSON.stringify({count:++count}),
          );
          
        }
      await UserService.update(userData?.id, {
        meta: { realtime_meeting_availaibility },
      });
      setIsAvailable(realtime_meeting_availaibility);
      dispatch({
        type: REALTIME_WIDGET_AVAILABILITY,
        payload: {
          availability: realtime_meeting_availaibility,
          userInteracted: true
        },
      });
    } catch (err) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: 'error',
          message: 'Failed to toggle your availability. Please try again.',
        },
      });
    }
  };

  const getUserProfileCookies = async () => {
    await UserService.getUserProfileCookies();
  };

  const updateFilteredQualificationQuestions = (updatedQualificationQuestions = []) => {
    if (window && window.sessionStorage) {
      window.sessionStorage.setItem('qualification_questions', JSON.stringify(updatedQualificationQuestions));
    }
    setWorkspaceQualificationQuestions(updatedQualificationQuestions);
    setSelectedQualificationQuestions(getQualificationQuestionFormat(updatedQualificationQuestions));
  };

  const getQualificationQuestionFormat = (updatedQualificationQuestions = []) => {
    const mappedQualificationQuestions = updatedQualificationQuestions
      .filter(object => object && object?.filter && object?.filter?.length)
      .map(object => ({ capture_id: object._id, capture_output: object.filter}));

    return mappedQualificationQuestions;
  }

  const getAllWorkspaceQualificationQuestions = async () => {
    try {
      const workspace_id = selectedWorkspace?.id;
      const response =
        await OrganisationService.getWorkspaceQualificationQuestions(
          workspace_id,
        );
      const questions = response?.data?.data.filter((item) => !item?.deleted_at && item.is_active).map(item => ({ ...item, filter: false}));
      const existingQuestions = window && window.sessionStorage.getItem('qualification_questions') ? JSON.parse(window.sessionStorage.getItem('qualification_questions')) : [];
      if (existingQuestions && existingQuestions.length) {
        setWorkspaceQualificationQuestions(existingQuestions);
        setFilterQualificationQuestions(existingQuestions);
      } else {
        setWorkspaceQualificationQuestions(questions);
        setFilterQualificationQuestions(questions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterApply = (questions, sources) => {
    const existingStatus = window && window.sessionStorage.getItem('filtered_status') ? JSON.parse(window.sessionStorage.getItem('filtered_status')) : [];
    const existingIntent = window && window.sessionStorage.getItem('filtered_intent') ? JSON.parse(window.sessionStorage.getItem('filtered_intent')) : [];
    const existingAssignee = window && window.sessionStorage.getItem('filtered_assignee') ? JSON.parse(window.sessionStorage.getItem('filtered_assignee')) : [];
    const existingDates = window && window.sessionStorage.getItem('filtered_date') ? JSON.parse(window.sessionStorage.getItem('filtered_date')) : '';
    const crmFilterStatus = checkedFilteredStatus?.length ? checkedFilteredStatus : existingStatus;
    const filteredIntent = checkedFilteredIntent?.length ? checkedFilteredIntent : existingIntent;
    const filteredAssignee = checkedFilteredAssignee?.length ? checkedFilteredAssignee : existingAssignee;
    const filterDates = searchDate && searchDate?.length ? searchDate : existingDates ? JSON.stringify(existingDates) : '';
    const meetingSources = sources;
    const qualificationQuestions = getQualificationQuestionFormat(questions);
    updateFilteredQualificationQuestions(questions);
    selectMeetingSource(sources);
    queryOverview(meetingSources, crmFilterStatus, filteredIntent, filteredAssignee, qualificationQuestions, filterDates);
  } 

  const handleFilterApplyClick = () => {
    handleFilterApply(filterQualificationQuestions, filterMeetingSources)
  }

  const handleFiltersReset = () => {
    const existingStatus = window && window.sessionStorage.getItem('filtered_status') ? JSON.parse(window.sessionStorage.getItem('filtered_status')) : [];
    const existingIntent = window && window.sessionStorage.getItem('filtered_intent') ? JSON.parse(window.sessionStorage.getItem('filtered_intent')) : [];
    const existingAssignee = window && window.sessionStorage.getItem('filtered_assignee') ? JSON.parse(window.sessionStorage.getItem('filtered_assignee')) : [];
    const existingDates = window && window.sessionStorage.getItem('filtered_date') ? JSON.parse(window.sessionStorage.getItem('filtered_date')) : '';
    const crmFilterStatus = checkedFilteredStatus?.length ? checkedFilteredStatus : existingStatus;
    const filteredIntent = checkedFilteredIntent?.length ? checkedFilteredIntent : existingIntent;
    const filteredAssignee = checkedFilteredAssignee?.length ? checkedFilteredAssignee : existingAssignee;
    const filterDates = searchDate && searchDate?.length ? searchDate : existingDates ? JSON.stringify(existingDates) : '';
    const newSources = checkedMeetingSource.map(itm => ({ ...itm, checked: false}));
    const newQualificationQuestions = workspaceQualificationQuestion.map(quest => ({...quest, filter: false}));
    selectMeetingSource(newSources)
    updateFilteredQualificationQuestions(newQualificationQuestions);
    setFilterMeetingSources(newSources);
    setFilterQualificationQuestions(newQualificationQuestions);
    const qualificationQuestions = getQualificationQuestionFormat(newQualificationQuestions);
    queryOverview(newSources, crmFilterStatus, filteredIntent, filteredAssignee, qualificationQuestions, filterDates);
  } 

  const selectAssignee = async (customerId: number, assigneeId: number) => {
    try {
      dispatch({ type: SHOW_LOADER});
      await CustomerService.updateCustomerAssignee(customerId, assigneeId);

      const meetingSourceQ = (updateMeetingSource(checkedMeetingSource) && updateMeetingSource(checkedMeetingSource)?.toString()) || null;
      const assignedUserIdQ = (updateAssigneeConveration(checkedFilteredAssignee) && updateAssigneeConveration(checkedFilteredAssignee)?.toString()) || null;
      const assignedIntentQ = (updateIntentConversation(checkedFilteredIntent) && updateIntentConversation(checkedFilteredIntent)?.toString()) || null;
      const assignedStatusQ = (updateStatusConversation(checkedFilteredStatus) && updateStatusConversation(checkedFilteredStatus)?.toString()) || null; 

      await setAdminOverviewCustomers({
        params: {
          ...(query?.search_term ? { search_term: query?.search_term } : {}),
          ...(query?.customer_id ? { customer_id: query?.customer_id } : {}),
          ...(query?.search_date ? { search_date: query?.search_date } : {}),
          page_number: Number(query?.page_number) || 1,
          sort_field: query?.sort_field || 'last_conversation_at',
          sort: query?.sort || 'desc',
          ...(meetingSourceQ ? { meeting_source_id: meetingSourceQ } : {}),
          ...(assignedUserIdQ ? { assigned_user_id: assignedUserIdQ } : {}),
          ...(assignedIntentQ ? { assigned_intent: assignedIntentQ } : {}),
          ...(assignedStatusQ ? { assigned_status: assignedStatusQ } : {}),
          ...(searchDate ? { last_conversation_date: searchDate } : {}),
          ...(!isAdmin && isManager ? { manager_id: user.id } : {}),
          ...(selectedQualificationQuestions?.length ? {qualification_questions: selectedQualificationQuestions} : {})
        },
      });
      
      showChangesSavedSnackbar();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: HIDE_LOADER});
    }
  } 

  const getAllUsers = async () => {
    const org_id = user?.organisation_id;
    const workspace_id = selectedWorkspace?.id;
    const response = await OrganisationService.getUsersFromSameWorkspace(
      org_id,
      workspace_id,
    );
    const users = (response?.data?.data || []).filter(usr => usr.status === 'active' && !usr.email.includes('zipteams@')); 
    setAllUsers(users || []);
  }

  useEffect(() => {
    if (selectedWorkspace && Object.keys(selectedWorkspace)?.length) {
      getAllUsers();
    }
  }, [selectedWorkspace])

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem('availability_toggle'))?.count === 1 &&
      realtimeWidget?.availability
    ) {
      setShowChromeExtensionModal(true);
    }
  }, [realtimeWidget?.availability]);

  useEffect(() => {
    setIsAvailable(Boolean(availability));
  }, [availability]);

  useEffect(() => {
    if (!workspaceQualificationQuestion.length && selectedWorkspace) {
      getAllWorkspaceQualificationQuestions();
    }
  }, [selectedWorkspace])

  useEffect(() => {
    setIsWorkspaceAdmin(isAdmin);
    getUserProfile();
    getUserProfileCookies();
    getCRMStatusList();
    getMeetingSources();
    getContactIntent()
    const existingDate = window && window.sessionStorage.getItem('filtered_date') ? JSON.parse(window.sessionStorage.getItem('filtered_date')) : '';
    setSearchDate(window.sessionStorage.getItem('filtered_date') || '');
    setStartDate(existingDate?.[0]);
    setEndDate(existingDate?.[1])
    const ignoreChromeExtnInstallModal = localStorage?.getItem('ignoreChromeExtnInstallModal');
    setShowChromeExtnInstallModal(Boolean(ignoreChromeExtnInstallModal) !== true && !isMobile && isChrome);
    const existingStatus = window && window.sessionStorage.getItem('filtered_status') ? JSON.parse(window.sessionStorage.getItem('filtered_status')) : [];
  }, []);

  useEffect(()=>{
    if (isAllConversation) {
      setShowOverdueMeeting(false);
    } else {
      setShowOverdueMeeting("show_missed_overdue_meetings_first" in user?.meta ? user?.meta?.show_missed_overdue_meetings_first : true);
    }
  }, [isAllConversation]);

  const meetingSourceLength = checkedMeetingSource && checkedMeetingSource.filter((source) => source.checked === true)?.length;
  const questionsLength = workspaceQualificationQuestion && workspaceQualificationQuestion.filter((source: any) => source?.filter && source?.filter?.length)?.length;
  
  return (
    <DashboardContext.Provider
      value={{
        handleCRMStatusChange,
        handleScheduleMeeting,
        handleEditCustomer: handleEditCustomerModalOpen,
        handleDeleteCustomer: handleDeleteCustomerModalOpen,
        handleShare: handleShareModalOpen,
        handleJoinMeeting: handleJoinMeetingClick,
        handleEditDate: handleDateModalOpen,
        handleCancelMeeting: handleCancelMeetingModalOpen,
      }}
    >
      <div className={styles['dashboard-container']}>
        {showChromeExtnInstallModal && (
          <ChromeExtensionModal onClose={() => setShowChromeExtnInstallModal(false)} />
        )}
        {!isMobile && (
          <div className="flex justify-between font-semibold pt-1 pb-2 mx-1">
            <h3>
              {isAllConversation ? 'All Conversations' : 'My Conversations'}
            </h3>
            <div className="flex items-center">
              <span>
                {realtimeWidget?.enabled && (
                  <CustomToolTip
                    placement="right"
                    title="Toggle your availability for instant meeting requests"
                  >
                    <div className="flex">
                      <AvailabilitySwitch className="" screen="toggleAvailability" handleOnChange={toggleInstantMeetingAvailability} isAvailable={isAvailable} />
                    </div>
                  </CustomToolTip>
                )}
              </span>
              <span className='flex items-center cursor-pointer'>
                {realtimeWidget?.enabled && (
                  <span
                    role='button'
                    tabIndex={0}
                    className="ml-2"
                    onClick={() => {
                      setShowChromeExtensionModal(prevState => !prevState);
                    }}
                    onKeyDown={() => {}}
                  >
                    <InfoIcon />
                  </span>
                )}
              </span>
              <Link href="/workspace?selected=my_profile">
                <span className="flex items-center ml-2 cursor-pointer">
                  <img
                    src={user?.avatar_url || '/icons/profile-default.svg'}
                    className="rounded-full w-7 h-7 object-cover"
                    alt="profile"
                    onError={e => {
                      e.currentTarget.onerror = null; // prevents looping
                      e.currentTarget.src = '/icons/profile-default.svg';
                    }}  
                  />
                </span>
                {/* <span className="pl-2 pr-6">
                  {user?.first_name} {user?.last_name}
                </span> */}
              </Link>
            </div>
          </div>
        )}
        {showChromeExtensionModal && (
          <div
            className={`${styles['tooltip']} fixed top-12 z-20 rounded mx-2 right-4 bg-primary text-white text-base leading-5`}
          >
            <div className={styles['tooltip-arrow']} />

            <div
              className="relative px-3 py-2 text-sm"
              style={{ width: '700px' }}
            >
              <div>
                <div className="font-bold">
                  Please complete the below steps to ensure you get instant
                  meeting alerts on your Chrome browser
                </div>
                <div className="mt-4 font-normal ">
                  <div className="pl-1">
                    1. Install Zipteams Chrome Extension to get instant meeting
                    alerts across on any tab that you working on Chrome.{' '}
                    <a
                      href="https://chrome.google.com/webstore/search/zipteams"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold underline"
                    >
                      Install Now.
                    </a>
                  </div>
                  <div className="mt-3 pl-1 pb-1">
                    2. Add{' '}
                    <a
                      className="font-bold"
                      href="https://app.zipteams.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      app.zipteams.com
                    </a>{' '}
                    to always active in Chrome &gt; Settings &gt; Performance as
                    shown below.{' '}
                    <a
                      className="font-bold underline"
                      href="https://blog.google/products/chrome/new-chrome-features-to-save-battery-and-make-browsing-smoother/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here
                    </a>{' '}
                    to know more.
                  </div>
                  <ChromePerfIcon />
                </div>
              </div>
              <button
                type="button"
                className="bg-transparent font-bold text-xs leading-3.5 flex justify-center items-center p-1 cursor-pointer rounded-full absolute top-1 right-1"
                onClick={() => {
                  chromeExtensionModalIgnoreClickHandler();
                }}
              >
                <CrossIcon className="w-3 h-3" />
              </button>
            </div>
          </div>
        )}
        <div className={styles['head-component-container']}>
          <SearchComponent
            searchTerm={urlSearchTerm}
            inUse="dashboard"
            isWorkspaceAdmin={isWorkspaceAdmin || isManager}
            isAllConversation={isAllConversation}
            setSearchDate={setSearchDate}
            searchDate={searchDate}
            startDate={startDate}
            endDate={endDate}
          />
          <div className=" lg:hidden">
            <Sheet>
              <SheetTrigger>  
                <IconButton
                  disableRipple
                  className={`${styles['meeting-source-button']} `}
                  // onClick={event => {
                  //   setMeetingSourceButtonEl(event.currentTarget);
                  // }}
                >
                  <MeetingSourceIcon />
                </IconButton>
              </SheetTrigger>
              <ConversationsFilter
                isMobile
                meetingSources={filterMeetingSources}
                selectedMeetingSourcesLength={meetingSourceLength}
                setSelectedMeetingSources={(id: number) => {
                  const questionsArr =  filterMeetingSources.map(item => {
                    if (item.id === id) {
                      return {
                        ...item,
                        checked: !item.checked
                      }
                    }
                    return item;
                  });
                  setFilterMeetingSources([...questionsArr]);
                }}
                qualificationQuestions={filterQualificationQuestions}
                selectedQualificationQuestionsLength={questionsLength}
                setSelectedQualificationQuestions={(id, value, type) => {
                  const getFilterVal = (val) => {
                    let filter = [...val];
                    if (type === 'number_min') {
                      filter[0] = value;
                    } else if (type === 'number_max') {
                      filter[1] = value;
                    } else if (type === 'boolean' || type === 'bool') {
                      filter = [value]; 
                    } else if (Array.isArray(value)){
                      filter = value;
                    }
                    return filter;
                  }
                  const questionsArr = filterQualificationQuestions.map(item => {
                    if (item._id === id) {
                      const filter = getFilterVal(item?.filter || []);
                      return { 
                        ...item, 
                        filter
                      }
                    }
                    return item;
                  });
                  setFilterQualificationQuestions([...questionsArr]);
                }}
                handleFiltersApply={handleFilterApplyClick}
                handleFiltersReset={handleFiltersReset}
              />
            </Sheet>
          </div>

          <MeetingSourceDropdown
            el={meetingSourceButtonEl}
            handleMenuClose={handleMeetingSourceDropdownClose}
            meeting_source={checkedMeetingSource}
            selectMeetingSource={selectMeetingSource}
          />

          <div className="w-full max-w-4xl hidden lg:block">
            <MetricsComponent
              metrics={metricsData}
              metricsLoading={metricsLoading}
              isFilterApplied={isFilterApplied}
            />
          </div>

          <IconButton
            className={styles['metrics-button']}
            disableRipple
            onClick={toggleMetricsButton}
          >
            <MetricsIcon className="w-4 h-4 text-white fill-current" />
          </IconButton>
        </div>
        {showMetrics && (
          <div className={styles['metrics-container-mob']}>
            <MetricsComponent
              metrics={metricsData}
              metricsLoading={metricsLoading}
              isFilterApplied={isFilterApplied}
            />
          </div>
        )}
        
        <div className="outline-none relative flex-1">
          <div className="absolute left-0 top-0 bottom-0 right-0">
            <div className={styles['content-container']}>
              {/* <ToggleButton
                  buttonType={listDisplayType}
                  handleClick={changeListDisplayType}
                  isInSearch={isInSearch}
                  isHidden={(!customerCount && !isInSearch) || false}
                /> */}
              {!isFetchingOverviewData &&
                checkListCount() &&
                !getQualificationQuestionFormat(workspaceQualificationQuestion)?.length &&
                !getQualificationQuestionFormat(filterQualificationQuestions)?.length &&
                updateMeetingSource(checkedMeetingSource) &&
                updateMeetingSource(checkedMeetingSource)?.length === 0 && !isContactIntentFilter && !isStatusFilter && !asPath.includes('admin') && !searchDate ? (
                <>
                  {isInSearch ? (
                    <EmptySearch searchTerm={urlSearchTerm} />
                  ) : (
                    <EmptyDashboard
                      handleAddCustomerModalOpen={handleAddCustomerModalOpen}
                    />
                  )}
                </>
              ) : (
                <>
                  {isMobile && (
                    <div className='flex justify-end mt-1 mb-1 lg:mb-2'>
                      <div className="flex items-center justify-start">
                        <input
                          type="checkbox"
                          checked={showOverdueMeeting}
                          onChange={()=>handleUpdateMissedOverdueMeetingFirst()}
                          className="form-checkbox h-4 w-4 text-secondary focus:ring-transparent cursor-pointer rounded-sm border-primary-grey"
                        />
                        <span className="ml-2 text-xxs md:text-xs lg:text-sm text-primary-dark font-normal">
                          Show missed/overdue meetings first
                        </span>
                      </div>
                    </div>
                  )}
                  <MobileListComponent
                    customers={
                      isAllConversation && (isWorkspaceAdmin || isManager)
                        ? customerListAdmin
                        : customerList
                    }
                    isInSearch={isInSearch}
                    count={
                      isAllConversation && (isWorkspaceAdmin || isManager)
                        ? customerListAdminCount
                        : customerCount
                    }
                    handleTablePageChange={handleTablePageChange}
                    pageNo={overviewParams.params.page_number}
                    isAllConversation={isAllConversation}
                    handleAddCustomerModalOpen={handleAddCustomerModalOpen}
                    customerCount={customerCount}
                    isContactIntentFilter={isContactIntentFilter}
                    isStatusFilter={isStatusFilter}
                    // prospectScoreEnabled={
                    //   user?.organisation?.feature_flags
                    //     ?.prospect_score_enabled || false
                    // }
                  />
                  {!isMobile && (
                    <div className='flex justify-end -mb-1 md:mb-1 lg:mb-2'>
                      <div className="flex items-center justify-start">
                        <input
                          type="checkbox"
                          checked={showOverdueMeeting}
                          onChange={()=>handleUpdateMissedOverdueMeetingFirst()}
                          className="form-checkbox h-4 w-4 text-secondary focus:ring-transparent cursor-pointer rounded-sm border-primary-grey"
                        />
                        <span className="ml-2 text-xxs md:text-xs lg:text-sm text-primary-dark font-normal">
                          Show missed/overdue meetings first
                        </span>
                      </div>
                    </div>
                  )}
                  <Sheet>
                    <TableComponent
                      customers={
                        isAllConversation && (isWorkspaceAdmin || isManager)
                          ? customerListAdmin
                          : customerList
                      }
                      count={
                        isAllConversation && (isWorkspaceAdmin || isManager)
                          ? customerListAdminCount
                          : customerCount
                      }
                      CRMStatusList={CRMStatusList}
                      pageNo={
                        isAllConversation
                          ? Number(query?.page_number) || 1
                          : overviewParams.params.page_number
                      }
                      sort={isAllConversation ? adminSort : overviewParams.params.sort}
                      sortBy={isAllConversation ? adminSortBy : overviewParams.params.sort_field}
                      isInsightsLocked={isInsightsLocked}
                      isWorkspaceAdmin={isWorkspaceAdmin || isManager}
                      isAllConversation={isAllConversation}
                      handleCRMStatusChange={handleCRMStatusChange}
                      handleEditCustomer={handleEditCustomerModalOpen}
                      handleDeleteCustomer={handleDeleteCustomerModalOpen}
                      handleShare={handleShareModalOpen}
                      handleTablePageChange={handleTablePageChange}
                      handleTablePageSort={handleTablePageSort}
                      handleJoinMeeting={handleJoinMeetingClick}
                      handleEditDate={handleDateModalOpen}
                      handleAddCustomerModalOpen={handleAddCustomerModalOpen}
                      customerCount={customerCount}
                      isInSearch={isInSearch}
                      // prospectScoreEnabled={
                      //   user?.organisation?.feature_flags
                      //     ?.prospect_score_enabled || false
                      // }
                      meetingSource={checkedMeetingSource}
                      selectMeetingSource={selectMeetingSource}
                      assigneeList={checkedFilteredAssignee}
                      filterAssigneeConversation={filterAssigneeConversation}
                      userConnectedCallers={userConnectedCallers}
                      handleSetNextMeetingSchedule={handleSetNextMeetingSchedule}
                      handleSelectModalIndex={handleSelectModalIndex}
                      getCustomerContactInfo={getCustomerContactInfo}
                      handleCancelScheduledMeeting={
                        handleCancelScheduledMeetingModalOpen
                      }
                      handleSetNextMeetingDetails={handleSetNextMeetingDetails}
                      filterIntentConversation={filterIntentConversation}
                      intentList={checkedFilteredIntent}
                      isContactIntentFilter={isContactIntentFilter}
                      filterStatusConversation={filterStatusConversation}
                      modifiedCrmList={checkedFilteredStatus}
                      isStatusFilter={isStatusFilter}
                      qualificationQuestions={workspaceQualificationQuestion}
                      handleFilterApply={handleFilterApply}
                      handleFiltersReset={handleFiltersReset}
                      allUsers={allUsers}
                      selectAssignee={selectAssignee}
                    />
                  </Sheet>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {addEditModalOpen && (
        <AddEditModal
          open={addEditModalOpen}
          type={modalType}
          handleSumbit={
            modalType === DASHBOARD_MODALS.ADD
              ? handleAddSumbit
              : handleEditSubmit
          }
          initialValues={{
            customerName: customerContactInfo?.name || '',
            primaryContactName:
              (customerContactInfo?.contacts &&
                customerContactInfo?.contacts[0]?.name) ||
              '',
            designation:
              (customerContactInfo?.contacts &&
                customerContactInfo?.contacts[0]?.designation) ||
              '',
            primaryContactEmail:
              (customerContactInfo?.contacts &&
                customerContactInfo?.contacts[0]?.email) ||
              '',
            phoneNumber:
              (customerContactInfo?.contacts &&
                customerContactInfo?.contacts[0]?.phone) ||
              '',
            linkedInUrl:
              (customerContactInfo?.contacts &&
                customerContactInfo?.contacts[0]?.linkedin_profile) ||
              '',
            sameNameCheck:
              (customerContactInfo?.name || '') ===
              ((customerContactInfo?.contacts &&
                customerContactInfo?.contacts[0]?.name) ||
                ''),
          }}
          handleClose={handleAddEditModalClose}
          displayOnlyFields={
            customerContactInfo?.meta?.scheduling_submissions || []
          }
          customerScheduleMeetingSource={
            customerContactInfo?.schedule?.[0]?.meeting_source?.name
          }
          customerScheduleMeetingSourceUrl={
            customerContactInfo?.meta?.scheduled_source_url || ''
          }
        />
      )}
      {cancelMeetingModalOpen && (
        <CancelMeetingModal
          open={cancelMeetingModalOpen}
          handleClose={handleCancelmeetingModalClose}
          primaryClickHandler={handleCancelMeeting}
          secondaryClickHandler={handleCancelmeetingModalClose}
        />
      )}
      {cancelScheduledMeetingModalOpen && (
        <CancelScheduledMeetingModal
          open={cancelScheduledMeetingModalOpen}
          handleClose={handleCancelmeetingModalClose}
          primaryClickHandler={handleCancelScheduledMeeting}
          secondaryClickHandler={handleCancelScheduledMeetingModalClose}
        />
      )}
      {postCancelMeetingModalOpen && (
        <PostCancelMeetingModal
          open={postCancelMeetingModalOpen}
          handleClose={handlePostCancelMeetingModalClose}
          meetingDetails={{
            cancelledMeetingDate: scheduledMeetingDetails?.nextMeeting,
            url: scheduledMeetingDetails?.room_id,
            passcode: scheduledMeetingDetails?.meeting_passcode,
          }}
          primaryClickHandler={() => handleCancelledMeetingShareModalCopy()}
          secondaryClickHandler={() => {
            if (isMobile) {
              handleCancelledMeetingShareModalTray();
            } else {
              handleShareCancelledMeetingModalEmail();
            }
          }}
          tertiaryClickHandler={() => handleShareCancelledMeetingInvite()}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          open={deleteModalOpen}
          handleClose={handleDeleteCustomerModalClose}
          primaryClickHandler={handleDeleteCustomer}
          secondaryClickHandler={handleDeleteCustomerModalClose}
        />
      )}
      {shareModalOpen && (
        <ShareLinkModal
          open={shareModalOpen}
          handleClose={handleShareModalClose}
          meetingDetails={{
            url: shareModalData?.meeting_url,
            passcode: shareModalData?.meeting_passcode,
          }}
          primaryClickHandler={() => handleShareModalCopy()}
          secondaryClickHandler={() => {
            if (isMobile) {
              handleShareModalTray();
            } else {
              handleShareModalEmail();
            }
          }}
          tertiaryClickHandler={handleShareInvite}
        />
      )}
      {isDateModalOpen && (
        <DateTimePickerComponent
          open={isDateModalOpen}
          date={nextMeetingDate}
          handleClose={setIsDateModalOpen}
          handleDateChange={newDate =>
            handleNextMeetingChange(newDate, selectedCustomerId)
          }
          allowPastDate={false}
        />
      )}

      {selectedModalIndex === nextMeetingSchedule?.cust_id && (
        <NextMeetingScheduleModal
          open={selectedModalIndex === nextMeetingSchedule?.cust_id}
          handleJoinMeeting={handleJoinMeetingClick}
          schedule={nextMeetingSchedule?.schedule}
          customerName={nextMeetingSchedule?.cust_name}
          contactName={nextMeetingSchedule?.contact_name}
          cust_id={nextMeetingSchedule?.cust_id}
          selectedModalIndex={selectedModalIndex}
          handleSetNextMeetingDetails={handleSetNextMeetingDetails}
          getFormattedDate={getFormattedDate}
          convertToUserTimezone={convertToUserTimezone}
          handleMenuClose={handleMenuClose}
          handleDateModalOpen={handleDateModalOpen}
          handleCancelMeeting={handleCancelScheduledMeetingModalOpen}
          customer={nextMeetingSchedule}
          handleNextMeetingShareModalOpen={handleNextMeetingShareModalOpen}
        />
      )}

      {openNextMeetingShareModal && (
        <NextMeetingShareModal
          open={openNextMeetingShareModal}
          handleClose={() => {
            handleSetNextMeetingDetails();
            setOpenNextMeetingShareModal(false);
          }}
          primaryClickHandler={() => {
            handleShareModalCopy(nextMeetingShareModalData);
          }}
          secondaryClickHandler={() => {
            handleShareModalEmail(nextMeetingShareModalData);
          }}
          tertiaryClickHandler={() => {
            handleShareInviteForMultipleSchedule(nextMeetingShareModalData);
          }}
          roomId={nextMeetingShareModalData?.room_id}
          passcode={nextMeetingShareModalData?.meeting_passcode}
          handleBackClick={handleBackClick}
        />
      )}
      {showIntroModal && (
        <PreTourComponent
          open={showIntroModal}
          idp={idp}
          handleClose={() => setShowIntroModal(false)}
        />
      )}
      {/* {showOnbModal && (
        <OnboardingModal
          open={showOnbModal}
          idp={idp}
          customers={customerList}
          meetingOverview={getOverviewData}
          addCustomerClick={handleAddCustomerModalOpen}
          handleClose={() => setShowOnbModal(false)}
          openIntroModal={() => setShowIntroModal(true)}
        />
      )} */}
      {showZipmeOnbModal && (
        <ZipmeOnbModal
          open={showZipmeOnbModal}
          handleClose={() => setShowZipmeOnbModal(false)}
          addCustomerClick={handleAddCustomerModalOpen}
        />
      )}
      {user?.organisation?.feature_flags?.enable_multi_upload && (
        <div className='absolute bottom-8 right-8'>
          <button
            type='button'
            onClick={() => dispatch({ type: TOGGLE_MULTIUPLOAD_MODAL, payload: true })}
            className='w-[50px] h-[50px] rounded-full bg-secondary flex items-center justify-center'
          >
            <FiUpload className='w-6 h-6 text-white'/>
          </button>
        </div>
      )}
    </DashboardContext.Provider>
  );
};

export default withAuthenticator(Dashboard, {
  redirectPath: `/auth/${ROUTES.AUTH_TYPES.LOGIN}`,
  onlyAdmin: true
});
