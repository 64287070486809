/* eslint-disable import/extensions */
import { API_BASE_URL } from '../../utils/constants';
import HttpClient from '../../utils/http-client';

class User {
  me = () => HttpClient.get(`${API_BASE_URL}/v1/user/me`);

  update = (id: number, payload: any) =>
    HttpClient.put(`${API_BASE_URL}/v1/user/${id}`, payload);

  getIdentities = (id: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/user/${id}/identities`);

  getUserProfileData = () => HttpClient.get(`${API_BASE_URL}/v1/user/profile`);

  getUserProfileCookies = () =>
    HttpClient.get(`${API_BASE_URL}/v1/user/profile/cookies`);

  updateProfile = (data) =>
    HttpClient.put(`${API_BASE_URL}/v1/user/profile`, data);

  updateProfilePicture = data =>
    HttpClient.put(`${API_BASE_URL}/v1/user/profilepicture`, data);

  setPreferredWorkspace = (workspace_id) =>
    HttpClient.post(`${API_BASE_URL}/v1/user/preferred-workspace`, { workspace_id })

  connectGoogleCalender = (data, mode: string = '') =>
    HttpClient.post(`${API_BASE_URL}/v1/integrations/google/oauth/token`, {
      code: data,
      ...(mode ? { mode } : {})
    });

  connectOutlookCalender = data =>
    HttpClient.post(`${API_BASE_URL}/v1/integrations/outlook/oauth/token`, {
      code: data,
    });

  disconnectGoogleCalender = () =>
    HttpClient.delete(`${API_BASE_URL}/v1/integrations/google/oauth/token`);

  disconnectOutlookCalender = () =>
    HttpClient.delete(`${API_BASE_URL}/v1/integrations/outlook/oauth/token`);

  connectHubspotCrm = data =>
    HttpClient.post(`${API_BASE_URL}/v1/integrations/hubspot/oauth/token`, {
      code: data,
    });

  disconnectHubspotCrm = () =>
    HttpClient.delete(`${API_BASE_URL}/v1/integrations/hubspot/oauth/token`);

  connectLeadSquaredCrm = data =>
    HttpClient.post(
      `${API_BASE_URL}/v1/integrations/leadsquared/auth/store-keys`,
      data,
    );

  disconnectLeadSquaredCrm = () =>
    HttpClient.delete(`${API_BASE_URL}/v1/integrations/hubspot/oauth/leadsquared`);

  disconnectHubSpotNewCrm = () => 
    HttpClient.delete(`${API_BASE_URL}/v1/integrations/hubspot/oauth/hubspot`);

  connectLeadSquaredForNonAdmins = (workspace_id) =>
    HttpClient.post(`${API_BASE_URL}/v1/integrations/leadsquared/${workspace_id}/lsq-connect-non-admins`, {});

  getIntegrationToken = () =>
    HttpClient.get(`${API_BASE_URL}/v1/integrations/integration-app/token`);

  storeCallHippoToken = (token) =>
    HttpClient.post(`${API_BASE_URL}/v1/integrations/callhippo/store-api-token`, {
      apiToken: token
    });

  getCallHippoToken = () =>
    HttpClient.get(`${API_BASE_URL}/v1/integrations/callhippo/api-token`);

  deleteCallHippoToken = () =>
    HttpClient.delete(`${API_BASE_URL}/v1/integrations/callhippo/api-token`);

  storeAdminCRMConnectionData = (type) =>
    HttpClient.post(`${API_BASE_URL}/v1/integrations/hubspot/admin-connection/${type}`, {})

  getUserProfile = (user_id) =>
    HttpClient.get(`${API_BASE_URL}/v1/user/profile/${user_id}`)

  enbleWorkingHours = (payload) =>
    HttpClient.post(`${API_BASE_URL}/v1/user/status-working-hours`, payload)

  updateShowMissedOverdueMeetingsFirst = (payload) =>
    HttpClient.post(`${API_BASE_URL}/v1/user/show-missed-overdue-meetings-first`, payload)

  setWorkingHours = (payload) =>
    HttpClient.put(`${API_BASE_URL}/v1/user/working-hours`, payload)
  
  setWorkingHoursTimeZone=(payload)=>
    HttpClient.post(`${API_BASE_URL}/v1/user/working-hours-timezone`, {timezone:payload})

}

const UserService = new User();

export default UserService;
