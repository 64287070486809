/* eslint-disable max-len */
import { API_BASE_URL } from '../../utils/constants';
import HttpClient from '../../utils/http-client';

// const defaultParams = {
//   params: {
//     page_number: 1,
//     page_size: ROWS_PER_PAGE,
//     sort_field: 'customer_name',
//     sort: 'asc',
//   },
// };

class Conversations {
  getOverview = param =>
    HttpClient.post(`${API_BASE_URL}/v2/conversation/overview`, { ...param.params });
  // getOverview = param =>
  //   HttpClient.get(`${API_BASE_URL}/v1/conversation/overview`, {
  //     params: { ...param.params },
  //   });

  getCustomerOverviewAdmin = param =>
    HttpClient.get(`${API_BASE_URL}/v1/conversation/admin/overview`, {
      params: { ...param.params },
    });

  getAdminOverview = (param, workspace_id) =>
    HttpClient.post(
      `${API_BASE_URL}/v2/conversation/overview/workspace/${workspace_id}`,
      {...param.params},
    );
  // getAdminOverview = (param, workspace_id) =>
  //   HttpClient.get(
  //     `${API_BASE_URL}/v1/conversation/overview/workspace/${workspace_id}`,
  //     {
  //       params: { ...param.params },
  //     },
  //   );

  getConversationMetrics = (workspace_id, customer_id = '', data = {}) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/metrics/${workspace_id}${customer_id ? `?customer_id=${customer_id}` : ''}`, data
    );

  getWorkspaceConversationMetrics = (workspace_id, data = {}) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/metrics/workspace/${workspace_id}`, data
    );

  getRecordingCookie = () =>
    HttpClient.get(`${API_BASE_URL}/v1/conversation/captures/cookie`);

  getRecordingUrl = id =>
    HttpClient.get(
      `${API_BASE_URL}/v1/conversation/captures?conversation_ids=${id}`,
    );

  getStreamableRecordingUrl = conversation_id => 
      HttpClient.get(
        `${API_BASE_URL}/v1/conversation/${conversation_id}/recording/url`,
        { timeout: 10000 }
      );

  getConversationsForCustomer = (params = {}) =>
    HttpClient.get(`${API_BASE_URL}/v1/conversation`, { params });

  getConversationDetails = (conversationId: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/conversation/${conversationId}`);

  getConversationData = (convo_id: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/conversation/data/${convo_id}`);

  updateAction = (
    conversationId: number,
    actionId: string,
    markerName: string,
    state: number,
  ) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/${conversationId}/action/${actionId}`,
      { state, marker_name: markerName },
    );

  setNotesMarkerTimetamp = (
    conversation_id: number,
    participant_id: String,
    timestamp: any,
  ) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/user_marked/captures?conversation_id=${conversation_id}`,
      // eslint-disable-next-line object-shorthand
      { participant_id: participant_id, timeline_timestamp: timestamp },
    );

  setMarkerNotes = (id: any, notes: any, speakerName: any, speakerRole: any, speakerUserId: any) =>
    HttpClient.put(
      `${API_BASE_URL}/v1/conversation/user_marked/captures/${id}`,
      // eslint-disable-next-line object-shorthand
      { 
        notes,
        speakerName,
        speakerRole,
        speakerUserId, 
      },
    );

  deleteNotesMarker = (conversation_capture_id: number) =>
    HttpClient.delete(
      `${API_BASE_URL}/v1/conversation/user_marked/${conversation_capture_id}`,
    );

  updateNotes = (conversation_capture_id: number, markerNotes: string) =>
    HttpClient.put(
      `${API_BASE_URL}/v1/conversation/user_marked/captures/${conversation_capture_id}`,
      { notes: markerNotes },
    );

  getConversationCollaborators = (convo_id: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/conversation/${convo_id}/collaborators`);

  getAllAssigneeUsers = (workspace_id: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/conversation/assignee/${workspace_id}`);

  getUserMarkedCaptures = (convo_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/conversation/captures?conversation_ids=${convo_id}&capture_type=USER_MARKED_CAPTURE`,
    );

  getConversationActionItems = (convo_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/conversation/${convo_id}/captured/action-items`,
    );

  updateConversationCollaborators = (data = {}) =>
    HttpClient.post(`${API_BASE_URL}/v1/conversation/share`, {
      ...data,
    });

  removeConversationCollaborators = (data = {}) =>
    HttpClient.delete(`${API_BASE_URL}/v1/conversation/share`, {
      ...data,
    });

  getSharedCustomerConverationsList = (params = {}) =>
    HttpClient.get(`${API_BASE_URL}/v1/conversation/shared/groupby`, {
      params,
    });

  getRequestedConversationCollaborators = (conversation_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/conversation/${conversation_id}/requested-users`,
    );

  getConversationInferenceInsights = (conversation_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/conversation/${conversation_id}/ml-inference-insights`,
    );

  getSharedConverationsList = (params = {}) =>
    HttpClient.get(`${API_BASE_URL}/v1/conversation/shared/all`, {
      params,
    });

  getSearchSuggestions = (search_term: string, page_size: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/conversation/suggestions?page_size=${page_size}&search_term=${search_term}`,
    );

  getShareCookie = (conversation_id: number, org_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/conversation/share/captures/cookie?conversation_id=${conversation_id}&org_id=${org_id}`,
    );

  sendDataToHubspot = data =>
    HttpClient.post(
      `${API_BASE_URL}/v1/integrations/hubspot/send-post-call-summary`,
      {
        ...data,
      },
    );

  getHubspotData = (conversation_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/integrations/hubspot/${conversation_id}`,
    );
  
  initiateMultipartUpload = (reqData) => HttpClient.post(
    `${API_BASE_URL}/v1/conversation/multipart-upload`, {
      ...reqData
    }
  );

  getPresignedUrl = (reqData) => HttpClient.get(
    `${API_BASE_URL}/v1/conversation/multipart-upload/presigned-url?uploadId=${reqData.uploadId}&uploadKey=${reqData.uploadKey}&partNumber=${reqData.partNumber}`
  );

  completeMultipartUpload = (reqData) => HttpClient.post(
      `${API_BASE_URL}/v1/conversation/multipart-upload/complete`, {
        ...reqData
      }
  );

  uploadPart = async (uploadUrl: string, part: Blob): Promise<string> => {
    const response = await HttpClient.put(uploadUrl, part, {
        headers: { 'Content-Type': 'audio/mpeg' },
    });
    return response.headers.etag;
};
  
  recordingUploadSync = (formData) => HttpClient.post(
    `${API_BASE_URL}/v1/conversation/recording-upload-sync`, {
    ...formData
  }
  );

  getPitchQualityAnalysis = (conversation_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/conversations/${conversation_id}/pitch-quality-analysis`,
    );

  updatePitchQualityAnalysisFeedback = (
    conversationId: number,
    parameter_id: string,
    feedback: number,
    feedback_reason: string | null,
    feedback_timestamp: number,
    org_id: number,
    workspace_id: number,
    user_id: number,
    user_role: string,
    userScore: number
  ) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/conversations/${conversationId}/pitch-quality-analysis/feedback`,
      { parameter_id, feedback, feedback_reason, feedback_timestamp, org_id, workspace_id, user_id, user_role, ...(userScore ? { user_score: userScore }: {}) },
    );

  getQualificationQuestions = (org_id: number, conversation_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/conversation/workspaces/${org_id}/conversations/${conversation_id}/data-capture`,
    );

  updateQualificationQuestion = (
    org_id: number,
    conversationId: number,
    question_id: string,
    updated_value: string,
  ) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/workspaces/${org_id}/conversations/${conversationId}/data-capture`,
      { question_id, updated_value },
    );
   
  updatePitchQualityComment = (conversation_id: number, data: { overall_comment: string; }) => {
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/${conversation_id}/pitch-quality-comment`,
      data,
    );
  }

  getPitchQualityComment = (conversation_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/conversation/${conversation_id}/pitch-quality-comment`,
    );

  notifyPitchQualityComment = (conversation_id: number, comment: string) => {
    console.log(`${API_BASE_URL}/v1/conversation/${conversation_id}/notify-pitch-quality-comment`);
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/${conversation_id}/notify-pitch-quality-comment`, { comment }
    );
  }
    
  createMultiUploadCustomers = (data: { meta: { [key:string]: any }[]}) => 
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/multiple-upload/metadata`,
      data,
    );
    
  updateIntentFeedback = (conv_id, data) => 
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/${conv_id}/update-intent-feedback`,
      data,
    );
  
  reprocessConversation = (payload: { conversation_id: number, workspace_id: number, inference: string }) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/reprocess`,
      payload,
    );

  salesTrainingCall = (
    payload: {
      conversation_id: number | string;
      customer_id: number;
      conversation_ended: boolean;
      audio_bytes: string;
    } 
  ) => 
    HttpClient.post(
      `${API_BASE_URL}/v1/conversation/sales-training-conversation`,
      payload,
    )
}
const ConversationsService = new Conversations();
export default ConversationsService;
