import React from 'react';

import styles from './empty-search.module.css';

interface IEmptySearch {
  searchTerm: string | null;
}

const EmptySearch: React.FC<IEmptySearch> = props => {
  const { searchTerm } = props;
  return (
    <div className={styles['empty-search-container']}>
      {searchTerm !== undefined ? (
        <>
          <p className={styles['empty-search-header']}>
            No Search Results Found
          </p>
          <p className={styles['empty-search-content']}>
            {`We couldn’t find a match for “${searchTerm}”. Please try
                            another search.`}
          </p>
        </>
      ) : (
        <>
          <p className={styles['empty-search-header']}>No Results Found</p>
          {/* <p className={styles['empty-search-content']}>
            {`We couldn’t find a match for “${searchTerm}”. Please try
                          another search.`}
          </p> */}
        </>
      )}
    </div>
  );
};

export default EmptySearch;
