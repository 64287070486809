/* eslint-disable max-len */
import { useState } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import ChromeIcon from '../../public/icons/chrome.svg';

const ChromeExtensionModal = (props: { onClose: () => void }) => {
	const { onClose } = props;
  const [checked, setChecked] = useState<boolean>(false);
	const handleClose = () => {
		if (checked) {
			localStorage.setItem('ignoreChromeExtnInstallModal', 'true');
		}
		onClose()
	}
  return (
    <>
      <div className="fixed w-full h-full bg-primary-dark bg-opacity-25 backdrop-blur-[2px] z-10 top-0 left-0" />

      <div className="absolute top-0 right-0 h-[180px] w-[455px] bg-white rounded m-3 p-5 pr-6 z-20">
				<button 
					type="button"
					onClick={handleClose}
					className='p-1 bg-transparent cursor-pointer absolute top-1 right-1'
				>
					<IoCloseOutline className='w-5 h-5 text-tag-text-three' />
				</button>
				<div className="flex gap-4 ml-1">
          <div>
            <ChromeIcon className="w-9 h-9" />
          </div>
          <div className="text-sm text-[#000] font-medium">
            Install Zipteams chrome extension to get meeting alerts, 
            manage your availability and schedule your meetings while working on any tab.
          </div>
        </div>
        <div className="ml-[52px] mt-4">
          <a
						target='_blank'
            href='https://chromewebstore.google.com/detail/zipteams/obcjlbhpiidmfcohobdeemabmkchjbib'
            className="bg-secondary rounded-[150px] flex items-center justify-center py-3 p-4 w-[250px] text-white no-underline text-sm font-bold"
						rel="noreferrer"
					>
            Install Chrome Extension
          </a>
          <div className="mt-[18px]">
            <label
              htmlFor="dont-show"
              className="font-medium text-sm flex items-center text-[#000]"
            >
              <input
                name="dont-show"
                type="checkbox"
                className="outline-secondary border-tag-text-three block px-2 mr-3 text-secondary border rounded-sm appearance-none cursor-pointer"
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              Don’t show me again
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChromeExtensionModal;
