/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Popover, Transition } from '@headlessui/react';
import { Search } from 'lucide-react';
import { Fragment, useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';

type Props = {
	allUsers: {[key: string]: any}[];
	selectAssignee: (id: number) => void;
}

export default function AssigneeSelector(props: Props) {
	const { allUsers, selectAssignee } = props;
	const [query, setQuery] = useState<string>('');

	const filteredUsers =
    query === ''
      ? (allUsers || [])
      : (allUsers || []).filter(user => {
				const name = `${user?.first_name || ''}${user?.last_name || ''}`
				const email = user?.email;
				return (
					name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')) ||
					email.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
				)
			}).sort((a, b) => {
        if (a.first_name > b.first_name) return 1;
        if (a.first_name < b.first_name) return -1;
        return 0; 
      });

  return (
    <div className="inline-flex">
      <Popover className="relative flex items-center">
        {({ open }) => (
          <>
            <Popover.Button type="button">
              <FaCaretDown
                className="h-5 w-5 text-primary-dark"
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute top-6 right-0 z-10 mt-2 w-60">
                <div
                  className="border border-[#D7D7D7] bg-white rounded-lg 
									shadow-[0px_4px_12px_0px_rgba(28,29,33 0.15)] h-full max-h-72 flex flex-col pb-3"
                >
                  <div className="w-full px-4 py-3">
                    <div className="relative">
                      <Search className="w-4 h-4 text-secondary absolute left-3 top-1/2 -translate-y-1/2 " />
                      <input
                        type="text"
                        className="w-full rounded-lg border-[1.4px] border-[#D7D7D7] 
												focus:border-secondary focus:outline-none focus:ring-0 text-sm 
												px-3 py-2 pl-8 font-normal"
                        value={query}
                        onChange={event => setQuery(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="h-full overflow-auto flex-1">
                    {filteredUsers && filteredUsers.map(user => (
											<Popover.Button 
                        key={user.user_id}
                        onClick={() => {
                          setQuery('');
                          selectAssignee(user.user_id)
                        }}
                        className='block w-full text-left'
                      >
                        <div
                          className="px-3.5 py-2  bg-white hover:bg-[#EDEDED] font-medium cursor-pointer w-full"
                        >
                          <div className='mb-1 text-sm w-full max-w-[220px] truncate'>
                            {`${user?.first_name || ''}${
                              user?.last_name
                                ? ` ${user?.last_name}`
                                : ''
                            }`}
                          </div>
                          <div className='text-[#8F8F8F] font-normal text-sm w-full max-w-[220px] truncate'>
                            {user?.email}
                          </div>
                        </div>
                      </Popover.Button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
