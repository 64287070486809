/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Pagination } from '@material-ui/lab';
import styles from './Pagination.module.css';

interface IPagination {
  selectedPage: number;
  onPageChange: Function;
  totalCount: number;
}

const PaginationComponent: React.FC<IPagination> = ({
  selectedPage,
  onPageChange,
  totalCount,
}) => {
  const renderPagintionItems = item => {
    const { type } = item;
    switch (type) {
      case 'previous':
        return (
          <div
            className={`${styles['item-prev']} ${
              item?.selected ? styles['selected'] : ''
            } ${item?.disabled ? styles['disabled'] : ''}`}
            {...item}
            onClick={item?.disabled ? () => {} : item?.onClick}
          >
            Prev
          </div>
        );
      case 'next':
        return (
          <div
            className={`${styles['item-next']} ${
              item?.selected ? styles['selected'] : ''
            } ${item?.disabled ? styles['disabled'] : ''}`}
            {...item}
            onClick={item?.disabled ? () => {} : item?.onClick}
          >
            Next
          </div>
        );
      case 'page':
        return (
          <div
            className={`${styles['item']} ${
              item?.selected ? styles['selected'] : ''
            } ${item?.disabled ? styles['disabled'] : ''}`}
            {...item}
            onClick={item?.disabled ? () => {} : item?.onClick}
          >
            {item?.page}
          </div>
        );
      case 'start-ellipsis':
        return (
          <div className="ml-4" {...item} onClick={() => {}}>
            ...
          </div>
        );
      case 'end-ellipsis':
        return (
          <div className="ml-4" {...item} onClick={() => {}}>
            ...
          </div>
        );
      default:
        return (
          <div
            className={`${styles['item']} ${
              item?.selected ? styles['selected'] : ''
            } ${item?.disabled ? styles['disabled'] : ''}`}
            {...item}
            onClick={item?.disabled ? () => {} : item?.onClick}
          >
            {item?.page}
          </div>
        );
    }
  };
  return (
    <div className={styles['pagination-container']}>
      <Pagination
        count={totalCount}
        defaultPage={1}
        page={selectedPage}
        size="large"
        shape="rounded"
        variant="outlined"
        color="primary"
        onChange={(e, page) => onPageChange(page)}
        renderItem={renderPagintionItems}
        // renderItem={renderPagintionItems}
      />
    </div>
  );
};

export default PaginationComponent;
