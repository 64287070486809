import { alpha, Button, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useRouter } from 'next/router';
import parse from 'autosuggest-highlight/parse';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES } from '../../utils/constants';
import CustomerService from '../../services/customer/customer.service';
import MenuIcon from '../../public/icons/menu-icon.svg';
import SearchIcon from '../../public/icons/search-1.svg';
import CrossIcon from '../../public/icons/cross-icon.svg';
import styles from './Search.module.css';
import { getIndices } from '../../utils/helper';
import { SHOW_SIDEBAR } from '../../store/types';
import { searchEvent, searchSuggestionsEvent } from '../../lib/ga/home.events';
import ConversationsService from '../../services/conversations/conversations.service';
import { RootState } from '../../store/reducers';
import Datepicker from '../common/datepicker';

interface ISearchComponentProps {
  searchTerm: string;
  inUse: string;
  isAllConversation: boolean;
  isWorkspaceAdmin: boolean;
  setSearchDate?: Function;
  searchDate: string;
  startDate?: Date;
  endDate?: Date;
}

const useStyles = makeStyles({
  option: {
    '&[data-focus="true"]': {
      backgroundColor: `${alpha('#122832', 0.3)}`,
    },
  },
});

const SearchComponent: React.FC<ISearchComponentProps> = ({
  searchTerm,
  inUse,
  isAllConversation,
  isWorkspaceAdmin,
  setSearchDate,
  searchDate,
  startDate: startDateUrl,
  endDate: endDateUrl
}) => {
  const router = useRouter();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>(searchTerm);
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState([]);

  const { isManager, user, isAdmin } = useSelector((state: RootState) => state.common)

  useEffect(() => {
    setValue(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (searchDate) {
      setIsDateFilterApplied(true);
    }
  }, [searchDate]);

  useEffect(() => {
    if (startDateUrl && startDateUrl) {
      setStartDate(startDateUrl);
      setEndDate(endDateUrl);
    }
  }, [startDateUrl, endDateUrl]);

  const debounceSuggestions = useCallback(
    debounce(async (newValue: string) => {
      (async () => {
        setIsLoading(true);
        const workspace_id = JSON.parse(
          localStorage.getItem('selected_workspace'),
        )[0].id;
        const method =
          inUse === 'shared'
            ? ConversationsService.getSearchSuggestions
            : CustomerService.getSearchSuggestions;
        const { data } = await method(newValue, 50, workspace_id);
        searchSuggestionsEvent(newValue);
        if (inUse === 'shared') {
          const contactList = data?.data?.map(i => ({
            customer_id: i?.customer_id,
            customer_name: `${i?.customer_name} | ${i?.org_name}`,
            contact_name: i?.user_name,
          }));
          setOptions(contactList);
        } else {
          const customerList = data?.data?.customerList?.map(i => ({
            customer_id: i?.customer_id,
            customer_name: i?.customer_name,
            contact_name: i?.contact_name,
            contact_email: i?.contact_email,
            contact_phone: i?.contact_phone,
          }));

          const topicList = data?.data?.topicsList?.map(i => ({
            topic_name: i?.topic,
          }));
          setOptions([...customerList, ...topicList]);
        }
        setIsLoading(false);
      })();
    }, 400),
    [],
  );

  const debounceSuggestionsWorkspace = useCallback(
    debounce(async (newValue: string) => {
      (async () => {
        setIsLoading(true);
        const workspace: any =
          localStorage &&
          JSON.parse(localStorage.getItem('selected_workspace'));
        const workspace_id = workspace[0]?.id;
        const method = CustomerService.getSearchSuggestionsWorkspace;
        const { data } = await method(newValue, 50, workspace_id, !isAdmin && isManager ? user.id : null);

        const customerList = data?.data?.customerList?.map(i => ({
          customer_id: i?.customer_id,
          customer_name: i?.customer_name,
          contact_name: i?.contact_name,
          contact_email: i?.contact_email,
          contact_phone: i?.contact_phone,
        }));

        const topicList = data?.data?.topicsList?.map(i => ({
          topic_name: i?.topic,
        }));
        setOptions([...customerList, ...topicList]);
        setIsLoading(false);
      })();
    }, 400),
    [],
  );

  const handleSearch = newValue => {
    if (isAllConversation && isWorkspaceAdmin) {
      debounceSuggestionsWorkspace(newValue);
    } else debounceSuggestions(newValue);
  };

  const handleConversationSearch = async (
    search_term: string,
    customer_id: number,
  ) => {
    searchEvent(search_term);
    const payload: any = {};
    payload.search_term = value || search_term;

    if (customer_id) payload.customer_id = customer_id;
    router.push({
      pathname: `/${
        // eslint-disable-next-line no-nested-ternary
        inUse === 'shared'
          ? ROUTES.SHARED_WITH_YOU
          : isAllConversation && isWorkspaceAdmin
          ? `${ROUTES.DASHBOARD}/admin`
          : ROUTES.DASHBOARD
      }`,
      query: payload,
    });
  };

  const handleClearSearchClick = () => {
    setValue('');
    if (Object.keys(router.query).length) {
      if (isAllConversation && isWorkspaceAdmin) {
        router.push({
          pathname: `/${ROUTES.ALLCONVERSATIONS}`,
          query: {
            page_number: 1,
          },
        });
      } else if (inUse !== 'shared') {
        router.push({
          pathname: `/${ROUTES.DASHBOARD}`,
          query: {
            page_number: 1,
          },
        });
      } else {
        router.push({
          pathname: `/${ROUTES.SHARED_WITH_YOU}`,
        });
      }
    }
  };

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [isDateFilterApplied, setIsDateFilterApplied] = useState<boolean>(false);

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchDate(null);
    setIsDateFilterApplied(false);
  }

  const handleApply = () => {
    const date = [startDate, endDate]
    setSearchDate(JSON.stringify(date));
    setIsDateFilterApplied(true);
  }

  return (
    <div
      className={`${styles['search-container']} ${
        inUse === 'shared' ? styles['shared'] : ''
      }`}
    >
      <div className={styles['field-box']}>
        <Autocomplete
          fullWidth
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          blurOnSelect
          options={options}
          getOptionLabel={option =>
            option?.customer_name ||
            option?.topic_name ||
            option?.contact_email ||
            option?.contact_phone
          }
          loading={isLoading}
          loadingText="Searching ..."
          noOptionsText="No matches found"
          onChange={(
            e,
            {
              customer_name,
              customer_id,
              topic_name,
              contact_email,
              contact_phone,
            },
          ) => {
            setValue(
              customer_name || topic_name || contact_email || contact_phone,
            );
            handleConversationSearch(
              customer_name || topic_name || contact_email || contact_phone,
              customer_id,
            );
          }}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                {...params.inputProps}
                value={value}
                type="text"
                placeholder={`Search by Company/Contact Name${
                  inUse === 'shared'
                    ? ''
                    : ', Email, Phone or Conversation Topics'
                } `}
                className={`${styles['search-field']} ${
                  inUse === 'shared' ? styles['search-field-shared'] : ''
                }`}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    setOpen(false);
                    handleConversationSearch(null, null);
                  }
                }}
                onChange={event => {
                  const { target } = event;
                  setValue(target.value);
                  if (target.value !== '') {
                    setOpen(true);
                    handleSearch(target.value);
                  } else {
                    setOpen(false);
                  }
                }}
              />
            </div>
          )}
          ListboxProps={{ style: { maxHeight: '100px'  } }}
          renderOption={option => {
            const custName = option?.customer_name;
            const contName = option?.contact_name;
            const contEmail = option?.contact_email;
            const contPhone = option?.contact_phone;

            const topicName = option?.topic_name;

            const custNameMatches = getIndices(value, custName, false);
            const contNameMatches = getIndices(value, contName, false);
            const contEmailMatches = getIndices(value, contEmail, false);
            const contPhoneMatches = getIndices(value, contPhone, false);

            const topicNameMatches = getIndices(value, topicName, false);

            const custNameParts = parse(custName, custNameMatches);
            const contNameParts = parse(contName, contNameMatches);
            const contEmailParts = parse(contEmail, contEmailMatches);
            const contPhoneParts = parse(contPhone, contPhoneMatches);

            const topicNameParts = parse(topicName, topicNameMatches);

            return (
              <div className="flex w-full">
                {custName ? (
                  <div className="flex w-full">
                    <img src="/icons/contact.svg" alt="contact" />
                    <div className="text-base text-primary-dark w-full ml-2 flex flex-row justify-between">
                      <div className="">
                        <div className="text-sm lg:text-base">
                          {custNameParts?.map((part, index) => (
                            <span
                              key={index}
                              className={
                                part.highlight
                                  ? 'font-bold text-black'
                                  : 'font-normal'
                              }
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                        {custName !== contName && (
                          <div className="text-xxs lg:text-xs">
                            {contNameParts?.map((part, index) => (
                              <span
                                key={index}
                                className={
                                  part.highlight
                                    ? 'font-bold text-black'
                                    : 'font-normal'
                                }
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="text-xxs lg:text-base">
                          {contEmailParts?.map((part, index) => (
                            <span
                              key={index}
                              className={
                                part.highlight
                                  ? 'font-bold text-black'
                                  : 'font-normal'
                              }
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                        <div className="text-xxs lg:text-xs text-right">
                          {contPhoneParts?.map((part, index) => (
                            <span
                              key={index}
                              className={
                                part.highlight
                                  ? 'font-bold text-black'
                                  : 'font-normal'
                              }
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <img src="/icons/search-capture.svg" alt="capture topic" />
                    <div className="text-base text-primary-dark w-full ml-2">
                      <div className="text-sm lg:text-base">
                        {topicNameParts?.map((part, index) => (
                          <span
                            key={index}
                            className={
                              part.highlight
                                ? 'font-bold text-black'
                                : 'font-normal'
                            }
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
          classes={{
            paper: styles['search-menu'],
            option: classes.option,
          }}
        />
        {inUse !== 'shared' && (
          <Button
            className={styles['menu-button']}
            disableRipple
            disableElevation
            onClick={() => dispatch({ type: SHOW_SIDEBAR })}
          >
            <MenuIcon />
          </Button>
        )}
        <Button
          className={`${styles['search-icon']} ${
            inUse === 'shared' ? styles['search-icon-shared'] : ''
          }`}
          disableRipple
          disableElevation
        >
          <SearchIcon className="w-4 h-4 text-secondary fill-current" />
        </Button>
        <div className={styles['icon-actions']}>
          {value && (
            <Button
              className={styles['clear-button']}
              disableRipple
              disableElevation
              onClick={handleClearSearchClick}
            >
              <CrossIcon className="w-2 h-2 lg:w-3 lg:h-3" />
            </Button>
          )}
          <Button
            className={styles['search-button']}
            disableRipple
            disableElevation
            onClick={() => handleConversationSearch(null, null)}
          >
            <SearchIcon className="w-4 h-4 text-primary fill-current" />
          </Button>
        </div>
      </div>
      <Datepicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleApply={handleApply}
        handleReset={handleReset}
      />
      {
        isDateFilterApplied && 
        <div className='hidden lg:inline-flex justify-center items-center w-3 h-3 bg-secondary text-white 
          font-bold rounded-full text-[8px] -mt-6 -ml-2 text-center'
        >
          1
        </div>
      }
      {/* <Button
        color="primary"
        variant="contained"
        className={styles['search-button-mob']}
        disableRipple
        disableElevation
        onClick={() => handleConversationSearch(null, null)}
      >
        <SearchIcon />
      </Button> */}
      {/* <Button
        color="primary"
        variant="contained"
        className={styles['search-button']}
        disableRipple
        disableElevation
        onClick={() => handleConversationSearch(null, null)}
      >
        Search Conversations
      </Button> */}
    </div>
  );
};

export default SearchComponent;
