import { Button, Modal } from '@material-ui/core';
import Lottie from 'lottie-react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './unlock-insights-modal.module.css';
import RecIcon from '../../public/icons/onb_rec.svg';
import InfoIcon from '../../public/icons/onb_info.svg';
import FaceIcon from '../../public/icons/onb_smile.svg';
import LockJSON from '../../public/assets/lock.json';
import CelebrationJSON from '../../public/assets/celebration.json';
import { insightsUnlockedDashboardButtonClicked } from '../../lib/ga/onboarding.events';
import { RootState } from '../../store/reducers';
import { SAVE_USER } from '../../store/types';
import UserService from '../../services/user/user.service';

type InsightsUnlockedModalProps = {
  open: boolean;
  isUnlocked: boolean;
  handleClose: () => void;
};

const UnlockedInsightsModal = (
  props: InsightsUnlockedModalProps,
): JSX.Element => {
  const { open, handleClose, isUnlocked } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.common);

  const updateUser = async () => {
    const response = await UserService.me();
    dispatch({
      type: SAVE_USER,
      payload: { ...response?.data?.data },
    });
  };

  return (
    <Modal
      className={styles['modal']}
      open={open}
      onClose={(event, reason) =>
        reason !== 'backdropClick' ? handleClose() : () => {}
      }
      disableEscapeKeyDown
    >
      <div className={styles['inner-container']}>
        <div className={styles['dialog-content']}>
          <div className={styles['page']}>
            <div className={styles['content']}>
              <div className="flex justify-center">
                <Lottie
                  animationData={isUnlocked ? CelebrationJSON : LockJSON}
                  loop
                  autoplay
                  className={`${
                    isUnlocked ? 'ml-6' : ''
                  } w-14 lg:w-26 h-14 lg:h-26`}
                />
              </div>
              <div className={`${styles['header']}`}>
                {isUnlocked
                  ? 'Congrats! Your AI insights are now unlocked'
                  : 'Your insights get unlocked after your first 10 minute meeting'}
              </div>
              <div className={styles['description']}>
                <div className={styles['row']}>
                  <div className={styles['row-header']}>
                    <RecIcon className="w-4 h-4 mr-1" />
                    <span>Complete recording of your call</span>
                  </div>
                  <div className={styles['row-desc']}>
                    Your meeting room includes intelligent insights about your
                    guests and it enables you to easily record and store
                    important meeting minutes
                  </div>
                </div>
                <div className={styles['row']}>
                  <div className={styles['row-header']}>
                    <InfoIcon className="w-4 h-4 mr-1" />
                    <span>AI Identified Questions & Next Steps</span>
                  </div>
                  <div className={styles['row-desc']}>
                    All questions and next steps discussed in the meeting are
                    automatically tagged for you to take your followup actions
                    easily.
                  </div>
                </div>
                <div className={styles['row']}>
                  <div className={styles['row-header']}>
                    <FaceIcon className="w-4 h-4 mr-1" />
                    <span>Sentiment & Involvement of your guests</span>
                  </div>
                  <div className={styles['row-desc']}>
                    Get to know how much each of your guests were involved in
                    the conversation and what was their sentiment during the
                    meeting
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['actions-container']}>
              <Button
                color="primary"
                variant="contained"
                className={`${styles['action-button']}`}
                disableElevation
                disableRipple
                onClick={
                  isUnlocked
                    ? async () => {
                        insightsUnlockedDashboardButtonClicked();
                        handleClose();
                        await UserService.update(user?.id, { meta: { insights_unlocked_viewed: true } });
                        await updateUser();
                      }
                    : () => {
                        handleClose();
                      }
                }
              >
                Go to Dashboard
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UnlockedInsightsModal;
