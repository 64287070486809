import { event } from './ga';

export const homePageListErrorEvent = (errorMessage) => {
  event({
    action: "home_page_list_error_event",
    params: {
      'event_category': 'conversation_overview_list',
      'event_label': 'home page list error event',
      'event_value': errorMessage
    }
  })
}

export const shareClickEvent = () => {
  event({
    action: "share_click_event",
    params: {
      'event_category': 'share',
      'event_label': 'share click event'
    }
  })
}

export const copyClickShareEvent = () => {
  event({
    action: "copy_click_event",
    params: {
      'event_category': 'share',
      'event_label': 'copy click event'
    }
  })
}

export const inviteClickShareEvent = () => {
  event({
    action: "invite_click_event",
    params: {
      'event_category': 'share',
      'event_label': 'invite click event'
    }
  })
}

export const closeShareModalEvent = () => {
  event({
    action: "close_share_modal_event",
    params: {
      'event_category': 'share',
      'event_label': 'close share modal event'
    }
  })
}

export const shareModalClickEvent = () => {
  event({
    action: "share_modal_click_event",
    params: {
      'event_category': 'conversation_list',
      'event_label': 'share modal click event'
    }
  })
}

export const editCustomerModalClickEvent = () => {
  event({
    action: "edit_customer_modal_click_event",
    params: {
      'event_category': 'conversation_list',
      'event_label': 'edit customer modal click event'
    }
  })
}

export const deleteCustomerModalClickEvent = () => {
  event({
    action: "delete_customer_modal_click_event",
    params: {
      'event_category': 'conversation_list',
      'event_label': 'delete customer modal click event'
    }
  })
}

export const scheduleCalendarClickEvent = () => {
  event({
    action: "schedule_calendar_click_event",
    params: {
      'event_category': 'conversation_list',
      'event_label': 'schedule calendar click event'
    }
  })
}

export const joinMeetingClickEvent = () => {
  event({
    action: "join_meeting_click_event",
    params: {
      'event_category': 'conversation_list',
      'event_label': 'join meeting click event'
    }
  })
}

export const cancelNewCustomerEvent = () => {
  event({
    action: "cancel_new_customer_event",
    params: {
      'event_category': 'add_customer',
      'event_label': 'cancel new customer event'
    }
  })
}

export const addNewCustomerEvent = () => {
  event({
    action: "add_new_customer_event",
    params: {
      'event_category': 'add_customer',
      'event_label': 'add new customer event'
    }
  })
}

export const sameAsCustomerNameEvent = () => {
  event({
    action: "same_as_customer_name_event",
    params: {
      'event_category': 'add_customer',
      'event_label': 'same as customer name ticked event'
    }
  })
}

export const meetingUrlClickEvent = () => {
  event({
    action: "meeting_url_click_event",
    params: {
      'event_category': 'share',
      'event_label': 'meeting url click event'
    }
  })
}

export const pageChangeEvent = (action: string) => {
  const pageMap = {
    next: { action: 'next_page', label:  'next page'}, 
    previous: { action: 'previous_page', label: 'previous page'}, 
  }
  event({
    action: pageMap[action].action,
    params: {
      'event_category': 'conversation_list',
      'event_label': pageMap[action].label
    }
  })
}

export const searchSuggestionsEvent = (searchTerm) => {
  event({
    action: "search_suggestions_event",
    params: {
      'event_category': 'search',
      'event_label': 'search suggestions event',
      'event_value': searchTerm
    }
  })
}

export const searchEvent = (searchTerm: string) => {
  event({
    action: "search_event",
    params: {
      'event_category': 'search',
      'event_label': 'search event',
      'event_value': searchTerm
    }
  })
}

export const insightsClickEvent = () => {
  event({
    action: "insights_click_event",
    params: {
      'event_category': 'conversation_list',
      'event_label': 'insights click event'
    }
  })
}


export const optionsClickEvent = () => {
  event({
    action: "kebab_menu_click_event",
    params: {
      'event_category': 'conversation_list',
      'event_label': 'kebab menu click event'
    }
  })
}

export const homePageListSortEvent = (headerValue) => {
  const headerEventMap = {
    customer_name: { action: 'home_page_customer_name_sort_event', label:  'home page list customer name sort event'}, 
    last_conversation_at: { action: 'home_page_last_conversation_at_sort_event', label: 'home page list last conversation at sort event'}, 
    next_scheduled_meeting: {action: 'home_page_next_meeting_sort_event', label:  'home page list next scheduled meeting sort event'}, 
    crm_status: { action: 'home_page_crm_status_sort_event', label: 'home page list crom status sort event' }
  };
  const eventAction = headerEventMap[headerValue] ? headerEventMap[headerValue].action : '';
  const eventLabel = headerEventMap[headerValue] ? headerEventMap[headerValue].label : '';
  if(eventAction !== '' && eventLabel !== ''){
    event({
      action: eventAction,
      params: {
        'event_category': 'conversation_overview_list_sort',
        'event_label': eventLabel
      }
    })
  }
}

export const homePageListTagClickEvent = (tagValue: string) => {
  event({
    action: "home_page_list_tag_click_event",
    params: {
      'event_category': 'conversation_overview_list',
      'event_label': 'home page list tag click event',
      'event_value': tagValue
    }
  })
}

export const homePageListTagMoreClickEvent = () => {
  event({
    action: "home_page_list_tag_more_click_event",
    params: {
      'event_category': 'conversation_overview_list',
      'event_label': 'home page list tag more click event'
    }
  })
}

export const removeMeetingClickEvent = () => {
  event({
    action: "remove_meeting_click_event",
    params: {
      'event_category': 'conversation_list',
      'event_label': 'remove meeting click event'
    }    
  })
}

export const removeMeetingConfirmedEvent = () => {
  event({
    action: "remove_meeting_confirmed_event",
    params: {
      'event_category': 'conversation_list',
      'event_label': 'remove meeting confirmed event'
    }
  })
}