/* eslint-disable import/extensions */
import { combineReducers } from 'redux';
import CommonReducer from './common.reducer';

const rootReducers = combineReducers({
  common: CommonReducer,
});

export default rootReducers;
export type RootState = ReturnType<typeof rootReducers>;
