/* eslint-disable no-nested-ternary */

"use client"

import * as React from "react"
import { format, subDays, subMonths } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { DateRange } from "react-day-picker"

import { cn } from "../../lib/utils"
import { Button } from "./button"
import { Calendar } from "./calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  selectedDate: DateRange
  setSelectedDate: React.Dispatch<React.SetStateAction<DateRange>>
}
export function DatePickerWithRange({
  selectedDate,
  setSelectedDate,
  className,
}: Props) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: selectedDate?.from,
    to: selectedDate?.to,
  });

  React.useEffect(() => {
    setDate(selectedDate);
  }, [selectedDate])

  React.useEffect(() => {
    if (date?.from && date?.to) {
      setSelectedDate(date);
    }
  }, [date])

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant="outline"
            className={cn(
              "w-full justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
            onClick={() => setOpen(true)}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 pointer-events-auto z-[60] flex flex-col" align="start">
          <Calendar
            mode="range"
            defaultMonth={subMonths(new Date(), 1)}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
            fromDate={subDays(new Date(), 31)}
            toDate={new Date()}
          />
          <Button className="ml-auto mr-1 mb-1" variant="secondary" onClick={() => setOpen(false)}>Select</Button>
        </PopoverContent>
      </Popover>
    </div>
  )
}
