/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { IconButton } from '@material-ui/core';
import Link from 'next/link';
import * as Sentry from "@sentry/nextjs";

import {
  MdArrowDropDown,
  MdMailOutline,
  MdPersonOutline,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlinePhone, HiOutlineVideoCamera } from 'react-icons/hi';
import styles from './sidebar.module.css';
import ConversationIcon from '../../public/icons/conversation-icon.svg';
import SetupIcon from '../../public/icons/setup-icon.svg';
import SpeechInfoIcon from '../../public/icons/icon-speech-info.svg';
import ShareIcon from '../../public/icons/shared-page-icon.svg';
import ChartIcon from '../../public/icons/chart-icon.svg';
import AllConversationIcon from '../../public/icons/all-conversations.svg';
import {
  PRIVACY_POLICY_LINK_URL,
  ROUTES,
  TNC_LINK_URL,
  HELP_CENTER_LINK_URL,
} from '../../utils/constants';
import AvailabilitySwitch from '../AvailabilitySwitch/availability-switch.component';
// import VIPIcon from '../../public/icons/vip.svg';
import OpenArrowIcon from '../../public/icons/open-arrow.svg';
import WorkspaceSelectionList from '../WorkspaceSelectionList/WorkspaceSelectionList.component';
import { RootState } from '../../store/reducers';
import { METABASE_DASHBOARDS, SET_SELECTED_WORKSPACE, REALTIME_WIDGET_AVAILABILITY, SHOW_SNACKBAR, SAVE_USER } from '../../store/types';
import UserService from '../../services/user/user.service';
import OrganisationService from '../../services/organisation/organisation.service';
import BugIcon from '../../public/icons/bug.svg';
import ReportIssueModal from '../report-issue-modal/report-issue-modal';

const Sidebar = ({
  open,
  handleClose,
  isWorkspaceAdmin,
  branding,
  realtimeWidget,
  userImg,
  name,
  workspaceAdminDetails,
}) => {
  const router = useRouter();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [selectedListAction, setSelectedListAction] = useState<string>('');
  const [activeWorkspace, setActiveWorkspace] = useState<string>('');
  const [showReportIssueBox, setShowReportIssueBox] = useState<boolean>(false);
  const [issueText, setIssueText] = useState<string>('');
  const [successPage, setSuccessPage] = useState<boolean>(false);
  const sidebarRef = useRef(null);
  const [isWpAdmin, setIsWpAdmin] = useState<boolean>(false);
  const { 
    workspaces,
    metabaseDashboards,
    isAdmin,
    isSuperAdmin,
    isManager,
    user,
    selectedWorkspace,
  } = useSelector(
    (state: RootState) => state?.common,
  );
  const listActions = [
    {
      icon: <ConversationIcon className="w-4 h-4 fill-current" />,
      label: 'My Conversations',
    },
    ...(isManager 
      ? [
          {
            icon: <AllConversationIcon className="h-4 w-4 fill-current" />,
            label: 'All Conversations',
          }
        ]
      : []),
    {
      icon: <ShareIcon className="h-4 w-4 fill-current" />,
      label: 'Shared With Me',
    },
    {
      icon: <SpeechInfoIcon className="w-4 h-4 fill-current" />,
      label: 'Help Center',
    },
  ];

  const listActionsAdmin = [
    {
      icon: <SetupIcon className="w-4 h-4 fill-current" />,
      label: 'Setup',
    },
    ...(isAdmin &&metabaseDashboards && Boolean(metabaseDashboards?.length)
      ? [
          {
            icon: <ChartIcon className="w-4 h-4 fill-current" />,
            label: 'Conversations Insights',
          },
        ]
      : []),
    {
      icon: <ConversationIcon className="w-4 h-4 fill-current" />,
      label: 'My Conversations',
    },
    {
      icon: <ShareIcon className="h-4 w-4 fill-current" />,
      label: 'Shared With Me',
    },
    {
      icon: <AllConversationIcon className="h-4 w-4 fill-current" />,
      label: 'All Conversations',
    },
    {
      icon: <SpeechInfoIcon className="w-4 h-4 fill-current" />,
      label: 'Help Center',
    },
  ];

  const handleActionClick = () => {
    if (successPage) {
      setSuccessPage(false);
      setShowReportIssueBox(false);
    } else {
      const eventId = Sentry.captureMessage('User Feedback');
      const userFeedback = {
        event_id: eventId,
        name: user.first_name,
        email: user.email,
        userId: user.id,
        comments: issueText,
      };
      Sentry.captureUserFeedback(userFeedback);
      setIssueText('');
      setSuccessPage(true);
    }
  }

  const handleSecondaryAction = () => {
    setSuccessPage(false);
  }

  const handleReportIssueModalClose = () => {
    setShowReportIssueBox(false);
  }


  useEffect(() => {
    setIsSidebarOpen(open);
  }, [open]);

  useEffect(() => {
    if (!isSidebarOpen) {
      sidebarRef.current.classList.add('-translate-x-full');
    } else {
      sidebarRef.current.classList.remove('-translate-x-full');
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (router?.asPath.startsWith(`/${ROUTES.CONVERSATIONS_DASHBOARD}`)) {
      setSelectedListAction('Conversations Insights');
    }
    if (router?.asPath.startsWith(`/${ROUTES.DASHBOARD}`)) {
      setSelectedListAction('My Conversations');
    }
    if (router?.asPath.startsWith(`/${ROUTES.SHARED_WITH_YOU}`)) {
      setSelectedListAction('Shared With Me');
    }
    if (router?.asPath.startsWith(`/${ROUTES.PROFILE}`)) {
      setSelectedListAction('Profile');
    }
    if (router?.asPath.startsWith(`/${ROUTES.DASHBOARD}/admin`)) {
      setSelectedListAction('All Conversations');
    }
  }, [router]);

  const handleListItemClick = label => {
    handleClose();
    if (selectedListAction === label) return;
    if (label === 'My Conversations') {
      setSelectedListAction('My Conversations');
      router.push(`/${ROUTES.DASHBOARD}`);
    } else if (label === 'Conversations Insights') {
      setSelectedListAction('Conversations Insights');
      router.push(`/${ROUTES.CONVERSATIONS_DASHBOARD}`);
    } else if (label === 'Setup') {
      setSelectedListAction('Setup');
      router.push(`/${ROUTES.WORKSPACE}`);
    } else if (label === 'All Conversations') {
      setSelectedListAction('All Conversations');
      router.push(`/${ROUTES.DASHBOARD}/admin`);
    } else if (label === 'Shared With Me') {
      setSelectedListAction('Shared With Me');
      router.push(`/${ROUTES.SHARED_WITH_YOU}`);
    } else if (label === 'Terms & Conditions') {
      setSelectedListAction('Terms & Conditions');
      window.open(TNC_LINK_URL, '_blank').focus();
      setTimeout(() => {
        setSelectedListAction('My Conversations');
      }, 300);
    } else if (label === 'Privacy Policy') {
      setSelectedListAction('Privacy Policy');
      window.open(PRIVACY_POLICY_LINK_URL, '_blank').focus();
      setTimeout(() => {
        setSelectedListAction('My Conversations');
      }, 300);
    } else if (label === 'Help Center') {
      setSelectedListAction('Help Center');
      window.open(HELP_CENTER_LINK_URL, '_blank').focus();
      setTimeout(() => {
        setSelectedListAction('My Conversations');
      }, 300);
    }
  };

  const [optionButtonEl, setOptionButtonEl] = useState<any>(null);

  const dispatch = useDispatch();
  const handleSelectWorkspace = async item => {
    const filtered = workspaces?.find(
      workspaceItem => workspaceItem?.id === item?.id,
    );
    dispatch({
      type: SET_SELECTED_WORKSPACE,
      payload: [filtered],
    });
    // await UserService.setPreferredWorkspace(filtered?.id);
    localStorage.setItem('selected_workspace', JSON.stringify([filtered]));
    await updateUser();
    handleMenuClose();
    if (typeof filtered?.id === "number" && (isAdmin || isSuperAdmin)) {
      await getMetabaseDashboard(filtered?.id);
    }
    window.location.reload();
  };

  const updateUser = async () => {
    const response = await UserService.me();
    dispatch({
      type: SAVE_USER,
      payload: { ...response?.data?.data },
    });
  };

  const getMetabaseDashboard = async id => {
    const metabaseDashboardResponse =
      await OrganisationService.getMetabaseDashboard(id);
    const metabaseDashboardsData = metabaseDashboardResponse?.data.data;
    dispatch({
      type: METABASE_DASHBOARDS,
      payload: metabaseDashboardsData,
    });
  };

  const handleWorkspaceList = event => {
    event.preventDefault();
    event.stopPropagation();
    setOptionButtonEl(true);
  };

  const handleMenuClose = () => {
    setOptionButtonEl(null);
  };

  const { realtimeWidget: { availability } } = useSelector((state: RootState) => state.common);
  const [isAvailable, setIsAvailable] = useState(Boolean(availability));

  const toggleInstantMeetingAvailability = async () => {
    const audio = new Audio('/assets/sweet_text.ogg');
    audio.muted = true;
    audio.play();
    try {
      const realtime_meeting_availaibility = !isAvailable;
      const userData: any =
        localStorage && JSON.parse(localStorage.getItem('user_data'));
        let count = JSON.parse(localStorage.getItem('availability_toggle'))?.count||0;
        if(realtime_meeting_availaibility){
          localStorage.setItem(
            'availability_toggle',
            JSON.stringify({count:++count}),
          );
          
        }
      await UserService.update(userData?.id, {
        meta: { realtime_meeting_availaibility },
      });
      // await updateUser();
      setIsAvailable(realtime_meeting_availaibility);
      dispatch({
        type: REALTIME_WIDGET_AVAILABILITY,
        payload: {
          availability: realtime_meeting_availaibility,
        },
      });
    } catch (err) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: 'error',
          message: 'Failed to toggle your availability. Please try again.',
        },
      });
    }
  };

  useEffect(() => {
    const workspace = workspaces && workspaces.length && workspaces.find((item) => item.id === user?.workspace_preference_id);
    setActiveWorkspace(workspace?.name);
  }, [user, workspaces]);

  useEffect(() => {
    setIsAvailable(Boolean(availability));
  }, [availability]);

  useEffect(() => {
    setIsWpAdmin(isAdmin);
  }, [isAdmin])

  return (
    <>
      {showReportIssueBox ? (
        <ReportIssueModal
          isMenuOpen={false}
          closeModal={handleReportIssueModalClose}
          issueText={issueText}
          successPage={successPage}
          updateIssueText={setIssueText}
          handlePrimaryAction={handleActionClick}
          handleSecondaryAction={handleSecondaryAction}
        />
      ) : null}
      <div
        onClick={handleClose}
        className={`${isSidebarOpen ? 'block' : 'hidden'} ${styles['sidebar-overlay']
          }`}
      />
      <div
        ref={sidebarRef}
        // eslint-disable-next-line max-len
        className="h-screen flex flex-col z-30 bg-white text-blue-100 w-72 absolute inset-y-0 left-0 transform -translate-x-full transition duration-200 ease-in-out"
      >
        <div className={styles['sidebar-header']}>
          <a className="flex items-center">
            <img
              src="/icons/logo-short-inverted.svg"
              alt="header-logo"
              className={styles['logo']}
            />
            <span className={styles['branding']}>
              <div className='flex flex-col gap-1'>
                {branding.headerText || ''}
                {
                  workspaces && workspaces?.length > 1 && (
                    <span className='font-medium text-xs text-audio-timeline whitespace-nowrap overflow-ellipsis overflow-hidden mr-1'>
                      {activeWorkspace}
                      {selectedWorkspace?.[0]?.subscription_tier === 'enterprise_tier' &&
                      isSuperAdmin && (
                        <MdArrowDropDown
                          className="inline-block"
                          onClick={handleWorkspaceList}
                        />
                      )} 
                    </span>
                  )
                }
              </div>
            </span>
          </a>

          <span className="w-4 absolute top-8 left-40">
            <WorkspaceSelectionList
              el={optionButtonEl}
              handleMenuClose={handleMenuClose}
              handleSelectWorkspace={handleSelectWorkspace}
              isHeader
              workspaces={workspaces}
            />
          </span>
          <span className="absolute top-5 -right-2">
            <IconButton
              style={{ display: isSidebarOpen ? 'block' : 'none' }}
              className={styles['menu-toggle-button']}
              onClick={handleClose}
            >
              <OpenArrowIcon className="text-white fill-current w-2 h-3 transition-all duration-300 transform rotate-180" />
            </IconButton>
          </span>
        </div>
        <div className={styles['sidebar-main']}>
          <div className={styles['sidebar-list-container']}>
            <ul className={styles['sidebar-list']}>
              {isAdmin
                ? listActionsAdmin &&
                listActionsAdmin.map((item, index) => (
                  <li
                    key={index}
                    className={`${styles['list-item']} ${selectedListAction === item?.label
                        ? styles['selected']
                        : ''
                      }`}
                    onClick={() => handleListItemClick(item?.label)}
                  >
                    {item?.icon}
                    <span className="ml-3">{item.label}</span>
                  </li>
                ))
                : listActions &&
                listActions.map((item, index) => (
                  <li
                    key={index}
                    className={`${styles['list-item']} ${selectedListAction === item?.label
                        ? styles['selected']
                        : ''
                      }`}
                    onClick={() => handleListItemClick(item?.label)}
                  >
                    {item?.icon}
                    <span className="ml-3">{item.label}</span>
                  </li>
                ))}
              {/* <li className={`${styles['list-item']} ${styles['banner']}`}>
                <VIPIcon className="w-11 h-9" />
                <span
                  className="font-normal text-secondary text-xs leading-3.5 p-2 w-3/5 text-center ml-1 border border-secondary"
                  style={{ backgroundColor: '#E1EBEF', borderRadius: '4px' }}
                >
                  Feels awesome to have you on our premium trial
                </span>
              </li> */}
            </ul>
          </div>
          {workspaceAdminDetails && (
            <div className={` w-10/12 mb-2`}>
              <div
                className={`${styles['support-border']} px-4 flex flex-col text-white text-xs `}
              >
                <div>
                  <h3 className="mt-2 font-semibold ">Your Admin</h3>
                  <div className="mt-2">
                    <MdPersonOutline className="inline h-4 w-4 pb-0.5 mr-0.5" />
                    {`${workspaceAdminDetails?.first_name} ${workspaceAdminDetails?.last_name || ''
                      }`}
                  </div>
                  <div className=" text-audio-timeline mt-0.5 mb-2">
                    <a href={`mailto:${workspaceAdminDetails?.email}`}>
                      <MdMailOutline className="inline h-3.5 w-3.5 pb-0.5 mr-1 text-white" />
                      {workspaceAdminDetails?.email}
                    </a>
                  </div>
                </div>
                <div className={styles['border-seperator']} />
                <div>
                  <h3 className="my-2 font-semibold">Our Support</h3>
                  <div className="mt-2">
                    <a href="mailto:hello@zipteams.com">
                      <MdMailOutline className="inline h-3.5 w-3.5 pb-0.5 mr-1 text-white" />
                      hello@zipteams.com
                    </a>
                  </div>
                  <div className="my-1">
                    <HiOutlinePhone className="inline h-3.5 w-3.5 pb-0.5 mr-1" />
                    +91 9653198284
                  </div>
                  <div className=" mb-2 underline">
                    <a
                      href="https://zipme.at/zipteams"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <HiOutlineVideoCamera className="inline h-4 w-4 pb-0.5 mr-1" />
                      zipme.at/zipteams
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='w-full my-2 text-white'>
            <div className="pl-4.5 flex gap-3 items-center cursor-pointer" onClick={() => {
                handleClose();
                setShowReportIssueBox(true);
              }}>
              <BugIcon
                className="w-5 h-5 fill-current"
                style={{ minWidth: '20px' }}
              />
              <span
                className={`'w-full font-normal leading-4.5 overflow-hidden whitespace-nowrap text-sm`}
              >
                Report an issue
              </span>
            </div>
          </div>
          <div className="flex flex-col mb-14">
            {realtimeWidget.enabled && (
              <div className={styles['availability-switch']}>
                <AvailabilitySwitch
                  className={styles['switch']}
                  screen="toggleAvailability"
                  handleOnChange={toggleInstantMeetingAvailability}
                  isAvailable={isAvailable}
                />
                <span className={styles['label']}>
                  Toggle your availability for instant meeting requests
                </span>
              </div>
            )}
            <Link href={`/${ROUTES.WORKSPACE}?selected=my_profile`}>
              <a className="cursor-pointer" onClick={handleClose}>
                <div className="flex items-center pl-3">
                  {userImg ? (
                    <object
                      data={userImg}
                      type="image/png"
                      className="w-9 h-9 rounded-full bg-white flex justify-center items-center text-primary"
                    />
                  ) : (
                    <img
                      className="w-9 h-9 rounded-full bg-white flex justify-center items-center text-primary"
                      src="/icons/profile-default.svg"
                      alt="profile-img"
                    />
                  )}
                  <span className="ml-2 text-white font-normal text-sm leading-4">
                    {name}
                  </span>
                </div>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
