import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

const usePreviousRoute = () => {
  const router = useRouter();

  const prevRoute = useRef<string | null>(null);

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (!shallow) {
        prevRoute.current = router.asPath;
      }  
    }
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    }
  }, [router]);

  return prevRoute.current;
}

export default usePreviousRoute;