/* eslint-disable import/extensions */
import { Color } from '@material-ui/lab';
import {
  HIDE_APP_MODAL,
  SHOW_APP_MODAL,
  SHOW_OTP_MODAL,
  SHOW_SIDEBAR,
  HIDE_SIDEBAR,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  SHOW_LOADER,
  HIDE_LOADER,
  BRANDING,
  SHOW_INV_MODAL,
  HIDE_INV_MODAL,
  HIDE_OTP_MODAL,
  SHOW_TOPIC_MODAL,
  HIDE_TOPIC_MODAL,
  SHOW_INSIGHTS_UNLOCK_MODAL,
  HIDE_INSIGHTS_UNLOCK_MODAL,
  SET_IDENTITIES,
  SET_WORKSPACES,
  SET_SELECTED_WORKSPACE,
  SET_IDP,
  SET_USER_TYPE,
  UPDATE_META,
  SHOW_MEETING_NOTIFY_MODAL,
  HIDE_MEETING_NOTIFY_MODAL,
  REALTIME_WIDGET_ENABLED,
  SAVE_USER,
  REMOVE_USER,
  REALTIME_WIDGET_AVAILABILITY,
  IS_GCAL_CONNECTED,
  IS_OUTLOOKCAL_CONNECTED,
  IS_ADMIN,
  CALL_HIPPO_TOKEN,
  CONNECTED_CRM,
  METABASE_DASHBOARDS,
  PAYMENT_MODAL,
  ACTIVE_SEATS,
  LSQ_CONNECT_CLICK,
  LSQ_ACCESS_KEY,
  LSQ_SECRET_KEY,
  LSQ_REGION,
  IS_SUPER_ADMIN,
  IS_MANAGER,
  PREVIOUS_PAGE,
  ALL_ADMINS,
  FIRST_ADMIN,
  TOGGLE_MULTIUPLOAD_MODAL,
  TOGGLE_CALLQREPORT_MODAL,
} from '../types';

interface CommonState {
  appModal: {
    visible: boolean;
    content: string;
    primaryActionText: string;
    secondaryActionText: string;
    primaryClickHandler: () => void;
    secondaryClickHandler: () => void;
    // eslint-disable-next-line no-undef
    heroImage: any;
    showSecondaryButton: boolean;
    closable?: boolean;
  };
  otpModal: {
    showOTPModal: boolean;
    authType: string | string[];
    redirectAfterSuccess: () => void;
    redirectToSignUp: () => void;
    handleOTPModalClose: () => void;
  };
  invModal: {
    showInvModal: boolean;
  };
  topicModal: {
    showTopicModal: boolean;
  };
  showSideBar: boolean;
  snackbar: {
    visible: boolean;
    message: string;
    type: Color;
    handleClose: Function;
  };
  loader: {
    visible: boolean;
  };
  branding: {
    headerText: string;
  };
  unlockedInsightsModal: {
    visible: boolean;
    isUnlocked: boolean;
  };
  identities: Array<Object> | [];
  selectedWorkspace:
  | {
    created_at: string | null;
    created_by: string | null;
    id: number;
    name: string;
    organisation_id: number;
    subscription_tier: string;
    tags: string[] | [];
    seats:number|null;
  }[]
  | [];
  workspaces:
  | {
    created_at: string | null;
    created_by: string | null;
    id: number;
    name: string;
    organisation_id: number;
    subscription_tier: string;
    tags: string[] | [];
  }[]
  | []
  | any;
  idp: string;
  userType: string;
  userMeta: {
    insights_unlocked: boolean;
    insights_unlocked_viewed: boolean;
  };
  meetingNotifyModal: {
    visible: boolean;
    meetingUrl: string;
    passcode: string;
    customerName: string;
    contactName: string;
    meetingSource: string;
    scheduledAt: string;
  };
  realtimeWidget: {
    enabled: boolean;
    availability: boolean;
    userInteracted: boolean;
  };
  user: {
    userMapWorkspace: any;
    id: number;
    first_name: string | null;
    middle_name: string | null;
    last_name: string | null;
    avatar_url: string | null;
    email: string;
    phone_ext: string | null;
    phone: string | null;
    timezone: string;
    last_signedin_at: string;
    organisation_id: number;
    status: string;
    workspace_preference_id: number | null;
    tags: string[];
    meta: {
      insights_unlocked: boolean;
      onboarding_viewed: boolean;
      insights_unlocked_viewed: boolean;
      realtime_meeting_availaibility: boolean;
      system_realtime_meeting_availaibility_revert: boolean;
      zipme_link: string;
      zipme_link_id: number | string;
      realtime_event_link: string;
      show_missed_overdue_meetings_first?: boolean;
      calendar_disconnected?: boolean;
    };
    last_polling_at: string;
    created_at: string;
    updated_at: string;
    identities: {
      id: number;
      user_id: number;
      external_user_id: string;
      idp: string;
      status: string;
      created_at: string;
      updated_at: string;
    }[];
    organisation: {
      id: number;
      name: string;
      logo: string | null;
      feature_flags: {
        realtime_widget_enabled: boolean;
        prospect_score_enabled: boolean;
        payment_enabled: boolean;
        offline_tag_limit?: number;
        online_tag_limit?: number;
        enable_pitch_throttle?: boolean;
        new_hubspot_integration?: boolean;
        enable_multi_upload?: boolean;
      };
      domains: {
        id: number;
        domain: string;
        organisation_id: number;
      }[] | null;
      preferences: {
        meet_domain: string;
        meet_primary_color: string;
        meet_logo: {
          data: string;
          file_name: string;
        };
        meet_custom: boolean;
        sync_analyse_recording?: boolean;
        room_provider?: string;
      } | null;
      created_at: string;
    } | null;
  } | null;
  activeSeatsUpdated: number;
  cardAdded: boolean;
  isLoggedIn: boolean;
  isGcalConnected: boolean;
  isOutlookcalConnected: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  callHippoToken: string;
  connectedCRM: string;
  metabaseDashboards: {
    dashboard_id: number | null;
    dashboard_name: string | null;
    dashboard_url: string | null;
    manager_urls?: {[key: string] : string}
  }[];
  lsqConnectClick: boolean;
  lsqAccessKey: string;
  lsqSecretKey: string;
  lsqRegion: string;
  isManager: boolean;
  isManagerLoaded: boolean;
  all_admins: {[key: string]: any}[];
  first_admin: {[key: string]: any};
  previousPage: string;
  multiUploadModal: boolean;
  callQualityReportExportModal: boolean;
}

const initialState = {
  appModal: {
    visible: false,
    content: '',
    primaryActionText: 'Continue',
    secondaryActionText: 'Cancel',
    primaryClickHandler: () => { },
    secondaryClickHandler: () => { },
    heroImage: null,
    showSecondaryButton: true,
    closable: false,
  },
  otpModal: {
    showOTPModal: false,
    authType: '',
    redirectAfterSuccess: () => { },
    redirectToSignUp: () => { },
    handleOTPModalClose: () => { },
  },
  invModal: {
    showInvModal: false,
  },
  topicModal: {
    showTopicModal: false,
  },
  showSideBar: false,
  snackbar: {
    visible: false,
    message: null,
    type: null,
    handleClose: () => { },
  },
  loader: {
    visible: false,
  },
  branding: {
    headerText: '',
  },
  unlockedInsightsModal: {
    visible: false,
    isUnlocked: false,
  },
  identities: [],
  selectedWorkspace: [],
  workspaces: [],
  idp: '',
  userType: '',
  userMeta: {
    insights_unlocked: false,
    insights_unlocked_viewed: false,
  },
  meetingNotifyModal: {
    visible: false,
    meetingUrl: '',
    customerName: '',
    contactName: '',
    passcode: '',
    meetingSource: '',
    scheduledAt: "",
  },
  realtimeWidget: {
    enabled: false,
    availability: false,
    userInteracted: false,
  },
  user: null,
  activeSeatsUpdated: 1,
  cardAdded: false,
  isLoggedIn: false,
  isGcalConnected: false,
  isOutlookcalConnected: false,
  isAdmin: false,
  isSuperAdmin: false,
  callHippoToken: "",
  connectedCRM: "",
  metabaseDashboards: [],
  lsqConnectClick: false,
  lsqAccessKey: '',
  lsqSecretKey: '',
  lsqRegion: '',
  isManager: false,
  isManagerLoaded: false,
  all_admins: [],
  first_admin: null,
  previousPage: '',
  multiUploadModal: false,
  callQualityReportExportModal: false,
};

const CommonReducer = (
  state: CommonState = initialState,
  action,
): CommonState => {
  const { type = '', payload = {} } = action;
  switch (type) {
    case SHOW_APP_MODAL:
      return {
        ...state,
        appModal: { ...state.appModal, ...payload },
      };
    case HIDE_APP_MODAL:
      return {
        ...state,
        appModal: initialState.appModal,
      };
    case SHOW_OTP_MODAL:
      return {
        ...state,
        otpModal: { ...state.otpModal, ...payload },
      };
    case HIDE_OTP_MODAL:
      return {
        ...state,
        otpModal: initialState.otpModal,
      };
    case SHOW_INV_MODAL:
      return {
        ...state,
        invModal: { ...state.invModal, ...payload },
      };
    case HIDE_INV_MODAL:
      return {
        ...state,
        invModal: initialState.invModal,
      };
    case SHOW_TOPIC_MODAL:
      return {
        ...state,
        topicModal: { ...state.topicModal, ...payload },
      };
    case HIDE_TOPIC_MODAL:
      return {
        ...state,
        topicModal: initialState.topicModal,
      };
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSideBar: true,
      };
    case HIDE_SIDEBAR:
      return {
        ...state,
        showSideBar: false,
      };
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          ...payload,
          visible: true,
        },
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          visible: false,
        },
      };
    case SHOW_LOADER:
      return {
        ...state,
        loader: {
          visible: true,
        },
      };
    case HIDE_LOADER:
      return {
        ...state,
        loader: {
          visible: false,
        },
      };
    case BRANDING:
      return {
        ...state,
        branding: { ...state, ...payload },
      };
    case SHOW_INSIGHTS_UNLOCK_MODAL:
      return {
        ...state,
        unlockedInsightsModal: {
          ...state?.unlockedInsightsModal,
          ...payload,
          visible: true,
        },
      };
    case HIDE_INSIGHTS_UNLOCK_MODAL:
      return {
        ...state,
        unlockedInsightsModal: initialState.unlockedInsightsModal,
      };
    case SHOW_MEETING_NOTIFY_MODAL:
      return {
        ...state,
        meetingNotifyModal: {
          ...state?.meetingNotifyModal,
          ...payload,
          visible: true,
        },
      };
    case HIDE_MEETING_NOTIFY_MODAL:
      return {
        ...state,
        meetingNotifyModal: initialState.meetingNotifyModal,
      };
    case SET_SELECTED_WORKSPACE:
      return {
        ...state,
        selectedWorkspace: [...payload],
      };
    case SET_WORKSPACES:
      return {
        ...state,
        workspaces: [...payload],
      };
    case SET_IDENTITIES:
      return {
        ...state,
        identities: [...payload],
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: payload,
      };
    case UPDATE_META:
      return {
        ...state,
        userMeta: {
          ...state.userMeta,
          ...payload,
        },
      };
    case SET_IDP:
      return {
        ...state,
        idp: payload,
      };
    case REALTIME_WIDGET_ENABLED:
      return {
        ...state,
        realtimeWidget: { ...state.realtimeWidget, ...payload },
      };
    case SAVE_USER:
      return {
        ...state,
        isLoggedIn: true,
        user: { ...state.user, ...payload },
      };
    case REMOVE_USER:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        identities: [],
        selectedWorkspace: [],
        workspaces: [],
      };
    case REALTIME_WIDGET_AVAILABILITY:
      return {
        ...state,
        realtimeWidget: { ...state.realtimeWidget, ...payload },
      };
    case IS_GCAL_CONNECTED:
      return {
        ...state,
        isGcalConnected: payload,
      };
    case IS_OUTLOOKCAL_CONNECTED:
      return {
        ...state,
        isOutlookcalConnected: payload,
      };
    case IS_ADMIN:
      return {
        ...state,
        isAdmin: payload,
      };
    case IS_SUPER_ADMIN:
      return {
        ...state,
        isSuperAdmin: payload,
      };

    case CALL_HIPPO_TOKEN:
      return {
        ...state,
        callHippoToken: payload
      }

    case CONNECTED_CRM:
      return {
        ...state,
        connectedCRM: payload
      }
    case METABASE_DASHBOARDS:
      return {
        ...state,
        metabaseDashboards: payload
      }
    case PAYMENT_MODAL:
      return {
        ...state,
        cardAdded: payload 
      }
    case ACTIVE_SEATS:
      return {
        ...state,
        activeSeatsUpdated: payload 
      }
    case LSQ_CONNECT_CLICK:
      return {
        ...state,
        lsqConnectClick: payload 
      }
    case LSQ_ACCESS_KEY:
      return {
        ...state,
        lsqAccessKey: payload 
      }
    case LSQ_SECRET_KEY:
      return {
        ...state,
        lsqSecretKey: payload 
      }    
    case LSQ_REGION:
      return {
        ...state,
        lsqRegion: payload 
      }    
    case IS_MANAGER: 
      return {
        ...state,
        isManager: payload,
        isManagerLoaded: true
      }
    case PREVIOUS_PAGE: 
      return {
        ...state,
        previousPage: payload 
      }
    case ALL_ADMINS: 
      return {
        ...state,
        all_admins: payload 
      }
    case FIRST_ADMIN: 
      return {
        ...state,
        first_admin: payload 
      }
    case TOGGLE_MULTIUPLOAD_MODAL: 
      return {
        ...state,
        multiUploadModal: payload 
      };
      case TOGGLE_CALLQREPORT_MODAL: 
      return {
        ...state,
        callQualityReportExportModal: payload 
      };

    default:
      return state;
  }
};

export default CommonReducer;
