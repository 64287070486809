import { useEffect, useState } from 'react';

declare let window: Window;
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    TOKEN: any;
    EMAIL: any;
    REGION:any;
  }
}

function useScript(token: string, emailId: string) {
  const [status, setStatus] = useState<string>('loading');
  useEffect(() => {
    // function to init callHippo widget

    if (!(token && emailId)) {
      setStatus('');
      return;
    }
    let callhippoScript: HTMLScriptElement;
    const callHippo = () => {
      const chWidgetDiv = document.createElement('div');
      chWidgetDiv.id = 'ch-dialer-container';
      document.getElementsByTagName('body')[0].appendChild(chWidgetDiv);
      window.TOKEN = token;
      window.EMAIL = emailId;
      window.REGION = 'india';
      (function () {
        callhippoScript = document.createElement('script');
        callhippoScript.type = 'text/javascript';
        callhippoScript.async = true;
        callhippoScript.setAttribute('data-status', 'loading');
        callhippoScript.src =
          'https://d1x9dsge91xf6g.cloudfront.net/callhippo/files/ch-dialer.js';
        callhippoScript.addEventListener('load', event => {
          callhippoScript.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error',
          );
        });
        callhippoScript.addEventListener('error', event => {
          callhippoScript.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error',
          );
        });
        const s = document.getElementsByTagName('script')[0];
        s?.parentNode?.insertBefore(callhippoScript, s);
      })();
    };
    const setStateFromEvent = (event: any) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };
    // getting the widget div and loaded scripts to check if it already exist
    const elm = document.getElementById('ch-dialer-container');
    const script = document.querySelector(
      `script[src="https://d1x9dsge91xf6g.cloudfront.net/callhippo/files/ch-dialer.js"]`,
    );
    if (script) {
      setStatus(script.getAttribute('data-status') || '');
    }
    // if the widget div is not present and token is available then only widget add function can be called
    if(token&&elm){
      return
    }

    if (!elm && token) {
      callHippo();
    }
    // Add event listeners
    script?.addEventListener('load', setStateFromEvent);
    script?.addEventListener('error', setStateFromEvent);
    // eslint-disable-next-line consistent-return
    return () => {
      script?.removeEventListener('load', setStateFromEvent);
      script?.removeEventListener('error', setStateFromEvent);
      callhippoScript?.removeEventListener('load', setStateFromEvent);
      callhippoScript?.removeEventListener('error', setStateFromEvent);
    };
  }, [token]);
  return status;
}

export default useScript;
