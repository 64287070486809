/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  // CircularProgress,
  ClickAwayListener,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { isPast, isFuture, isToday, parseISO, format } from 'date-fns';
import { useRouter } from 'next/router';

import styles from './MobileList.module.css';
import {
  DashboardContext,
  ICustomers,
} from '../../pages/dashboard/[[...index]]';
import VideoIcon from '../../public/icons/video-icon.svg';
import CalendarIcon from '../../public/icons/calendar.svg';
import {
  CUSTOMER_SORT_TYPE,
  ROUTES,
  ROWS_PER_PAGE,
} from '../../utils/constants';
import Pagination from '../PaginationComponent/Pagination.component';
import { pageChangeEvent } from '../../lib/ga/home.events';

interface IListComponentProps {
  customers: ICustomers[];
  isInSearch: boolean;
  count: number;
  handleTablePageChange: Function;
  pageNo: number;
  isAllConversation: boolean;
  handleAddCustomerModalOpen: Function;
  customerCount: number;
  // prospectScoreEnabled: boolean;
  isContactIntentFilter: boolean;
  isStatusFilter: boolean;
}

interface IListItemProps {
  customer: ICustomers;
  isInSearch: boolean;
  isAllConversation: boolean;
  // prospectScoreEnabled: boolean;
}

const useStyles = makeStyles({
  touch: {
    fontSize: '0.75rem',
  },
  toolTip: {
    margin: 0,
  },
  root_red: {
    color: '#F0142F',
  },
  root_green: {
    color: '#34A853',
  },
  root_orange: {
    color: '#FFA959',
  },
  root_bg: {
    color: '#E8E8E8',
    position: 'relative',
    left: '30px',
  },
});

const MobileListComponent: React.FC<IListComponentProps> = ({
  customers,
  isInSearch,
  count,
  handleTablePageChange,
  pageNo,
  isAllConversation,
  handleAddCustomerModalOpen,
  customerCount,
  isContactIntentFilter,
  isStatusFilter,
  // prospectScoreEnabled,
  // userConnectedCallers,
}) => {
  const [page, setPage] = useState(pageNo);

  useEffect(() => {
    setPage(pageNo);
  }, [pageNo]);

  // const sortCustomers = () => {
  //   const upcomingArr = [];
  //   const olderArr = [];
  //   const todayArr = [];
  //   for (let index = 0; index < customers?.length; index++) {
  //     const nextMeeting = customers[index]?.cust_next_meeting_at;
  //     const nextMeetingDate = (nextMeeting && nextMeeting.split('T')[0]) || '';
  //     if (isToday(parseISO(nextMeetingDate))) {
  //       todayArr.push(customers[index]);
  //     } else if (isFuture(parseISO(nextMeetingDate))) {
  //       upcomingArr.push(customers[index]);
  //     } else if (isPast(parseISO(nextMeetingDate))) {
  //       olderArr.push(customers[index]);
  //     } else {
  //       olderArr.push(customers[index]);
  //     }
  //   }
  //   return [
  //     {
  //       title: 'Today',
  //       type: CUSTOMER_SORT_TYPE.TODAY,
  //       items: todayArr,
  //     },
  //     {
  //       title: 'Upcoming',
  //       type: CUSTOMER_SORT_TYPE.FUTURE,
  //       items: upcomingArr,
  //     },
  //     {
  //       title: 'Earlier',
  //       type: CUSTOMER_SORT_TYPE.PAST,
  //       items: olderArr,
  //     },
  //   ];
  // };

  const handlePageChange = (pageNumber: number) => {
    if (page !== pageNumber) {
      const event = page > pageNumber ? 'previous' : 'next';
      pageChangeEvent(event);
    }

    setPage(pageNumber);
    const params = {
      page_number: pageNumber,
      page_size: ROWS_PER_PAGE,
    };
    handleTablePageChange({ params });
  };

  let sortedCustomers = [];
  if (Array.isArray(customers)) {
    sortedCustomers = [...customers];
  } else {
    sortedCustomers = [];
  }

  // if (isInSearch) {
  //   sortedCustomers = [...customers];
  // } else {
  //   sortedCustomers = sortCustomers();
  // }

  // eslint-disable-next-line consistent-return

  return (
    <>
      <div className={styles['list-container']}>
        {isInSearch ? (
          <div className={styles['section']}>
            <div className={styles['section-title']}>Results</div>
            <div className={styles['section-list']}>
              {sortedCustomers?.length &&
                sortedCustomers.map(customer => (
                  <div key={customer?.contact_id}>
                    <DashboardListItem
                      customer={customer}
                      isInSearch={isInSearch}
                      isAllConversation={isAllConversation}
                    // prospectScoreEnabled={prospectScoreEnabled}
                    />
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className={styles['section']}>
            {/* <div className={styles['section-title']}>Results</div> */}
            <div className={styles['section-list']}>
              {
                sortedCustomers?.length > 0 ? (
                  sortedCustomers.map(customer => (
                    <div key={customer?.contact_id}>
                      <DashboardListItem
                        customer={customer}
                        isInSearch={isInSearch}
                        isAllConversation={isAllConversation}
                      // prospectScoreEnabled={prospectScoreEnabled}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    {
                      (isContactIntentFilter || isStatusFilter) ?
                        (
                          <div className=" flex flex-col items-center my-24 h-full lg:hidden" >
                            <img
                              src="/images/no-result-found.svg"
                              alt="No results found"
                            />
                            <p className="text-primary-dark text-xl font-bold text-center my-6 pl-4">
                              No results found
                            </p>
                          </div>
                        ) : (

                          <div className=" flex flex-col items-center my-24 h-full lg:hidden">
                            <img
                              src="/images/no-result-found.svg"
                              alt="No customers found"
                            />
                            <p className="text-primary-dark text-xl font-bold text-center my-6 pl-4">
                              No conversations found
                            </p>
                          </div>
                        )
                    }
                  </>
                )
              }
              {
                (isContactIntentFilter || isStatusFilter) && !customers ? null : (
                  (
                    customerCount === 0 && !isInSearch) || isAllConversation ? (
                    []
                  ) : (
                    <div className="sticky bg-app-background-1 bottom-0">
                      <div
                        className="flex justify-center place-items-center"
                        style={{ minHeight: '68px' }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          className={styles['add-contact-button']}
                          onClick={() => {
                            handleAddCustomerModalOpen();
                          }}
                          disableElevation
                          disableRipple
                        >
                          <span className="px-8 py-1">Add new contact</span>
                        </Button>
                      </div>
                    </div>
                  )
                )
              }
            </div>
          </div>
          // <>
          //   {sortedCustomers?.length &&
          //     sortedCustomers.map(section => (
          //       <div key={section?.title} className={styles['section']}>
          //         <div className={styles['section-title']}>
          //           {section?.title}
          //         </div>
          //         <div className={styles['section-list']}>
          //           {section?.items?.length === 0 ? (
          //             <div className={styles['section-empty']}>
          //               {section?.type === CUSTOMER_SORT_TYPE.TODAY
          //                 ? 'No conversations today! Time to set some next ones'
          //                 : 'No upcoming calls, schedule some now.'}
          //             </div>
          //           ) : (
          //             <>
          //               {section?.items?.length &&
          //                 section?.items?.map(customer => (
          //                   <div key={customer?.cust_id}>
          //                     {renderListItem(customer, section?.type)}
          //                   </div>
          //                 ))}
          //             </>
          //           )}
          //         </div>
          //       </div>
          //     ))}
          // </>
        )}
        {customers && customers?.length > 0 && (
          <div className="mt-5 block lg:hidden">
            <Pagination
              totalCount={Math.ceil((count || 0) / ROWS_PER_PAGE)}
              selectedPage={page}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </>
  );
};

const DashboardListItem: React.FC<IListItemProps> = props => {
  const router = useRouter();
  const classes = useStyles();
  const { customer, isInSearch, isAllConversation,
    // prospectScoreEnabled 
  } =
    props;
  const { cust_next_meeting_at: nextMeetingDate } = customer;

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const dashboardContext = useContext(DashboardContext);

  const { handleJoinMeeting, handleEditDate } = dashboardContext;

  const handleListIconClick = (
    event,
    customerData: ICustomers,
    type: string,
  ) => {
    event.stopPropagation();
    if (type === CUSTOMER_SORT_TYPE.PAST) {
      handleEditDate(customerData?.cust_next_meeting_at, customerData?.cust_id);
    } else {
      handleJoinMeeting(customerData?.cust_meet_url);
    }
  };

  const handleListItemClick = (customerData: ICustomers) => {
    router.push(`/${ROUTES.CUSTOMER}/${customerData?.cust_id}`);
  };

  const renderDate = (type: string, customerData: ICustomers) => {
    if (
      type === CUSTOMER_SORT_TYPE.FUTURE &&
      customerData?.cust_next_meeting_at
    ) {
      return format(
        parseISO(customerData?.cust_next_meeting_at),
        "dd MMM''' 'yy",
      );
    } else if (type === CUSTOMER_SORT_TYPE.PAST) {
      if (customerData?.cust_next_meeting_at) {
        return format(
          parseISO(customerData?.cust_next_meeting_at),
          "dd MMM''' 'yy",
        );
      } else if (customerData?.updated_at) {
        return format(parseISO(customerData?.updated_at), "dd MMM''' 'yy");
      } else {
        return 'Schedule Now';
      }
    }
    return '';
  };

  // const getProspectScoreColorClass = (score: number) => {
  //   if (score >= 0 && score <= 34) {
  //     return classes.root_red;
  //   } else if (score > 34 && score <= 67) {
  //     return classes.root_orange;
  //   } else if (score > 67 && score <= 100) {
  //     return classes.root_green;
  //   }
  //   return classes.root_red;
  // };

  let type = '';
  if (isToday(parseISO(nextMeetingDate))) {
    type = CUSTOMER_SORT_TYPE.TODAY;
  } else if (isFuture(parseISO(nextMeetingDate))) {
    type = CUSTOMER_SORT_TYPE.FUTURE;
  } else if (isPast(parseISO(nextMeetingDate))) {
    type = CUSTOMER_SORT_TYPE.PAST;
  } else {
    type = CUSTOMER_SORT_TYPE.PAST;
  }

  if (isInSearch) {
    if (nextMeetingDate && isFuture(parseISO(nextMeetingDate))) {
      type = CUSTOMER_SORT_TYPE.FUTURE;
    } else {
      type = CUSTOMER_SORT_TYPE.PAST;
    }
  }

  const getCustomerIntent = (intent: string | null) => {
    switch (intent) {
      case 'HIGH_INTENT':
        return (
          <div className="w-14 text-sm leading-3.5 text-positive-green font-semibold py-2 flex justify-center items-center bg-positive-green bg-opacity-20 rounded">
            High
          </div>
        );
      case 'INTERESTED':
        return (
          <div className="w-14 text-xs leading-3.5 text-secondary-orange font-semibold py-1 flex justify-center items-center bg-secondary-orange bg-opacity-20 rounded">
            Interested
          </div>
        );
      case 'NOT_INTERESTED':
      case 'LOW_INTENT':
        return (
          <div className="w-14 text-xs leading-3.5 text-negative-red font-semibold py-1 flex justify-center items-center bg-negative-red bg-opacity-20 rounded">
            Low
          </div>
        );
      case 'NEUTRAL':
        return (
          <div className="w-14 text-xs leading-3.5 text-neutral-orange font-semibold py-1 flex justify-center items-center bg-neutral-orange bg-opacity-20 rounded">
            Neutral
          </div>
        );
      default:
        return <div className={styles['null-state']}>-</div>;
    }
  };

  return (
    <div
      className={styles['list-item']}
      onClick={() => handleListItemClick(customer)}
    >
      <div className={`${styles['name']} pl-3`}>
        <div className={styles['primary']}>{customer?.cust_name}</div>
        {customer?.contact_name !== customer?.cust_name && (
          <div className={styles['secondary']}>{customer?.contact_name}</div>
        )}
        {customer?.contact_phone && (
          <div className={styles['secondary']}>{customer?.contact_phone}</div>
        )}
      </div>
      <div className={styles['intent-container']}>
        {getCustomerIntent(customer?.contact_intent || null)}
      </div>
      <div className={styles['date-time-action']}>
        {/* {prospectScoreEnabled && (
          <div className={styles['score-container']}>
            {customer.prospect_score ? (
              <div className={styles['score-circular']}>
                <CircularProgress
                  variant="determinate"
                  value={100}
                  className={classes.root_bg}
                  size={30}
                  thickness={4}
                />
                <CircularProgress
                  variant="determinate"
                  value={customer.prospect_score}
                  className={getProspectScoreColorClass(
                    customer.prospect_score,
                  )}
                  size={30}
                  thickness={4}
                />
                <div className={styles['score-value']}>
                  {customer.prospect_score}
                </div>
              </div>
            ) : (
              <div style={{ minWidth: '50px', textAlign: 'center' }}>-</div>
            )}
          </div>
        )} */}
        <div className={styles['date-time']}>
          {type !== CUSTOMER_SORT_TYPE.TODAY && (
            <div className={styles['date']}>{renderDate(type, customer)}</div>
          )}
          {type !== CUSTOMER_SORT_TYPE.PAST && customer?.cust_next_meeting_at && (
            <div
              className={styles['time']}
              style={
                type === CUSTOMER_SORT_TYPE.TODAY
                  ? {
                    color: '#1C1D21',
                    fontWeight: 600,
                  }
                  : {}
              }
            >
              {customer?.cust_next_meeting_at
                ? format(parseISO(customer?.cust_next_meeting_at), 'h:mm a')
                : ''}
            </div>
          )}
        </div>
        {!isAllConversation && (
          <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
            <Tooltip
              title={`${!customer?.cust_next_meeting_at
                ? 'Please schedule the meeting first'
                : ''
                }`}
              classes={{
                touch: classes.touch,
                tooltip: classes.toolTip,
              }}
              open={showTooltip}
              placement="top"
              arrow
            >
              <div
                className={styles['action']}
                onClick={e => {
                  setShowTooltip(true);
                  e.stopPropagation();
                }}
              >
                <IconButton
                  className={styles['action-button']}
                  color="primary"
                  onClick={event => {
                    handleListIconClick(event, customer, type);
                  }}
                  disabled={
                    type !== CUSTOMER_SORT_TYPE.PAST &&
                    !customer?.cust_next_meeting_at
                  }
                >
                  {type === CUSTOMER_SORT_TYPE.PAST ? (
                    <CalendarIcon />
                  ) : (
                    <VideoIcon
                      className={`${!customer?.cust_next_meeting_at
                        ? 'text-gray-400'
                        : 'text-secondary'
                        } fill-current w-4.5 h-3`}
                    />
                  )}
                </IconButton>
              </div>
            </Tooltip>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};
export default MobileListComponent;
