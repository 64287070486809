import { Snackbar as MuiSnackbar, Slide, makeStyles } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import SuccessIcon from '../../public/icons/sb-success.svg';
import ErrorIcon from '../../public/icons/sb-error.svg';
import CloseButton from '../../public/icons/close-button.svg';
import styles from './snackbar.module.css';

const Snackbar = ({
  visible,
  message,
  type = 'success' as Color,
  noTitle = false,
  handleClose,
}) => {
  const toastConfigs = {
    success: {
      messageTitle: 'Success',
      color: '#4BC593',
    },
    error: {
      messageTitle: 'Error',
      color: '#F95849',
    },
  };
  const useStyles = makeStyles({
    root: {
      // width: '100%',
      borderLeft: '0.5em solid',
      borderColor: toastConfigs[type]?.color,
      boxShadow: '-1px 5px 8px rgba(0, 0, 0, 0.06)',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    action: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
    },
    standardSuccess: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
    },
    standardError: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
    },
  });
  const classes = useStyles();
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsToastVisible(visible);
  }, [visible]);

  return (
    <MuiSnackbar
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      autoHideDuration={5000}
      open={isToastVisible}
      onClose={handleClose}
      TransitionComponent={Slide}
      className="lg:w-full"
    >
      <Alert
        classes={classes}
        className={`w-full lg:w-2/6 ${noTitle ? 'lg:!w-1/5' : ''}`}
        variant="standard"
        iconMapping={{ success: <SuccessIcon />, error: <ErrorIcon /> }}
        action={
          <button aria-label="Close alert" type="button">
            <CloseButton onClick={handleClose} />
          </button>
        }
        severity={type}
      >
        <div className={styles['alert-msg']}>
          {!noTitle && (
            <div className={styles['alert-title']}>
              {toastConfigs[type]?.messageTitle}
            </div>
          )}
          <div className={`${styles['alert-detail']} p-1`}>{message}</div>
        </div>
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
