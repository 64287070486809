import { API_BASE_URL, ZOOM_CLIENT_ID, ZOOM_REDIRECT_URI } from '../../utils/constants';
import HttpClient from '../../utils/http-client';

const encodedUrl = encodeURIComponent(ZOOM_REDIRECT_URI);

class Meeting {

    connectZoom = data =>
        HttpClient.post(`${API_BASE_URL}/v1/integrations/zoom/oauth/token`, {
            code: data,
        });

    disconnectZoom = () =>
        HttpClient.delete(`${API_BASE_URL}/v1/integrations/zoom/oauth/token`);

    zoomAuthorize = () => {
        window.location.href = `https://zoom.us/oauth/authorize?response_type=code&client_id=${ZOOM_CLIENT_ID}&redirect_uri=${encodedUrl}`
    }

    currentRoom = () =>
        HttpClient.get(`${API_BASE_URL}/v1/organisation/current/room`);

    updateRoomProvider = data =>
        HttpClient.get(`${API_BASE_URL}/v1/organisation/update/room?room=${data}`);

    msTeamsAuthorize = () =>
        HttpClient.get(`${API_BASE_URL}/v1/integrations/microsoft/teams/authorize`);

    connectMsTeams = data =>
        HttpClient.post(`${API_BASE_URL}/v1/integrations/microsoft/teams/oauth/token`, {
            code: data,
        });

    disconnectMsTeams = () =>
        HttpClient.delete(`${API_BASE_URL}/v1/integrations/microsoft/teams/oauth/token`);

}

const MeetingService = new Meeting();

export default MeetingService;