import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { MdArrowDropDown } from 'react-icons/md'

const FilterAssigneeListComponentNew = ({
    assigneeList,
    filterAssigneeConversation,
}) => {
    const [selectedAssigneeList, setSelectedAssigneeList] = useState(assigneeList || []);

    const handleCheck = (id) => {
        setSelectedAssigneeList((prevList) => prevList?.map((item) => {
            if (item.assigned_user_id === id) {
                return { ...item, checked: !item.checked };
            }
            return item;
        }));
    };

    useEffect(() => {
        setSelectedAssigneeList(assigneeList);
    }, [assigneeList]);

    return (
        <div className="inline-flex">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button >
                        <MdArrowDropDown className="inline-block h-5 w-5" />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute mt-2 min-w-[150px] max-h-[300px] right-0 origin-top-right
                        bg-white shadow-lg ring-1 ring-black/5 focus:outline-none rounded-md"
                    >
                        <div className="px-1 py-2 max-h-[200px] overflow-y-auto">
                            <Menu.Item onClick={(e) => e.preventDefault()} as="div">
                                {() => (
                                    <div className="flex justify-start items-center px-2 lg:px-3 py-2 hover:bg-app-background-1">
                                        <input
                                            type="checkbox"
                                            checked={
                                                selectedAssigneeList?.every(object => object.checked === false)
                                            }
                                            className="form-checkbox h-3.5 w-3.5 lg:h-4 lg:w-4 text-secondary 
                                            focus:ring-transparent cursor-pointer rounded-sm border-secondary"
                                            onClick={(e)=> e.stopPropagation()}
                                            onChange={() => {
                                                setSelectedAssigneeList((prevList) =>
                                                    prevList?.map((item) => ({
                                                        ...item,
                                                        checked: false,
                                                    }))
                                                );
                                            }}
                                        />
                                        <img
                                            src="/icons/all-icon.svg"
                                            className="rounded-full w-6 h-6 inline ml-2"
                                            alt="all-img"
                                            onError={e => {
                                                e.currentTarget.onerror = null;
                                                e.currentTarget.src = '/icons/all-icon.svg';
                                            }}
                                        />
                                        <h4 className="text-primary-dark text-xs lg:text-sm font-medium leading-4 mx-2">All</h4>
                                    </div>
                                )}
                            </Menu.Item>
                            {selectedAssigneeList &&
                                selectedAssigneeList.map(
                                    ({
                                        assigned_user_id,
                                        checked,
                                        assigned_user_first_name,
                                        assigned_user_last_name,
                                        assigned_user_avatar_url,
                                    }, idx) => (
                                        <Menu.Item onClick={(e) => e.preventDefault()} key={idx} as="div">
                                            {() => (
                                                <div className="flex justify-start items-center px-2 lg:px-3 py-2 hover:bg-app-background-1">
                                                    <input
                                                        type="checkbox"
                                                        checked={checked}
                                                        className="form-checkbox h-3.5 w-3.5 lg:h-4 lg:w-4 text-secondary 
                                                            focus:ring-transparent cursor-pointer rounded-sm border-secondary"
                                                        name={assigned_user_id}
                                                        value={assigned_user_id}
                                                        id={assigned_user_id}
                                                        onClick={(e)=> e.stopPropagation()}
                                                        onChange={() => {
                                                            handleCheck(assigned_user_id);
                                                        }}
                                                    />
                                                    <img
                                                        src={assigned_user_avatar_url || `/icons/profile-default.svg`}
                                                        className="rounded-full w-6 h-6 inline ml-2"
                                                        alt="profile-img"
                                                        onError={e => {
                                                            e.currentTarget.onerror = null;
                                                            e.currentTarget.src = '/icons/profile-default.svg';
                                                        }}
                                                    />
                                                    <h4 className="text-primary-dark text-xs lg:text-sm font-medium leading-4 mx-2">
                                                        {`${assigned_user_first_name} ${assigned_user_last_name ?? ''}`}
                                                    </h4>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    ),
                                )}
                        </div>
                        <div className='flex justify-center px-1 py-4'>
                            <button
                                type='button'
                                className='h-8 w-28 rounded-lg text-center text-xxs lg:text-xs text-white font-bold bg-secondary'
                                onClick={() => {
                                    filterAssigneeConversation(selectedAssigneeList);
                                }}
                            >
                                Apply
                            </button>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

export default FilterAssigneeListComponentNew;