import React from "react";

const cleanPercentage = (percentage) => {
    const tooLow = !Number.isFinite(+percentage) || percentage < 0;
    const tooHigh = percentage > 100;
    // eslint-disable-next-line no-nested-ternary
    return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct }) => {
    const r = 20;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - pct) * circ) / 100;
    return (
        <circle
            r={r}
            cx={25}
            cy={25}
            fill="transparent"
            stroke={strokePct !== circ ? colour : ""}
            strokeWidth="5px"
            strokeDasharray={circ}
            strokeDashoffset={pct ? strokePct : 0}
            strokeLinecap="round"
        />
    );
};

const Text = ({ percentage }) => (
        <text
            x="50%"
            y="50%"
            dominantBaseline="central"
            textAnchor="middle"
            fontSize="12px"
            fontWeight="bold"
        >
            {percentage.toFixed(0)}%
        </text>
    );

const Pie = ({ percentage }) => {
    const pct = cleanPercentage(percentage);
    // eslint-disable-next-line no-nested-ternary
    const colour = pct === 0 ? "#E8E8E8" : (pct > 0 && pct <= 50) ? "#F0142F" : (pct > 50 && pct <= 75) ? "#FFA959" : "#34A853"
    return (
        <svg width={50} height={50}>
            <g transform="rotate(-90 25 25)">
                <Circle colour="#E8E8E8" pct={0}/>
                <Circle colour={colour} pct={pct} />
            </g>
            <Text percentage={pct} />
        </svg>
    );
};

export default Pie;
