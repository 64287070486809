import { API_BASE_URL } from '../../utils/constants';
import HttpClient from '../../utils/http-client';

class OAauth {
  getClient = (id: string) =>
    HttpClient.get(`${API_BASE_URL}/v1/oauth/client/${id}`);

  authorize = queryParams =>
    HttpClient.post(
      `${API_BASE_URL}/v1/oauth/authorize`,
      {},
      {
        params: queryParams,
      },
    );

  getTokens = (clientName: string) =>
    HttpClient.get(`${API_BASE_URL}/v1/oauth/tokens?client=${encodeURI(clientName)}`);
}

const OAuthService = new OAauth();
export default OAuthService;
