import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import useDeviceDimension from '../../hooks/useDeviceDimension';

const useStyles = makeStyles({
  menu: {
    marginLeft: 180,
    marginTop: -18,
    backgroundColor: 'white',
    '& li': {
      fontSize: 14,
      fontWeight: 400,
      minHeight: 0,
      minWidth: 120,
      color:"black",
      '&:hover': {
        backgroundColor:'#E1EBEF',
      },
    },
    border: '1px solid #e0e0e0',
  },
  menuMobile: {
    marginTop: 80,
    '& li': {
      fontSize: 10,
      fontWeight: 400,
      minHeight: 0,
    },
    border: '1px solid #e0e0e0',
  },

  header: {
    backgroundColor: '#fff',
    marginTop: '0.5rem',
    '& li': {
      fontSize: 12,
      fontWeight: 500,
      minHeight: 0,
      color: '#1C1D21',
      minWidth: 120,
      '&:hover': {
        backgroundColor: '#EDEDED',
        color: '#1C1D21',
      },
    },
  },
});

const WorkspaceSelectionList = ({
  el,
  handleMenuClose,
  handleSelectWorkspace,
  isHeader,
  workspaces,
}) => {
  const classes = useStyles();

  const kebabMenuOpen = Boolean(el);
  const isMobile = useDeviceDimension();

  return (
    <Menu
      anchorEl={el}
      open={kebabMenuOpen}
      onClose={handleMenuClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        // eslint-disable-next-line no-nested-ternary
        vertical: isHeader ? 50 : isMobile ? 0 : 110,
        // eslint-disable-next-line no-nested-ternary
        horizontal: isHeader ? 55 : isMobile ? 10 : 95,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        // eslint-disable-next-line no-nested-ternary
        paper: isHeader
          ? classes.header
          : isMobile
          ? classes.menuMobile
          : classes.menu,
      }}
    >
      {workspaces &&
        workspaces?.map(item => (
          <MenuItem key={item?.id} onClick={() => handleSelectWorkspace(item)}>
            <div>{item.name}</div>
          </MenuItem>
        ))}
    </Menu>
  );
};
export default WorkspaceSelectionList;
