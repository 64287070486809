/* eslint-disable import/extensions */
import { API_BASE_URL  } from '../../utils/constants';
import HttpClient from '../../utils/http-client';

class Auth {
  signin = data =>
    HttpClient.put(`${API_BASE_URL}/v1/auth/google/signin`, data);

  sendOTP = data =>
     HttpClient.post(`${API_BASE_URL}/v1/auth/otp`, data);
     
  signInOTP = data => 
    HttpClient.put(`${API_BASE_URL}/v1/auth/zipteams/signin`, data);

  signUpOTP = data => 
    HttpClient.post(`${API_BASE_URL}/v1/auth/zipteams/signup`, data);

  signup = data =>
    HttpClient.post(`${API_BASE_URL}/v1/auth/google/signup`, data);

  signInMicrosoft = data =>
    HttpClient.put(`${API_BASE_URL}/v1/auth/microsoft/signin`,data);
  
  signUpMicrosoft = data =>
    HttpClient.post(`${API_BASE_URL}/v1/auth/microsoft/signup`,data);

  signInWithPassword = data =>
    HttpClient.post(`${API_BASE_URL}/v1/auth/password/signin`,data);

   changePassword = data =>
    HttpClient.post(`${API_BASE_URL}/v1/auth/change/password`,data);

  logout = data => HttpClient.post(`${API_BASE_URL}/v1/auth/logout`, data);
}

const AuthService = new Auth();
export default AuthService;
