/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
import { format, parseISO } from 'date-fns';
import { isMacOs } from 'react-device-detect';
import { GOOGLE_CALENDAR_BASE_URL } from './constants';

export const getIndices = (
  searchString: string,
  string: string,
  caseSensitive: boolean,
) => {
  let searchStringCopy = searchString;
  let stringCopy = string;
  const searchStrLen = searchStringCopy.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0;
  let index = 0;
  const indices = [];
  if (!caseSensitive) {
    stringCopy = string?.toLowerCase();
    searchStringCopy = searchString?.toLowerCase();
  }
  // eslint-disable-next-line no-cond-assign
  while ((index = stringCopy?.indexOf(searchStringCopy, startIndex)) > -1) {
    indices.push([index, index + searchStrLen]);
    startIndex = index + searchStrLen;
  }
  return indices;
};

export const getGoogleCalendarTemplate = (
  subject: string,
  start: Date,
  end: Date,
  invitee: string,
  details: string,
  location: string,
) => {
  const gCalUrl: URL = new URL('/calendar/render', GOOGLE_CALENDAR_BASE_URL);

  gCalUrl.searchParams.append('action', 'TEMPLATE');
  gCalUrl.searchParams.append('text', subject);
  gCalUrl.searchParams.append(
    'dates',
    `${format(start, "yyyyMMdd'T'kkmm00")}/${format(end, "yyyyMMdd'T'kkmm00")}`,
  );
  gCalUrl.searchParams.append('add', invitee);
  gCalUrl.searchParams.append('details', details);
  gCalUrl.searchParams.append('location', location);

  return gCalUrl.href;
};

export const getDurationBreakdown = (duration: number) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);

  return { hours, minutes };
};

export const formatDurationToHrsAndMins = (duration: number): string => {
  if (!duration) {
    return '0 mins';
  }
  const breakdown = getDurationBreakdown(duration);
  if (breakdown.hours < 0 || breakdown.minutes < 0) return '';
  const hours =
    breakdown.hours === 0
      ? ''
      : breakdown.hours === 1
        ? `${breakdown.hours} hr`
        : `${breakdown.hours} hrs`;
  const minutes =
    breakdown.minutes === 0
      ? ''
      : breakdown.minutes === 1
        ? `${breakdown.minutes} min`
        : `${breakdown.minutes} mins`;
  return `${breakdown.hours === 0 && breakdown.minutes === 0
    ? 'Less than a minute'
    : `${hours} ${minutes}`
    }`;
};

export const getFormattedDate = (rawDate: string) =>
  format(parseISO(rawDate), "dd MMM''' 'yy");

export const getDaysSinceDate = pastDate => {
  const today = new Date();
  const pastDateWithoutTime = new Date(pastDate.toISOString().split('T')[0]);
  const diff = today.getTime() - pastDateWithoutTime.getTime();
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  if (diffDays === 0) {
    return 'Today';
  } else if (diffDays === 1) {
    return '1 day ago';
  } else {
    return `${diffDays} days ago`;
  }
};

export const getDeviceTimeZone = () =>
  Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

export const sleep = duration =>
  new Promise(resolve => setTimeout(resolve, duration));

export const poll = async (promiseFn, duration) =>
  promiseFn().then(() => sleep(duration).then(() => poll(promiseFn, duration)));

/* source: https://stackoverflow.com/a/23625419 */
export function formatBytes(bytes: number) {
  const marker = isMacOs ? 1000 : 1024;
  const decimal = 2;
  const kiloBytes = marker; // One Kilobyte is 1024 bytes
  const megaBytes = marker * marker; // One MB is 1024 KB
  const gigaBytes = marker * marker * marker; // One GB is 1024 MB
  const teraBytes = marker * marker * marker * marker; // One TB is 1024 GB

  // return bytes if less than a KB
  if (bytes < kiloBytes) return `${bytes} B`;
  // return KB if less than a MB
  else if (bytes < megaBytes)
    return `${(bytes / kiloBytes).toFixed(decimal)} KB`;
  // return MB if less than a GB
  else if (bytes < gigaBytes)
    return `${(bytes / megaBytes).toFixed(decimal)} MB`;
  // return GB if less than a TB
  else if (bytes < teraBytes)
    return `${(bytes / gigaBytes).toFixed(decimal)} GB`;
  // return TB
  else return `${(bytes / teraBytes).toFixed(decimal)} TB`;
}

export const isValidUrl = (val: string) => {
  const urlRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  return urlRegex.test(val);
};

export const isValidHexCode = (val: string) => {
  const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return regex.test(val);
};

export function stripHTMLTags(str) {
  return str?.replaceAll(/<[^>]+>/g, '') || '';
}

export function replaceLineBreaks(str) {
  return str?.replaceAll(/<br\s*[\/]?>/gi, '\n') || '';
}

export const convertToBase64 = file =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });

export function countChangedWords(oldStr, newStr) {
  // Split the strings into arrays of words
  const oldWords = oldStr && oldStr.trim().split(/\s+/);
  const newWords = newStr && newStr.trim().split(/\s+/);

  // Initialize counters for changed, added, and removed words
  let changedWordCount = 0;
  let addedWordCount = 0;
  let removedWordCount = 0;

  // Loop through each word in the new string
  for (let i = 0; i < newWords.length; i++) {
      // If there's a corresponding word in the old string
      if (oldWords?.[i]) {
          // If the word is different, increment the changed word counter
          if (oldWords?.[i] !== newWords?.[i]) {
              changedWordCount++;
          }
      } else {
          // If there's no corresponding word in the old string, it's an added word
          addedWordCount++;
      }
  }

  // Count the removed words (words present in the old string but not in the new one)
  removedWordCount = oldWords?.length - newWords?.length;

  // Return true if the total count of changed, added, and removed words is at least 3, otherwise false
  return (changedWordCount + addedWordCount + (removedWordCount >= 0 ? removedWordCount : 0));
}


export function toTitleCase(str) {
  return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}