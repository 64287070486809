import React from 'react';
import { Button, makeStyles, Modal } from '@material-ui/core';
import { FaCircleCheck } from 'react-icons/fa6';

import { useSelector } from 'react-redux';
import styles from './ShareModal.module.css';
import EmailIcon from '../../public/icons/email-icon.svg';
import ShareIcon from '../../public/icons/share.svg';
import CopyIcon from '../../public/icons/copy-icon.svg';
import CloseIcon from '../../public/icons/close-icon.svg';
import BackIcon from '../../public/icons/back-btn.svg';
import InviteIcon from '../../public/icons/invite-icon.svg';
import { meetingUrlClickEvent } from '../../lib/ga/home.events';
import { GOOGLE_MEET_BASE_URL, MEET_BASE_URL, ZOOM_MEET_BASE_URL } from '../../utils/constants';
import { RootState } from '../../store/reducers';

interface INextMeetingShareModalProps {
  open: boolean;
  handleClose: () => void;
  roomId: string;
  passcode: string;
  primaryClickHandler: () => void;
  secondaryClickHandler: () => void;
  tertiaryClickHandler: () => void;
  handleBackClick?: Function;
}

const useStyles = makeStyles({
  containedError: {
    backgroundColor: `#E1EBEF`,
  },
});

const NextMeetingShareModal: React.FC<INextMeetingShareModalProps> = ({
  open,
  handleClose,
  primaryClickHandler,
  secondaryClickHandler,
  tertiaryClickHandler,
  roomId,
  passcode,
  handleBackClick,
}) => {
  const classes = useStyles();

  const { isGcalConnected, isOutlookcalConnected } = useSelector (
    (state: RootState) => state.common,
  );

  let googleMeetUrl;
  if (
    roomId?.split('-').length >= 2
  ) {
    googleMeetUrl =`${GOOGLE_MEET_BASE_URL}/${roomId}`
  }

  let zoomMeetUrl;
  if (
    roomId?.includes('j/')
  ) {
    zoomMeetUrl = `${ZOOM_MEET_BASE_URL}/${roomId}`
  }

  const regex = new RegExp('^\\d+$');

  let msTeamsUrl;
  if (regex.test(roomId) && passcode.length === 6){
    msTeamsUrl = `https://teams.microsoft.com/l/meetup-join/${roomId}`
  }

  return (
    /* eslint-disable react/jsx-no-target-blank */
    <Modal className={styles['modal']} open={open} onClose={handleClose}>
      <div className={styles['inner-container']}>
        <div className=" relative w-full">
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-3 right-3"
          >
            <CloseIcon />
          </button>

          <button
            type="button"
            onClick={() => {
              handleClose();
              handleBackClick();
            }}
            className="absolute top-3 left-3"
          >
            <BackIcon />
          </button>
        </div>
        <div className={styles['dialog-content']}>
          <div className={styles['share-title']}>
            {isGcalConnected || isOutlookcalConnected ? (
              <div className="flex gap-1 items-start">
                <FaCircleCheck className="text-positive-green w-6 h-6" />
                <div className='text-xl leading-6 font-bold text-center'>Meeting invite sent from your calendar</div>
              </div>
            ) : (
              'Share'
            )}
          </div>
          <div className={styles['share-link']}>
            <div className={styles['link-item']}>
              <span>Meeting Link:</span>
              <a
                target="_blank"
                href={
                  googleMeetUrl || zoomMeetUrl || msTeamsUrl ||
                  `${MEET_BASE_URL}/${roomId}?passcode=${passcode}`
                }
                className={styles['link']}
                onClick={meetingUrlClickEvent}
              >
                {googleMeetUrl|| zoomMeetUrl || msTeamsUrl || `${MEET_BASE_URL}/${roomId}?passcode=${passcode}`}
              </a>
            </div>
            {passcode !== '.' && (
              <div className={styles['link-item']}>
                <span>Passcode:</span>
                <span className={styles['code']}>{passcode}</span>
              </div>
            )}
          </div>
          <div className={styles['action-container']}>
            <div className={styles['action-button']}>
              <Button
                variant="contained"
                disableElevation
                disableRipple
                className={styles['share-button']}
                onClick={secondaryClickHandler}
                classes={{
                  contained: classes.containedError,
                }}
              >
                <EmailIcon
                  className={`${styles['share-desktop']} w-5 h-5 text-secondary fill-current`}
                />
                <ShareIcon
                  className={`${styles['share-mobile']} w-5 h-5 text-secondary fill-current`}
                />
              </Button>
              <span className={styles['action-label']}>Share</span>
            </div>
            <div className={styles['action-button']}>
              <Button
                variant="contained"
                disableRipple
                disableElevation
                className={styles['share-copy-button']}
                onClick={primaryClickHandler}
                classes={{
                  contained: classes.containedError,
                }}
              >
                <CopyIcon className="w-5 h-5 text-secondary fill-current" />
              </Button>
              <span className={styles['action-label']}>Copy</span>
            </div>
            {!(isGcalConnected || isOutlookcalConnected) && (
              <div className={styles['action-button']}>
                <Button
                  variant="contained"
                  disableRipple
                  disableElevation
                  className={styles['share-invite-button']}
                  onClick={tertiaryClickHandler}
                  classes={{
                    contained: classes.containedError,
                  }}
                >
                  <InviteIcon className="w-5 h-5 text-secondary fill-current" />
                </Button>
                <span className={styles['action-label']}>Invite</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NextMeetingShareModal;
