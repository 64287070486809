/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable max-len */
import { Button, Modal } from '@material-ui/core';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';

import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import styles from './pretour.module.css';
import BackArrowIcon from '../../public/icons/backarrow-icon.svg';
import { RootState } from '../../store/reducers';
import Clipboard from '../../public/icons/copy-clipboard-icon.svg';
import ClipboardMobile from '../../public/icons/copy-clipboard-icon-mobile.svg';
import { SAVE_USER, SHOW_SNACKBAR } from '../../store/types';
import UserService from '../../services/user/user.service';

interface IPretourComponent {
  open: boolean;
  handleClose: () => void;
  idp: string;
}

const screens = [
  {
    image:
      'https://dev-zipteams-assets.s3.ap-south-1.amazonaws.com/new-intro-images/web_1.png',
    title: 'Welcome! Glad to have you part of Zipteams family',
    descr:
      'Zipteams provides you with meeting rooms and call recording analytics for you to do and keep all your customer conversations in one place.',
    format: 'format1',
  },
  // {
  //   image:
  //     'images/onboarding-2.svg',
  //   title: 'First things first, we are fully secure and follow global security protocols',
  //   descr:
  //     'Zipteams meeting room and call analytics features are ISO certified and follow all SOC2 security protocols',
  //   format: 'format2',
  // },
  {
    image:
      'images/onboarding-3.svg',
    title: 'Get started in just a few steps',
    descr:
      'Setup meeting rooms, connect your calendar or calling system and start your inbound and outbound meetings/calls on Zipteams',
    format: 'format2',
  },
  {
    image:
      'images/onboarding-4.svg',
    title: 'Get information about your customer prior to the meeting',
    descr:
      'Zipteams will send you all the latest news & information about your customer, so you can prepare yourself to build a rapport with them easily.',
    format: 'format2',
  },
  {
    image:
      'images/onboarding-5.png',
    title: 'Get summary and analytics of all your customer conversations',
    descr:
      'Zipteams will analyze your conversation in real-time and send you insights into important customer reactions and needs.',
    format: 'format2',
  },
  // {
  //   image:
  //     'https://dev-zipteams-assets.s3.ap-south-1.amazonaws.com/new-intro-images/web_6.png',
  //   title: 'Your dashboard will show you how can keep improving',
  //   descr:
  //     'You can keep tracking & improving your guests involvement and positivity to drive better engagement and fruitful business outcomes.',
  //   format: 'format2',
  // },
  // {
  //   image:
  //     'https://dev-zipteams-assets.s3.ap-south-1.amazonaws.com/new-intro-images/web_7.png',
  //   title: 'Any help you need, we are right here!',
  //   descr:
  //     'Use our chat support to get instant replies on your queries/issues.',
  //   format: 'format2',
  // },
  {
    image:
      'https://dev-zipteams-assets.s3.ap-south-1.amazonaws.com/new-intro-images/web_8.png',
    title: "Don't wait to get started, every conversation is important!",
    descr:
      'Your customer conversations have immense growth insights. Let us get you started on getting those immediately.',
    actionText: 'Lets get you setup',
    format: 'format3',
  },
];

const mobScreens = [
  {
    image:
      'images/mobile-1.png',
    title: 'Welcome! Glad to have you part of the Zipteams family',
    descr:
      'Zipteams provides you with meeting rooms and call recording analytics for you to do and keep all your customer conversations in one place.',
    format: 'format1',
  },
  // {
  //   image:
  //     'images/mobile-2.svg',
  //   title: 'First things first, we are fully secure and follow global security protocols',
  //   descr:
  //     'Zipteams meeting room and call analytics features are ISO certified and follow all SOC2 security protocols',
  //   format: 'format2',
  // },
  {
    image:
      'images/mobile-3.png',
    title:
      'Get started in just a few steps',
      descr:
      'Setup meeting rooms, connect your calendar or calling system and start your inbound and outbound meetings/calls on Zipteams',
    format: 'format4',
  },
  {
    image:
      'images/mobile-4.png',
    title: 'Get information about your customer prior to the meeting',
    descr:
      'Zipteams will send you all the latest news & information about your customer, so you can prepare yourself to build a rapport with them easily.',
    format: 'format2',
  },
  {
    image:
      'images/mobile-5.png',
    title: 'Get summary and analytics of all your customer conversations',
    descr:
      'Zipteams will analyze your conversation in real-time and send you insights into important customer reactions and needs.',
    format: 'format2',
  },
  // {
  //   image:
  //     'images/mobile-6.png',
  //   title: 'Zipteams meeting rooms are easy to access & fully secure',
  //   format: 'format2',
  // },
  // {
  //   image:
  //     'https://dev-zipteams-assets.s3.ap-south-1.amazonaws.com/new-intro-images/mobile_7.png',
  //   title: 'Get a summary of your conversation immediately after the meeting',
  //   format: 'format2',
  // },
  // {
  //   image:
  //     'https://dev-zipteams-assets.s3.ap-south-1.amazonaws.com/new-intro-images/mobile_8.png',
  //   title: 'Your dashboard will show you how can keep improving',
  //   format: 'format2',
  // },
  // {
  //   image:
  //     'https://dev-zipteams-assets.s3.ap-south-1.amazonaws.com/new-intro-images/mobile_9.png',
  //   title: 'Use our chat support to get instant replies on your queries/issues',
  //   format: 'format2',
  // },
  // {
  //   image:
  //     'https://dev-zipteams-assets.s3.ap-south-1.amazonaws.com/new-intro-images/mobile_10.png',
  //   title: 'Or Whatsapp us on +919653198284',
  //   format: 'format2',
  // },
  {
    image:
      'images/mobile-6.png',
    title: "Don't wait to get started, every conversation is important!",
    descr:
      'Your customer conversations have immense growth insights. Let us get you started on getting those immediately.',
    actionText: 'Lets get your setup',
    format: 'format3',
  },
];

const PreTourComponent: React.FC<IPretourComponent> = props => {
  const { open, handleClose, idp } = props;
  const dispatch = useDispatch();
  const router = useRouter();
  const handleShowSnackbar = (type, message) => {
    dispatch({
      type: SHOW_SNACKBAR,
      payload: {
        type,
        message,
      },
    });
  };
  const [page, setPage] = useState<number>(1);

  const { user } = useSelector((state: RootState) => state.common);

  const handleBackClick = () => {
    setPage(page - 1);
  };

  const handleNextClick = () => {
    // if page is greater than 7, then will remove localstorage item
    // so that this modal will not be visible again
    if (
      page >= 7 &&
      localStorage &&
      localStorage.getItem('is_new_user') === 'true'
    ) {
      localStorage.removeItem('is_new_user');
    }
    setPage(page + 1);
  };

  const updateUser = async () => {
    const response = await UserService.me();
    dispatch({
      type: SAVE_USER,
      payload: { ...response?.data?.data },
    });
  };

  const handleAddCustomerClick = async () => {
    handleClose();
    await UserService.update(user?.id, { meta: { onboarding_viewed: true } });
    await updateUser()
    // addCustomerClick();
    router.push('/workspace?selected=my_profile');
  };

  // const handleOpenOnbModal = () => {
  //   letsGetStartedButtonClicked();
  //   handleClose();
  //   openOnbModal();
  // };

  const getTourScreenByFormat = (pageNo: number) => {
    const item = isMobile
      ? mobScreens && mobScreens?.[pageNo - 1]
      : screens && screens?.[pageNo - 1];
    if (item?.format === 'format1') {
      return (
        <>
          <div onClick={handleAddCustomerClick} className='w-full text-tag-text-three text-right underline text-xs lg:text-sm -mt-2 lg:-mt-4 mb-2 cursor-pointer'>Skip Intro</div>
          <img
            alt="screen-img"
            src={item?.image}
            className={styles['image-1']}
          />
          <div className={styles['text-section']}>
            <div className={styles['title']}>{item?.title}</div>
            {item?.descr && <div className={styles['desc']}>{item?.descr}</div>}
          </div>
        </>
      );
    }
    if (item?.format === 'format3') {
      return (
        <>
          <div onClick={handleAddCustomerClick} className='w-full text-tag-text-three text-right underline text-xs lg:text-sm -mt-2 lg:-mt-4 mb-2 cursor-pointer'>Skip Intro</div>
          <img
            alt="screen-img"
            src={item?.image}
            className={styles['image-3']}
          />
          <div className={styles['text-section']}>
            <div className={styles['title']}>{item?.title}</div>
            {item?.descr && <div className={styles['desc']}>{item?.descr}</div>}
            {item?.actionText && (
              <Button
                variant="contained"
                color="primary"
                className={styles['button']}
                disableElevation
                disableRipple
                onClick={handleAddCustomerClick}
                // onClick={
                //   idp && idp !== 'zipteams'
                //     ? handleOpenOnbModal
                //     : handleAddCustomerClick
                // }
              >
                {idp && idp !== 'zipteams'
                  ? `Let’s get you started`
                  : item?.actionText}
              </Button>
            )}
          </div>
        </>
      );
    }
    if (item?.format === 'format4') {
      return (
        <>
          <div onClick={handleAddCustomerClick} className='w-full text-tag-text-three text-right underline text-xs lg:text-sm -mt-2 lg:-mt-4 mb-2 cursor-pointer'>Skip Intro</div>
          <div className={styles['text-section']}>
            <div className={styles['title']}>{item?.title}</div>
            {item?.descr && <div className={styles['desc']}>{item?.descr}</div>}

            {user?.meta?.zipme_link && (
              <div>
                <h4 className=" text-primary-dark text-xxs lg:text-base font-medium my-2">
                  Or send your own scheduling link to your contacts
                </h4>
                <div className=" text-center flex justify-center">
                  <a
                    href={`${user.meta.zipme_link}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-secondary underline text-xs lg:text-base"
                  >
                    {user?.meta?.zipme_link}
                  </a>
                  <button
                    className="ml-2"
                    onClick={() => {
                      copy(`${user?.meta?.zipme_link}`);
                      handleShowSnackbar(
                        'success',
                        'zipme link copied to the clipboard.',
                      );
                    }}
                    type="button"
                  >
                    {isMobile ? <ClipboardMobile /> : <Clipboard />}
                  </button>
                </div>
              </div>
            )}
          </div>
          <img
            alt="screen-img"
            src={item?.image}
            className={styles['image-4']}
          />
        </>
      );
    }
    if (pageNo === 2 && item?.format === 'format2') {
      return (
        <>
          <div onClick={handleAddCustomerClick} className='w-full text-tag-text-three text-right underline text-xs lg:text-sm -mt-2 lg:-mt-4 mb-2 cursor-pointer'>Skip Intro</div>
          <div className={styles['text-section']}>
            <div className={styles['title']}>{item?.title}</div>
            {item?.descr && <div className={styles['desc']}>{item?.descr}</div>}

            {user?.meta?.zipme_link && (
              <div>
                <h4 className=" text-primary-dark text-xxs lg:text-base font-medium my-2">
                  Or send your own scheduling link to your contacts
                </h4>
                <div className=" text-center flex justify-center">
                  <a
                    href={`${user?.meta?.zipme_link}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-secondary underline text-xs lg:text-base"
                  >
                    {user?.meta?.zipme_link}
                  </a>
                  <button
                    className="ml-2"
                    onClick={() => {
                      copy(`${user?.meta?.zipme_link}`);
                      handleShowSnackbar(
                        'success',
                        'zipme link copied to the clipboard.',
                      );
                    }}
                    type="button"
                  >
                    {isMobile ? <ClipboardMobile /> : <Clipboard />}
                  </button>
                </div>
              </div>
            )}
          </div>
          <img
            alt="screen-img"
            src={item?.image}
            className={styles['image-4']}
          />
        </>
      );
    }
    return (
      <>
        <div onClick={handleAddCustomerClick} className='w-full text-tag-text-three text-right underline text-xs lg:text-sm -mt-2 lg:-mt-4 mb-2 cursor-pointer'>Skip Intro</div>
        <div className={styles['text-section']}>
          <div className={styles['title']}>{item?.title}</div>
          {item?.descr && <div className={styles['desc']}>{item?.descr}</div>}
        </div>
        <img alt="screen-img" src={item?.image} className={styles['image-2']} />
      </>
    );
  };

  const screenLength = isMobile
    ? mobScreens && mobScreens?.length
    : screens && screens?.length;

  return (
    <Modal
      className={styles['modal']}
      open={open}
      onClose={(event, reason) =>
        reason !== 'backdropClick' ? handleClose() : f => f
      }
      disableEscapeKeyDown
    >
      <div className={styles['inner-container']}>
        <div className={styles['dialog-content']}>
          <div className={styles['screen']}>{getTourScreenByFormat(page)}</div>
          <div className={styles['footer']}>
            <div>
              {page !== 1 && (
                <button type="button" onClick={handleBackClick}>
                  <BackArrowIcon className="w-2 h-2 lg:w-3.5 lg:h-3.5 text-white fill-current" />
                </button>
              )}
            </div>
            <div>{`${page}/${screenLength}`}</div>
            <div>
              {page < screenLength && (
                <button type="button" onClick={handleNextClick}>
                  <BackArrowIcon className="w-2 h-2 lg:w-3.5 lg:h-3.5 text-white fill-current transform rotate-180" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PreTourComponent;
