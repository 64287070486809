/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import Head from 'next/head';
import { addSeconds, isPast } from 'date-fns';

import styles from './Layout.module.css';
import Sidebar from '../../components/sidebar/sidebar.component';
import { RootState } from '../../store/reducers';
import {
  HIDE_SIDEBAR,
  HIDE_SNACKBAR,
  HIDE_INSIGHTS_UNLOCK_MODAL,
  SHOW_INSIGHTS_UNLOCK_MODAL,
  SHOW_MEETING_NOTIFY_MODAL,
  HIDE_MEETING_NOTIFY_MODAL,
  SHOW_APP_MODAL,
  HIDE_APP_MODAL,
  SHOW_SNACKBAR,
  REALTIME_WIDGET_AVAILABILITY,
  IS_GCAL_CONNECTED,
  IS_OUTLOOKCAL_CONNECTED,
  CALL_HIPPO_TOKEN,
  CONNECTED_CRM,
  IS_ADMIN,
  ALL_ADMINS,
  FIRST_ADMIN,
  TOGGLE_MULTIUPLOAD_MODAL,
  TOGGLE_CALLQREPORT_MODAL,
} from '../../store/types';
import Snackbar from '../../components/snackbar/snackbar.component';
import Loader from '../../components/loader/loader.component';
import UnlockedInsightsModal from '../../components/unlock-insights-modal/unlock-insights-modal.component';
import ScheduleService from '../../services/schedule/schedule.service';
import { sleep } from '../../utils/helper';
import CustomerService from '../../services/customer/customer.service';
import MeetingNotifyModal from '../../components/meeting-notify-modal/meeting-notify-modal.component';
import SideHeader from '../../components/header/header-side.component';
import AppModalComponent from '../../components/app-modal/app-modal.component';
import UserService from '../../services/user/user.service';
import OrganisationService from '../../services/organisation/organisation.service';
import OAuthService from '../../services/auth/oauth.service';
import useDeviceDimension from '../../hooks/useDeviceDimension';
import { SocketContext } from '../../contexts/socket.context';
import MultiRecordingUploadModal from '../../components/DashboardComponents/multi-recording-upload-modal';
import { ExportToXLSXModal } from '../../pages/reports';
import GoogleCalendarPopup from '../../components/google-calendar-popup-modal/google-calendar-popup-modal';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = props => {
  const { children } = props;
  const dispatch = useDispatch();
  const router = useRouter();
  const { pathname } = router;
  const {
    showSideBar,
    snackbar,
    loader,
    branding,
    unlockedInsightsModal,
    meetingNotifyModal,
    userMeta,
    userType,
    realtimeWidget,
    user,
    isLoggedIn,
    appModal,
    selectedWorkspace,
    all_admins,
    multiUploadModal,
    callQualityReportExportModal
  } = useSelector((state: RootState) => state.common);
  const { insights_unlocked, insights_unlocked_viewed } = userMeta;
  const { availability, enabled, userInteracted } = realtimeWidget;
  const [isWorkspaceAdmin, setIsWorkspaceAdmin] = useState<boolean>(false);
  const [showGoogleCalendarPopup, setShowGoogleCalendarPopup] = useState<boolean>(false);
  const [workspaceAdminDetails, setWorkspaceAdminDetails] = useState<{
    first_name: string;
    last_name: string;
    email: string;
  }>();
  const isMobile = useDeviceDimension();
  const userRef = useRef<Object | null>(null);
  userRef.current = user;
  const socket = useContext(SocketContext);

  const shouldRenderHeader = () =>
    !pathname.startsWith('/auth') && pathname !== '/oauth' && pathname !== '/' && !pathname.startsWith('/warning');

  const getCallHippoApiKey = async () => {
    const data = await UserService.getCallHippoToken();

    if (data.data?.data?.id_token) {
      dispatch({ type: CALL_HIPPO_TOKEN, payload: data?.data?.data?.id_token });
    }
  };
  
  const getCrmConnections = async () => {
    const response = await OrganisationService.getCrmConnections();
    if (response?.data?.data?.name) {
      dispatch({ type: CONNECTED_CRM, payload: response?.data?.data?.name });
    } else {
      dispatch({ type: CONNECTED_CRM, payload: '' });
    }
  };

  const startPolling = async duration => {
    const startDate = new Date().toISOString();
    const endDate = addSeconds(new Date(), 10).toISOString();
    if (Object.keys(userRef.current || {}).length) {
      try {
        const response = await ScheduleService.getUpcomingConversation(
          startDate,
          endDate,
          true,
        );
        const { data = [] } = response?.data;
        if (data?.length) {
          const userSchedule = data?.filter(item => item?.user_id === user?.id);
          if (userSchedule?.length) {
            const sch = userSchedule?.[0];
            const {
              meet_url,
              passcode,
              customer_id,
              meeting_source,
              scheduled_at,
            } = sch;
            // polling API will not handle realtime notifcation as those are handled by WS
            // In next phase, all notification will be from WS, and plling to be removed
            if (meeting_source !== 'ZIPTEAMS_CALENDAR_REALTIME' && (scheduled_at && !isPast(new Date(scheduled_at)))) {
              const resp = await CustomerService.getCustomer(customer_id);
              const { data: respData } = resp?.data;
              const { name, contacts = [] } = respData;
              const { name: contactName } = contacts?.[0];
              dispatch({
                type: SHOW_MEETING_NOTIFY_MODAL,
                payload: {
                  meetingUrl: meet_url,
                  passcode,
                  customerName: name,
                  contactName,
                  meetingSource: meeting_source,
                  scheduledAt: scheduled_at,
                },
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
      await sleep(duration);
      startPolling(duration);
    }
  };

  const showMeetingNotificationModal = async (data) => {
    if (data && Object.keys(data)?.length) {
      const { userId, schedule } = data;
      if (Number(userId) === user?.id) {
        const {
          meet_url,
          passcode,
          meeting_source,
          scheduled_at,
          customer
        } = schedule;

        const { contacts = [] } = customer;
        const { name: contactName } = contacts?.[0];
        dispatch({
          type: SHOW_MEETING_NOTIFY_MODAL,
          payload: {
            meetingUrl: meet_url,
            passcode,
            customerName: customer?.name || '',
            contactName,
            meetingSource: meeting_source?.name,
            scheduledAt: scheduled_at,
          },
        });
      }
    }
  }

  const checkWorkspaceAdmin = async () => {
    const org_id = user?.organisation_id;
    let workspace_id = selectedWorkspace?.[0]?.id;
    if (!workspace_id) {
      const workspace: any =
        localStorage && JSON.parse(localStorage.getItem('selected_workspace'));
      workspace_id = workspace?.[0]?.id;
    }

    let workspace_admins = [];
    let first_admin = null;
    if (all_admins && all_admins.length) {
      workspace_admins = [...all_admins];
      first_admin = all_admins?.[0];
    } else {
      const response = await OrganisationService.getWorkspaceAdmins(
        org_id,
        workspace_id,
      );
      workspace_admins = response?.data?.data;
      dispatch({ type: ALL_ADMINS, payload: workspace_admins});
      dispatch({ type: FIRST_ADMIN, payload: workspace_admins?.[0] || null});
      first_admin = workspace_admins?.[0];
    }

    if (first_admin && Object.keys(first_admin).length && !workspaceAdminDetails) {
      const workspace_admin_id = first_admin?.id;
      const workspaceAdminDetailsResponse = await UserService.getUserProfile(
        workspace_admin_id,
      );

      setWorkspaceAdminDetails(workspaceAdminDetailsResponse?.data?.data);
    }
  };

  const initApp = async () => {
    const APIs = [
      OAuthService.getTokens('Google Calendar'),
      OAuthService.getTokens('Microsoft Calendar'),
    ];
    const response = await Promise.allSettled(APIs);
    for (let index = 0; index < response?.length; index++) {
      const item = response?.[index];
      if (item?.status === 'fulfilled') {
        const respData = item?.value?.data;
        if (respData?.data && respData?.data?.length) {
          if (index === 0) dispatch({ type: IS_GCAL_CONNECTED, payload: true });
          if (index === 1)
            dispatch({ type: IS_OUTLOOKCAL_CONNECTED, payload: true });
        }
      }
    }
  };

  useEffect(() => {
    if(isLoggedIn){
      if (!isMobile) {
        getCallHippoApiKey();
      }
      initApp();
      startPolling(8000);
      getCrmConnections();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const  calendar_disconnected = user?.meta?.calendar_disconnected ;
    const ignorePopup = localStorage.getItem('ignoreGoogleCalendarPopupModal') === 'true';
    const queryParam = router.query.selected;
  
    if (calendar_disconnected && !ignorePopup && queryParam !== 'sync_calendar_tab') {
      setShowGoogleCalendarPopup(true);
    } else {
      setShowGoogleCalendarPopup(false);
    }
  }, [router.query]);

  useEffect(() => {
    if (
      insights_unlocked &&
      !insights_unlocked_viewed &&
      userType === 'free_tier'
    ) {
      dispatch({
        type: SHOW_INSIGHTS_UNLOCK_MODAL,
        payload: {
          isUnlocked: true,
        },
      });
    }
  }, [insights_unlocked, insights_unlocked_viewed, userType]);

  useEffect(() => {
    if (enabled && availability && shouldRenderHeader() && !userInteracted) {
      dispatch({
        type: SHOW_APP_MODAL,
        payload: {
          visible: true,
          content: 'Keep your availability ON for instant meeting requests?',
          primaryActionText: 'Keep it on',
          secondaryActionText: 'Turn it off',
          primaryClickHandler: () => {
            const audio = new Audio('/assets/sweet_text.ogg');
            audio.muted = true;
            audio.play();
            dispatch({ type: HIDE_APP_MODAL });
          },
          secondaryClickHandler: () => {
            const audio = new Audio('/assets/sweet_text.ogg');
            audio.muted = true;
            audio.play();
            (async () => {
              try {
                await UserService.update(user?.id, {
                  meta: { realtime_meeting_availaibility: false },
                });
                dispatch({
                  type: REALTIME_WIDGET_AVAILABILITY,
                  payload: { availability: false },
                });
                dispatch({ type: HIDE_APP_MODAL });
              } catch (err) {
                dispatch({
                  type: SHOW_SNACKBAR,
                  payload: {
                    type: 'error',
                    message:
                      'Failed to toggle your availability. Please try again.',
                  },
                });
              }
            })();
          },
          showSecondaryButton: true,
          closable: false,
        },
      });
    }
  }, [enabled, availability, userInteracted]);

  useEffect(() => {
    if (selectedWorkspace?.length) {
      checkWorkspaceAdmin();
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (isLoggedIn) {
      const wsIO = socket();
      wsIO.on('connect', () => {
        console.log('WS connected'); 
      });
      wsIO.on('realtimeNotification', (data) => {
        showMeetingNotificationModal(data)
      })
      wsIO.on('notificationToggle', (msgData) => {
        const { data } = msgData
        dispatch({
          type: REALTIME_WIDGET_AVAILABILITY,
          payload: { 
            availability: data?.realtime_meeting_availaibility
          },
        });
      })
      return () => {
        console.log('removing sockets');
        wsIO.off('connect');
        wsIO.off('realtimeNotification');
        wsIO.off('notificationToggle');
      }
    } 
    return () => {}
  }, [isLoggedIn])

  return (
    <>
      <Head>
        <title>Zipteams</title>
      </Head>
      <div className={styles['layout-container']}>
        <Loader {...loader} />
        <Snackbar
          {...snackbar}
          handleClose={() => dispatch({ type: HIDE_SNACKBAR })}
        />
        <Sidebar
          open={showSideBar}
          handleClose={() => dispatch({ type: HIDE_SIDEBAR })}
          branding={branding}
          realtimeWidget={realtimeWidget}
          isWorkspaceAdmin={isWorkspaceAdmin}
          userImg={user?.avatar_url}
          name={`${user?.first_name} ${user?.last_name || ''}`}
          workspaceAdminDetails={workspaceAdminDetails}
        />
        <div className="flex w-full h-full flex-auto">
          {shouldRenderHeader() && (
            <SideHeader
              branding={branding}
              workspaceAdminDetails={workspaceAdminDetails}
            />
          )}
          <main
            className={`${styles['main-container']} ${
              !shouldRenderHeader() ? styles['no-margin'] : ''
            }`}
          >
            {children}
          </main>
        </div>
        {unlockedInsightsModal?.visible && (
          <UnlockedInsightsModal
            open={unlockedInsightsModal?.visible}
            isUnlocked={unlockedInsightsModal?.isUnlocked}
            handleClose={() => dispatch({ type: HIDE_INSIGHTS_UNLOCK_MODAL })}
          />
        )}
        {meetingNotifyModal?.visible && (
          <MeetingNotifyModal
            open={meetingNotifyModal.visible}
            handleClose={() => dispatch({ type: HIDE_MEETING_NOTIFY_MODAL })}
            meetingDetails={{
              customerName: meetingNotifyModal?.customerName,
              contactName: meetingNotifyModal?.contactName,
              meetingUrl: meetingNotifyModal?.meetingUrl,
              passcode: meetingNotifyModal?.passcode,
              meetingSource: meetingNotifyModal?.meetingSource,
              scheduledAt: meetingNotifyModal?.scheduledAt,
            }}
          />
        )}
        {appModal.visible && (
          <AppModalComponent
            open={appModal.visible}
            closable={appModal.closable}
            content={appModal.content}
            primaryActionText={appModal.primaryActionText}
            secondaryActionText={appModal.secondaryActionText}
            primaryClickHandler={appModal.primaryClickHandler}
            secondaryClickHandler={appModal.secondaryClickHandler}
            showSecondaryButton={appModal.showSecondaryButton}
            heroImage={appModal.heroImage}
          />
        )}
        <MultiRecordingUploadModal 
          open={multiUploadModal}
          setOpen={(val) => dispatch({ type: TOGGLE_MULTIUPLOAD_MODAL, payload: val })}
        />

        <ExportToXLSXModal 
          open={callQualityReportExportModal}
          setOpen={(val) => dispatch({ type: TOGGLE_CALLQREPORT_MODAL, payload: val })}
        />

        {showGoogleCalendarPopup && (
          <GoogleCalendarPopup onClose={() => setShowGoogleCalendarPopup(false)}
           open={showGoogleCalendarPopup}
           /> 
        )}
      </div>
    </>
  );
};

export default Layout;
