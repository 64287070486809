/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @next/next/no-img-element */
import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  MdArrowDropDown,
  MdPersonOutline,
  MdMailOutline,
} from 'react-icons/md';
import { HiOutlinePhone, HiOutlineVideoCamera } from 'react-icons/hi';
import * as Sentry from "@sentry/nextjs";

import styles from './header.module.css';
import ConversationIcon from '../../public/icons/conversation-icon.svg';
import ShareIcon from '../../public/icons/shared-page-icon.svg';
import SetupIcon from '../../public/icons/setup-icon.svg';
import AllConversationIcon from '../../public/icons/all-conversations.svg';
import ChartIcon from '../../public/icons/chart-icon.svg';
import SpeechInfoIcon from '../../public/icons/icon-speech-info.svg';
import OpenArrowIcon from '../../public/icons/open-arrow.svg';
import BugIcon from '../../public/icons/bug.svg';
import { HELP_CENTER_LINK_URL, ROUTES } from '../../utils/constants';
import { RootState } from '../../store/reducers';
import WorkspaceSelectionList from '../WorkspaceSelectionList/WorkspaceSelectionList.component';
import { METABASE_DASHBOARDS, SAVE_USER, SET_SELECTED_WORKSPACE } from '../../store/types';
import UserService from '../../services/user/user.service';
import OrganisationService from '../../services/organisation/organisation.service';
import ReportIssueModal from '../report-issue-modal/report-issue-modal';
// import VIPIcon from '../../public/icons/vip.svg';
// import AvailabilitySwitch from '../AvailabilitySwitch/availability-switch.component';
// import WarningTriangle from '../../public/icons/warning-triangle.svg';
// import ChromePerfIcon from '../../public/icons/chrome-perf-ss.svg';
// import InfoIcon from '../../public/icons/white-info.svg';

interface ISideHeader {
  branding: {
    headerText: string;
  };
  workspaceAdminDetails: {
    first_name: string;
    last_name: string;
    email: string;
  };
}

const CustomToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#122832',
    color: '#ffffff',
    fontSize: 14,
    padding: '8px',
  },
}))(Tooltip);

const SideHeader = (props: ISideHeader): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);
  const {
    branding,
    workspaceAdminDetails,
  } = props;
  const [selectedListAction, setSelectedListAction] = useState<string>('');
  const router = useRouter();

  const {
    workspaces,
    selectedWorkspace,
    metabaseDashboards,
    isAdmin,
    isManager,
    isSuperAdmin,
    user,
  } = useSelector((state: RootState) => state?.common);
  
  const [activeWorkspace, setActiveWorkspace] = useState<string>('');
  const [optionButtonEl, setOptionButtonEl] = useState<any>(null);
  const [showReportIssueBox, setShowReportIssueBox] = useState<boolean>(false);
  const [issueText, setIssueText] = useState<string>('');
  const [successPage, setSuccessPage] = useState<boolean>(false);
  const [isWpAdmin, setIsWpAdmin] = useState<boolean>(false);

  useEffect(() => {
    const menuOpen = window && window.localStorage.getItem('menuExpanded');
     setIsMenuOpen(menuOpen !== 'false');
  }, [])
  

  const dispatch = useDispatch();

  const handleActionClick = () => {
    if (successPage) {
      setSuccessPage(false);
      setShowReportIssueBox(false);
    } else {
      const eventId = Sentry.captureMessage('User Feedback');
      const userFeedback = {
        event_id: eventId,
        name: user.first_name,
        email: user.email,
        userId: user.id,
        comments: issueText,
      };
      Sentry.captureUserFeedback(userFeedback);
      setIssueText('');
      setSuccessPage(true);
    }
  }

  const handleSecondaryAction = () => {
    setSuccessPage(false);
  }

  const handleReportIssueModalClose = () => {
    setShowReportIssueBox(false);
  }

  const updateUser = async () => {
    const response = await UserService.me();
    dispatch({
      type: SAVE_USER,
      payload: { ...response?.data?.data },
    });
  }

  const handleSelectWorkspace = async item => {
    const filtered: any =
      workspaces &&
      workspaces?.find(workspaceItem => workspaceItem?.id === item?.id);

    dispatch({
      type: SET_SELECTED_WORKSPACE,
      payload: [filtered],
    });

    // await UserService.setPreferredWorkspace(filtered?.id);
    updateUser();
    localStorage.setItem('selected_workspace', JSON.stringify([filtered]));
    handleMenuClose();
    if (typeof filtered?.id === 'number' && (isAdmin || isSuperAdmin)) {
      await getMetabaseDashboard(filtered?.id);
    }
    window.location.reload();
  };

  const getMetabaseDashboard = async id => {
    const metabaseDashboardResponse =
      await OrganisationService.getMetabaseDashboard(id);
    const metabaseDashboardsData =
      metabaseDashboardResponse?.data.data?.[0]?.dashboard_url;
    dispatch({
      type: METABASE_DASHBOARDS,
      payload: metabaseDashboardsData,
    });
  };

  const setListItemAsPerRoute = () => {
    if (router?.asPath.startsWith(`/${ROUTES.CONVERSATIONS_DASHBOARD}`)) {
      setSelectedListAction('Conversations Insights');
    }
    if (router?.asPath.startsWith(`/${ROUTES.DASHBOARD}`)) {
      setSelectedListAction('My Conversations');
    }
    if (router?.asPath.includes(`/admin`)) {
      setSelectedListAction('All Conversations');
    }
    if (router?.asPath.startsWith(`/${ROUTES.SHARED_WITH_YOU}`)) {
      setSelectedListAction('Shared With Me');
    }
    if (router?.asPath.startsWith(`/${ROUTES.PROFILE}`)) {
      setSelectedListAction('Profile');
    }
    if (router?.asPath.startsWith(`/${ROUTES.WORKSPACE}`)) {
      setSelectedListAction('Setup');
    }
  };

  useEffect(() => {
    setIsWpAdmin(isAdmin);
  }, [isAdmin]);

  useEffect(() => {
    setListItemAsPerRoute();
  }, [router?.query]);

  const handleListItemClick = label => {
    if (selectedListAction === label) return;
    setSelectedListAction(label);
  };

  // to add menu item, add objects in this array
  const menuItems = [
    ...([
      {
        toolTipText: 'Setup',
        linkItem: (
          <Link href="/workspace">
            <a className="flex items-center h-10 no-underline w-full">
              <SetupIcon
                className="w-5 h-5 text-white fill-current"
                style={{ minWidth: '20px' }}
              />
              <span
                className={`font-normal text-white leading-4.5 overflow-hidden whitespace-nowrap text-sm ml-4 ${isMenuOpen ? 'w-full' : 'w-0'
                  }`}
              >
                Setup
              </span>
            </a>
          </Link>
        ),
      },
    ]),
    ...((isAdmin || isManager) && metabaseDashboards && metabaseDashboards?.length
      ? [
        {
          toolTipText: 'Conversations Insights',
          linkItem: (
            <Link href={`/${ROUTES.CONVERSATIONS_DASHBOARD}`}>
              <a
                className="flex items-center h-10 no-underline w-full"
              // onClick={() => setIsMenuOpen(false)}
              >
                <ChartIcon
                  className="w-5 h-5 text-white fill-current"
                  style={{ minWidth: '20px' }}
                />
                <span
                  className={`font-normal text-white leading-4.5 overflow-hidden whitespace-nowrap text-sm ml-4 ${isMenuOpen ? 'w-full' : 'w-0'
                    }`}
                >
                  Conversations Insights
                </span>
              </a>
            </Link>
          ),
        },
      ]
      : []),
    {
      toolTipText: 'My Conversations',
      linkItem: (
        <Link href={`/${ROUTES.DASHBOARD}`}>
          <a
            className="flex items-center h-10 no-underline w-full"
          // onClick={() => setIsMenuOpen(false)}
          >
            <ConversationIcon
              className="w-5 h-5 text-white fill-current"
              style={{ minWidth: '20px' }}
            />
            <span
              className={`font-normal text-white leading-4.5 overflow-hidden whitespace-nowrap text-sm ml-4 ${isMenuOpen ? 'w-full' : 'w-0'
                }`}
            >
              My Conversations
            </span>
          </a>
        </Link>
      ),
    },
    {
      toolTipText: 'Shared With Me',
      linkItem: (
        <Link href={`/${ROUTES.SHARED_WITH_YOU}`}>
          <a
            className="flex items-center h-10 no-underline w-full"
          // onClick={() => setIsMenuOpen(false)}
          >
            <ShareIcon
              className="w-5 h-5 text-white fill-current"
              style={{ minWidth: '20px' }}
            />
            <span
              className={`font-normal text-white leading-4.5 overflow-hidden whitespace-nowrap text-sm ml-4 ${isMenuOpen ? 'w-full' : 'w-0'
                }`}
            >
              Shared With Me
            </span>
          </a>
        </Link>
      ),
    },
    ...(isAdmin || isManager
      ? [
        {
          toolTipText: 'All Conversations',
          linkItem: (
            <Link href={`/${ROUTES.DASHBOARD}/admin`}>
              <a
                className="flex items-center h-10 no-underline w-full"
              // onClick={() => setIsMenuOpen(false)}
              >
                <AllConversationIcon
                  className="w-5 h-5 text-white fill-current"
                  style={{ minWidth: '20px' }}
                />
                <span
                  className={`font-normal text-white leading-4.5 overflow-hidden whitespace-nowrap text-sm ml-4 ${isMenuOpen ? 'w-full' : 'w-0'
                    }`}
                >
                  All Conversations
                </span>
              </a>
            </Link>
          ),
        },
      ]
      : []),
    {
      toolTipText: 'Help Center',
      linkItem: (
        <a
          className="flex items-center h-10 no-underline w-full"
          onClick={() => {
            window.open(HELP_CENTER_LINK_URL, '_blank').focus();
            // setIsMenuOpen(false);
          }}
        >
          <SpeechInfoIcon
            className="w-5 h-5 text-white fill-current"
            style={{ minWidth: '20px' }}
          />
          <span
            className={`font-normal text-white leading-4.5 overflow-hidden whitespace-nowrap text-sm ml-4 ${isMenuOpen ? 'w-full' : 'w-0'
              }`}
          >
            Help Center
          </span>
        </a>
      ),
    },
  ];

  const handleWorkspaceList = event => {
    event.preventDefault();
    event.stopPropagation();
    setOptionButtonEl(true);
  };

  const handleMenuClose = () => {
    setOptionButtonEl(null);
  };

  const getPrefWorkspaceId = () => {
    const selectedWpFromStorage = window && window.localStorage && JSON.parse(localStorage.getItem('selected_workspace'));
    if (isSuperAdmin && selectedWpFromStorage && selectedWpFromStorage?.length) {
      return Number(selectedWpFromStorage?.[0]?.id)
    } else {
      return user?.workspace_preference_id
    }
  }

  useEffect(() => {
    if (user && workspaces && workspaces.length) {
      const wpId = getPrefWorkspaceId()
      const workspace = workspaces && workspaces.length && workspaces.find((item) => item.id === wpId);
      setActiveWorkspace(workspace?.name);
    }
  }, [user, workspaces, selectedWorkspace]);

  return (
    <nav
      style={{ width: isMenuOpen ? '15rem' : '60px' }}
      className="group relative bg-primary h-screen z-10 transition-all duration-300 flex-col hidden lg:flex"
    >
      <a
        className="p-0 mt-4 mb-6 h-10 flex items-center pl-3 w-full"
        // onClick={() => setIsMenuOpen(false)}
      >
        <img
          src="/icons/logo-short-inverted.svg"
          className="h-9"
          alt="header-logo"
        />
        <span
          className={`text-white leading-5 overflow-hidden whitespace-nowrap font-bold text-lg ml-4 text-left ${isMenuOpen ? 'w-full' : 'w-0'
            }`}
        >
            <div className='flex flex-col gap-1'>
              {branding.headerText || ''}
              {
                workspaces && workspaces?.length > 1 && (
                  <span className='font-medium text-xs text-audio-timeline whitespace-nowrap overflow-ellipsis overflow-hidden mr-1'>
                    {activeWorkspace}
                    {selectedWorkspace?.[0]?.subscription_tier === 'enterprise_tier' && isSuperAdmin && (
                      <MdArrowDropDown
                        className="inline-block w-5 h-5"
                        onClick={handleWorkspaceList}
                      />
                    )} 
                  </span>
                )
              }
            </div>
        </span>
      </a>

      <span className="w-4 absolute top-8 left-40">
        <WorkspaceSelectionList
          el={optionButtonEl}
          handleMenuClose={handleMenuClose}
          handleSelectWorkspace={handleSelectWorkspace}
          isHeader
          workspaces={workspaces}
        />
      </span>

      <span className="absolute top-6 -right-2.5">
        <IconButton
          className={styles['menu-toggle-button']}
          onClick={() => { 
            setIsMenuOpen(!isMenuOpen);
            window.localStorage.setItem('menuExpanded',JSON.stringify(!isMenuOpen));
          }}
        >
          <OpenArrowIcon
            className={`text-white fill-current w-2 h-3 transition-all duration-300 ${isMenuOpen ? 'transform rotate-180' : 'transform rotate-0'
              }`}
          />
        </IconButton>
      </span>
      <ul className="list-none p-0 m-0 flex flex-col items-center flex-1 h-full">
        {menuItems.map((item, idx) => (
          <CustomToolTip
            key={`menuListItem${idx}`}
            placement="right"
            title={!isMenuOpen ? item?.toolTipText || '' : ''}
          >
            <li
              className={` w-full pl-3.5 border-l-4 border-transparent hover:bg-secondary mb-3 hover:border-white cursor-pointer ${selectedListAction === item?.toolTipText
                ? 'border-white bg-secondary'
                : ''
                }`}
              onClick={() => handleListItemClick(item.toolTipText)}
            >
              {item?.linkItem || []}
            </li>
          </CustomToolTip>
        ))}
        {/* subscription banner */}
        {/* <li className="w-full my-auto flex flex-col items-center px-4 relative">
          <VIPIcon className="w-11 h-9 absolute -top-6" />
          <span
            className={`text-center font-normal text-secondary text-xs leading-3.5 p-2 pt-3.5 border border-secondary ${
              isMenuOpen ? 'block' : 'hidden'
            }`}
            style={{ backgroundColor: '#E1EBEF', borderRadius: '4px' }}
          >
            Feels awesome to have you on our premium trial
          </span>
        </li> */}
        {/* profle item & availability toggle */}
        {isMenuOpen && workspaceAdminDetails && (
          <li className="w-10/12 text-white  h-full flex">
            <div
              className={`${styles['support-border']} flex flex-col text-sm px-4 mt-auto`}
            >
              <div>
                <h3 className="mt-2">Your Admin</h3>
                <div className="mt-2">
                  <MdPersonOutline className="inline h-4 w-4 pb-0.5 mr-0.5" />
                  {`${workspaceAdminDetails?.first_name} ${workspaceAdminDetails?.last_name || ''
                    }`}
                </div>
                <div className=" text-audio-timeline mt-0.5 mb-2">
                  <a
                    href={`mailto:${workspaceAdminDetails?.email}`}
                    className="flex"
                  >
                    <div className="h-full">
                      <MdMailOutline className="inline h-3.5 w-3.5 pb-0.5 mr-1 text-white" />
                    </div>
                    <span className="break-all">
                      {workspaceAdminDetails?.email}
                    </span>
                  </a>
                </div>
              </div>
              <div className={styles['border-seperator']} />
              <div>
                <h3 className="my-2">Our Support</h3>
                <div className="mt-2">
                  <a href="mailto:hello@zipteams.com">
                    <MdMailOutline className="inline h-3.5 w-3.5 pb-0.5 mr-1 text-white" />
                    hello@zipteams.com
                  </a>
                </div>
                <div className="my-1">
                  <HiOutlinePhone className="inline h-3.5 w-3.5 pb-0.5 mr-1" />
                  +91 9653198284
                </div>
                <div className=" mb-2 underline">
                  <a
                    href="https://zipme.at/zipteams"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <HiOutlineVideoCamera className="inline h-4 w-4 pb-0.5 mr-1" />
                    zipme.at/zipteams
                  </a>
                </div>
              </div>
            </div>
          </li>
        )}
        {isMenuOpen && (
          <li className='w-full my-6 text-white'>
            <div className="pl-4.5 flex gap-3 items-center cursor-pointer" onClick={() => {
                setShowReportIssueBox(true)
              }}>
              <BugIcon
                className="w-5 h-5 fill-current"
                style={{ minWidth: '20px' }}
              />
              <span
                className={`font-normal leading-4.5 overflow-hidden whitespace-nowrap text-sm ${
                  isMenuOpen ? 'w-full' : 'w-0'
                }`}
              >
                Report an issue
              </span>
            </div>
          </li>
        )}
        {/* <li
          className={`w-full mt-auto mb-2.5 cursor-pointer flex  ${
            isMenuOpen
              ? 'flex-row justify-between items-start pl-3.5'
              : 'flex-col items-center'
          }`}
        >
          <Link href={`/${ROUTES.PROFILE}`}>
            <a
              className="flex items-center h-10 no-underline"
              // onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center mb-3">
                <div className="relative">
                  <img
                    className="w-9 h-9 rounded-full bg-white flex justify-center items-center text-primary"
                    src={userImg || '/icons/profile-default.svg'}
                    alt="profile-img"
                    onError={e => {
                      e.currentTarget.onerror = null; // prevents looping
                      e.currentTarget.src = '/icons/profile-default.svg';
                    }}
                  />
                  {!isGcalConnected && !isOutlookcalConnected && (
                    <WarningTriangle className="w-4 h-4 absolute -top-1.5 -right-1" />
                  )}
                </div>
                <span
                  className={`ml-2 text-white font-normal text-sm leading-4 ${
                    isMenuOpen ? 'block' : 'hidden'
                  }`}
                >
                  {name}
                </span>
              </div>
            </a>
          </Link>
          <div
            className={`flex flex-row items-center ${
              isMenuOpen ? 'mr-2.5' : ''
            }`}
          >
            {realtimeWidget?.enabled && (
              <CustomToolTip
                placement="right"
                title="Toggle your availability for instant meeting requests"
              >
                <div className="flex mb-3 mt-1">
                  <AvailabilitySwitch />
                </div>
              </CustomToolTip>
            )}
            {isMenuOpen && realtimeWidget?.enabled && (
              <span
                className="mb-1.5 ml-2"
                onClick={() => {
                  setShowChromeExtensionModal(prevState => !prevState);
                }}
              >
                <InfoIcon />
              </span>
            )}
          </div>
        </li> */}
      </ul>
      {showReportIssueBox ? (
        <ReportIssueModal 
          isMenuOpen={isMenuOpen}
          closeModal={handleReportIssueModalClose}
          issueText={issueText}
          successPage={successPage}
          updateIssueText={setIssueText}
          handlePrimaryAction={handleActionClick}
          handleSecondaryAction={handleSecondaryAction}
        />
      ) : null}
      {/* {showInstallModal && !isInstallModalIgnoreClicked && (
        <div
          className={`fixed bottom-2 rounded mx-2 ${
            isMenuOpen ? 'left-60' : 'left-16'
          } bg-primary text-white text-base leading-5`}
        >
          <div className="relative w-64 px-3 py-2">
            Please install our Calendar Addons to quickly add Zipteams rooms to
            your meetings
            <div className="flex justify-end">
              <button
                type="button"
                className="bg-secondary font-bold text-xs leading-3.5 flex justify-center items-center px-4 py-2 cursor-pointer rounded"
                onClick={() => {
                  router?.push('/profile?selected=integrations');
                  setShowInstallModal(false);
                }}
              >
                Install Now
              </button>
            </div>
            <button
              type="button"
              className="bg-transparent font-bold text-xs leading-3.5 flex justify-center items-center p-1 cursor-pointer rounded-full absolute top-1 right-1"
              onClick={() => {
                installModalIgnoreClickHandler();
              }}
            >
              <CrossIcon className="w-3 h-3" />
            </button>
          </div>
        </div>
      )}
      {showChromeExtensionModal && (
          <div
            className={`${styles['tooltip']} fixed bottom-2 rounded mx-2 ${
              isMenuOpen ? 'left-60' : 'left-16'
            } bg-primary text-white text-base leading-5`}
          >
            <div className={styles['tooltip-arrow']} />

            <div
              className="relative px-3 py-2 text-sm"
              style={{ width: '700px' }}
            >
              <div>
                <div className="font-bold">
                  Please complete the below steps to ensure you get instant
                  meeting alerts on your Chrome browser
                </div>
                <div className="mt-4 font-normal ">
                  <div className="pl-1">
                    1. Install Zipteams Chrome Extension to get instant meeting
                    alerts across on any tab that you working on Chrome.{' '}
                    <a
                      href="https://chrome.google.com/webstore/search/zipteams"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold underline"
                    >
                      Install Now.
                    </a>
                  </div>
                  <div className="mt-3 pl-1 pb-1">
                    2. Add{' '}
                    <a
                      className="font-bold"
                      href="https://app.zipteams.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      app.zipteams.com
                    </a>{' '}
                    to always active in Chrome &gt; Settings &gt; Performance as
                    shown below.{' '}
                    <a
                      className="font-bold underline"
                      href="https://blog.google/products/chrome/new-chrome-features-to-save-battery-and-make-browsing-smoother/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here
                    </a>{' '}
                    to know more.
                  </div>
                  <ChromePerfIcon />
                </div>
              </div>
              <button
                type="button"
                className="bg-transparent font-bold text-xs leading-3.5 flex justify-center items-center p-1 cursor-pointer rounded-full absolute top-1 right-1"
                onClick={() => {
                  chromeExtensionModalIgnoreClickHandler();
                }}
              >
                <CrossIcon className="w-3 h-3" />
              </button>
            </div>
          </div>
      )} */}
    </nav>
    // <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
    // </ClickAwayListener>
  );
};

export default SideHeader;
