/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-extra-boolean-cast */
import { addSeconds, format, isValid } from 'date-fns';
import { ChangeEvent, DragEvent, useRef, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { Modal } from '@material-ui/core';

import styles from './common.module.css';
import useSnackbar from '../../hooks/useSnackbar';
import CrossIcon from '../../public/icons/cross-icon.svg';
import FileUploadIcon from '../../public/icons/file-upload.svg';
import MusicFileIcon from '../../public/icons/music-file.svg';
import TinyTick from '../../public/icons/tiny-tick.svg';
import ConversationsService from '../../services/conversations/conversations.service';
import { RootState } from '../../store/reducers';
import { formatBytes } from '../../utils/helper';
import ThreeDotLoader from '../loader/three-dots-loader';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
};

interface Part {
  ETag: string;
  PartNumber: number;
}

const maxTotalSize = 2 * (isMacOs ? 1000 * 1000 * 1000 : 1024 * 1024 * 1024); // 2GB

const MultiRecordingUploadModal = (props: Props) => {
  const { open, setOpen } = props;
  const [xlsxFile, setXlsxFile] = useState<File>(null);
  const [xlsxData, setXlsxData] = useState<{ [key: string]: any }[]>([]);
  const [uploadedAudioFiles, setUploadedAudioFiles] = useState<File[]>([]);
  const [uploadedAudioFileNames, setUploadedAudioFileNames] = useState<
    string[]
  >([]);
  const [uploadedAudioFileSize, setUploadedAudioFileSize] = useState<number>(0);
  const [fileNamesArr, setFileNamesArr] = useState<string[]>([]);
  const [error, setError] = useState<string>('');
  const [audioError, setAudioError] = useState<{ [key: string]: string }>(null);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [fileUploadData, setFileUploadData] =
    useState<{ [key: string]: any }>(null);
  const [customerData, setCustomerData] =
    useState<{ [key: string]: any }>(null);
  const [showFileRemove, setShowFileRemove] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [isFileProcessing, setIsFileProcessing] = useState<boolean>(false);
  const [isInBounds, setIsInBounds] = useState<boolean>(false);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const { user } = useSelector((state: RootState) => state.common);
  const { showSnackbar } = useSnackbar();

  const xlsxAccept = ['.xlsx'];
  const audioAccept = ['.mp3', '.wav'];

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e?.target?.files?.length) return;
    setXlsxFile(e?.target?.files?.[0]);
    handleXlsxFileParse(e?.target?.files?.[0]);
  };

  const handleFileAudioInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e?.target?.files?.length) return;
    handleAudioFileUpload(e?.target?.files);
  };

  const handleAudioFileUpload = (_files: FileList) => {
    let fileSize = 0;
    const fileArr = [];
    const fNameArr = [];
    for (let i = 0; i < _files.length; i++) {
      const file = _files?.[i];
      const isAudioValid = validateAudioFile(file);
      if (isAudioValid && !uploadedAudioFileNames.includes(file.name)) {
        fileSize += file.size;
        fileArr.push(file);
        fNameArr.push(file.name);
        if (!fileNamesArr.includes(file.name)) {
          setAudioError(prevState => ({
            ...prevState,
            [file.name]: `File name not present in the uploaded sheet`,
          }));
        }
      }
    }

    setUploadedAudioFileSize(prevState => prevState + fileSize);
    setUploadedAudioFiles([...uploadedAudioFiles, ...fileArr]);
    setUploadedAudioFileNames([...uploadedAudioFileNames, ...fNameArr]);
    const diff =
      fileNamesArr?.length - [...uploadedAudioFileNames, ...fNameArr]?.length;
    if (diff) {
      setError(
        `${
          diff > 0
            ? `You have ${diff} more recording(s) to upload`
            : 'Uploaded files mismatch'
        }`,
      );
    } else {
      setError('');
    }
  };

  const handleXlsxFileParse = (_file: File) => {
    setIsFileProcessing(true);
    const isValidFile = validate(_file);
    if (isValidFile) {
      const reader = new FileReader();

      reader.onload = async event => {
        const workbook = XLSX.read(event.target.result, {
          type: 'binary',
          cellDates: true,
          cellText: false,
        });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet);
        console.log(sheetData);
        
        const isValidXlsx = validateXlsxSheetData(sheetData);
        if (isValidXlsx && sheetData.length) {
          const payloadArr = [];
          const userEmails = [];
          const fileNameArr =
            sheetData &&
            sheetData.map(row => {
              const payload = {};
              payload['customer_name'] = row?.['Customer Name'];
              payload['customer_email'] = row?.['Contact Email'];
              payload['salesperson_email'] = row?.['Salesperson Email'];
              payload['filename'] = row?.['Filename'];
              payload['conversation_date'] = addSeconds(row?.['Date'], 10);
              payload['customer_contact_number'] = row?.['Phone Number'];
              payload['disposition_status'] = row?.['Disposition Status'];
              payloadArr.push(payload);
              userEmails.push(row?.['Salesperson Email']);
              return row?.['Filename'];
            });
          setFileNamesArr(fileNameArr);
          const res = await createUpdateCustomers(payloadArr, userEmails);
          if (res) {
            setPage(1);
            setXlsxData(sheetData);
          }
        }
        setIsFileProcessing(false);
      };
      reader.readAsBinaryString(_file);
    }
  };

  const createUpdateCustomers = async (payloadArr, userEmails) => {
    if (!(payloadArr && payloadArr.length)) return false;
    const filtered = [];
    try {
      const payload = {
        meta: [...payloadArr],
      };
      const response = await ConversationsService.createMultiUploadCustomers(
        payload,
      );
      const results = response?.data?.data?.results || [];
      
      if (results?.length) {
        const emails = results.map(row => row?.email);
        userEmails.forEach(email => {
          if (!emails.includes(email)) {
            filtered.push(email);
          }
        })
        if (filtered?.length) {
          setError(
            `Salesperson with email${filtered.length > 1 ? 's' : ''} 
            ${filtered.join(', ')} ${filtered.length > 1 ? 'do' : 'does'}  
            not exist. Please check and upload again`
          )
        }
      }
      setCustomerData(results);
    } catch (err) {
      console.log(err);
      showSnackbar({
        type: "error",
        message: "Failed to fetch metadata"
      })
    }
    return filtered?.length === 0
  };

  const validateXlsxSheetData = (data: { [key: string]: any }[]) => {
    let counter = 0;
    if (data && data.length <= 0) {
      counter += 1;
      setError(`Uploaded sheet is empty. Please check and try again`);
    }

    if (data && data.length > 40) {
      counter += 1;
      setError(`Maximum 40 recordings can only be uploaded at a time`);
    }

    const emailRegex = /^(?!.*\.{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
    const isMandatoryDataPresent =
      data &&
      data.every(
        row =>
          row?.['Date'] &&  
          row?.['Customer Name'] &&
          row?.['Contact Email'] &&
          row?.['Salesperson Email'] &&
          row?.['Filename'],
      );

    const isDateInvalid = data && data.filter(row => !(isValid(row?.['Date']) && format(row?.['Date'], 'HH:mm:ss') !== '23:59:50'));

    if (isDateInvalid?.length) {
      counter += 1;
      setError(
        `It seems date is not in right format of dd/mm/yyyy hh:mm:ss. Please check and upload again.`,
      );
    }
        
    const isEmailValid = data && data.every(row => row?.['Contact Email'] && emailRegex.test(row?.['Contact Email']))
    if (!isEmailValid) {
      counter += 1;
      setError(
        `It seems some email IDs are not valid. Please check and upload again.`,
      );
    }

    if (!isMandatoryDataPresent) {
      counter += 1;
      setError(
        `Some rows are missing mandatory data, Please check and upload again`,
      );
    }
    if (counter === 0 && error) {
      setError('');
    }
    return counter === 0;
  };

  const validateAudioFile = (_file: File) => {
    let errorCounter = 0;
    const maxFileSize = 50 * (isMacOs ? 1000 * 1000 : 1024 * 1024); // 50MB
    
    const totalSize = uploadedAudioFileSize + _file.size;
    const fileExt = `.${
      _file?.name?.substring(
        _file?.name?.lastIndexOf('.') + 1,
        _file?.name?.length,
      ) || ''
    }`;
    if (audioAccept && audioAccept?.length && !audioAccept.includes(fileExt)) {
      errorCounter += 0;
      setAudioError(prevState => ({
        ...prevState,
        [_file.name]: `File size should be in mp3 & wav format.`,
      }));
    }
    if (_file?.size > maxFileSize) {
      errorCounter += 0;
      setAudioError(prevState => ({
        ...prevState,
        [_file.name]: `File size should be max 50MB. Try Again.`,
      }));
    }
    if (totalSize >= maxTotalSize) {
      errorCounter += 1;
      setError(`Total size of files should not exceed 2GB`);
    }
    return errorCounter === 0;
  };

  const validate = (_file: File): boolean => {
    let errorCounter = 0;
    const fileExt = `.${
      _file?.name?.substring(
        _file?.name?.lastIndexOf('.') + 1,
        _file?.name?.length,
      ) || ''
    }`;
    if (xlsxAccept && xlsxAccept?.length && !xlsxAccept.includes(fileExt)) {
      errorCounter += 1;
      setError(
        `Document could not be uploaded. Please check the format and upload again.`,
      );
    }
    return errorCounter === 0;
  };

  const handleXlsxFileRemove = () => {
    setXlsxData(null);
    setXlsxFile(null);
    setError('');
  }

  const handleFileRemove = (name: string) => {
    const filteredFiles = uploadedAudioFiles.filter(
      pFile => pFile.name !== name,
    );
    const filteredFileNames = uploadedAudioFileNames.filter(
      pName => pName !== name,
    );
    const totalFileSize = filteredFiles.reduce(
      (acc, pFile) => acc + pFile.size,
      0,
    );

    const aError = { ...audioError };
    delete aError?.[name];

    let errorCounter = 0;
    const diff = fileNamesArr?.length - filteredFileNames?.length;
    if (diff) {
      errorCounter += 1;
      setError(
        `${
          diff > 0
            ? `You have ${diff} more recording(s) to upload`
            : 'Uploaded files mismatch'
        }`,
      );
    } else {
      setError('');
    }
    if (totalFileSize >= maxTotalSize) {
      setError(`Total size of files should not exceed 2GB`);
    } else if (errorCounter === 0) {
      setError('');
    }

    setAudioError(aError);
    setUploadedAudioFileSize(totalFileSize);
    setUploadedAudioFiles(filteredFiles);
    setUploadedAudioFileNames(filteredFileNames);
  };

  const uploadFileInMultiParts = async (
    conversationDate,
    customerId,
    fileExtn,
    uploadedFile,
  ) => {
    try {
      const initiatedUploadObj =
        await ConversationsService.initiateMultipartUpload({
          userEmail: user?.email,
          scheduledAt: conversationDate,
          customerId,
          fileExtn,
        });

      const partSize = 5 * 1024 * 1024; // 5MB chunk size
      const parts: Part[] = [];
      setFileUploadData(prevState => ({
        ...prevState,
        [uploadedFile.name]: { progress: 0, isCompleted: false },
      }));
      for (let i = 0; i < uploadedFile.size; i += partSize) {
        const partNumber = Math.floor(i / partSize) + 1;
        const part = uploadedFile.slice(i, i + partSize);

        const uploadUrl = await ConversationsService.getPresignedUrl({
          uploadId: initiatedUploadObj?.data?.data?.uploadId,
          uploadKey: initiatedUploadObj?.data?.data?.uploadKey,
          partNumber,
        });

        const etag = await ConversationsService.uploadPart(
          uploadUrl?.data?.data?.presignedUrl,
          part,
        );
        parts.push({ ETag: etag, PartNumber: partNumber });
        const perc = (((i + partSize) / uploadedFile.size) * 100).toFixed(0);
        setFileUploadData(prevState => ({
          ...prevState,
          [uploadedFile.name]: {
            progress: Number(perc) > 100 ? 100 : perc,
            isCompleted: false,
          },
        }));
      }

      const multipartUploadResponse =
        await ConversationsService.completeMultipartUpload({
          uploadId: initiatedUploadObj?.data?.data?.uploadId,
          uploadKey: initiatedUploadObj?.data?.data?.uploadKey,
          uploadedParts: parts,
        });
      setFileUploadData(prevState => ({
        ...prevState,
        [uploadedFile.name]: { progress: 100, isCompleted: true },
      }));

      return multipartUploadResponse?.data?.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const handleFilesUpload = async () => {
    try {
      const payloadArr = [];
      uploadedAudioFiles.forEach(file => {
        const customer =
          customerData &&
          customerData.filter(cust => cust.filename === file.name)?.[0];
        const fileExtn = file.name.split('.')?.[1];
        if (customer) {
          payloadArr.push(
            uploadFileInMultiParts(
              customer?.conversation_date,
              customer?.customer_id,
              fileExtn,
              file,
            ),
          );
        }
      });
      setShowFileRemove(false);
      setIsFileUploading(true);
      await Promise.allSettled(payloadArr);
      setIsFileUploading(false);
      setIsFileUploaded(true);
    } catch (err) {
      console.log(err);
      showSnackbar({
        type: 'error',
        message: 'Error uploading a file, Please try again',
      });
    }
  };

  const handleModalClose = () => {
    setXlsxData(null);
    setUploadedAudioFiles([]);
    setUploadedAudioFileNames([]);
    setUploadedAudioFileSize(0);
    setFileNamesArr([]);
    setError('');
    setAudioError(null);
    setIsFileUploading(false);
    setIsFileUploaded(false);
    setFileUploadData(null);
    setCustomerData(null);
    setShowFileRemove(true);
    setOpen(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsInBounds(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsInBounds(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsInBounds(false);
    if (!e?.dataTransfer?.files?.length) return;
    if (xlsxData?.length && page === 1) {
      handleAudioFileUpload(e?.dataTransfer?.files);
    } else {
      setXlsxFile(e?.dataTransfer?.files?.[0]);
      handleXlsxFileParse(e?.dataTransfer?.files?.[0]);
    }
  };

  return (
    <Modal
      className={styles['modal']}
      open={open}
      onClose={(event, reason) =>
        reason !== 'backdropClick' ? setOpen(false) : f => f
      }
    >
      <div className={styles['inner-container']}>
        <div className={styles['dialog-content']}>
          <div
            className="w-4 h-4 absolute right-3"
            role="button"
            tabIndex={0}
            onClick={() => setOpen(false)}
            aria-hidden
          >
            <CrossIcon />
          </div>
          <div className="text-xl leading-7 font-bold mx-auto">
            {`Step ${
              page === 1
                ? '2 - Upload Recordings'
                : '1 - Upload Recordings Metadata'
            }`}
          </div>
          <div className="mt-6 mb-1">
            <div
              className={`${
                isInBounds
                  ? 'border-2 bg-opacity-10 border-solid'
                  : 'border-[1.4px] bg-opacity-[.35] border-dashed'
                } border-secondary bg-[#E1EBEF]/[.35]
                rounded-lg h-[350px] flex justify-center`}
              onClick={() => {
                inputFileRef.current.value = '';
                inputFileRef.current.click();
              }}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              {xlsxData?.length && page === 1 ? (
                <input
                  type="file"
                  ref={inputFileRef}
                  className="hidden sr-only absolute top-0 left-0"
                  onChange={handleFileAudioInputChange}
                  accept={audioAccept.join(',')}
                  multiple
                  onClick={() => {
                    inputFileRef.current.value = '';
                  }}
                />
              ) : (
                <input
                  type="file"
                  ref={inputFileRef}
                  className="hidden sr-only absolute top-0 left-0"
                  onChange={handleFileInputChange}
                  accept={xlsxAccept.join(',')}
                  onClick={() => {
                    inputFileRef.current.value = '';
                  }}
                />
              )}
              <div
                className={`flex flex-col items-center text-sm leading-5 ${
                  (page === 0 && xlsxFile) || (page === 1 && uploadedAudioFiles?.length) ? 'mt-6 w-full' : 'w-[65%] mt-[60px]'
                }`}
              >
                <div className="w-20 h-20 bg-secondary-light rounded-full flex justify-center items-center relative min-h-[80px]">
                  <FileUploadIcon />
                </div>
                {error && (
                  <div 
                    className={`text-xs text-negative-red w-full 
                    ${(page === 0 && xlsxFile) || (page === 1 && uploadedAudioFiles?.length) ? 
                      'text-left' : 'text-center'} px-[30px] mt-1`}>
                    {error}
                  </div>
                )}

                {page === 1 ? (
                  <>
                    {uploadedAudioFiles?.length ? (
                      <div className="h-full bg-red w-full overflow-auto mt-4 flex flex-col gap-2 px-[30px]">
                        {uploadedAudioFiles.map(file => (
                          <FileItem
                            key={file.name}
                            file={file}
                            handleFileRemove={handleFileRemove}
                            error={audioError?.[file.name] || ''}
                            progress={fileUploadData?.[file.name]?.progress || 0}
                            isCompleted={
                              fileUploadData?.[file.name]?.isCompleted || false
                            }
                            isProcessing={false}
                            canRemove={showFileRemove}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="mt-3 text-center">
                        <div className="text-sm font-semibold leading-5 mb-2">
                          Upload {xlsxData?.length} recordings
                        </div>
                        <div className="text-xs font-medium">
                          File types supported: {audioAccept.join(',') } <br />
                          Max size of all files at a time: {formatBytes(maxTotalSize)}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {xlsxFile ? (
                      <div className="h-full bg-red w-full overflow-auto mt-4 flex flex-col gap-2 px-[30px]">
                        <FileItem
                          key={xlsxFile.name}
                          file={xlsxFile}
                          handleFileRemove={handleXlsxFileRemove}
                          error=''
                          progress={0}
                          isCompleted={false}
                          isProcessing={isFileProcessing}
                          canRemove={!isFileProcessing}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="my-[22px]">
                          <ol className="list-decimal">
                            <li>
                              Download the recordings file format{' '}
                              <a 
                                href="https://dev-zipteams-assets.s3.ap-south-1.amazonaws.com/zipteams_bulk_recording_upload.xlsx" 
                                className="text-secondary font-medium cursor-pointer"
                                onClick={(e) => e.stopPropagation()}
                              >
                                here
                              </a>
                              .
                            </li>
                            <li>
                              Fill all the mandatory fields for the recordings.
                            </li>
                            <li>Upload the file.</li>
                          </ol>
                        </div>
                        <div>
                          Please note: You can upload maximum 40 recordings at a
                          time or total file size of {formatBytes(maxTotalSize)}.
                        </div>
                      </>  
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="h-5 mb-3 text-sm leading-5">
            {xlsxFile?.name && page === 1 ? (
              <>
                File Uploaded: <span className="font-bold">{xlsxFile?.name}</span>
              </>
            ) : (
              ''
            )}
          </div>
          {Boolean(xlsxData?.length) && (
            <>
              {page === 1 ? (
                <div className="flex justify-between items-baseline">              
                  {!(isFileUploading || isFileUploaded) ? (
                    <span 
                      className='text-secondary underline cursor-pointer text-sm'
                      onClick={() => { 
                        setPage(0);
                        setUploadedAudioFiles([]);
                        setUploadedAudioFileNames([]);
                        setUploadedAudioFileSize(0);
                        setFileNamesArr([]);
                        setError('');
                        setAudioError(null);
                        setIsFileUploading(false);
                        setIsFileUploaded(false);
                        setFileUploadData(null);
                        setCustomerData(null);
                      }}
                    >
                      Back
                    </span>
                  ) : <div className='w-11 h-4'/>}
                  <button
                    type="button"
                    className={`w-[200px] flex items-center justify-center rounded-lg text-sm font-semibold 
                    py-3.5 bg-secondary text-white disabled:bg-secondary/60`}
                    disabled={
                      isFileUploading||
                      !Boolean(uploadedAudioFiles && uploadedAudioFiles.length) ||
                      Boolean(audioError && Object.keys(audioError).length) ||
                      Boolean(error)
                    }
                    onClick={isFileUploaded ? handleModalClose : handleFilesUpload}
                  >
                    {isFileUploading ? (
                      <ThreeDotLoader />
                    ) : isFileUploaded ? (
                      'Done'
                    ) : (
                      'Process Recordings'
                    )}
                  </button>
                  <div className='w-11 h-4'/>
                </div>
              ) : (
                <div className="mx-auto">
                  <button
                    type="button"
                    className={`w-[200px] flex items-center justify-center rounded-lg text-sm font-semibold 
                    py-3.5 bg-secondary text-white disabled:bg-secondary/60`}
                    disabled={isFileProcessing || Boolean(error)}
                    onClick={() => {
                      handleXlsxFileParse(xlsxFile)
                    }}
                  >
                    {isFileProcessing ? (
                      <ThreeDotLoader />
                    ) : (
                      'Process File'
                    )}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export const FileItem = (props: {
  file: File;
  error: string;
  progress: number;
  isCompleted: boolean;
  canRemove: boolean;
  isProcessing: boolean;
  handleFileRemove: (name: string) => void;
}): JSX.Element => {
  const { file, handleFileRemove, error, progress, isCompleted, isProcessing, canRemove } = props;
  return (
    <div
      className={`border-[1.4px] ${
        error ? 'border-negative-red' : 'border-secondary'
      } p-4 w-full rounded-lg flex justify-between bg-white`}
    >
      <div className="flex gap-3 items-center">
        <MusicFileIcon className="w-5 h-5 text-secondary self-start" />
        <div className="flex flex-col gap-1.5">
          <div className="text-xs leading-3 font-semibold">{file.name}</div>
          <div className="text-xs leading-3 font-medium text-tag-text-three">
            {formatBytes(file.size)}
          </div>
        </div>
      </div>
      <div className="flex gap-2.5 items-center">
        {error ? (
          <div className="w-[120px] text-xxs text-negative-red">{error}</div>
        ) : (
          <>
            {Boolean(progress) && !Boolean(isCompleted) && (
              <div className="text-tag-text-three text-xs leading-3">
                {progress ? `${progress}%` : ''}
              </div>
            )}
            {Boolean(isCompleted) && (
              <div className="text-xs leading-3 text-positive-green w-full flex">
                <TinyTick />{' '}
                <span className="mx-1 text-primary-grey">File Uploaded</span>
              </div>
            )}
            {Boolean(isProcessing) && (
              <div className="text-tag-text-three text-xs leading-3">
                Processing...
              </div>
            )}
          </>
        )}
        {canRemove ? (
          <button
            type="button"
            className="w-fit h-fit p-1 rounded-full"
            onClick={event => {
              event.stopPropagation();
              handleFileRemove(file.name);
            }}
          >
            <CrossIcon className="w-4 h-4 text-tag-text-three fill-current" />
          </button>
        ) : (
          <div className='w-4 h-4 bg-transparent' />
        )}
      </div>
    </div>
  );
};

export default MultiRecordingUploadModal;
