/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '../ui/accordian';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"

type QualificationQuestionsAccordianProps = {
  qualificationQuestions: { [key: string]: any }[];
  handleQuestionFilterUpdate: (qId: string, value: string | number | boolean | string[], type: string ) => void
};

const QualificationQuestionsAccordian = (
  props: QualificationQuestionsAccordianProps,
): JSX.Element => {
  const { qualificationQuestions, handleQuestionFilterUpdate } = props;
  const [expandedQuestion, setExpandedQuestion] = useState<string>('');

  const handleQuestionClick = (item: string) => {
    setExpandedQuestion(expandedQuestion === item ? '' : item);
  };

  if (!qualificationQuestions?.length) return <></>;
  return (
    <div className="h-full">
      <Accordion type="single" collapsible className="w-full">
        {qualificationQuestions.map((question, idx1) => (
          <Question 
            key={`${question?._id}${idx1}`} 
            question={question}
            expandedQuestion={expandedQuestion} 
            handleQuestionClick={handleQuestionClick}
            handleQuestionFilterUpdate={handleQuestionFilterUpdate}
          />
        ))}
      </Accordion>
    </div>
  );
};

const Question = (props: { 
  question: {[key:string]: any};
  expandedQuestion: string;
  handleQuestionClick: (item: string) => void;
  handleQuestionFilterUpdate: (qId: string, value: string | number | boolean | string[], type: string ) => void
}) => {
  const { question, expandedQuestion, handleQuestionClick, handleQuestionFilterUpdate } = props;
  const [numberMin, setNumberMin] = useState<number>();
  const [numberMax, setNumberMax] = useState<number>();
  const [selectValue, setSelectedValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchBadges, setSearchBadges] = useState<string[]>([]);

  const handleKeyDown = e => {
    const { value } = e.target;
    if (e.key === 'Enter' && value && !searchBadges.includes(value)) {
      setSearchBadges(prev => [...prev, value]);
      setSearchValue('');
    } else if (e.key === 'Backspace' && !value && searchBadges.length) {
      const badgesCopy = [...searchBadges];
      badgesCopy.pop();
      setSearchBadges(badgesCopy);
    }
  };

  const getAccordianContent = (id: string, type: string, filterValue: any[]) => {
    switch (type) {
      case 'integer':
        return (
          <div className="border border-secondary rounded-lg p-3 pb-3 flex flex-col gap-1">
            <div className="text-sm font-medium">Range</div>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={numberMin || filterValue?.[0] || ''}
                className="border border-[#D7D7D7] rounded-lg w-full focus:ring-0 
                focus:outline-none focus:border-secondary focus:border-[1.5px] text-sm"
                placeholder="Min"
                onChange={(e) => {
                  const { value } = e.target;
                  setNumberMin(Number(value));
                  handleQuestionFilterUpdate(id, Number(value), 'number_min');
                }}
              />
              <input
                type="number"
                value={numberMax || filterValue?.[1] || ''}
                className="border border-[#D7D7D7] rounded-lg w-full focus:ring-0 
                focus:outline-none focus:border-secondary focus:border-[1.5px] text-sm"
                placeholder="Max"
                onChange={(e) => {
                  const { value } = e.target;
                  setNumberMax(Number(value));
                  handleQuestionFilterUpdate(id, Number(value), 'number_max');
                }}
              />
            </div>
          </div>
        );
      case 'string':
        return (
          <div className="flex relative">
            <div className="w-full flex items-center flex-wrap gap-2 p-2 border border-[#D7D7D7] rounded-lg">
              {searchBadges &&
                searchBadges.map(badge => (
                  <BadgeComp
                    key={badge}
                    text={badge}
                    removeBadge={(text: string) => {
                      const newBadges = searchBadges.filter(
                        itm => itm !== text,
                      );
                      setSearchBadges(newBadges);
                    }}
                  />
                ))}
              <input
                type="text"
                value={searchValue}
                placeholder="Search..."
                onChange={e => setSearchValue(e.target.value)}
                className="border-0 p-0 h-[22px] focus:outline-none focus:ring-0 text-sm font-normal max-h-[150px]"
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        );
      case 'boolean':
      case 'bool':
        return (
          <div className="flex items-center justify-center w-full">
            <Select 
              value={selectValue || Array.isArray(filterValue) && filterValue.length ? filterValue?.[0] : ''} 
              onValueChange={(value) => {
                setSelectedValue(value);
                handleQuestionFilterUpdate(id, value ? value === 'yes' : false, 'bool');
              }}
            >
              <SelectTrigger className="w-full border border-[#D7D7D7] focus:border-secondary focus:ring-0 aria-expanded:border-secondary">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectItem
                  value="yes"
                  className="py-1 hover:bg-[#D7D7D7] bover:bg-opacity-40 cursor-pointer"
                >
                  Yes
                </SelectItem>
                <SelectItem
                  value="no"
                  className="py-1 hover:bg-[#D7D7D7] bover:bg-opacity-40 cursor-pointer"
                >
                  No
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        );
      default:
        return <></>;
    }
  };

  const handleQuestionReset = (id, type) => {
    if (type === 'integer') {
      setNumberMin(0);
      setNumberMax(0);
    } else if (type === 'string') {
      setSearchBadges([]);
      setSearchValue('');
    } else if (type === 'boolean' || type === 'bool') {
      setSelectedValue('');
    }
    handleQuestionFilterUpdate(id, '', 'reset');
  }

  useEffect(() => {
    if (question && question?.return_type === 'string' && question?.filter && question?.filter?.length && !question?.filter?.includes()) {
      setSearchBadges([...question?.filter])
    }
  }, [question])

  useEffect(() => {
    const val = searchBadges.every(r=> question?.filter && question?.filter.includes(r))
    if (searchBadges && searchBadges.length && expandedQuestion && !val) {
      handleQuestionFilterUpdate(expandedQuestion, searchBadges, 'string');
    }
  }, [searchBadges]);
  
  return (
    <AccordionItem
      value={question?._id}
      
      className={`border-b ${
        expandedQuestion === question._id
          ? 'border-secondary border-t px-4'
          : 'border-[#d7d7d7] mx-4'
      }`}
    >
      <AccordionTrigger
        className="text-sm font-semibold px-0.5 py-4 hover:no-underline"
        onClick={e => {
          e.stopPropagation();
          handleQuestionClick(question?._id);
        }}
      >
        <div className="w-full flex justify-between items-center pr-1.5 text-left">
          {question.qualification_question}
            {question.filter && Boolean(question.filter?.length) && (
              <div className='flex gap-1 items-center'>
                <div className='w-3 h-3 rounded-full bg-secondary/20 flex justify-center items-center'>
                  <div className='w-2 h-2 rounded-full bg-secondary' />
                </div>
                <X 
                  className='w-3.5 h-3.5 text-primary-dark'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleQuestionReset(question?._id, question?.return_type)
                  }}
                />
              </div>
            )}
        </div>
      </AccordionTrigger>
      <AccordionContent className="h-full px-0 overflow-y-auto">
        {getAccordianContent(question?._id, question?.return_type, question?.filter)}
      </AccordionContent>
    </AccordionItem>
  )
}

const BadgeComp = (props: { text: string, removeBadge: (text: string) => void }) => {
  const { text, removeBadge } = props
  return (
    <span className='flex items-center p-2 pr-4 bg-[#E1EBEF] rounded relative'>
      <X className="h-2.5 w-2.5 text-primary dark absolute top-1 right-1" onClick={() => removeBadge(text)}/>
      <span className='text-sm font-normal'>{text}</span>
    </span>
  );
}

export default QualificationQuestionsAccordian;