/* eslint-disable @next/next/no-img-element */

import { useRouter } from 'next/router';
import { Button, Modal } from '@material-ui/core';


import styles from './zipme-onb-modal.module.css';
import { ROUTES } from '../../utils/constants';

type ZipmeOnbModalProps = {
  open: boolean;
  handleClose: () => void;
  addCustomerClick: () => void
};

const ZipmeOnbModal = (props: ZipmeOnbModalProps): JSX.Element => {
  const { open, handleClose, addCustomerClick } = props;

  const router = useRouter();

  const handleContinueClick = () => {
    handleClose();
    router.push(`/${ROUTES.WORKSPACE}?selected=integrations_tab`)
  };

  const handleCustomerClick = () => {
    handleClose();
    addCustomerClick()
  }

  return (
    <Modal
      className={styles['modal']}
      open={open}
      onClose={(event, reason) =>
        reason !== 'backdropClick' ? handleClose() : () => {}
      }
      disableEscapeKeyDown
    >
      <div className={styles['inner-container']}>
        <div className={styles['dialog-content']}>
          <div className='w-full flex mb-4'>
            <img 
              alt="onb-img"
              src="/images/zipme-onb-img.svg"
              className='mx-auto w-32 lg:w-40 h-32 lg:h-40'
            />
          </div>
          <div className='text-xs leading-3.5 lg:text-base lg:leading-5 text-primary-dark text-center font-bold'>
            All your conversations will be visible on this dashboard. You can also schedule a new conversation directly by adding a contact
          </div>
          <div className={styles['actions-container']}>
            <Button
              color="primary"
              variant="contained"
              className={`${styles['secondary-button']}`}
              disableElevation
              disableRipple
              onClick={() => handleContinueClick()}
            >
              Continue with setup
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={`${styles['primary-button']}`}
              disableElevation
              disableRipple
              onClick={() => handleCustomerClick()}
            >
              Add a Contact
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ZipmeOnbModal;
