/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';

import SuccessCheckIcon from '../../public/icons/successmarked.svg';
import CrossIcon from '../../public/icons/thin-cross.svg';

type ReportIssueModalProps = {
  isMenuOpen: boolean;
  closeModal: () => void;
  successPage: boolean;
  issueText: string;
  updateIssueText: (txt: string) => void;
  handlePrimaryAction: () => void;
  handleSecondaryAction: () => void;
};

function ReportIssueModal({
  isMenuOpen,
  closeModal,
  successPage,
  issueText,
  updateIssueText,
  handlePrimaryAction,
  handleSecondaryAction,
}: ReportIssueModalProps) {
  const issueTextAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!successPage) issueTextAreaRef.current.focus();
  }, [successPage]);

  return (
    <div
      className="fixed w-full h-full bg-transparent bg-opacity-40 z-[99]"
      onClick={closeModal}
    >
      <div
        className={`shadow-[5px_5px_25px_0px_rgba(0,0,0,0.1)] fixed rounded lg:mx-2 ${
          isMenuOpen ? 'left-60' : 'left-1/2 -translate-x-1/2 lg:left-16'
        } bottom-1/2 translate-y-1/2 lg:bottom-3 lg:translate-y-0 bg-white 
      text-primary-dark text-base leading-5 w-[85%] max-w-[340px] p-4 pb-6 z-[100]`}
        onClick={e => e.stopPropagation()}
      >
        <button
          type="button"
          className="bg-transparent p-1 cursor-pointer rounded-full absolute top-1 right-1"
          onClick={closeModal}
        >
          <CrossIcon className="w-3 h-3 text-primary-dark stroke-current" />
        </button>
        <div className="flex flex-col items-center">
          {successPage ? (
            <SuccessCheckIcon className="mb-4.5" />
          ) : (
            <div className="text-base leading-5 font-bold mb-4">
              Report an issue
            </div>
          )}
          <div className="flex flex-col gap-5 items-center">
            {successPage ? (
              <div className="flex flex-col gap-2 items-center text-center">
                <div className="text-base leading-5 font-bold">
                  Issue created
                </div>
                <div className="text-sm font-normal">
                  We will get back to with an update via your email soon.
                </div>
              </div>
            ) : (
              <label htmlFor="issueBox" className="text-sm font-bold">
                Please describe your issue
                <textarea
                  ref={issueTextAreaRef}
                  className="rounded-lg p-2 h-[100px] mt-2 w-full font-normal text-sm outline-none focus:ring-secondary focus:border-secondary"
                  value={issueText}
                  onChange={e => updateIssueText(e.target.value)}
                />
              </label>
            )}
            <button
              type="button"
              className="w-[150px] lg:w-[200px] rounded-lg bg-secondary text-white font-bold py-3 
              mb-2 text-xs lg:text-sm cursor-pointer disabled:bg-opacity-40"
              disabled={!successPage && !issueText}
              onClick={handlePrimaryAction}
            >
              {successPage ? 'Okay' : 'Submit'}
            </button>
          </div>
          {successPage && (
            <button
              type="button"
              className="w-auto rounded-lg bg-transparent text-secondary font-bold mt-1 text-xs lg:text-sm underline cursor-pointer"
              onClick={handleSecondaryAction}
            >
              Report another issue
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportIssueModal;
