/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @next/next/no-html-link-for-pages */
import { Tooltip, withStyles } from '@material-ui/core';
import { format } from 'date-fns';
import { Loader2 } from 'lucide-react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { isMobile } from 'react-device-detect';
import { RiFolderUploadLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import AvailabilitySwitch from '../../components/AvailabilitySwitch/availability-switch.component';
import MobileNav from '../../components/mobile-navbar/mobile-navbar';
import { Button } from '../../components/ui/button';
import { DatePickerWithRange } from '../../components/ui/date-picker';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import withAuthenticator from '../../hoc/withAuthenticator/withAuthenticator';
import useDeviceDimension from '../../hooks/useDeviceDimension';
import useSnackbar from '../../hooks/useSnackbar';
import ChromePerfIcon from '../../public/icons/chrome-perf-ss.svg';
import InfoIcon from '../../public/icons/fluent_info-24-regular.svg';
import CrossIcon from '../../public/icons/thin-cross.svg';
import OrganisationService from '../../services/organisation/organisation.service';
import UserService from '../../services/user/user.service';
import { RootState } from '../../store/reducers';
import { REALTIME_WIDGET_AVAILABILITY, SHOW_SNACKBAR, TOGGLE_CALLQREPORT_MODAL } from '../../store/types';
import { ROUTES } from '../../utils/constants';
import styles from './common.module.css';

const CustomToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#122832',
    color: '#ffffff',
    fontSize: 14,
    padding: '8px',
  },
}))(Tooltip);

const MetabaseDashboard: React.FC = () => {
  const { metabaseDashboards, user, realtimeWidget } = useSelector(
    (state: RootState) => state.common,
  );
  const dispatch = useDispatch();
  const { realtimeWidget: { availability } } = useSelector((state: RootState) => state.common);
  const [isAvailable, setIsAvailable] = useState(Boolean(availability));

  const isMobileWidth = useDeviceDimension();

  const [selectedDashboard, setSelectedDashboard] = useState('');
  const [selectedDashboardName, setSelectedDashboardName] = useState<string>('');
  const [showChromeExtensionModal, setShowChromeExtensionModal] = useState<boolean>(false); 

  const handleSelectDahboard = id => {
    const filteredDashBoard =
      metabaseDashboards.length &&
      metabaseDashboards.find(item => item?.dashboard_id === id);
    
    if (
      filteredDashBoard?.manager_urls && 
      Object.keys(filteredDashBoard?.manager_urls)?.length &&
      filteredDashBoard?.manager_urls?.[user.email]
    ) {
      setSelectedDashboard(filteredDashBoard?.manager_urls?.[user.email]);
      setSelectedDashboardName(filteredDashBoard?.dashboard_name);
    } else {
      setSelectedDashboard(filteredDashBoard?.dashboard_url);
      setSelectedDashboardName(filteredDashBoard?.dashboard_name);
    }
  };

  const chromeExtensionModalIgnoreClickHandler = () => {
    setShowChromeExtensionModal(false);
    localStorage.setItem('availability_toggle', JSON.stringify({ count: 2 }));
  };

  const toggleInstantMeetingAvailability = async () => {
    const audio = new Audio('/assets/sweet_text.ogg');
    audio.muted = true;
    audio.play();
    try {
      const realtime_meeting_availaibility = !isAvailable;
      const userData: any =
        localStorage && JSON.parse(localStorage.getItem('user_data'));
        let count = JSON.parse(localStorage.getItem('availability_toggle'))?.count||0;
        if(realtime_meeting_availaibility){
          localStorage.setItem(
            'availability_toggle',
            JSON.stringify({count:++count}),
          );
          
        }
      await UserService.update(userData?.id, {
        meta: { realtime_meeting_availaibility },
      });
      setIsAvailable(realtime_meeting_availaibility);
      dispatch({
        type: REALTIME_WIDGET_AVAILABILITY,
        payload: {
          availability: realtime_meeting_availaibility,
          userInteracted: true
        },
      });
    } catch (err) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: 'error',
          message: 'Failed to toggle your availability. Please try again.',
        },
      });
    }
  };

  const getUserProfileCookies = async () => {
    await UserService.getUserProfileCookies();
  };

  useEffect(() => {
    if (metabaseDashboards?.length) {
      if (
        metabaseDashboards?.[0]?.manager_urls && 
        Object.keys(metabaseDashboards?.[0]?.manager_urls)?.length && 
        metabaseDashboards?.[0]?.manager_urls?.[user.email]
      ) {
        setSelectedDashboard(metabaseDashboards?.[0]?.manager_urls?.[user.email]);
        setSelectedDashboardName(metabaseDashboards?.[0]?.dashboard_name)
      } else {
        setSelectedDashboard(metabaseDashboards?.[0]?.dashboard_url);
        setSelectedDashboardName(metabaseDashboards?.[0]?.dashboard_name)
      }
    }
  }, [metabaseDashboards]);

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem('availability_toggle'))?.count === 1 &&
      realtimeWidget?.availability
    ) {
      setShowChromeExtensionModal(true);
    }
  }, [realtimeWidget?.availability]);

  useEffect(() => {
    setIsAvailable(Boolean(availability));
  }, [availability]);

  useEffect(() => {
    getUserProfileCookies();
  }, []);

  return (
    <div className="h-full w-full flex flex-col">
      {!isMobile && !isMobileWidth ? (
        <div className="flex justify-between font-semibold mt-2 mx-6">
          <h3>Conversations Insights</h3>
          <div className="flex items-center">
            <span>
              {realtimeWidget?.enabled && (
                <CustomToolTip
                  placement="right"
                  title="Toggle your availability for instant meeting requests"
                >
                  <div className="flex">
                    <AvailabilitySwitch className="" screen="toggleAvailability" handleOnChange={toggleInstantMeetingAvailability} isAvailable={isAvailable} />
                  </div>
                </CustomToolTip>
              )}
            </span>
            <span className="flex items-center cursor-pointer">
              {realtimeWidget?.enabled && (
                <span
                  className="ml-2"
                  onClick={() => {
                    setShowChromeExtensionModal(prevState => !prevState);
                  }}
                >
                  <InfoIcon />
                </span>
              )}
            </span>
            <a href="/workspace?selected=my_profile" className="flex items-center ml-2">
              <span>
                <img
                  src={user?.avatar_url || '/icons/profile-default.svg'}
                  className="rounded-full w-7 h-7 object-cover"
                  alt="profile"
                  onError={e => {
                    e.currentTarget.onerror = null; // prevents looping
                    e.currentTarget.src = '/icons/profile-default.svg';
                  }}
                />
              </span>
              {/* <span className="pl-2 pr-6">
                {user?.first_name} {user?.last_name}
              </span> */}
            </a>
          </div>
        </div>
      ) : (
        <MobileNav
          title="Conversation Insights"
          showHeaderMenu
          showMenu={false}
          showBack={false}
        />
      )}
      {!isMobile && !isMobileWidth && showChromeExtensionModal && (
        <div
          className={`${styles['tooltip']} fixed top-12 z-20 rounded mx-2 right-4 bg-primary text-white text-base leading-5`}
        >
          <div className={styles['tooltip-arrow']} />

          <div
            className="relative px-3 py-2 text-sm"
            style={{ width: '700px' }}
          >
            <div>
              <div className="font-bold">
                Please complete the below steps to ensure you get instant
                meeting alerts on your Chrome browser
              </div>
              <div className="mt-4 font-normal ">
                <div className="pl-1">
                  1. Install Zipteams Chrome Extension to get instant meeting
                  alerts across on any tab that you working on Chrome.{' '}
                  <a
                    href="https://chrome.google.com/webstore/search/zipteams"
                    target="_blank"
                    rel="noreferrer"
                    className="font-bold underline"
                  >
                    Install Now.
                  </a>
                </div>
                <div className="mt-3 pl-1 pb-1">
                  2. Add{' '}
                  <a
                    className="font-bold"
                    href="https://app.zipteams.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    app.zipteams.com
                  </a>{' '}
                  to always active in Chrome &gt; Settings &gt; Performance as
                  shown below.{' '}
                  <a
                    className="font-bold underline"
                    href="https://blog.google/products/chrome/new-chrome-features-to-save-battery-and-make-browsing-smoother/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>{' '}
                  to know more.
                </div>
                <ChromePerfIcon />
              </div>
            </div>
            <button
              type="button"
              className="bg-transparent font-bold text-xs leading-3.5 flex justify-center items-center p-1 cursor-pointer rounded-full absolute top-1 right-1"
              onClick={() => {
                chromeExtensionModalIgnoreClickHandler();
              }}
            >
              <CrossIcon className="w-3 h-3" />
            </button>
          </div>
        </div>
      )}
      <div className={`${styles['horizontal-menu']} relative w-full whitespace-nowrap overflow-x-auto flex flex-col max-w-[200px] min-w-full`}>
        <div className="flex w-full">
          {metabaseDashboards?.length &&
            metabaseDashboards.map(item => (
              <button   
                className={`${
                  selectedDashboard === item?.dashboard_url &&
                  styles['text-underline']
                } pb-8 pt-4`}
                key={item?.dashboard_id}
                type="button"
                onClick={() => handleSelectDahboard(item?.dashboard_id)}
              >
                <div
                  className={`${
                    selectedDashboard === item?.dashboard_url
                      ? 'font-semibold text-base lg:text-xl'
                      : 'font-normal  lg:text-lg'
                  } my-2 px-6 text-metabase-tab`}
                >
                  {item?.dashboard_name}
                </div>
              </button>
            ))}
        </div>
      </div>
      {(selectedDashboardName === 'Call Quality Deep Dive' || selectedDashboardName === 'Call Quality') && (
        <button
          type="button"
          className='self-end mx-4 pb-3 text-sm font-bold text-secondary flex items-center justify-center'
          onClick={() => dispatch({ type: TOGGLE_CALLQREPORT_MODAL, payload: true })}
        >
          <RiFolderUploadLine className='w-5 h-4.5 text-secondary mr-1'/>
          Export as CSV
        </button> 
      )}
      <div className="h-full">
        <iframe
          src={selectedDashboard}
          className="h-full w-full"
          title="metabase-dashboard"
        />
      </div>
    </div>
  );
};

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const ExportToXLSXModal = (props: Props) => {
  const { showSnackbar } = useSnackbar();
  const { selectedWorkspace } = useSelector((state: RootState) => state.common);
  const { open, setOpen } = props;
  const [date, setDate] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const params = {
        started_at: format(date?.from || new Date(), 'yyyy-MM-dd'),
        ended_at: format(date?.to || new Date(), 'yyyy-MM-dd')
      }
      const response = await OrganisationService.exportCallQualityReport(selectedWorkspace?.[0]?.id, params);
      const downloadLink = response?.data?.data?.result?.download_link;
      const fileName = response?.data?.data?.result?.filename;
      if (downloadLink && fileName) {
        let downloadElm = document.createElement('a');
        downloadElm.href = downloadLink;
        downloadElm.download = fileName;
        document.body.appendChild(downloadElm);
        downloadElm.click();
        document.body.removeChild(downloadElm);
        downloadElm = null;
      }

      setDate({
        from: undefined,
        to: undefined,
      })
      if (fileName) {
        await OrganisationService.deleteCallQualityReport(selectedWorkspace?.[0]?.id, fileName); 
      }
    } catch (error) {
      console.log(error);
      showSnackbar({
        type: 'error',
        message: 'Error exporting the file, Please try again'
      }) 
    } finally {
      setIsExporting(false);
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent 
        className="max-w-[90%] min-h-[230px] md:max-w-[450px] pt-6 pb-8 px-[50px] bg-white gap-0"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogHeader className="space-y-0 mx-auto">
          <DialogTitle className="text-xl font-bold leading-7 text-center">Export Report</DialogTitle>
        </DialogHeader>
        <div className='my-6 w-full'>
          <div className='flex flex-col gap-1.5'>
            <div className='font-semibold leading-5 text-sm'>Conversation Date</div>
            <DatePickerWithRange selectedDate={date} setSelectedDate={setDate} />
          </div>
          <span className='text-xxs'>Note: Exporting report is available for maximum 31 days at a time</span>
        </div>
        <DialogFooter className='mx-auto flex-col sm:flex-col items-center sm:items-center'>
          <Button
            variant='secondary'
            className='w-[200px] flex gap-3'
            disabled={!date?.from || !date?.to || isExporting}
            onClick={handleExport}
          >
            {isExporting ? <Loader2 className='w-5 h-5 animate-spin' /> : ''}
            {isExporting ? 'Exporting...' : 'Export'}
          </Button>
          {isExporting && (
            <div className="text-xs mt-2">Export is in process, it may take few minutes so you can close the popup and check again in some time.</div>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default withAuthenticator(MetabaseDashboard, {
  redirectPath: `/auth/${ROUTES.AUTH_TYPES.LOGIN}`,
  onlyAdmin: true,
});
