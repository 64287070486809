import { event } from './ga';

export const unsupportedBrowserPage = () => {
  event({
    action: 'unsupported_browser_page',
    params: {
      event_category: 'unsupported_browser',
      event_label: 'Unsupported Browser Page',
    },
  });
};

export const unsupportedBrowserRedirect = (browserName: string) => {
  event({
    action: 'unsupported_browser_redirect',
    params: {
      event_category: 'unsupported_browser',
      event_label: `Unsupported Browser Redirect - ${browserName}`,
    },
  });
};

export const unsupportedBrowserOkay = () => {
  event({
    action: 'unsupported_browser_okay',
    params: {
      event_category: 'unsupported_browser',
      event_label: `Unsupported Browser - Okay Clicked`,
    },
  });
};
