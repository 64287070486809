import { Button, Modal, IconButton, makeStyles } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import styles from './PostMeetingCancel.module.css';
import EmailIcon from '../../public/icons/email-icon.svg';
import ShareIcon from '../../public/icons/share.svg';
import CopyIcon from '../../public/icons/copy-icon.svg';
import CloseIcon from '../../public/icons/close-icon.svg';
import InviteIcon from '../../public/icons/invite-icon.svg';
import { RootState } from '../../store/reducers';

const useStyles = makeStyles({
  containedError: {
    backgroundColor: `#E1EBEF`,
  },
});

const PostCancelMeetingModal = ({
  open,
  handleClose,
  meetingDetails,
  primaryClickHandler,
  secondaryClickHandler,
  tertiaryClickHandler,
}) => {
  const classes = useStyles();

  const { isGcalConnected, isOutlookcalConnected } = useSelector (
    (state: RootState) => state.common,
  );

  let zoomUrl = null;

  function replaceUrl(url: string): string {
    const regex = /\/\/.*?\//;
    const newUrl = url.replace(regex, "//zoom.us/");
    return newUrl;
  }

  if (meetingDetails?.url?.includes('/j/')) {
    zoomUrl = replaceUrl(meetingDetails?.url)
  }

  return (
    <Modal className={styles['modal']} open={open} onClose={handleClose}>
      <div className={styles['inner-container']}>
        <div className={styles['dialog-content']}>
          <div className={styles['share-title']}>Meeting Cancelled</div>
          <div className={styles['share-link']}>
            <div className={styles['link-item']}>
              <span>Meeting Date:</span>
              <span className={styles['link']}>
                {`${format(
                  parseISO(meetingDetails?.cancelledMeetingDate),
                  'MMM dd, yyyy',
                )} | `}
                <span>
                  {format(
                    parseISO(meetingDetails?.cancelledMeetingDate),
                    'hh:mm a',
                  )}
                </span>
              </span>
            </div>
            <div className={styles['link-item']}>
              <span>Meeting Link:</span>
              <div className={styles['link']}>{zoomUrl || meetingDetails?.url || ''}</div>
            </div>
            {(!meetingDetails?.url.includes('google') || meetingDetails?.passcode !== ".") && (
              <div className={styles['link-item']}>
                <span>Passcode:</span>
                <span className={styles['code']}>
                  {meetingDetails?.passcode || ''}
                </span>
              </div>
            )}
          </div>
          <div className={styles['action-container']}>
            <div className={styles['action-button']}>
              <Button
                variant="contained"
                disableElevation
                disableRipple
                className={styles['share-button']}
                onClick={secondaryClickHandler}
                classes={{
                  contained: classes.containedError,
                }}
              >
                <EmailIcon
                  className={`${styles['share-desktop']} w-5 h-5 text-secondary fill-current`}
                />
                <ShareIcon
                  className={`${styles['share-mobile']} w-5 h-5 text-secondary fill-current`}
                />
              </Button>
              <span className={styles['action-label']}>Share</span>
            </div>
            <div className={styles['action-button']}>
              <Button
                variant="contained"
                disableRipple
                disableElevation
                className={styles['share-copy-button']}
                onClick={primaryClickHandler}
                classes={{
                  contained: classes.containedError,
                }}
              >
                <CopyIcon className="w-5 h-5 text-secondary fill-current" />
              </Button>
              <span className={styles['action-label']}>Copy</span>
            </div>
            {!(isGcalConnected || isOutlookcalConnected) && (
              <div className={styles['action-button']}>
                <Button
                  variant="contained"
                  disableRipple
                  disableElevation
                  className={styles['share-invite-button']}
                  onClick={tertiaryClickHandler}
                  classes={{
                    contained: classes.containedError,
                  }}
                >
                  <InviteIcon className="w-5 h-5 text-secondary fill-current" />
                </Button>
                <span className={styles['action-label']}>Invite</span>
              </div>
            )}
          </div>
        </div>
        <IconButton className={styles['close-button']} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </Modal>
  );
};

export default PostCancelMeetingModal;
