/* eslint-disable @next/next/no-page-custom-font */
/* eslint-disable import/extensions */
import { useEffect, useState, createContext } from 'react';
import router from 'next/router';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import {
  isSafari,
  isChrome,
  isFirefox,
  isChromium,
  isEdge,
} from 'react-device-detect';

import '../styles/globals.css';
import Layout from '../hoc/Layout/Layout';
import initStore from '../store';
import BrowserSupportComponent from '../components/browser-support/browser-support.component';
import { SocketContext, socket } from '../contexts/socket.context';
import NetworkInterceptor from '../services/interceptors';
import usePreviousRoute from '../hooks/usePreviousPath';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2C6F78',
    },
  },
  typography: {
    fontFamily: ['Manrope', 'sans-serif'].join(','),
  },
});

const store = initStore({});

NetworkInterceptor.setupInterceptors(store?.dispatch);

export const PreviousPathnameContext = createContext<string | undefined>(undefined);

function MyApp({ Component, pageProps }: AppProps) {
  const [isSupported, setIsSupported] = useState<boolean>(true);
  const [unauthenticatedLayout, setUnauthenticatedLayout] = useState<boolean>(false);
  const previousPath = usePreviousRoute();

  useEffect(() => {
    if (!isSafari && !isChrome && !isFirefox && !isChromium && !isEdge) {
      setIsSupported(false);
    }
    const path = window.location.pathname;
    if (path === '/lsqconnect') setUnauthenticatedLayout(true)
  }, []);

  useEffect(() => {
    const handleRouteChange = url => {
      (window as any).gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
      });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  if (unauthenticatedLayout) {
    return <Component {...pageProps} />
  }

  if (!isSupported) {
    return (
      <>
        <Head>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap"
            rel="stylesheet"
          />
          <link 
            href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap"
            rel="stylesheet"
          />
        </Head>
        <BrowserSupportComponent />
      </>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap"
          rel="stylesheet"
        />
        <link 
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap" 
          rel="stylesheet"
        />

      </Head>
      <Provider store={store}>
        <SocketContext.Provider value={socket}>
          <PreviousPathnameContext.Provider value={previousPath}>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </PreviousPathnameContext.Provider>
        </SocketContext.Provider>
      </Provider>
    </ThemeProvider>
  );
}

export default MyApp;
