/* eslint-disable arrow-body-style */
import React from 'react';
import { IDashMetrics } from '../../pages/dashboard/[[...index]]';

import styles from './common.module.css';
import ConversationMetrics from './conversation-metric.component';
// import HappinessMetrics from './happiness-metric.component';
import InvolvementMetrics from './involvment-metric.component';
import SkeletonLoader from './skeleton-loader';
import PitchQualityMetrics from './pitch-quality-metrics.component';

interface IMetricsComponent {
  metrics: IDashMetrics | any;
  metricsLoading: boolean;
  isFilterApplied: boolean;
}

const MetricsComponent: React.FC<IMetricsComponent> = ({
  metrics = {},
  metricsLoading,
  isFilterApplied
}) => {
  const {
    customer_involvement,
    customer_involvement_growth,
    total_conversation_count,
    total_duration,
    current_week_conversation_count,
    conversation_count_growth,
    salesperson_involvement,
    salesperson_involvement_growth,
    // conversation_duration_growth,
    pitch_audit_score_latest,
    pitch_audit_growth

  } = metrics;

  return (
    <div>
      {metricsLoading ? (
        <div className={styles['metrics-container']}>
          {Array(3)
            .fill(true)
            .map((_, i) => (
              <SkeletonLoader key={i} />
            ))}
        </div>
      ) : (
        <div className={styles['metrics-container']}>
          <ConversationMetrics
            value={total_conversation_count}
            percentage={conversation_count_growth}
            currentWeekCalls={current_week_conversation_count}
            totalCallDuration={total_duration}
            totalConversationCount={total_conversation_count}
            isFilterApplied={isFilterApplied}
          />
          {/* <HappinessMetrics
            total_conversation_count={total_conversation_count}
            totalCallDuration={total_duration}
            conversation_duration_growth={conversation_duration_growth}
          /> */}
          <InvolvementMetrics
            customer_involvement={customer_involvement}
            salesperson_involvement={salesperson_involvement}
            percentage={customer_involvement_growth}
            salesperson_involvement_growth={salesperson_involvement_growth}
          />
          <PitchQualityMetrics 
            pitchAuditGrowth={pitch_audit_growth}
            pitchAuditScore={pitch_audit_score_latest}
          />
        </div>
      )}
    </div>
  );
};

export default MetricsComponent;
