import { useContext, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import styles from './mobile-navbar.module.css';
import BackArrowIcon from '../../public/icons/backarrow-icon.svg';
import KebabIcon from '../../public/icons/kebab-icon.svg';
import MenuListComponent from '../MenuList/MenuList.component';
import HamburgerMenuIcon from '../../public/icons/hamburger-menu-icon.svg';
import { DashboardContext } from '../../pages/dashboard/[[...index]]';
import { SHOW_SIDEBAR } from '../../store/types';

const MobileNav = props => {
  const dispatch = useDispatch();
  const [optionButtonEl, setOptionButtonEl] = useState(null);
  const dashboardContext = useContext(DashboardContext);

  const { handleEditCustomer, handleDeleteCustomer } =
    dashboardContext;

  const {
    handleClose,
    title,
    customerInfo,
    showMenu = false,
    showHeaderMenu = false,
    showBack = true,
    render,
  } = props;

  const handleOptionsClick = event => {
    if (customerInfo) {
      setOptionButtonEl(event.currentTarget);
    } else {
      setOptionButtonEl(null);
    }
  };

  const handleMenuClose = () => {
    setOptionButtonEl(null);
  };

  return (
    <div className={styles['header']}>
      {showHeaderMenu ? (
        <IconButton
          className={styles['header-button']}
          onClick={() => dispatch({ type: SHOW_SIDEBAR })}
        >
          <HamburgerMenuIcon className="w-3.5 h-3.5 text-secondary fill-current" />
        </IconButton>
      ) : (
        <IconButton
          className={styles['header-button']}
          onClick={() => handleClose()}
          style={
            !showBack ? { visibility: 'hidden' } : { visibility: 'visible' }
          }
        >
          <BackArrowIcon className="w-3.5 h-3.5 text-secondary fill-current" />
        </IconButton>
      )}
      <div className={`${styles['header-title']}`}>
        {render ? <>{render}</> : <>{title}</>}
      </div>

      <>
        <IconButton
          className={styles['header-button']}
          onClick={handleOptionsClick}
          style={
            !showMenu ? { visibility: 'hidden' } : { visibility: 'visible' }
          }
        >
          <KebabIcon className="h-4 text-primary-dark fill-current" />
        </IconButton>
        <MenuListComponent
          el={optionButtonEl}
          customerInfo={customerInfo}
          handleMenuClose={handleMenuClose}
          handleDeleteCustomer={handleDeleteCustomer}
          handleEditCustomer={handleEditCustomer}
        />
      </>
    </div>
  );
};

export default MobileNav;
