/* eslint-disable @next/next/no-img-element */
/* eslint-disable arrow-body-style */
import { useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import {
  unsupportedBrowserPage,
  unsupportedBrowserRedirect,
} from '../../lib/ga/unsupported-browser.events';

import styles from './browser-support.module.css';

const BrowserSupportComponent = () => {
  useEffect(() => {
    unsupportedBrowserPage();
  }, []);

  return (
    <div className={styles['unsupported-container']}>
      <div className={styles['content-container']}>
        <div className={styles['title']}>ZIPTEAMS</div>
        <div className={styles['sub-title']}>It’s not you, its us!</div>
        <div className={styles['desc']}>
          Zipteams currently works best in Chrome, Edge, Safari or Mozilla browsers.
          Please use one of these to continue.
        </div>
        <div className={styles['browsers']}>
          <IconButton
            onClick={() => {
              unsupportedBrowserRedirect('Chrome');
              window
                .open('https://www.google.com/intl/en_in/chrome/', '_blank')
                .focus();
            }}
          >
            <img src="/icons/chrome.svg" alt="chrome" className="w-9 lg:w-14" />
          </IconButton>
          <IconButton
            onClick={() => {
              window
                ?.open('https://www.microsoft.com/en-us/edge', '_blank')
                ?.focus();
              unsupportedBrowserRedirect('Edge');
            }}
          >
            <img src="/icons/edge.svg" alt="edge" className="w-9 lg:w-14" />
          </IconButton>
          <IconButton
            onClick={() => {
              unsupportedBrowserRedirect('Safari');
              window
                .open('https://support.apple.com/downloads/safari', '_blank')
                .focus();
            }}
          >
            <img src="/icons/safari.svg" alt="safari" className="w-9 lg:w-14" />
          </IconButton>
          <IconButton
            onClick={() => {
              unsupportedBrowserRedirect('Firefox');
              window
                .open('https://www.mozilla.org/en-US/firefox/new/', '_blank')
                .focus();
            }}
          >
            <img
              src="/icons/firefox.svg"
              alt="firefox"
              className="w-9 lg:w-14"
            />
          </IconButton>
        </div>
        {/* <Button
          variant="contained"
          color="primary"
          className={styles['okay-button']}
          onClick={() => {
            unsupportedBrowserOkay();
          }}
        >
          Okay
        </Button> */}
      </div>
    </div>
  );
};
export default BrowserSupportComponent;
