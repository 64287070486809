/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Button } from '@material-ui/core';

import styles from './empty-dashboard.module.css';

interface IEmptyDashboard {
  handleAddCustomerModalOpen: () => void;
}

const EmptyDashboard: React.FC<IEmptyDashboard> = props => {
  const { handleAddCustomerModalOpen } = props;
  return (
    <div className={styles['empty-content']}>
      <div className={styles['image-container']}>
        <img
          src="/images/dashboard/empty-dashboard.svg"
          alt="dashboardImg"
          className={styles['image']}
        />
      </div>
      <p className="text-base lg:text-xl fond-bold text-primary-dark leading-6 lg:leading-7 mb-2">
        Start your first conversation now
      </p>
      <p className="text-sm lg:text-base font-normal leading-5 text-primary-grey mb-10 md:mb-11">
        Looks like you don’t have any conversation history!
      </p>
      <Button
        color="primary"
        variant="contained"
        className={styles['add-customer-button']}
        disableRipple
        disableElevation
        onClick={handleAddCustomerModalOpen}
      >
        Add new contact
      </Button>
    </div>
  );
};

export default EmptyDashboard;
