import {  ListItemIcon, makeStyles, Menu, MenuItem } from '@material-ui/core';

import CrossIcon from '../../public/icons/cross-black.svg';
import CalendarIcon from '../../public/icons/calender-black.svg';
import InviteIcon from '../../public/icons/invite-black.svg';

const useStyles = makeStyles({
  menu: {
    '& li': {
      fontSize: 12,
      fontWeight: 400,
      minHeight: 0,
    },
    border: '1px solid #efefef',
  },
});

const ScheduleNextMeetingMenu = props => {
  const {
    el,
    handleShareMenuClose,
    handleDateModalOpen,
    handleCancelMeeting,
    customerInfo,
    handleNextMeetingShareModalOpen,
    handleScheduleModalClose,
  } = props;
  const classes = useStyles();
  const kebabMenuOpen = Boolean(el);


  return (
    <Menu
      anchorEl={el}
      open={kebabMenuOpen}
      onClose={handleShareMenuClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: classes.menu,
      }}
    >
      <MenuItem
        onClick={() => {
          handleScheduleModalClose();
          handleNextMeetingShareModalOpen();
        }}
      >
        <ListItemIcon style={{ minWidth: '30px' }}>
          <InviteIcon className=" cursor-pointer text-icon-gray stroke-current" />
        </ListItemIcon>
        <span className="text-primary">Resend Link</span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleShareMenuClose();
          handleScheduleModalClose()
          handleDateModalOpen(
            customerInfo?.cust_next_meeting_at,
            customerInfo?.cust_id,
            'reschedule',
          );
        }}
      >
        <ListItemIcon style={{ minWidth: '30px' }}>
          <CalendarIcon className=" cursor-pointer text-icon-gray stroke-current" />
        </ListItemIcon>
        <span className="text-primary">Reschedule meeting</span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleShareMenuClose();
          handleScheduleModalClose();
          handleCancelMeeting(true);
        }}
      >
        <ListItemIcon style={{ minWidth: '30px' }}>
          <CrossIcon className=" cursor-pointer" />
        </ListItemIcon>
        <span className="text-primary">Cancel meeting</span>
      </MenuItem>
    </Menu>
  );
};

export default ScheduleNextMeetingMenu;
