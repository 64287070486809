import Axios from 'axios';

class HttpService {
  get(url, config = {}) {
    return Axios.get(url, { ...config, withCredentials: true });
  }

  post(url, data, config = {}) {
    return Axios.post(url, data, { ...config, withCredentials: true });
  }

  put(url, data, config = {}) {
    return Axios.put(url, data, { ...config, withCredentials: true });
  }

  delete(url, data = {}, config = {}) {
    return Axios.delete(url, { data, ...config, withCredentials: true });
  }

  patch(url, data, config = {}) {
    return Axios.patch(url, data, { ...config, withCredentials: true });
  }
}

const HttpClient = new HttpService();

export default HttpClient;
