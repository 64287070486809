export const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV;

export const ROUTES = {
  AUTH_TYPES: {
    LOGIN: 'login',
    SIGN_UP: 'signup',
  },
  WORKSPACE: 'workspace',
  CONVERSATIONS_DASHBOARD:'reports',
  DASHBOARD: 'dashboard',
  ALLCONVERSATIONS: 'dashboard/admin',
  PROFILE: 'profile',
  INSIGHTS: {
    RAW: 'insights/raw',
    DETAILS: 'insights/details',
  },
  SHARED_WITH_YOU: 'shared',
  CUSTOMER: 'customer',
};

export const DASHBOARD_MODALS = {
  ADD: 'add',
  EDIT: 'edit',
};

export const CUSTOMER_SORT_TYPE = {
  FUTURE: 'future',
  PAST: 'past',
  TODAY: 'today',
};

export const DASH_LIST_TYPE = {
  ALL: 'all',
  RECOMMENDED: 'recommended',
};
export const OTP_API_ERROR_RESPONSES = {
  OTP_VERIFICATION_FAILED: 'OTP_VERIFICATION_FAILED',
  DOMAIN_NOT_ALLOWED: 'DOMAIN_NOT_ALLOWED',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_IS_SET_INACTIVE: 'USER_IS_SET_INACTIVE',
  UNINVITED_USER: 'UNINVITED_USER',
  FREE_TRIAL_EXPIRED: 'FREE_TRIAL_EXPIRED',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
};

export const freeMailProviders = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'protonmail.com',
  'rediffmail.com',
  'icloud.com',
  'aol.com',
];

export const ROWS_PER_PAGE = 10;

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

export const MEET_BASE_URL = process.env.NEXT_PUBLIC_ZIPMEET_BASE_URL;

export const APP_BASE_URL = process.env.NEXT_PUBLIC_APP_BASE_URL;

export const ZIPME_APP_BASE_URL = process.env.NEXT_PUBLIC_ZIPME_APP_BASE_URL;

export const ZIPME_API_BASE_URL = process.env.NEXT_PUBLIC_ZIPME_API_BASE_URL;

export const ZIPTEAMS_OAUTH_CLIENT_ID = process.env.NEXT_PUBLIC_ZIPTEAMS_OAUTH_CLIENT_ID;

export const ZOOM_CLIENT_ID = process.env.NEXT_PUBLIC_ZOOM_CLIENT_ID;

export const ZOOM_REDIRECT_URI = process.env.NEXT_PUBLIC_ZOOM_REDIRECT_URI;

export const HUBSPOT_CLIENT_ID = process.env.NEXT_PUBLIC_HUBSPOT_CLIENT_ID;

export const OUTLOOK_CALENDAR_XML_URL =
  process.env.NEXT_PUBLIC_OUTLOOK_CALENDAR_XML_URL;

export const TNC_LINK_URL = 'https://zipteams.com/terms-of-use';
export const PRIVACY_POLICY_LINK_URL = 'https://zipteams.com/privacy-policy';
export const HELP_CENTER_LINK_URL = 'https://zipteams.com/help-center';

export const GOOGLE_CALENDAR_BASE_URL = 'https://calendar.google.com';
export const GOOGLE_MEET_BASE_URL = 'https://meet.google.com';
export const ZOOM_MEET_BASE_URL = 'https://zoom.us';

export const IDP = {
  GOOGLE: 'google',
  MICROSOFT: 'microsoft',
};
