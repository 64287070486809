import React from 'react';
import Image from 'next/image';
import { alpha, withStyles, Button, Modal, IconButton } from '@material-ui/core';
import styles from './app-modal.module.css';
import CrossIcon from '../../public/icons/cross-icon.svg';

const themeStyles = () => ({
  containedSecondary: {
    color: '#122832',
    backgroundColor: `${alpha('#122832', 0.1)}`,
  },
});

interface AppModalProps {
  open: boolean;
  content: string;
  classes: {
    containedSecondary: string;
  };
  primaryActionText: string;
  showSecondaryButton: boolean;
  secondaryActionText: string;
  primaryClickHandler: () => void;
  secondaryClickHandler: () => void;
  heroImage?: any;
  closable?: boolean;
  handleClose?: () => void;
}

const AppModal: React.FC<AppModalProps> = props => {
  const {
    open,
    classes,
    content = 'Confirm ?',
    primaryActionText = 'Continue',
    secondaryActionText = 'Cancel',
    showSecondaryButton = true,
    primaryClickHandler = () => {},
    secondaryClickHandler = () => {},
    heroImage,
    closable = true,
    handleClose = () => {},
  } = props;

  const renderHeroImage = () => {
    if (heroImage) {
      return (
        <Image src={heroImage}/>
      )
    } else {
      return (<></>)
    }
  }

  const renderSecondaryButton = () => {
    if (showSecondaryButton) {
      return (<Button
      classes={{ contained: classes.containedSecondary }}
      className={styles['secondary-button']}
      variant="contained"
      onClick={secondaryClickHandler}
    >
      {secondaryActionText}
    </Button>)
    } else {
      return (<></>)
    }
  }

  return (
    <Modal
      className={styles['modal']}
      open={open}
      onClose={(event, reason) =>
        reason !== 'backdropClick' ? handleClose() : f => f
      }
    >
      <div className={styles['inner-container']}>
        <div className={styles['dialog-content']}>
          {closable && <div className={styles['close-button']}>
            <IconButton
              style={{ backgroundColor: 'transparent' }}
              onClick={handleClose}
              className="absolute bottom-0 left-0"
            >
              <CrossIcon className="w-3 h-3 lg:w-4 lg:h-4" />
            </IconButton>
          </div>}
        <div className={styles['hero-image']}>{renderHeroImage()}</div>
          <div className={styles['description']}>{content}</div>
          <div className={styles['actions']}>
            {renderSecondaryButton()}
            <Button
              className={styles['primary-button']}
              variant="contained"
              color="primary"
              onClick={primaryClickHandler}
            >
              {primaryActionText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AppModal.defaultProps = {
  heroImage: null,
  closable: false,
  handleClose: () => {},
};

export default withStyles(themeStyles)(AppModal);
