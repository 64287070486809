/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
import {
  Button,
  withStyles,
  alpha,
  Dialog,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { format } from 'date-fns';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import { sameAsCustomerNameEvent } from '../../lib/ga/home.events';
import { DASHBOARD_MODALS } from '../../utils/constants';

import styles from './AddEditModal.module.css';
import addJson from './_addCus.json';
import editJson from './_editCus.json';
import InfoIcon from '../../public/icons/info-icon.svg';

interface FormErrors {
  customerName: string;
  primaryContactName: string;
  primaryContactEmail: string;
  phoneNumber: string;
}

interface AddEditModalProps {
  open: boolean;
  type: string;
  handleClose: () => void;
  initialValues: {
    customerName: string;
    primaryContactName: string;
    primaryContactEmail: string;
    sameNameCheck: boolean;
    designation: string;
    phoneNumber: string;
    linkedInUrl: string;
  };
  // eslint-disable-next-line no-unused-vars
  handleSumbit: (values: FormErrors) => void;
  classes: {
    containedSecondary: string;
  };
  displayOnlyFields:
    | { label: string; answer: string; ts: number; type: string }[][]
    | [];
  customerScheduleMeetingSource: string;
  customerScheduleMeetingSourceUrl: string;
}

const themeStyles = () => ({
  containedSecondary: {
    color: '#122832',
    backgroundColor: `${alpha('#122832', 0.1)}`,
  },
});

const CustomToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#122832',
    color: '#ffffff',
    fontSize: 12,
    padding: '8px',
    width: '190px',
  },
}))(Tooltip);

const AddEditModal: React.FC<AddEditModalProps> = props => {
  const {
    open,
    type = '',
    handleClose,
    handleSumbit,
    classes,
    initialValues,
    displayOnlyFields,
    customerScheduleMeetingSource,
    customerScheduleMeetingSourceUrl,
  } = props;
  const displayFields = type === DASHBOARD_MODALS.ADD ? addJson : editJson;
  const displayOnlyFieldsTopMost = displayOnlyFields?.length - 1;

  const customChangeHandler = (
    event,
    field,
    formikProps,
    value: string = '',
  ) => {
    if (field.fieldName === 'primaryContactName') {
      if (formikProps?.values?.sameNameCheck) {
        formikProps?.setFieldValue('customerName', event.target.value, true);
      }
      formikProps?.handleChange(event);
    } else if (field.fieldName === 'phoneNumber') {
      formikProps?.setFieldValue('phoneNumber', value, true);
    } else {
      formikProps?.handleChange(event);
    }
  };

  const getInitialValues = () => {
    if (type === DASHBOARD_MODALS.EDIT) {
      return {
        ...initialValues,
        sameNameCheck:
          initialValues?.customerName === initialValues?.primaryContactName,
      };
    } else {
      return {
        customerName: '',
        primaryContactName: '',
        primaryContactEmail: '',
        phoneNumber: '',
        sameNameCheck: false,
      };
    }
  };


  return (
    <Dialog
      className={styles['modal']}
      open={open}
      onClose={(event, reason) =>
        reason !== 'backdropClick' ? handleClose() : f => f
      }
      scroll="body"
      fullWidth
      maxWidth="xs"
    >
      <div className={styles['inner-container']}>
        <div className={styles['dialog-content']}>
          <div className={styles['title']}>
            {type === DASHBOARD_MODALS.ADD
              ? 'Add a new contact'
              : 'Edit contact'}
          </div>
          <Formik
            initialValues={getInitialValues()}
            onSubmit={async (values, { setSubmitting }) => {
              await handleSumbit(values);
              setSubmitting(false);
            }}
            validateOnChange
            validateOnBlur
            validate={values => {
              const errors: Partial<FormErrors> = {};
              if (!values.customerName) {
                errors.customerName = 'Company Name is Required';
              }

              if (!values.primaryContactName) {
                errors.primaryContactName = 'Contact Name is Required';
              }

              if (!values.primaryContactEmail) {
                errors.primaryContactEmail = 'Email is Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
                  values.primaryContactEmail,
                )
              ) {
                errors.primaryContactEmail = 'Invalid email address';
              }
              // eslint-disable-next-line no-useless-escape
              else if (
                values['phoneNumber'] &&
                !/^[+-\s\(\)0-9]{10,15}$/g.test(values['phoneNumber'])
              ) {
                errors.phoneNumber = 'Invalid number';
              }
              return errors;
            }}
          >
            {formikProps => (
              <form onSubmit={formikProps?.handleSubmit}>
                {displayFields?.length &&
                  displayFields.map((field, idx) => {
                    if (field.fieldName === 'customerName') {
                      return (
                        <div
                          key={idx}
                          className={styles['form-input-container']}
                        >
                          <div
                            className={`${styles['form-label']} ${
                              formikProps.errors.customerName &&
                              formikProps.touched.customerName
                                ? styles['label-error']
                                : ''
                            } ${
                              !formikProps.errors.customerName &&
                              formikProps.values.customerName
                                ? styles['label-filled']
                                : ''
                            }`}
                          >
                            {field.fieldLabel}
                          </div>
                          <input
                            className={`${styles['form-input']} ${
                              formikProps.errors.customerName &&
                              formikProps.touched.customerName
                                ? styles['input-error']
                                : ''
                            } ${
                              formikProps.values.sameNameCheck
                                ? styles['input-disabled']
                                : ''
                            }`}
                            disabled={formikProps.values.sameNameCheck}
                            type="text"
                            placeholder={field.fieldPlaceHolder}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.customerName}
                            name="customerName"
                          />
                          {formikProps.errors.customerName &&
                            formikProps.touched.customerName && (
                              <div className={styles['error-msg']}>
                                {formikProps.errors.customerName}
                              </div>
                            )}
                          <div className={styles['same-name-check']}>
                            <input
                              type="checkbox"
                              checked={formikProps.values.sameNameCheck}
                              onChange={event => {
                                formikProps.handleChange(event);
                                formikProps.setFieldValue(
                                  'customerName',
                                  formikProps.values.primaryContactName,
                                  true,
                                );
                                if (!formikProps.values.sameNameCheck) {
                                  sameAsCustomerNameEvent();
                                }
                              }}
                              className="form-checkbox h-5 w-5 text-secondary focus:ring-transparent cursor-pointer rounded-md border-primary-grey"
                              name="sameNameCheck"
                            />
                            <span className="ml-3 text-sm font-bold text-secondary">
                              Contact is an individual
                            </span>
                          </div>
                        </div>
                      );
                    } else if (field.fieldName === 'phoneNumber') {
                      return (
                        <div
                          key={idx}
                          className={styles['form-input-container']}
                        >
                          <div
                            className={`${styles['form-label']} ${
                              formikProps?.errors[field.fieldName] &&
                              formikProps?.touched[field.fieldName]
                                ? styles['label-error']
                                : ''
                            } ${
                              !formikProps?.errors[field.fieldName] &&
                              formikProps?.values[field.fieldName]
                                ? styles['label-filled']
                                : ''
                            }`}
                          >
                            {field.fieldLabel}{' '}
                            {!field.isMandatory && (
                              <span className={styles['optional-label']}>
                                {field.optionalLabel}
                              </span>
                            )}
                          </div>
                          <PhoneInput
                            enableSearch
                            preferredCountries={[
                              'in',
                              'us',
                              'gb',
                              'sg',
                              'au',
                              'de',
                            ]}
                            country="in"
                            preserveOrder={['preferredCountries']}
                            placeholder={field?.fieldPlaceHolder}
                            enableAreaCodes
                            inputClass={`${
                              formikProps?.errors?.[field.fieldName]
                                ? styles['number-input-error']
                                : ''
                            } ${styles['number-input']}`}
                            inputProps={{
                              name: field?.fieldName,
                              required: field?.isMandatory,
                            }}
                            countryCodeEditable={false}
                            // eslint-disable-next-line max-len
                            value={
                              formikProps?.values?.[field.fieldName]
                                ? formikProps?.values?.[
                                    field.fieldName
                                  ]?.includes('+')
                                  ? formikProps?.values[field.fieldName]
                                  : `+91${formikProps?.values[field.fieldName]}`
                                : '+91'
                            }
                            onChange={(v, c, e) => {
                              const dialCode = c?.['dialCode'];
                              customChangeHandler(
                                e,
                                field,
                                formikProps,
                                `${v !== dialCode ? `+${v}` : ''}`,
                              );
                            }}
                          />
                          {formikProps?.errors[field.fieldName] &&
                            formikProps?.touched[field.fieldName] && (
                              <div className={styles['error-msg']}>
                                {formikProps?.errors[field.fieldName]}
                              </div>
                            )}
                        </div>
                      );
                    }
                    return (
                      <div key={idx} className={styles['form-input-container']}>
                        <div
                          className={`${styles['form-label']} ${
                            formikProps?.errors[field.fieldName] &&
                            formikProps?.touched[field.fieldName]
                              ? styles['label-error']
                              : ''
                          } ${
                            !formikProps?.errors[field.fieldName] &&
                            formikProps?.values[field.fieldName]
                              ? styles['label-filled']
                              : ''
                          }`}
                        >
                          {field.fieldLabel}{' '}
                          {!field.isMandatory && (
                            <span className={styles['optional-label']}>
                              {field.optionalLabel}
                            </span>
                          )}
                        </div>
                        <input
                          className={`${styles['form-input']} ${
                            formikProps?.errors[field.fieldName] &&
                            formikProps?.touched[field.fieldName]
                              ? styles['input-error']
                              : ''
                          }`}
                          type={field.fieldType}
                          placeholder={field.fieldPlaceHolder}
                          onChange={event =>
                            customChangeHandler(event, field, formikProps)
                          }
                          onBlur={formikProps?.handleBlur}
                          value={formikProps?.values[field.fieldName]}
                          name={field.fieldName}
                        />
                        {formikProps?.errors[field.fieldName] &&
                          formikProps?.touched[field.fieldName] && (
                            <div className={styles['error-msg']}>
                              {formikProps?.errors[field.fieldName]}
                            </div>
                          )}
                      </div>
                    );
                  })}

                {displayOnlyFields?.length &&
                displayOnlyFields[displayOnlyFieldsTopMost]?.length &&
                type === DASHBOARD_MODALS.EDIT ? (
                  <>
                    {displayOnlyFields[displayOnlyFieldsTopMost]?.map(
                      (item, idx) => (
                        <div
                          key={idx}
                          className={styles['form-input-container']}
                        >
                          <div className={styles['form-label']}>
                            <label htmlFor={item?.label}>{item?.label}</label>

                            <CustomToolTip
                              key={`menuListItem${idx}`}
                              placement="bottom-start"
                              title={
                                item?.ts
                                  ? `Data collected via meeting scheduling form on ${format(
                                      new Date(item?.ts || 0),
                                      'MMM dd, yyyy',
                                    )}`
                                  : ''
                              }
                              enterTouchDelay={0}
                            >
                              <IconButton
                                className={styles['info-button']}
                                disableRipple
                              >
                                <InfoIcon className="w-4 h-4 text-primary-grey fill-current cursor-pointer" />
                              </IconButton>
                            </CustomToolTip>
                          </div>
                          <input
                            type="text"
                            className={styles['form-input']}
                            name={item?.label}
                            value={item?.answer}
                            disabled
                          />
                        </div>
                      ),
                    )}
                  </>
                ) : (
                  []
                )}
                {(customerScheduleMeetingSource ===
                  'ZIPTEAMS_CALENDAR_REALTIME' ||
                  customerScheduleMeetingSource === 'ZIPTEAMS_CALENDAR') && (
                  <div className="mb-6 mt-4 lg:-mx-2">
                    <div className={`${styles['form-border']} lg:mx-1`} />
                    <div className="my-5">
                      <h4 className="text-primary-dark font-bold text-xs lg:text-sm mb-3">
                        Meeting Scheduling Form Inputs
                      </h4>
                      <table>
                        <tbody className="">
                          <tr className="">
                            <td className="text-primary-dark font-bold py-1 text-xs lg:text-sm">
                              Name:
                            </td>
                            <td className="pl-4 text-xs lg:text-sm">
                              {
                                displayOnlyFields?.[0]?.find(
                                  x => x.type === 'zt_contact_name',
                                )?.answer
                              }
                            </td>
                          </tr>
                          <tr className="">
                            <td className="text-primary-dark font-bold py-1 text-xs lg:text-sm">
                              Email:
                            </td>
                            <td className="pl-4 text-xs lg:text-sm">
                              {
                                displayOnlyFields?.[0]?.find(
                                  x => x.type === 'zt_contact_email',
                                )?.answer
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className="text-primary-dark text-xs lg:text-sm font-bold py-1">
                              Company Name:
                            </td>
                            <td className="pl-4 text-xs lg:text-sm">
                              {
                                displayOnlyFields?.[0]?.find(
                                  x => x.type === 'zt_customer_name',
                                )?.answer
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className="text-primary-dark font-bold text-xs lg:text-sm py-1">
                              Phone Number:
                            </td>
                            <td className="pl-4 text-xs lg:text-sm">
                              {displayOnlyFields?.[0]?.find(
                                x => x.type === 'phone_number',
                              )?.answer || '-'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={`${styles['form-border']}`} />
                    {customerScheduleMeetingSourceUrl && (
                      <div className="my-5">
                        <h4 className="text-primary-dark font-bold mb-3 text-xs lg:text-sm">
                          Meeting Scheduling Source
                        </h4>
                        <table>
                          <tbody>
                            <tr>
                              <td className="text-primary-dark font-bold py-1 text-xs lg:text-sm">
                                Scheduled From(Page URL):
                              </td>
                              <td className="text-xs lg:text-sm text-secondary font-bold underline break-all">
                                {customerScheduleMeetingSourceUrl}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-primary-dark font-bold py-1 text-xs lg:text-sm">
                                Scheduling Source:
                              </td>
                              <td className="text-xs lg:text-sm">
                                {customerScheduleMeetingSource ===
                                'ZIPTEAMS_CALENDAR_REALTIME'
                                  ? 'Instant Meeting'
                                  : 'Scheduled Meeting'}
                              </td>
                            </tr>

                            <tr>
                              <td className="text-primary-dark font-bold py-1 text-xs lg:text-sm">
                                UTM Campaign:
                              </td>
                              <td className="text-xs lg:text-sm">
                                {displayOnlyFields?.[0]?.find(
                                  x => x.label === 'UTM Campaign',
                                )?.answer || '-'}
                              </td>
                            </tr>

                            <tr>
                              <td className="text-primary-dark font-bold py-1 text-xs lg:text-sm">
                                UTM Source:
                              </td>
                              <td className="text-xs lg:text-sm">
                                {displayOnlyFields?.[0]?.find(
                                  x => x.label === 'UTM Source',
                                )?.answer || '-'}
                              </td>
                            </tr>

                            <tr>
                              <td className="text-primary-dark font-bold py-1 text-xs lg:text-sm">
                                UTM Medium:
                              </td>
                              <td className="text-xs lg:text-sm">
                                {displayOnlyFields?.[0]?.find(
                                  x => x.label === 'UTM Medium',
                                )?.answer || '-'}
                              </td>
                            </tr>

                            <tr>
                              <td className="text-primary-dark font-bold py-1 text-xs lg:text-sm">
                                UTM Term:
                              </td>
                              <td className="text-xs lg:text-sm">
                                {displayOnlyFields?.[0]?.find(
                                  x => x.label === 'UTM Term',
                                )?.answer || '-'}
                              </td>
                            </tr>

                            <tr>
                              <td className="text-primary-dark font-bold py-1 text-xs lg:text-sm">
                                UTM Content:
                              </td>
                              <td className="text-xs lg:text-sm">
                                {displayOnlyFields?.[0]?.find(
                                  x => x.label === 'UTM Content',
                                )?.answer || '-'}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                    {customerScheduleMeetingSourceUrl && (
                      <div className={`${styles['form-border']}`} />
                    )}
                  </div>
                )}

                <div
                  className={styles['actions-container']}
                  style={
                    type === DASHBOARD_MODALS.EDIT ? { paddingTop: '2px' } : {}
                  }
                >
                  <Button
                    variant="contained"
                    classes={{ contained: classes.containedSecondary }}
                    className={styles['secondary-button']}
                    onClick={handleClose}
                    disableRipple
                    disableElevation
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={styles['primary-button']}
                    disableRipple
                    disableElevation
                    disabled={formikProps?.isSubmitting}
                  >
                    {type === DASHBOARD_MODALS.ADD ? 'Add' : 'Save'}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Dialog>
  );
};

export default withStyles(themeStyles)(AddEditModal);
