import React, { useState } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Input,
} from '@material-ui/core';
import { ICRMStatus, ICustomers } from '../../pages/dashboard/[[...index]]';

import styles from './Dropdown.module.css';

interface IDropdownProps {
  list: Array<ICRMStatus>;
  value: ICustomers;
  handleChange: Function;
  shouldDisableUnderline: boolean;
}

const useStyles = makeStyles({
  underline: {
    '&:before': {
      borderBottom: '1px solid #ECECF2',
    },
    '&:after': {
      borderBottom: '2px solid #ECECF2',
    },
  },
  select: {
    '& li': {
      fontSize: 14,
      fontWeight: 700,
    },
  },
});

const Dropdown: React.FC<IDropdownProps> = ({
  list,
  value,
  handleChange,
  shouldDisableUnderline,
}) => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState(value?.crm_status_id || 'none');

  const handleSelectChange = (event) => {
    const selectedValue = event?.target?.value as string;
    setSelectedOption(selectedValue);
    handleChange(event, value);
  };

  return (
    <FormControl fullWidth className={styles['dropdown']}>
      <Select
        value={selectedOption}
        onChange={(e) => handleSelectChange(e)}
        disableUnderline={shouldDisableUnderline}
        input={
          <Input
            classes={{
              underline: classes.underline,
            }}
          />
        }
        inputProps={{
          classes: {
            root: styles['input-root'],
          },
        }}
        MenuProps={{
          classes: {
            paper: classes.select,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="none" disabled>
          Select
        </MenuItem>
        {list?.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
