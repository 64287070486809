/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import {
  IconButton,
  Tooltip,
  ClickAwayListener,
  makeStyles,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';

import styles from './common.module.css';
import ArrowTopRightIcon from '../../public/icons/arrow-top-right.svg';
import ArrowBottomRightIcon from '../../public/icons/arrow-bottom-right.svg';
import InfoIcon from '../../public/icons/info-icon.svg';

interface IInvolvementMetrics {
  customer_involvement: number | null;
  salesperson_involvement: number | null;
  salesperson_involvement_growth: number | null;
  percentage: number | null;
}

const useStyles = makeStyles({
  touch: {
    fontSize: '0.75rem',
  },
});

const InvolvementMetrics: React.FC<IInvolvementMetrics> = ({
  customer_involvement,
  salesperson_involvement,
  salesperson_involvement_growth,
  percentage,
}) => {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);
  const growthIcon = () => {
    if ((salesperson_involvement_growth || 0) > 0) {
      return (
        <ArrowTopRightIcon className="w-2.5 h-2.5 text-positive-green fill-current mr-1.5" />
      );
    } else if ((salesperson_involvement_growth || 0) < 0) {
      return (
        <ArrowBottomRightIcon className="w-2.5 h-2.5 text-negative-red fill-current mr-1.5" />
      );
    } else {
      return <span className="w-2.5 h-2.5 mr-1.5 font-bold">~</span>;
    }
  };

  const growthClassName = () => {
    if ((salesperson_involvement_growth || 0) > 0)
      return styles['end-value-up'];
    else if ((salesperson_involvement_growth || 0) < 0)
      return styles['end-value-down'];
    else return styles['end-value-zero'];
  };

  const handleClick = () => {
    if (showTooltip) {
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }
  };


  return (
    <div className={styles['conversation-container']}>
      <p className={styles['header']}>Talk-to-listen Ratio</p>
      <p className={styles['content']}>
        {!(salesperson_involvement === 0 || customer_involvement === 0) ? (
          <span className={styles['content-value']}>
            {Number(salesperson_involvement)?.toFixed(0) || 0}:
            {Number(customer_involvement)?.toFixed(0) || 0}
          </span>
        ) : (
          <span className={styles['content-value']}>0:0</span>
        )}
      </p>
      {/* <p className={styles['end']}>
        {growthIcon()}
        <span className={growthClassName()}>
          {Math.abs(salesperson_involvement_growth || 0)?.toFixed(1)}%
        </span>{' '}
        Since last week
      </p> */}
      <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
        <div
          className="inline"
          onMouseOver={
            isMobile
              ? () => {}
              : () => {
                  setShowTooltip(true);
                }
          }
          onMouseLeave={
            isMobile
              ? () => {}
              : () => {
                  setShowTooltip(false);
                }
          }
        >
          <Tooltip
            title="Percentage of time your contacts spoke across all your conversations"
            placement="bottom-end"
            arrow
            open={showTooltip}
            classes={{
              touch: classes.touch,
            }}
            leaveDelay={1000}
            leaveTouchDelay={1000}
          >
            <IconButton
              className={styles['tooltip-button']}
              disableRipple
              onClick={handleClick}
            >
              <InfoIcon className="w-4 h-4 text-primary-grey fill-current cursor-pointer" />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default InvolvementMetrics;
