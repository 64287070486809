/* eslint-disable max-len */
/* eslint-disable import/extensions */
import { API_BASE_URL } from '../../utils/constants';
import HttpClient from '../../utils/http-client';

class Organisation {
  getWorkspaces = (organisation_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/${organisation_id}/workspaces`,
    );

  createWorkspace = (organisation_id: number, workspace_name: string) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/${organisation_id}/workspaces`,
      { workspace_name },
    );

  createNewWorkspace = (workspace_name: string) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/new/workspace/${workspace_name}`,
      null,
    );

  switchWorkspace = (user_id: number, workspace_id: number) =>
    HttpClient.put(
      `${API_BASE_URL}/v1/organisation/switch/workspace`,
      {user_id, workspace_id},
    );

  deleteWorkspace = (organisation_id: number, workspace_id: number) =>
    HttpClient.delete(
      `${API_BASE_URL}/v1/organisation/${organisation_id}/workspaces/${workspace_id}`,
    );

  getWorkspaceTopics = async (workspace_id, active_topics) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/${workspace_id}/topics?only_active=${active_topics}`,
    );

  addWorkspaceTopics = async (workspace_id, topics) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/${workspace_id}/topics`,
      topics,
    );

  getUsersFromSameWorkspace = async (org_id, workspace_id) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/${org_id}/workspace/${workspace_id}/users`,
    );

  requestSubscriptionUpgrade = async (org_id, workspace_id, payload) =>
    HttpClient.put(
      `${API_BASE_URL}/v1/organisation/${org_id}/workspace/${workspace_id}/subscription`,
      payload,
    );

  sendInvite = async (org_id, workspace_id, payload) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/${org_id}/workspace/${workspace_id}/invite`,
      payload,
    );

  cancelInvite = async (org_id, workspace_id, email) =>
    HttpClient.delete(
      `${API_BASE_URL}/v1/organisation/${org_id}/workspace/${workspace_id}/invite/${email}`,
    );

  getUnacceptedInviteeList = async (org_id, workspace_id) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/${org_id}/workspace/${workspace_id}/invitees`,
    );

  getWorkspaceAdmins = async (org_id, workspace_id) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/${org_id}/workspace/${workspace_id}/admins`,
    );

  makeWorkspaceAdmin = async (org_id, workspace_id, user_id, role) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/${org_id}/workspace/${workspace_id}/makeadmin/${user_id}/${role}`,
    );

  removeWorkspaceAdmin = async (org_id, workspace_id, user_id, role) =>
    HttpClient.delete(
      `${API_BASE_URL}/v1/organisation/${org_id}/workspace/${workspace_id}/removeadmin/${user_id}/${role}`,
    );

  addTags = async (workspace_id, user_id, payload) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/user/${user_id}/tags`,
      payload,
    );

  removeTags = async (workspace_id, payload) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/remove-tags`,
      payload,
    );

  getUserTags = async workspace_id =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/tags`,
    );

  getFAQNudges = async (org_id: number, workspace_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/faq-nudge`,
    );

  uploadFAQNudges = async (
    workspace_id: number,
    body: { data: string; format_type: string },
  ) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/faq-nudge`,
      body,
    );

  changeFAQNudgeStatus = async (
    org_id: number,
    workspace_id: number,
    data: { is_enabled: boolean },
  ) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/set-faq-nudge-status`,
      data,
    );

  getWorkspaceTopicCategories = workspace_id =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/topics/categories`,
    );

  getWorkspaceConfigureTopics = workspace_id =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/topics`,
    );

  getWorkspaceQualificationQuestions = workspace_id =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/qualification-questions`,
    );

  editOrganisationName = (org_id, data) =>
    HttpClient.put(
      `${API_BASE_URL}/v1/organisation/${org_id}`, data
    );

  addNewWorkspaceTopics = (workspace_id: number, data) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/topics`,
      { ...data },
    );

  addNewWorkspaceQualificationQuestion = (workspace_id: number, data) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/qualification-questions`,
      { ...data },
    );

  updateWorkspaceQualificationQuestion = (workspace_id: number, data, question_id) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/qualification-questions/${question_id}`,
      { ...data },
    );

  deleteWorkspaceQualificationQuestion = (workspace_id: number, data, question_id) =>
    HttpClient.delete(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/qualification-questions/${question_id}`,
      { ...data },
    );

  deleteWorkspaceTopics = (workspace_id: number, topic_name) =>
    HttpClient.delete(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/topics?topic_name=${topic_name}`,
    );

  getCrmConnections = () =>
    HttpClient.get(
      `${API_BASE_URL}/v1/integrations/hubspot/workspace/crm-connection`,
    );

  getMetabaseDashboard = (workspace_id: number, email?: string) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/workspaces/${workspace_id}/dashboards${email ? `?manager_email=${email}` : ''}`,
    );

  removeCrmConnections = (workspace_id, name) =>
    HttpClient.delete(
      `${API_BASE_URL}/v1/integrations/hubspot/crm-connection/${workspace_id}/${name}`,
    );

  addOrgPreferences = (
    orgId: number,
    body: {
      meet_domain: string;
      meet_primary_color: string;
      meet_logo: { data: string; file_name: string };
      meet_custom: boolean;
    },
  ) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/${orgId}/customisation`,
      body,
    );


  getCrmIntegationPreferences = (workspace_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/crm/integration-preferences/${workspace_id}`,
    );

  updateCrmIntegationPreferences = (workspace_id: number, crm_setting: string, value: boolean) =>
    HttpClient.put(
      `${API_BASE_URL}/v1/crm/update-integration-preferences/${workspace_id}`,
      {
        key: crm_setting,
        value
      }
    );

  getPostCallEmailPreferences = async (workspace_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/${workspace_id}/post_call_email_preferences`,
    )

  configurePostCallEmailPreferences = async (workspace_id: number, setting, value) => {
    const payload = {}
    payload[setting] = value;
    return HttpClient.post(
      `${API_BASE_URL}/v1/organisation/${workspace_id}/post_call_email_preferences`,
      payload
    )
  }

  getFeaturesList = (orgId: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/organisation/features/list/${orgId}`);

  getSubscriptionDetails = (orgId: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/organisation/subscription/details/${orgId}`);

  getEnterpriseSubscriptionDetails = (orgId: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/organisation/enterprise/subscription/details/${orgId}`);

  getStripeCustomerPortalLink = (orgId: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/organisation/stripe/customer/portal/${orgId}`);

  getStripeBillingPortalUrl = (payload) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/stripe/billing/portal`,
      payload,
    );

  toggleFeature = async (org_id: number, payload) =>
    HttpClient.patch(
      `${API_BASE_URL}/v1/organisation/feature/toggle/${org_id}`,
      payload,
    );

  updatePitchScoreMeta = async (org_id: number, payload) => 
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/feature/pitch-score/${org_id}/settings`,
      payload
    );

  getOrgUsageHistory = (startDate, endDate) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/usage/history?start_date=${startDate}&end_date=${endDate}`,
    );

  getOrgFeatureFlags = async () =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/feature/flags`,
    );

  getQualityParameters = workspace_id =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/pitch-audit`,
    );

  addNewQualityParameter = (workspace_id: number, data) => 
     HttpClient.post(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/pitch-audit`,
      { ...data },
    );

    
  sortQualityParameter = (workspace_id: number, payload) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/pitch-audit-sort`,
      payload
    )

  updateQualityParameter = (workspace_id: number, data, question_id) =>
    HttpClient.put(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/pitch-audit/${question_id}`,
      { ...data },
    );

  deleteQualityParameter = (workspace_id: number, question_id) =>
    HttpClient.delete(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/pitch-audit/${question_id}`,
    );

  getPitchAuditGroups = workspace_id =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/workspace/${workspace_id}/pitch-audit-group`,
    );

  updateOrgLogo = (orgId: number, data: {data_url: string}) =>
    HttpClient.put(`${API_BASE_URL}/v1/organisation/${orgId}/logo`, data);

  getOrganisationCookies = (orgId: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/organisation/${orgId}/cookie`);

  updateOrganisationPreference = (setting: string, value: any) => {
    const payload = {}
    payload[setting] = value;
    return HttpClient.put(
      `${API_BASE_URL}/v1/organisation/preferences/settings`,
        payload,
      );
  }

  getManagersList = emp_id =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/manager/${emp_id}`,
    );

  updateManger = payload =>
    HttpClient.post(
      `${API_BASE_URL}/v1/organisation/manager`,
      payload,
    );

  getAllManagers = () =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/managers`,
    );

  getUsersForManager = (manager_id: number) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/manager/${manager_id}/users`,
    );

  getPagesUrl = () =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/pages`,
    );

  getCrmStatus = () =>
    HttpClient.get(
      `${API_BASE_URL}/v1/organisation/crm-status`,
    );

  updateQualityParamNotifications = (data) =>
    HttpClient.put(`${API_BASE_URL}/v1/organisation/quality-param-notifications`, data);

  getQualityParamNotifications = (parameter_id: string) =>
    HttpClient.get(`${API_BASE_URL}/v1/organisation/quality-param-notifications/${parameter_id}`);

  getUserTagCount = (orgId: number) =>
    HttpClient.get(`${API_BASE_URL}/v1/organisation/${orgId}/users/tag-count`);

  exportCallQualityReport = (wpId: number, params) =>
    HttpClient.get(`${API_BASE_URL}/v1/organisation/workspace/${wpId}/call-quality-report-export`, { params })

  deleteCallQualityReport = (wpId: number, fileName:string) =>
    HttpClient.delete(`${API_BASE_URL}/v1/organisation/workspace/${wpId}/call-quality-report-export${fileName ? `?filename=${fileName}` : ''}`, {})

}

const OrganisationService = new Organisation();

export default OrganisationService;
