import { API_BASE_URL } from '../../utils/constants';
import HttpClient from '../../utils/http-client';

class Schedule {
  getUpcomingConversation = (startDate, endDate, isPolling) =>
    HttpClient.get(
      `${API_BASE_URL}/v1/schedule/organisation?start_date=${startDate}&end_date=${endDate}&is_polling=${isPolling}`,
    );

  customersNextSchedule = (data) =>
    HttpClient.post(
      `${API_BASE_URL}/v1/schedule/next-schedule`, data
    );
  
  getMeetingSources = () => 
    HttpClient.get(
      `${API_BASE_URL}/v1/schedule/meeting-sources`,
    );
}

const ScheduleService = new Schedule();
export default ScheduleService;
