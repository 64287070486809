import React from 'react';
import styles from './loader.module.css';

interface LoaderProps {
  visible: boolean;
}

const Loader: React.FC<LoaderProps> = ({ visible }) =>
  visible && (
    <div className={styles['loader-container']}>
      <div className={styles['lds-spinner']}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );

export default Loader;
