import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { format, isValid, } from 'date-fns';
import CalendarIcon from '../../public/icons/calendar-outlined.svg';

interface IDatePickerComponentProps {
    startDate: Date;
    endDate: Date;
    setStartDate: Function;
    setEndDate: Function;
    handleReset: () => void;
    handleApply: () => void;
}

const Datepicker: React.FC<IDatePickerComponentProps> = ({
    startDate,
    endDate,
    setEndDate,
    setStartDate,
    handleApply,
    handleReset,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className="hidden lg:inline-flex">
            <Menu as="div" className="relative inline-block text-left" ref={menuRef}>
                <div>
                    <Menu.Button onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <CalendarIcon
                            className="inline-block ml-2 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    show={isMenuOpen}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute z-50 rounded-lg left-0 mt-2 w-64 origin-top-right divide-y divide-gray-100
                     bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div className="px-2 py-4 w-full flex flex-col items-center">
                            <span className='text-sm font-bold'>Last Conversation Date</span>
                            <div className="flex flex-col lg:flex-row gap-4 w-full my-4">
                                <div id="start_date">
                                    <div className="flex flex-col gap-2">
                                        <span
                                            className="text-xs font-semibold text-primary-dark"
                                        >
                                            From
                                        </span>
                                        <input
                                            name="filter_start_date"
                                            value={startDate ? format(isValid(startDate) ?
                                                new Date(startDate) : new Date(), 'yyyy-MM-dd') : ''
                                            }
                                            className="block max-w-[110px] py-2.5 text-primary-dark appearance-none rounded-lg 
                                                border border-audio-timeline disabled:bg-white focus:ring-secondary 
                                                focus:border-secondary focus-visible:outline-0 text-xxs font-normal cursor-pointer"
                                            type="date"
                                            onChange={e => {
                                                setStartDate(e.target.value ? new Date(e.target.value) : null)
                                            }}
                                            required
                                        />
                                    </div>
                                </div>
                                <div id="end_date">
                                    <div className="flex flex-col gap-2">
                                        <span
                                            className="text-xs font-semibold text-primary-dark"
                                        >
                                            To
                                        </span>
                                        <input
                                            name="filter_end_date"
                                            value={endDate ? format(isValid(endDate) ?
                                                new Date(endDate) : new Date(), 'yyyy-MM-dd') : ''
                                            }
                                            className="relative block max-w-[110px] py-2.5 text-primary-dark appearance-none rounded-lg border 
                                                border-audio-timeline disabled:bg-white focus:ring-secondary focus:border-secondary 
                                                focus-visible:outline-0 text-xxs font-normal cursor-pointer"
                                            type="date"
                                            onChange={e => {
                                                setEndDate(e.target.value ? new Date(e.target.value) : null)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-between items-center w-full gap-4'>
                                <button
                                    type='button'
                                    disabled={!startDate || !endDate}
                                    className={`w-1/2 min-h-[35px] bg-secondary-light text-secondary text-xs font-bold rounded-md 
                                        ${!startDate || !endDate ? 'opacity-60' : 'opacity-100'}`}
                                    onClick={handleReset}
                                >
                                    Reset
                                </button>
                                <button
                                    type='button'
                                    disabled={!startDate || !endDate}
                                    className={`w-1/2 min-h-[35px] bg-secondary text-white text-xs font-bold rounded-md
                                        ${!startDate || !endDate ? 'opacity-60' : 'opacity-100'}`}
                                    onClick={() => {
                                        handleApply();
                                        setIsMenuOpen(false);
                                    }}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

export default Datepicker;
