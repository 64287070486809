/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import {
  ClickAwayListener,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';

import styles from './common.module.css';
import ArrowTopRightIcon from '../../public/icons/arrow-top-right.svg';
import ArrowBottomRightIcon from '../../public/icons/arrow-bottom-right.svg';
import InfoIcon from '../../public/icons/info-icon.svg';
import { formatDurationToHrsAndMins } from '../../utils/helper';

interface IConversationMetrics {
  value: number | null;
  percentage: number | null;
  currentWeekCalls: number | null;
  totalCallDuration: number | null;
  totalConversationCount: number | null;
  isFilterApplied: boolean;
}

const useStyles = makeStyles({
  touch: {
    fontSize: '0.75rem',
  },
});

const ConversationMetrics: React.FC<IConversationMetrics> = ({
  value,
  percentage,
  currentWeekCalls,
  totalCallDuration,
  totalConversationCount,
  isFilterApplied
}) => {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);
  const duration = formatDurationToHrsAndMins(totalCallDuration || 0);

  const growthIcon = () => {
    if ((percentage || 0) > 0) {
      return (
        <ArrowTopRightIcon className="w-2.5 h-2.5 text-positive-green fill-current mr-1.5" />
      );
    } else if ((percentage || 0) < 0) {
      return (
        <ArrowBottomRightIcon className="w-2.5 h-2.5 text-negative-red fill-current mr-1.5" />
      );
    } else {
      return <span className="w-2.5 h-2.5 mr-1.5 font-bold">~</span>;
    }
  };

  const growthClassName = () => {
    if ((percentage || 0) > 0) return styles['end-value-up'];
    else if ((percentage || 0) < 0) return styles['end-value-down'];
    else return styles['end-value-zero'];
  };

  const handleClick = () => {
    if (showTooltip) {
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }
  };

  const averageConversationDuration = totalCallDuration / totalConversationCount;

  const formattedAvgDuration = formatDurationToHrsAndMins(averageConversationDuration || 0);

  return (
    <div className={styles['conversation-container']}>
      <p className={styles['header']}>Total Conversations</p>
      <p className={styles['content']}>
        <span className={styles['content-value']}>{value || 0}</span>{' '}
        Conversations | {duration}
      </p>
      <div className="flex items-center justify-between">
        <p className={styles['mid']}>{currentWeekCalls || 0} This Week</p>
        <p className={styles['mid']}>Avg. {formattedAvgDuration}</p>
      </div>
      {!isFilterApplied && (
        <div className={styles['end']}>
          {growthIcon()}
          <span className={growthClassName()}>
            {Math.abs(percentage || 0).toFixed(1)}%
          </span>{' '}
          Since last week
        </div>
      )}
      <ClickAwayListener
        onClickAway={() => {
          if (showTooltip) setShowTooltip(false);
        }}
      >
        <div
          className="inline"
          onMouseOver={
            isMobile
              ? () => {}
              : () => {
                  setShowTooltip(true);
                }
          }
          onMouseLeave={
            isMobile
              ? () => {}
              : () => {
                  setShowTooltip(false);
                }
          }
        >
          <Tooltip
            title="Total duration across all your conversations"
            placement="bottom-end"
            arrow
            open={showTooltip}
            classes={{
              touch: classes.touch,
            }}
            leaveDelay={1000}
            leaveTouchDelay={1000}
          >
            <IconButton
              className={styles['tooltip-button']}
              disableRipple
              onClick={handleClick}
            >
              <InfoIcon className="w-4 h-4 text-primary-grey fill-current cursor-pointer" />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ConversationMetrics;
