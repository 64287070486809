import React from 'react';
import { Button, IconButton, makeStyles, Modal } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { FaCircleCheck } from 'react-icons/fa6';

import styles from './ShareModal.module.css';
import EmailIcon from '../../public/icons/email-icon.svg';
import ShareIcon from '../../public/icons/share.svg';
import CopyIcon from '../../public/icons/copy-icon.svg';
import CloseIcon from '../../public/icons/close-icon.svg';
import InviteIcon from '../../public/icons/invite-icon.svg';
import { meetingUrlClickEvent } from '../../lib/ga/home.events';
import { RootState } from '../../store/reducers';

interface IShareModalProps {
  open: boolean;
  handleClose: () => void;
  meetingDetails: {
    url: string;
    passcode: string;
  };
  primaryClickHandler: () => void;
  secondaryClickHandler: () => void;
  tertiaryClickHandler: () => void;
}

const useStyles = makeStyles({
  containedError: {
    backgroundColor: `#E1EBEF`,
  },
});

const ShareLinkModal: React.FC<IShareModalProps> = ({
  open,
  handleClose,
  meetingDetails,
  primaryClickHandler,
  secondaryClickHandler,
  tertiaryClickHandler,
}) => {
  const classes = useStyles();

  const { isGcalConnected, isOutlookcalConnected } = useSelector(
    (state: RootState) => state.common,
  );

  return (
    /* eslint-disable react/jsx-no-target-blank */
    <Modal className={styles['modal']} open={open} onClose={handleClose}>
      <div className={styles['inner-container']}>
        <div className={styles['dialog-content']}>
          <div className={styles['share-title']}>
            {isGcalConnected || isOutlookcalConnected ? (
              <div className="flex gap-1 items-start">
                <FaCircleCheck className="text-positive-green w-6 h-6" />
                <div className='text-xl leading-6 font-bold text-center'>Meeting invite sent from your calendar</div>
              </div>
            ) : (
              'Share'
            )}
          </div>
          <div className={styles['share-link']}>
            <div className={styles['link-item']}>
              <span>Meeting Link:</span>
              <a
                target="_blank"
                href={meetingDetails?.url}
                className={styles['link']}
                onClick={meetingUrlClickEvent}
              >
                {meetingDetails?.url || ''}
              </a>
            </div>
            {(!meetingDetails?.url?.includes('google') ||
              meetingDetails?.passcode !== '.') && (
              <div className={styles['link-item']}>
                <span>Passcode:</span>
                <span className={styles['code']}>
                  {meetingDetails?.passcode || ''}
                </span>
              </div>
            )}
          </div>
          <div className={styles['action-container']}>
            <div className={styles['action-button']}>
              <Button
                variant="contained"
                disableElevation
                disableRipple
                className={styles['share-button']}
                onClick={secondaryClickHandler}
                classes={{
                  contained: classes.containedError,
                }}
              >
                <EmailIcon
                  className={`${styles['share-desktop']} w-5 h-5 text-secondary fill-current`}
                />
                <ShareIcon
                  className={`${styles['share-mobile']} w-5 h-5 text-secondary fill-current`}
                />
              </Button>
              <span className={styles['action-label']}>Share</span>
            </div>
            <div className={styles['action-button']}>
              <Button
                variant="contained"
                disableRipple
                disableElevation
                className={styles['share-copy-button']}
                onClick={primaryClickHandler}
                classes={{
                  contained: classes.containedError,
                }}
              >
                <CopyIcon className="w-5 h-5 text-secondary fill-current" />
              </Button>
              <span className={styles['action-label']}>Copy</span>
            </div>
            {!(isGcalConnected || isOutlookcalConnected) && (  
              <div className={styles['action-button']}>
                <Button
                  variant="contained"
                  disableRipple
                  disableElevation
                  className={styles['share-invite-button']}
                  onClick={tertiaryClickHandler}
                  classes={{
                    contained: classes.containedError,
                  }}
                >
                  <InviteIcon className="w-5 h-5 text-secondary fill-current" />
                </Button>
                <span className={styles['action-label']}>Invite</span>
              </div>
            )}
          </div>
        </div>
        <IconButton className={styles['close-button']} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </Modal>
  );
};

export default ShareLinkModal;
