import { event } from './ga';

const getUser = () =>
  localStorage && JSON.parse(localStorage.getItem('user_data'));

export const letsGetStartedButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onboarding_letsgetyoustarted_clicked',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-Lets Get You Started CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const replayIntroClicked = () => {
  const user = getUser();
  event({
    action: 'onb_replayintro_clicked',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-Replay Intro CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const installGCalNextButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onb_installgcal_next_clicked',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-Install GCal Next CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const installGCalContinueButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onb_installgcal_contw/install_clicked',
    params: {
      event_category: 'onboarding',
      event_label:
        'Onboarding-Install GCal Continue Without Install CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const scheduleMeetingNextButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onb_schmeeting_next_clicked',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-Schedule Meeting Next CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const scheduleMeetingManualButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onb_schmeeting_schmanual_clicked',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-Schedule Meeting Schedule Manual CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const scheduleManualMeetingNextButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onb_schmanual_next_clicked',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-Schedule Manual Meeting Next CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const firstMeetingSetNextButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onb_firstmeetingset_next_clicked',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-First Meeting Set Next CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const getInsightsDashboardButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onb_getinsights_getondashboard_clicked',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-Get Insights Get on Dashboard CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const getInsightsContactButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onb_getinsights_addfirstcontact_clicked',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-Get Insights Add your First Contact CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const insightsLockedButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onb_insights_locked_click_event',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-Locked Insight CTA Clicked',
      user_id: user?.id,
    },
  });
};

export const insightsUnlockedDashboardButtonClicked = () => {
  const user = getUser();
  event({
    action: 'onb_insightsunlocked_getondb_clicked',
    params: {
      event_category: 'onboarding',
      event_label: 'Onboarding-Insights Unlocked Get on Dashboard CTA Clicked',
      user_id: user?.id,
    },
  });
};
