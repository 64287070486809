/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styles from './availability-switch.module.css';


const AvailabilitySwitch = ({ className = '', screen, handleOnChange, isAvailable }:
  {
    className?: string, screen: string, handleOnChange: () => void, isAvailable: boolean
  }) => (
  <>
    {
      screen === "toggleFeature" && (
        <div>
          <div className={styles['switch-container']}>
            <label htmlFor="availabilitySwitch" className={styles['switch-label']}>
              <div className="relative">
                <input
                  type="checkbox"
                  id="availabilitySwitch"
                  className="sr-only"
                  checked={isAvailable}
                  onChange={() => { }}
                />
                <div onClick={handleOnChange}>
                  <div
                    className={`${styles['switch-background']} ${isAvailable ? 'bg-positive-green' : 'bg-tag-text-three'
                      }`}
                  />
                  <div
                    className={`${styles['switch-dot']} ${isAvailable
                      ? 'right-1 bg-white'
                      : 'left-1 bg-app-background-1'
                      }`}
                  />
                </div>
              </div>
            </label>
          </div>
        </div>
      )
    }
    {
      screen === "toggleAvailability" && (
        <div className={className}>
          <div className={styles['switch-container']}>
            <label htmlFor="availabilitySwitch" className={styles['switch-label']}>
              <div className="relative">
                <input
                  type="checkbox"
                  id="availabilitySwitch"
                  className="sr-only"
                  checked={isAvailable}
                  onChange={() => { }}
                />
                <div onClick={handleOnChange}>
                  <div
                    className={`${styles['switch-background']} ${isAvailable ? 'bg-positive-green' : 'bg-tag-text-three'
                      }`}
                  />
                  <div
                    className={`${styles['switch-dot']} ${isAvailable
                      ? 'right-1 bg-white'
                      : 'left-1 bg-app-background-1'
                      }`}
                  />
                </div>
              </div>
            </label>
          </div>
        </div>
      )
    }
  </>
);

export default AvailabilitySwitch;
