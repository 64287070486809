import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './common.module.css';

function SkeletonLoader() {
  return (
    <div className={styles['skeleton-container']}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </div>
  );
}

export default SkeletonLoader;
