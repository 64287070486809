/* eslint-disable no-restricted-globals */
import { Menu, MenuItem, makeStyles } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
import styles from './meeting-source-dropdown.module.css';

const useStyles = makeStyles({
  menu: {
    '& li': {
      fontSize: 14,
      fontWeight: 700,
      minHeight: 0,
    },
    border: '1px solid #e0e0e0',
  },
});

const MenuListComponent = ({
  el,
  handleMenuClose,

  meeting_source,
  selectMeetingSource,
}) => {
  const classes = useStyles();
  const meetingSourceMenuOpen = Boolean(el);

  return (
    <Menu
      anchorEl={el}
      open={meetingSourceMenuOpen}
      onClose={handleMenuClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: classes.menu,
      }}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
        }}
        value="none"
        disabled
      >
        Meeting Source
      </MenuItem>

      {meeting_source &&
        meeting_source.map(({ label, id, checked }, index) => (
          <MenuItem key={index}>
            <input
              type="checkbox"
              checked={checked}
              className="form-checkbox h-3.5 w-3.5 lg:h-4 lg:w-4 text-secondary focus:ring-transparent cursor-pointer rounded-sm border-secondary"
              name={label}
              value={label}
              id={label}
              onChange={() => selectMeetingSource(id)}
            />
            <label
              htmlFor={label}
              className="mx-2 font-medium text-primary-dark"
            >
              {label}
            </label>
          </MenuItem>
        ))}
    </Menu>
  );
};
export default MenuListComponent;
