export const SHOW_APP_MODAL = 'SHOW_APP_MODAL';
export const HIDE_APP_MODAL = 'HIDE_APP_MODAL';

export const SHOW_INV_MODAL = 'SHOW_INV_MODAL';
export const HIDE_INV_MODAL = 'HIDE_INV_MODAL';

export const SHOW_OTP_MODAL = 'SHOW_OTP_MODAL';
export const HIDE_OTP_MODAL = 'HIDE_OTP_MODAL';

export const SHOW_TOPIC_MODAL = 'SHOW_TOPIC_MODAL';
export const HIDE_TOPIC_MODAL = 'HIDE_TOPIC_MODAL';

export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const BRANDING = 'BRANDING';

export const SHOW_INSIGHTS_UNLOCK_MODAL = 'SHOW_INSIGHTS_UNLOCK_MODAL';
export const HIDE_INSIGHTS_UNLOCK_MODAL = 'HIDE_INSIGHTS_UNLOCK_MODAL';

export const SHOW_MEETING_NOTIFY_MODAL = 'SHOW_MEETING_NOTIFY_MODAL';
export const HIDE_MEETING_NOTIFY_MODAL = 'HIDE_MEETING_NOTIFY_MODAL';

export const SET_IDENTITIES = 'SET_IDENTITIES';

export const SET_SELECTED_WORKSPACE = 'SET_SELECTED_WORKSPACE';
export const SET_WORKSPACES = 'SET_WORKSPACES';

export const SET_USER_TYPE = 'SET_USER_TYPE';

export const UPDATE_META = 'UPDATE_META';

export const SET_IDP = 'SET_IDP';

export const REALTIME_WIDGET_ENABLED = 'REALTIME_WIDGET_ENABLED';

export const SAVE_USER = 'SAVE_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const REALTIME_WIDGET_AVAILABILITY = 'REALTIME_WIDGET_AVAILABILITY';

export const IS_GCAL_CONNECTED = 'IS_GCAL_CONNECTED';
export const IS_OUTLOOKCAL_CONNECTED = 'IS_OUTLOOKCAL_CONNECTED';

export const IS_ADMIN = 'IS_ADMIN';
export const IS_MANAGER = 'IS_MANAGER';
export const IS_SUPER_ADMIN = 'IS_SUPER_ADMIN';

export const CALL_HIPPO_TOKEN = 'CALL_HIPPO_TOKEN';
export const CONNECTED_CRM = 'CONNECTED_CRM';

export const METABASE_DASHBOARDS = 'METABASE_DASHBOARDS';

export const PAYMENT_MODAL = 'PAYMENT_MODAL';
export const ACTIVE_SEATS = 'ACTIVE_SEATS';

export const LSQ_CONNECT_CLICK = 'LSQ_CONNECT_CLICK';
export const LSQ_ACCESS_KEY = 'LSQ_ACCESS_KEY';
export const LSQ_SECRET_KEY = 'LSQ_SECRET_KEY';
export const LSQ_REGION = 'LSQ_REGION';

export const PREVIOUS_PAGE = 'PREVIOUS_PAGE';
export const ALL_ADMINS = 'ALL_ADMINS';
export const FIRST_ADMIN = 'FIRST_ADMIN';

export const TOGGLE_MULTIUPLOAD_MODAL = 'TOGGLE_MULTIUPLOAD_MODAL';
export const TOGGLE_CALLQREPORT_MODAL = 'TOGGLE_CALLQREPORT_MODAL';

