import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { MdArrowDropDown } from 'react-icons/md'

interface ISelectedList {
    assigned_intent: string;
    checked: boolean;
}

const FilterIntentListComponentNew = ({
    intentList,
    filterIntentConversation,
}) => {
    const [selectedIntentList, setSelectedIntentList] = useState<ISelectedList[]>(intentList || []);

    const handleCheck = (status: string) => {
        setSelectedIntentList((prevList) => prevList?.map((item) => {
            if (item.assigned_intent === status) {
                return { ...item, checked: !item.checked };
            }
            return item;
        }));
    };

    useEffect(() => {
        setSelectedIntentList(intentList);
    }, [intentList]);

    return (
        <div className="inline-flex">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button >
                        <MdArrowDropDown className="inline-block h-5 w-5" />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items 
                        className="absolute mt-2 min-w-[150px] max-h-[300px] right-0 origin-top-right
                        bg-white shadow-lg ring-1 ring-black/5 focus:outline-none rounded-md"
                    >
                        <div className="px-1 py-2 max-h-[200px] overflow-y-auto">
                            <Menu.Item onClick={(e)=>e.preventDefault()} as="div">
                                {() => (
                                    <div className="flex justify-start px-2 lg:px-3 py-2 hover:bg-app-background-1">
                                        <input
                                            type="checkbox"
                                            checked={
                                                selectedIntentList?.every(object => object.checked === false)
                                            }
                                            className="form-checkbox h-3.5 w-3.5 lg:h-4 lg:w-4 text-secondary 
                                            focus:ring-transparent cursor-pointer rounded-sm border-secondary"
                                            onClick={(e)=> e.stopPropagation()}
                                            onChange={() => {
                                                setSelectedIntentList((prevList) =>
                                                    prevList?.map((item) => ({
                                                        ...item,
                                                        checked: false,
                                                    }))
                                                );
                                            }}
                                        />
                                        <h4 className="text-primary-dark text-xs lg:text-sm font-medium leading-4 mx-2">All</h4>
                                    </div>
                                )}
                            </Menu.Item>
                            {selectedIntentList &&
                                selectedIntentList.map(
                                    ({
                                        assigned_intent,
                                        checked,
                                    }, idx) => (
                                        <Menu.Item onClick={(e)=>e.preventDefault()} key={idx} as="div">
                                            {() => (
                                                <div className="flex justify-start px-2 lg:px-3 py-2 hover:bg-app-background-1">
                                                    <input
                                                        type="checkbox"
                                                        checked={checked}
                                                        className="form-checkbox h-3.5 w-3.5 lg:h-4 lg:w-4 text-secondary 
                                                            focus:ring-transparent cursor-pointer rounded-sm border-secondary"
                                                        name={assigned_intent}
                                                        value={assigned_intent}
                                                        id={assigned_intent}
                                                        onClick={(e)=> e.stopPropagation()}
                                                        onChange={() => {
                                                            handleCheck(assigned_intent);
                                                        }}
                                                    />
                                                    <h4 className="text-primary-dark text-xs lg:text-sm font-medium leading-4 mx-2">
                                                        {assigned_intent}
                                                    </h4>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    ),
                                )}
                        </div>
                        <div className='flex justify-center px-1 py-4'>
                            <button
                                type='button'
                                className='h-8 w-28 rounded-lg text-center text-xxs lg:text-xs text-white font-bold bg-secondary'
                                onClick={() => {
                                    filterIntentConversation(selectedIntentList);
                                }}
                            >
                                Apply
                            </button>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

export default FilterIntentListComponentNew;