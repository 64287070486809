import { Button, Modal, makeStyles } from '@material-ui/core';
import styles from './DeleteCustModal.module.css';

const useStyles = makeStyles({
  containedError: {
    backgroundColor: '#FF0000',
    color: '#FFFFFF',
  },
});
const DeleteModal = ({
  open,
  handleClose,
  primaryClickHandler,
  secondaryClickHandler,
}) => {
  const classes = useStyles();
  return (
    <Modal
      className={styles['modal']}
      open={open}
      onClose={(event, reason) =>
        reason !== 'backdropClick' ? handleClose() : f => f
      }
    >
      <div className={styles['inner-container']}>
        <div className={styles['dialog-content']}>
          <div className={styles['title']}>
            You are about to delete a Customer
          </div>
          <div className={styles['description']}>
            This will delete all conversations with this customer
          </div>
          <div className={styles['confirmation']}>Are you sure?</div>
          <div className={styles['action-container']}>
            <Button
              variant="outlined"
              disableElevation
              disableRipple
              className={styles['secondary']}
              onClick={secondaryClickHandler}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableRipple
              disableElevation
              className={styles['primary']}
              classes={{
                contained: classes.containedError,
              }}
              onClick={primaryClickHandler}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
