import { ListItemIcon, Menu, MenuItem, makeStyles } from '@material-ui/core';

import EditIcon from '../../public/icons/edit-icon.svg';
import DeleteIcon from '../../public/icons/delete-icon.svg';

const useStyles = makeStyles({
  menu: {
    '& li': {
      fontSize: 14,
      fontWeight: 700,
      minHeight: 0,
    },
    border: '1px solid #e0e0e0',
  },
});

const MenuListComponent = ({
  el,
  handleMenuClose,
  customerInfo,
  handleEditCustomer,
  handleDeleteCustomer,
}) => {
  const classes = useStyles();
  const kebabMenuOpen = Boolean(el);
  return (
    <Menu
      anchorEl={el}
      open={kebabMenuOpen}
      onClose={handleMenuClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: classes.menu,
      }}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          handleEditCustomer(customerInfo);
        }}
      >
        <ListItemIcon style={{ minWidth: '32px' }}>
          <EditIcon />
        </ListItemIcon>
        <span className="mr-8">View / Edit</span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          handleDeleteCustomer(customerInfo);
        }}
      >
        <ListItemIcon style={{ minWidth: '32px' }}>
          <DeleteIcon />
        </ListItemIcon>
        <span className="mr-8">Delete</span>
      </MenuItem>
    </Menu>
  );
};
export default MenuListComponent;
