/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Modal } from '@material-ui/core';
import styles from '../pitch-quality-comment-modal/notification-modal.module.css';
import  DisconnectionIcon  from "../../public/icons/disconnection.svg";

const GoogleCalendarPopup = ({ onClose, open }) => {
  const router = useRouter()
  const [checked, setChecked] = useState<boolean>(false);
 
  const handleClose = () => {
    if (checked) {
      localStorage.setItem('ignoreGoogleCalendarPopupModal', 'true');
    }
    onClose();
  };

  const handleGoogleConnect = async () => {
    router.push("/workspace?selected=sync_calendar_tab");
    onClose();
  };

  useEffect(() => {
    const shouldShowModal = localStorage.getItem('ignoreGoogleCalendarPopupModal') !== 'true';
    if (!shouldShowModal) {
      handleClose();
    }
  }, []);

  return (
    <Modal
      className={styles['modal']}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      <div className={styles['inner-container']} >
        <div className={styles['dialog-content']}>
            <div className="flex flex-col items-center justify-center w-full max-w-lg ">
            <div className="flex items-center mb-4">
              <div className="full flex items-center justify-center">
                    <DisconnectionIcon />
              </div>
            </div>
              <h2 className="text-xl font-semibold mb-2 font-manrope text-center whitespace-nowrap overflow-hidden text-ellipsis">
                Your calendar is disconnected
              </h2>
              <div className="w-full max-w-md">
                <p className="text-sm lg:text-base font-light mb-4 font-manrope text-center text-primary-dark leading-normal">
                  Please connect your calendar immediately to ensure your meeting scheduling is working properly.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row justify-between sm:justify-center items-center w-full max-w-sm space-y-2 sm:space-y-0 sm:space-x-2 mt-2">
                <button
                  type="button"
                  className="w-full sm:w-42 px-4 py-2 h-[40px] rounded-md bg-secondary-light text-secondary sm:mr-2 text-[#31667F] text-sm"
                  onClick={handleClose}
                >
                  Do it Later
                </button>
                <button
                  type="button"
                  className="w-full sm:w-42 px-4 py-2 h-[40px] rounded-md bg-secondary text-background-white text-sm"
                  onClick={handleGoogleConnect}
                >
                  Connect Calendar Now
                </button>
              </div>
            </div>
          <div className="mt-[18px] flex justify-end">
            <label
              htmlFor="dont-show"
              className="font-medium text-sm flex items-center text-[#000]"
            >
              <input
                name="dont-show"
                type="checkbox"
                className="outline-secondary border-tag-text-three block px-2 mr-3 text-secondary border rounded-sm appearance-none cursor-pointer"
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              Don’t show me again
            </label>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GoogleCalendarPopup;
