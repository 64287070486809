/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import {
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '../ui/sheets';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '../ui/accordian';
import { Button } from '../ui/button';
import { IMeetingSource } from '../TableComponent/Table.component';
import MeetingSources from './meeting-sources';
import QualificationQuestionsAccordian from './qualification-question';

type ConversationsFilterProps = {
  isMobile: boolean;
  meetingSources: IMeetingSource[];
  selectedMeetingSourcesLength: number;
  setSelectedMeetingSources: (id: number) => void;
  qualificationQuestions: { [key: string]: any }[];
  selectedQualificationQuestionsLength: number;
  setSelectedQualificationQuestions: (qId: string, value: string | number | boolean | string[], type: string) => void
  handleFiltersApply: () => void;
  handleFiltersReset: () => void;
};

const ConversationsFilter = (props: ConversationsFilterProps) => {
  const {
    isMobile,
    meetingSources,
    selectedMeetingSourcesLength,
    setSelectedMeetingSources,
    qualificationQuestions,
    selectedQualificationQuestionsLength,
    setSelectedQualificationQuestions,
    handleFiltersApply,
    handleFiltersReset
  } = props;
  const [accordianOpen, setAccordianOpen] = useState<string>('');
  
  const getAccordianName = item => {
    switch (item) {
      case 'meeting_sources':
        return 'Meeting Sources';
      case 'qualification_questions':
        return 'Qualification Questions';
      default:
        return '';
    }
  };

  const getAccordianContent = item => {
    switch (item) {
      case 'meeting_sources':
        return (
          <MeetingSources
            meetingSources={meetingSources}
            handleCheck={handleCheck}
          />
        )
      case 'qualification_questions':
        return (
          <QualificationQuestionsAccordian
            qualificationQuestions={qualificationQuestions}
            handleQuestionFilterUpdate={handleQuestionFilterUpdate}
          />
        );
      default:
        return '';
    }
  };

  const getFilteredNumber = (item: string) => {
    let filterAmtLength: any = ''
    switch (item) {
      case 'meeting_sources':
        filterAmtLength = selectedMeetingSourcesLength;
        break;
      case 'qualification_questions':
        filterAmtLength = selectedQualificationQuestionsLength;
        break;
      default:
        filterAmtLength = '';
        break;
    }
    if (!filterAmtLength) return <></>
    return (
      <>
        {Boolean(filterAmtLength) && (
          <div className="w-4 h-4 bg-secondary/20 rounded-full flex items-center justify-center">
            <div className="w-3 h-3 bg-secondary text-white text-[8px] font-bold rounded-full flex items-center justify-center">
              {filterAmtLength}
            </div>
          </div>
        )}
      </>
    )
  }

  const handleAccordianItemClick = item => {
    setAccordianOpen(accordianOpen === item ? '' : item);
  };

  const handleCheck = (id: number) => {
    setSelectedMeetingSources(id);
  };

  const handleQuestionFilterUpdate = (qId: string, value: string | number | boolean | string[], type: string) => {
    setSelectedQualificationQuestions(qId, value, type);
  }

  return (
    <SheetContent 
      side={isMobile ? 'bottom' : 'right'} 
      className={`bg-white ${isMobile ? 'top-[100px] bottom-10' : 'top-[192px] bottom-3'} h-auto p-0 flex flex-col gap-0`}
    >
      <SheetHeader className="bg-secondary px-5 py-4">
        <SheetTitle className="text-white font-bold text-base">
          Filters
        </SheetTitle>
      </SheetHeader>
      <div className={`p-4 ${isMobile ? 'h-auto min-h-[500px]' : 'h-full'} overflow-y-auto`}>
        <Accordion type="single" collapsible className="w-full [&>div]:mb-2">
          {['qualification_questions', 'meeting_sources'].map((item, idx) => (
            <AccordionItem
              value={item}
              key={`${item}${idx}`}
              className={`border ${
                accordianOpen === item ? 'border-secondary' : 'border-[#d7d7d7]'
              } rounded-lg`}
            >
              <AccordionTrigger
                className="text-sm font-bold px-4 py-4 hover:no-underline"
                onClick={() => handleAccordianItemClick(item)}
              >
                <div className="w-full flex justify-between pr-1.5">
                  {getAccordianName(item)}
                  {getFilteredNumber(item)}                  
                </div>
              </AccordionTrigger>
              <AccordionContent
                className={`h-full max-h-[280px] ${
                  item === 'qualification_questions' ? 'px-0' : 'px-4'
                } overflow-y-auto [&>div:last-child]:pb-0`}
              >
                {getAccordianContent(item)}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <SheetFooter className="py-4 mx-auto flex flex-row gap-4">
        <SheetClose asChild>
          <Button 
            className="bg-secondary/20 hover:bg-secondary/20 text-secondary hover:text-secondary rounded-lg text-xs font-bold w-[140px]"
            onClick={handleFiltersReset}
          >
            Reset
          </Button>
        </SheetClose>
        <SheetClose asChild>
          <Button 
            className="bg-secondary hover:bg-secondary text-white hover:text-white rounded-lg text-xs font-bold w-[140px]"
            onClick={handleFiltersApply}
          >
            Apply
          </Button>
        </SheetClose>
      </SheetFooter>
    </SheetContent>
  );
};

export default ConversationsFilter;
