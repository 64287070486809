/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import {
  Tooltip,
  ClickAwayListener,
  makeStyles,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';

import ArrowTopRightIcon from '../../public/icons/arrow-top-right.svg';
import ArrowBottomRightIcon from '../../public/icons/arrow-bottom-right.svg';
import InfoIcon from '../../public/icons/info-icon.svg';

type Props = {
  pitchAuditScore: number;
  pitchAuditGrowth: number;
};

const useStyles = makeStyles({
  touch: {
    fontSize: '0.75rem',
  },
});

const PitchQualityMetrics = (props: Props) => {
  const classes = useStyles();
  const { pitchAuditGrowth, pitchAuditScore } = props
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const growthIcon = () => {
    if ((pitchAuditGrowth || 0) > 0) {
      return (
        <ArrowTopRightIcon className="w-2.5 h-2.5 text-positive-green fill-current mr-1.5" />
      );
    } else if ((pitchAuditGrowth || 0) < 0) {
      return (
        <ArrowBottomRightIcon className="w-2.5 h-2.5 text-negative-red fill-current mr-1.5" />
      );
    } else {
      return <span className="w-2.5 h-2.5 mr-1.5 font-bold">~</span>;
    }
  };

  const growthClassName = () => {
    if ((pitchAuditGrowth || 0) > 0)
      return 'text-positive-green mr-2';
    else if ((pitchAuditGrowth || 0) < 0)
      return 'text-negative-red mr-2';
    else return 'text-primary-dark mr-2';
  };

  const handleClick = () => {
    if (showTooltip) {
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }
  };

  return (
    <div className="relative flex flex-col px-4 py-1 bg-background-white w-full max-w-xs min-w-[280px] lg:min-w-max rounded">
      <p className="text-sm leading-5 font-bold mb-1 text-primary-grey">
        Call Quality Score
      </p>
      <p className="text-sm leading-5 font-normal text-primary-dark mb-1">
        <span className="text-xl font-bold">
          {pitchAuditScore && !Number.isNaN(Number(pitchAuditScore)) ? `${Math.round(Number(pitchAuditScore))}%` : '-'}
        </span>
      </p>
      {/* <p className="text-xs leading-4 font-normal text-secondary flex items-baseline;">
        {growthIcon()}
        <span className={growthClassName()}>
          {Math.abs(pitchAuditGrowth || 0)?.toFixed(1)}%
        </span>{' '}
        Since last week
      </p> */}
      <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
        <div
          className="inline"
          onMouseOver={
            isMobile
              ? () => {}
              : () => {
                  setShowTooltip(true);
                }
          }
          onMouseLeave={
            isMobile
              ? () => {}
              : () => {
                  setShowTooltip(false);
                }
          }
        >
          <Tooltip
            title="Call Quality Score is the avg. score on parameters set for call quality standards."
            placement="bottom-end"
            arrow
            open={showTooltip}
            classes={{
              touch: classes.touch,
            }}
            leaveDelay={1000}
            leaveTouchDelay={1000}
          >
            <button
              type='button'
              className="absolute top-1 right-1 h-7 lg:h-5 w-7 lg:w-5 p-0 bg-transparent rounded-full cursor-pointer"
              onClick={handleClick}
            >
              <InfoIcon className="w-4 h-4 text-primary-grey fill-current cursor-pointer" />
            </button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default PitchQualityMetrics;
