/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable import/extensions */
import { NextRouter, withRouter } from 'next/router';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import UserService from '../../services/user/user.service';
import { ROUTES } from '../../utils/constants';
import {
  BRANDING,
  SET_IDENTITIES,
  SET_IDP,
  SHOW_SNACKBAR,
  SET_SELECTED_WORKSPACE,
  SET_WORKSPACES,
  SET_USER_TYPE,
  UPDATE_META,
  REALTIME_WIDGET_ENABLED,
  SAVE_USER,
  REALTIME_WIDGET_AVAILABILITY,
  IS_ADMIN,
  METABASE_DASHBOARDS,
  IS_SUPER_ADMIN,
  IS_MANAGER,
} from '../../store/types';
import { getDeviceTimeZone } from '../../utils/helper';
import { getWorkspaces } from '../../handlers/handlers';
import OrganisationService from '../../services/organisation/organisation.service';

interface Options {
  redirectPath?: string;
  onlyAdmin?: boolean;
}

interface AuthenticatedRouteState {
  isLoading: boolean;
  isProcessing: boolean;
}

interface AuthenticatedRouteProps {
  router: NextRouter;
  identities: Array<Object> | [];
  selectedWorkspace: Array<{[key:string] : any}> | [];
  isLoggedIn: boolean;
  userType: string;
  user: {[key:string] : unknown};
  mtDasboards: {[key: string]: any}[];
  isManagerLoaded: boolean;
  isManager: boolean;
  dispatch: Dispatch;
}

const withAuthenticator = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  options: Options,
) => {
  class AuthenticatedRoute extends React.Component<
    P & AuthenticatedRouteProps,
    AuthenticatedRouteState
  > {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        isProcessing: false,
      };
    }

    componentDidMount() {
      const { router } = this.props;
      this.routeChangeComplete();
      router.events.on('routeChangeComplete', this.routeChangeComplete);
    }

    componentWillUnmount() {
      const { router } = this.props;
      router.events.off('routeChangeComplete', this.routeChangeComplete);
    }

    fetchIdentities = async (id: number) => {
      const { dispatch } = this.props;
      try {
        const response: any = await UserService.getIdentities(id);
        const { data } = response?.data;
        if (data?.length) {
          dispatch({
            type: SET_IDENTITIES,
            payload: data,
          });
          const result = data.reduce((fi, se) =>
            new Date(se.created_at) < new Date(fi.created_at) ? fi : se,
          );
          dispatch({
            type: SET_IDP,
            payload: result?.idp || '',
          });
          localStorage.setItem('identities', JSON.stringify(data));
        }
      } catch (err) {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: 'error',
            message:
              err.response?.data.message ||
              'There was some issue retrieving information',
          },
        });
      }
    };

    fetchWorkspacesData = async (id: number, userdata) => {
      const { dispatch } = this.props;
      try {
        const data = await getWorkspaces(id);
        let isSuperAdmin = false;
        let wpPrefId = null;
        // incase of superadmin checking if localstorage has selected wp, if not then preference id is used
        if (userdata?.userMapWorkspace && userdata?.userMapWorkspace?.length) {
          isSuperAdmin = userdata?.userMapWorkspace.some(map => map.is_superadmin);
        }
        const selectedWpFromStorage = window && window.localStorage && JSON.parse(localStorage.getItem('selected_workspace'));
        if (isSuperAdmin && selectedWpFromStorage && selectedWpFromStorage.length) {
          wpPrefId = Number(selectedWpFromStorage?.[0]?.id);
        } else {
          wpPrefId = userdata?.workspace_preference_id;
        }
        const selected_workspace_id = wpPrefId;
        const selected_workspace = data.filter((item)=>item?.id === selected_workspace_id);

        if (data?.length) {
          const userType = data?.[0]?.subscription_tier || '';
          dispatch({
            type: SET_WORKSPACES,
            payload: data,
          });

          localStorage.setItem('workspaces_data', JSON.stringify(data));
          dispatch({
            type: SET_USER_TYPE,
            payload: userType,
          });

          if(selected_workspace && selected_workspace?.length > 0){
            localStorage?.setItem(
              'selected_workspace',
              JSON.stringify([selected_workspace?.[0]]),
            );
            dispatch({
              type: SET_SELECTED_WORKSPACE,
              payload: [selected_workspace?.[0]],
            });
            if (!isSuperAdmin) {
              await UserService.setPreferredWorkspace(selected_workspace?.[0]?.id);
              dispatch({
                type: SAVE_USER,
                payload: {
                  ...userdata,
                  workspace_preference_id: selected_workspace?.[0]?.id
                }
              });
            }
          }
        }
        return selected_workspace?.[0]?.id || false;
      } catch (err) {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: 'error',
            message:
              err.response?.data.message ||
              'There was some issue retrieving information',
          },
        });
        return false;
      }
    };

    checkWorkspaceAdmin = async (userId, orgId, workspaceId) => {
      const { dispatch } = this.props;
      let isUserAdmin = false;
      try {
        const response = await OrganisationService.getWorkspaceAdmins(
          orgId,
          workspaceId,
        );
        const workspace_admins = response?.data?.data;
        workspace_admins.forEach(entry => {
          if (entry['id'] === userId) {
            isUserAdmin = true;
            dispatch({ type: IS_ADMIN, payload: true });
            if (entry['super_admin']) {              
              dispatch({ type: IS_SUPER_ADMIN, payload: true });
            }
          }
        });
        return isUserAdmin;
      } catch (err) {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: 'error',
            message:
              err.response?.data.message ||
              'There was some issue retrieving information',
          },
        });
        return false;
      }
    };

    checkUserIsManager = async (userId) => {
      const { dispatch, isManagerLoaded, isManager: reduxIsManager } = this.props;
      let isManager = false;
      try {
        if (isManagerLoaded) return reduxIsManager;
        const response = await OrganisationService.getAllManagers();
        const managers = response?.data?.data;
        managers.forEach(entry => {
          if (entry['manager_id'] === userId) {
            isManager = true;
            dispatch({ type: IS_MANAGER, payload: true });
          }
        });
        return isManager;
      } catch (err) {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: 'error',
            message:
              err.response?.data.message ||
              'There was some issue retrieving information',
          },
        });
        return false;
      }
    };

    handleUserRole = async (userData, id) => {
      const { dispatch } = this.props
      let isUserAdmin = false;
      if (userData?.userMapWorkspace && userData?.userMapWorkspace?.length && userData?.workspace_preference_id) {
        const wpData = userData?.userMapWorkspace.filter(wp => wp?.workspace_id === userData?.workspace_preference_id);
        if (wpData?.length && wpData?.[0]?.role === 'admin') {
          isUserAdmin = true;
          dispatch({ type: IS_ADMIN, payload: true });
          if (wpData?.[0]?.is_superadmin) {
            dispatch({ type: IS_SUPER_ADMIN, payload: true });
          }
        }
      } else {
        isUserAdmin = await this.checkWorkspaceAdmin(userData?.id, userData?.organisation?.id, id);
      }
      return isUserAdmin;
    }

    routeChangeComplete = async () => {
      const { router, identities, selectedWorkspace, user, dispatch, mtDasboards } = this.props;
      const { isProcessing } = this.state; 
      if (isProcessing) return;
      try {
        this.setState({ isProcessing: true });
        let userData = null;
        if (user && Object.keys(user).length) {
          userData = { ...user };
        } else {
          const response = await UserService.me();
          userData = response?.data?.data;
        }
        let isUserAdmin = false;
        let metabaseDahboards = [];
        if (Object.keys(userData).length !== 0) {
          this.saveUser(userData);
          this.setBranding(userData?.organisation);
          const isManager = await this.checkUserIsManager(userData?.id);
          if (!selectedWorkspace?.length) {
            this.fetchWorkspacesData(
              userData?.organisation?.id || 0,
              userData
            );
            let isSuperAdmin = false;
            let wpPrefId = null;
            // incase of superadmin checking if localstorage has selected wp, if not then preference id is used
            if (userData?.userMapWorkspace && userData?.userMapWorkspace?.length) {
              isSuperAdmin = userData?.userMapWorkspace.some(map => map.is_superadmin);
            }
            const selectedWpFromStorage = window && window.localStorage && JSON.parse(localStorage.getItem('selected_workspace'));
            if (isSuperAdmin && selectedWpFromStorage && selectedWpFromStorage.length) {
              wpPrefId = Number(selectedWpFromStorage?.[0]?.id);
            } else {
              wpPrefId = userData?.workspace_preference_id;
            }
            isUserAdmin = await this.handleUserRole(userData, wpPrefId);
            if(wpPrefId && typeof wpPrefId === "number" && (isUserAdmin || isManager)){
              const managerEmail = !isUserAdmin && isManager ? userData.email : '';
              OrganisationService.getMetabaseDashboard(wpPrefId, managerEmail)
                .then((response) => {
                  metabaseDahboards = response?.data?.data;
                  dispatch({
                    type: METABASE_DASHBOARDS,
                    payload: metabaseDahboards,
                  });
                }).catch((err) => {
                  console.log(err);
                });
            }
          } else {
            if (!(mtDasboards && mtDasboards?.length)) {
              let isSuperAdmin = false;
              let wpPrefId = null;
              // incase of superadmin checking if localstorage has selected wp, if not then preference id is used
              if (userData?.userMapWorkspace && userData?.userMapWorkspace?.length) {
                isSuperAdmin = userData?.userMapWorkspace.some(map => map.is_superadmin);
              }
              const selectedWpFromStorage = window && window.localStorage && JSON.parse(localStorage.getItem('selected_workspace'));
              if (isSuperAdmin && selectedWpFromStorage && selectedWpFromStorage.length) {
                wpPrefId = Number(selectedWpFromStorage?.[0]?.id);
              } else {
                wpPrefId = userData?.workspace_preference_id;
              }
              if(wpPrefId && typeof wpPrefId === "number" && (isUserAdmin || isManager)){
                const managerEmail = !isUserAdmin && isManager ? userData.email : '';
                OrganisationService.getMetabaseDashboard(wpPrefId, managerEmail)
                  .then((response) => {
                    metabaseDahboards = response?.data?.data;
                    dispatch({
                      type: METABASE_DASHBOARDS,
                      payload: metabaseDahboards,
                    });
                  }).catch((err) => {
                    console.log(err);
                  });
              }
            } else {
              metabaseDahboards = [...mtDasboards];
            }
            isUserAdmin = await this.handleUserRole(userData, selectedWorkspace?.[0].id!);
          }
          this.setInsightsModal(userData?.meta || {});
          this.setRealtimeWidget(userData?.organisation);
          this.setRealtimeWidgetAvailability(userData);
          if (!identities?.length) {
            await this.fetchIdentities(userData?.id || 0);
          }
          localStorage.setItem('user_data', JSON.stringify(userData));
          // this.updateTimeZone(data);
          if (this.cannotAccess(userData, isUserAdmin, isManager, metabaseDahboards)) {
            router.replace(`/${ROUTES.DASHBOARD}`);
          } else if (router.pathname.startsWith('/auth')) {
            router.replace(`/${ROUTES.DASHBOARD}`);
          } else {
            this.setState({ isLoading: false });
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (router.pathname.startsWith('/auth')) {
            this.setState({ isLoading: false });
          } else {
            const url = new URL(decodeURIComponent(window.location.href));
            const searchState = url?.searchParams?.get('state') || '';
            router.push({
              pathname:
                options?.redirectPath ||
                `/auth/${
                  searchState?.includes('action=ziplanding')
                    ? ROUTES.AUTH_TYPES.SIGN_UP
                    : ROUTES.AUTH_TYPES.LOGIN
                }`,
              query: { rd_path: router.asPath },
            });
          }
        }
      } catch (err) {
        if (router.pathname.startsWith('/auth')) {
          this.setState({ isLoading: false });
        } else {
          const url = new URL(decodeURIComponent(window.location.href));
          const searchState = url?.searchParams?.get('state') || '';
          router.push({
            pathname:
              options?.redirectPath ||
              `/auth/${
                searchState?.includes('action=ziplanding')
                  ? ROUTES.AUTH_TYPES.SIGN_UP
                  : ROUTES.AUTH_TYPES.LOGIN
              }`,
            query: { rd_path: router.asPath },
          });
        }
      } finally {
        this.setState({ isProcessing: false });
      }
    };

    cannotAccess = (user, isAdmin: boolean, isManager: boolean, metabaseDahboards: any[]) => {
      const { router, userType } = this.props;
      const pathName = router.pathname;
      const asPath = router?.asPath;
      if (options?.onlyAdmin) {
        if (pathName.startsWith('/insights')) {
          return (
            userType === 'free_tier' &&
            !user?.meta?.insights_unlocked
          )  
        }
        if (asPath.includes('/reports') && (isManager || isAdmin) && metabaseDahboards && metabaseDahboards?.length) {
          return false;
        }
        if (asPath.includes('/admin') && (isManager || isAdmin)) {
          return false;
        }
        if (asPath.startsWith('/dashboard') && !asPath.includes('/admin')) {
          return false;
        }
        return (options?.onlyAdmin && !isAdmin)
      }
      return (
        userType === 'free_tier' &&
        !user?.meta?.insights_unlocked &&
        router.pathname.startsWith('/insights')
      );
    };

    saveUser = data => {
      const { dispatch } = this.props;
      dispatch({
        type: SAVE_USER,
        payload: { ...data },
      });
    };

    setBranding = organisation => {
      const { dispatch } = this.props;
      dispatch({
        type: BRANDING,
        payload: { headerText: organisation.name },
      });
    };

    setInsightsModal = meta => {
      const { dispatch } = this.props;
      const { insights_unlocked = false, insights_unlocked_viewed = false } =
        meta;
      dispatch({
        type: UPDATE_META,
        payload: { insights_unlocked, insights_unlocked_viewed },
      });
    };

    updateTimeZone = async user => {
      if (!user) return;

      const timezone = getDeviceTimeZone();
      if (user.timezone === timezone) return;

      await UserService.update(Number(user.id), { timezone });
    };

    setRealtimeWidget = organisation => {
      const { dispatch } = this.props;
      dispatch({
        type: REALTIME_WIDGET_ENABLED,
        payload: {
          enabled: organisation.feature_flags?.realtime_widget_enabled,
        },
      });
    };

    setRealtimeWidgetAvailability = user => {
      if (user?.meta?.realtime_meeting_availaibility) {
        const { dispatch } = this.props;
        dispatch({
          type: REALTIME_WIDGET_AVAILABILITY,
          payload: {
            availability: user.meta.realtime_meeting_availaibility,
          },
        });
      }
    };

    render() {
      const { isLoading = true } = this.state;
      const { ...props } = this.props;

      return isLoading ? <div /> : <WrappedComponent {...(props as P)} />;
    }
  }

  const mapStateToProps = state => ({
    branding: state.common.branding,
    identities: state.common.identities,
    isLoggedIn: state.common.isLoggedIn,
    selectedWorkspace: state.common.selectedWorkspace,
    user: state.common.user,
    userType: state.common.userType,
    mtDasboards: state.common.metabaseDashboards,
    isManagerLoaded: state.common.isManagerLoaded,
    isManager: state.common.isManager
  });

  return connect(mapStateToProps)(withRouter(AuthenticatedRoute));
};

export default withAuthenticator;
