/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { IMeetingSource } from '../TableComponent/Table.component';

type MeetingSourcesProps = {
  meetingSources: IMeetingSource[];
  handleCheck: (id: number) => void
};

const MeetingSources = (props: MeetingSourcesProps) => {
  const { meetingSources, handleCheck } = props
  return (
    <div>
      {meetingSources &&
        meetingSources.map((source, idx1) => (
          <div
            key={idx1}
            className="flex justify-start py-2 cursor-pointer"
            onClick={() => {
              handleCheck(source?.id);
            }}
          >
            <input
              type="checkbox"
              checked={source?.checked}
              className="form-checkbox h-3.5 w-3.5 lg:h-4 lg:w-4 text-secondary focus:ring-transparent cursor-pointer rounded-sm border-secondary"
              name={source?.label}
              value={source?.label}
              id={source?.label}
            />
            <h4 className="text-primary-dark text-xs lg:text-sm font-medium leading-4 mx-2">
              {source?.label}
            </h4>
          </div>
        ))}
    </div>
  );
};

export default MeetingSources;
