import { IconButton, Modal } from '@material-ui/core';
import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import styles from './next-meeting-schedule-modal.module.css';
import CrossIcon from '../../public/icons/cross-icon.svg';
import CalendarIcon from '../../public/icons/calendar.svg';
import { MEET_BASE_URL } from '../../utils/constants';
import ScheduleNextMeetingMenu from '../ScheduleMeetingMenu/next-meeting-schedule-menu.component';
import VideoIcon from '../../public/icons/video-icon.svg';
import KebabIcon from '../../public/icons/kebab-icon.svg';
import NextMeetingScheduleModalIcon from '../../public/icons/next-meeting-schedule-modal.svg';
import { ICustomers } from '../../pages/dashboard/[[...index]]';

interface INextMeetingScheduleModalProps {
  open: boolean;
  handleJoinMeeting: Function;
  schedule:
    | {
        id: number;
        scheduled_at: string;
        passcode: number;
        room_id: string;
        meet_url?: string;
      }[]
    | undefined;
  customerName: string;
  contactName: string;
  selectedModalIndex: number;
  cust_id: number;
  handleSetNextMeetingDetails: Function;
  getFormattedDate: Function;
  convertToUserTimezone: Function;
  handleMenuClose: Function;
  handleDateModalOpen: Function;
  handleCancelMeeting: Function;
  customer: ICustomers;
  handleNextMeetingShareModalOpen: Function;
}

const NextMeetingScheduleModal: React.FC<INextMeetingScheduleModalProps> =
  props => {
    const {
      open,
      handleJoinMeeting,
      schedule,
      customerName,
      contactName,
      selectedModalIndex,
      cust_id,
      handleSetNextMeetingDetails,
      getFormattedDate,
      convertToUserTimezone,
      handleMenuClose,
      handleDateModalOpen,
      handleCancelMeeting,
      customer,
      handleNextMeetingShareModalOpen,
    } = props;

    const [
      nextConvoScheduleOptionButtonEl,
      setNextConvoScheduleOptionButtonEl,
    ] = useState<any>(null);

    if (selectedModalIndex !== cust_id) {
      return null;
    }

    // todo - need to refactor
    const handleVideoConnect = (item) => {
      if(item?.meet_url){
        window.open(item?.meet_url, '_blank').focus();
      } else {
        handleJoinMeeting(
          `${MEET_BASE_URL}/${item?.room_id}?passcode=${item?.passcode}`,
        )
      }
    }

    return (
      // <ClickAwayListener onClickAway={()=>setNextConvoScheduleOptionButtonEl(null)}>

      <Modal
        className={styles['modal']}
        open={open}
        onClose={(event, reason) =>
          reason !== 'backdropClick' ? handleMenuClose() : f => f
        }
      >
        <div className={styles['inner-container']}>
          <div className={styles['dialog-content']}>
            <div className={styles['close-button']}>
              <IconButton
                onClick={() => {
                  handleMenuClose();
                }}
              >
                <CrossIcon className="w-3 h-3 lg:w-4 lg:h-4 text-primary-dark fill-current" />
              </IconButton>
            </div>

            <div className={styles['description']}>
              <div className="absolute top-0 left-2">
                <IconButton>
                  <NextMeetingScheduleModalIcon />
                </IconButton>
              </div>
              <div className='pl-8'>
                <h4 className="text-primary-dark font-bold text-xl">
                  Upcoming Conversations
                </h4>
                <h5 className="text-primary-dark font-bold text-base w-72 mx-auto">
                  {contactName} - {customerName}
                </h5>
              </div>
              <div className="mt-4 overflow-y-auto pr-4" style={{maxHeight:'70vh'}}>
                {schedule &&
                  schedule?.map(item => (
                    <div key={item.id} className="flex justify-between mt-6">
                      <div className="flex align-middle">
                        <CalendarIcon className=" cursor-pointer text-icon-gray stroke-current mt-0.5" />
                        <div className="font-medium mx-2">
                          {item?.scheduled_at &&
                            getFormattedDate(item?.scheduled_at)}{' '}
                        </div>
                        <div className="font-extralight text-center text-gray-400">
                          |
                        </div>
                        <div className="ml-2 font-medium">
                          {format(parseISO(convertToUserTimezone(item?.scheduled_at)), 'hh:mm a')}
                        </div>
                      </div>
                      <div className="flex">
                        <button
                          className=" min-w-0 w-5 h-5 pl-1 bg-secondary rounded-full my-auto"
                          type="button"
                          onClick={() =>
                            handleVideoConnect(item)
                          }
                        >
                          <VideoIcon className="text-white fill-current w-3 h-3" />
                        </button>
                        <div className="relative">
                          <IconButton
                            style={{ padding: '4px' }}
                            color="primary"
                            onClick={event => {
                              const roomDetails = `${MEET_BASE_URL}/${item?.room_id}?passcode=${item?.passcode}`;
                              handleSetNextMeetingDetails(
                                item?.scheduled_at,
                                roomDetails,
                                item?.passcode,
                                item?.room_id,
                              );
                              setNextConvoScheduleOptionButtonEl(
                                event?.currentTarget,
                              );
                            }}
                            type="button"
                          >
                            <KebabIcon className="h-4 text-primary-grey fill-current ml-4" />
                          </IconButton>

                          <ScheduleNextMeetingMenu
                            el={nextConvoScheduleOptionButtonEl}
                            handleShareMenuClose={() => {
                              setNextConvoScheduleOptionButtonEl(null);
                            }}
                            handleDateModalOpen={handleDateModalOpen}
                            handleCancelMeeting={handleCancelMeeting}
                            customerInfo={customer}
                            handleNextMeetingShareModalOpen={
                              handleNextMeetingShareModalOpen
                            }
                            handleScheduleModalClose={handleMenuClose}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      // </ClickAwayListener>
    );
  };

export default NextMeetingScheduleModal;
