var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"n_3FRdr1Abm120v3_Rd3p"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SAMPLING_RATE = process.env.NEXT_PUBLIC_SENTRY_SAMPLING_RATE;
const ENVIROMENT = process.env.NEXT_PUBLIC_APP_ENV;
const RELEASE = process.env.npm_package_version;

if (ENVIROMENT === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: Number(SAMPLING_RATE),
    environment: ENVIROMENT,
    release: RELEASE,
    // Session Replay
    // This sets the sample rate at 10%.
    // You may want to change it to 100% while in development
    // and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.0,
    // If you're not already sampling the entire session,
    // change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 0.2,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.'
    ]
  });
}